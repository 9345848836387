import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Extra } from './extra';
import { QrCode } from './qrcode';

export interface _ExtraQrCode {
    status: string;
    extra: number;
    qrcode: number;
};

interface _ExtraQrCodeData extends _ExtraQrCode {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ExtraQrCodeData extends DataObject {
    protected _extraqrcode: _ExtraQrCodeData = {
        status: null,
        extra: null,
        qrcode: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._extraqrcode.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._extraqrcode.created;
    }

    get status(): string {
        return this._extraqrcode.status;
    }

    set status(value: string) {
        if(this.patchValue(this._extraqrcode, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get extra() : Extra {
        return this._children['extra'] || null;
    }

    set extra(value: Extra) {
        if (this.SetChild('extra', value, 'extra')){
            this.ToUpdate = true;
        }
    }

    get qrcode() : QrCode {
        return this._children['qrcode'] || null;
    }

    set qrcode(value: QrCode) {
        if (this.SetChild('qrcode', value, 'qrcode')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._extraqrcode.status,
            extra: this._extraqrcode.extra,
            qrcode: this._extraqrcode.qrcode
        };
    }

    protected get Depend() {
        return {
            extra: { item: this.extra, relation_info: { to: 'tables', by: 'extra' } },  // this[by -> 'extra'][to -> 'tables'] => this
            qrcode: { item: this.qrcode, relation_info: { to: null, by: 'qrcode' } }    // no relation to this in this[by -> 'qrcode']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_extraqrcode: _ExtraQrCode){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._extraqrcode, 'status', _extraqrcode['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._extraqrcode, 'extra', _extraqrcode['extra']) || _toUpdate;
        _toUpdate = this.patchValue(this._extraqrcode, 'qrcode', _extraqrcode['qrcode']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_extraqrcode: _ExtraQrCode){
        this.patchValue(this._extraqrcode, 'created', _extraqrcode['created']);
        
        if (this._patchData(_extraqrcode)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._extraqrcode;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_extraqrcode: _ExtraQrCode){
        this._patchData(_extraqrcode);

        if (_extraqrcode['extra']){     // update children: 'extra'
            let _objid = _extraqrcode['extra'].toString();
            this.SetChild('extra', new Extra(_objid, this.data, this._objoptions), 'extra')
        }
        else {
            this.SetChild('extra', null, 'extra')
        }

        if (_extraqrcode['qrcode']){    // update children: 'table'
            let _objid = _extraqrcode['qrcode'].toString();
            this.SetChild('qrcode', new QrCode(_objid, this.data, this._objoptions), 'qrcode')
        }
        else {
            this.SetChild('qrcode', null, 'qrcode')
        }
    }

    private _ddbb(info): _ExtraQrCodeData {
        let _extraqrcode: _ExtraQrCodeData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            extra: info['extra'] ? parseInt(info['extra']) : null,
            qrcode: info['qrcode'] ? parseInt(info['qrcode']) : null
        };
        return _extraqrcode;
    }

    protected _OnUpdate(info){
        let _extraqrcode = this._ddbb(info);
        this.patchValue(this._extraqrcode, 'objid', _extraqrcode['objid']);
        this.patchValue(this._extraqrcode, 'created', _extraqrcode['created']);
        this.DoPatchValues(_extraqrcode);
    }
}

export class ExtraQrCode extends ExtraQrCodeData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('EXTRATABLE', objid, data, objoptions);
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []): ExtraQrCode {
        return this._Copy(store) as ExtraQrCode;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if ((this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.extra.DelTable(this);
            }            
            this.extra.ToUpdate = true;
        }                

        super.status = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE') && this.qrcode.IsValid;
    }    
}


