import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';

export interface _PlaceOption {
    place: number;
    opkey: string;
    value: string;
};

interface _PlaceOptionData extends _PlaceOption {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class PlaceOptionData extends DataObject {
    protected _option: _PlaceOptionData = {
        place: null,
        opkey: null,
        value: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);

        this._option.created = new Date();
        this._option.updated = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._option.created;
    }

    get updated(){
        return this._option.updated;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get opkey(): string {
        return this._option.opkey;
    }

    set opkey(value: string) {
        if (this.patchValue(this._option, 'opkey', value)){
            this.ToUpdate = true;
        }
    }

    get value(): string {
        return this._option.value;
    }

    set value(value: string) {
        if (this.patchValue(this._option, 'value', value)){
            this.ToUpdate = true;
        }
    }

    get eval(){
        return (this._option.value == '1');
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._option.place,
            opkey: this._option.opkey,
            value: this._option.value
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'options', by: 'place' } }      // this[by -> 'place'][to -> 'options'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_option: _PlaceOption){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._option, 'place', _option['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'opkey', _option['opkey']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'value', _option['value']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_option: _PlaceOption){
        this.patchValue(this._option, 'created', _option['created']);
        this.patchValue(this._option, 'updated', _option['updated']);
        
        if (this._patchData(_option)){
            this.ToUpdate = true;
        }
   }

    get Info(){
        return this._option;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_option: _PlaceOption){
        this._patchData(_option);

        if(_option['place']){      // update children: 'place'
            let _objid = _option['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        } 
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _PlaceOptionData {
        let _option: _PlaceOptionData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            place: info['place'] ? parseInt(info['place']) : null,
            opkey: info['opkey'],
            value: info['value']
        };
        return _option;
    }

    protected _OnUpdate(info){
        let _option = this._ddbb(info);
        this.patchValue(this._option, 'objid', _option['objid']);
        this.patchValue(this._option, 'created', _option['created']);
        this.patchValue(this._option, 'updated', _option['updated']);
        this.DoPatchValues(_option);
    }
}

export class PlaceOption extends PlaceOptionData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PLACEOPT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): PlaceOption {
        return this._Copy(store) as PlaceOption;
    }

    /****************************/
    /* KIOSK MODE CONTROL       */
    /****************************/

    private get _isViewThemeOption(){
        return (this.opkey == 'optionViewTheme');
    }

    private get _isZoomLevelOption(){
        return (this.opkey == 'optionZoomLevel');
    }

    private get _isKioskOption(){
        return (this.opkey == 'optionVirtualKeyboard');
    }

    private get _isScrollbarOption(){
        return (this.opkey == 'optionScrollbar');
    }

    private get _isLoggerCampaignOption(){
        return (this.opkey == 'optionLoggerCampaign');
    }

    private get _deviceValue(){
        let _devices = this.value.split('|');
        for(let _device of _devices){
            let _parts = _device.split(';')
            if (_parts[0] == this.data.device){
                return (_parts.length > 1) ? _parts[1] : true;
            }
        }

        return false;                
    }

    private _ApplyValue(){      
        /*
            Place is still not loaded, and we want to avoid aplying more than once the same option
            So we store in the temporally created place the applied options and we'll only apply newer ones
        */

        if (!('__applyopts' in this.place)){
            this.place['__applyopts'] = new Map <string, PlaceOption> ();
        }

        let _applied = this.place['__applyopts'].get(this.opkey);
        if (_applied && (_applied.updated.getTime() > this.updated.getTime())){
            return;     // already applied a more recent version
        }
        else {          // store the current option as the applied one
            this.place['__applyopts'].set(this.opkey, this);
        }

        if (this._isKioskOption){
            setTimeout(() => {   // allow place to commit
                this.data.isKiosk = !!this._deviceValue;
            }, 0);
        }

        if (this._isViewThemeOption){
            this.data.ViewTheme = this._deviceValue as string;
        }

        if (this._isZoomLevelOption){
            setTimeout(() => {  // allow place to commit
                let _devicevalue = this._deviceValue;
                if (_devicevalue){
                    this.data.ZoomLevel = parseInt(_devicevalue as string) / 100;
                }
            }, 0);
        }

        if (this._isScrollbarOption){
            setTimeout(() => {  // allow place to commit
                this.data.Scrollbar = !!this._deviceValue;
            }, 0);
        }

        if (this._isLoggerCampaignOption){
            setTimeout(() => {  // allow place to commit
                this.data.LoggerCampaign = (this._deviceValue ? this._deviceValue : null) as string;
            }, 0);
        }
    }

    protected _OnUpdate(info){
        super._OnUpdate(info);
        if (this.place && this.data.place){
            this._ApplyValue();
        }
    }

    get value(): string {
        return super.value;
    }

    set value(value: string) {
        super.value = value;
        if (this.place && this.data.place){
            this._ApplyValue();
        }
    }
}


