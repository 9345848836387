import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Subject } from 'rxjs';
import { fromEvent, merge } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { Network } from '@ionic-native/network/ngx';

import { map } from 'rxjs/operators';

import { AppConstants} from '@app/app.constants';
import { platformService } from '@app/modules/common/platform';

class onlineService {
    private _isOnline = true; 

    private _onOnlineChanged = new Subject <any> ();
    public OnOnlineChanged = this._onOnlineChanged.asObservable();
    
    get isOnline(){
        return this._isOnline;
    }
    
    set isOnline(value){
        if (value != this._isOnline){
            this._isOnline = value;
            this._onOnlineChanged.next(this._isOnline);
        }
    }

    private _connect_subscription = null;
    private _disconn_subscription = null;

    constructor(private http: httpService, private platform: platformService, private network: Network) {
        if (this.platform.is('cordova')){
            this.isOnline = this.network.type !== 'none';

            this._disconn_subscription = this.network.onDisconnect().subscribe(() => {
                console.warn("[local connection - offline]");
                this.isOnline = false;
            });

            this._connect_subscription = this.network.onConnect().subscribe(() => {        
                console.info("[local connection - online]");
                this.isOnline = true;
            });
        }
        else {
            this._doObserveOnline().subscribe(data => {
                if (this.isOnline != navigator.onLine){
                    if (this.isOnline){
                        console.warn("[local connection - offline]");
                    }
                    else {
                        console.info("[local connection - online]");
                    }                    
                    this.isOnline = navigator.onLine;
                }
            });  

            this.isOnline = navigator.onLine;
        }
    }

    // https://stackoverflow.com/questions/46598777/check-internet-connection-in-web-using-angular-4    
    private _doObserveOnline() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            })
        );
  }    
    
    private _retries = 0;
    
    public checkOnline(observer){
        let _subscription = this.http.check().subscribe(
        data => {
            this._retries = 0;

            observer.next(true);
            observer.complete();
            
            if (!this.isOnline){
                console.info("[server connection - online]");
            }

            this.isOnline = true;

        },
        error => {
            if (++this._retries >= 2){
                
                observer.next(false);
                observer.complete();
                
                if (this.isOnline){
                    console.warn("[online] exeeced retry count -- now yow are offline");

                    this.isOnline = false;

                    setTimeout(() => {
                        this.waitOnline();
                    }, 500);
                }
            }
            else {
                console.warn("[online] server is not responding (retry count: " + this._retries + ")", error);
                setTimeout(() => {
                    this.checkOnline(observer);
                }, 250);
            }
        },
        () => {
            _subscription.unsubscribe();
        });
    }
    
    private waitOnline() {
        let observable = new Observable((observer) => {
            this.checkOnline(observer);
        });
                                        
        let _subscription = observable.subscribe(
        data => {
            if (!data){
                setTimeout(() => {
                    this.waitOnline()
                }, 500);
            }
        },
        error => {
            // nothing to do
        },
        () => {
            _subscription.unsubscribe();
        });
    }    
};

@Injectable()
export class httpService {
    private online: onlineService = null;
    
    get IsOnline(){
        return this.online.isOnline;
    }

    get OnOnline(){
        return this.online.OnOnlineChanged;
    }

    constructor(private platform: platformService, private ionic: HTTP, private network: Network, private angular: HttpClient) { 
        this.online = new onlineService(this, this.platform, this.network); 
    }

    /********************************************/
    /* DEBUG TRACES                             */
    /********************************************/
    
    private _dbgTrace = false;
    private debuglog(...args: any[]){
        if (this._dbgTrace){
            console.info(...args);
        }
    }
    
    /********************************************/
    /* DEFAULT HEADER FOR HTTP REQUESTS         */
    /********************************************/
    
    public static get headers() {
        return {
            'Accept': 'text/html',
            'Content-Type': 'application/x-www-form-urlencoded'
        };
    };
    
    private _HttpError(observer, error){
        let observable = new Observable((_observer) => {
            this.online.checkOnline(_observer);
        });
        
        observer.error(error);

        let _subscription = observable.subscribe(
        data => {
            _subscription.unsubscribe();
            if (data){
                if (error.status == 0){
                    console.error("[http] HTTP request received error 0");
                }
                observer.error(error);              // error in http request
            }
            else {
                observer.error({ status: 0 });       // offline
            }
        });
    }
    
    /********************************************/
    /* Browser -- use angular HttpClient        */
    /********************************************/
        
    private __angular_get(url, headers){
        let observable = new Observable((observer) => {
            try {
                let _subscription = this.angular.get(url, headers).subscribe(
                    data => {
                        observer.next(data);
                    },
                    error => {
                        this._HttpError(observer, error);
                    },
                    () => {
                        _subscription.unsubscribe();
                        observer.complete();
                    });        
            }
            catch {
                observer.complete();
            }
        });
            
        return observable;
    }

    private __angular_post(url, data, headers){
        let observable = new Observable((observer) => {
            let _subscription = this.angular.post(url, data, headers).subscribe(
            data => {
                observer.next(data);  
            },
            error => {
                this._HttpError(observer, error);
            },
            () => {
                _subscription.unsubscribe();
                observer.complete();
            });
        });
            
        return observable;
    }

    private __angular_check(url, headers){
        return this.angular.get(url, headers);
    }
    
    private __angular_head(url, headers) {
        let observable = new Observable(observer => {
            let _subscription = this.angular.head(url, { headers: headers, observe: 'response' }).subscribe(
            response => {
                if (response.status == 200) {
                    let _expectedContent = headers['headers'].get('Content-Type');
                    let _responseContent = response.headers.get('Content-Type');

                    observer.next(_expectedContent == _responseContent);
                    observer.complete();
                } else {
                    observer.next(false);
                    observer.complete();
                }
            }, 
            error => {
                this._HttpError(observer, error);
            },
            () => {
                _subscription.unsubscribe();
                observer.complete();
            });
        });

        return observable;
    }

    /********************************************/
    /* Android / IOS -- use ionic HTTP          */
    /********************************************/
            
    private __cordova_get(url, headers, timeout, responseType){
        this.debuglog("[cordova] get url: " + url); 
        let observable = new Observable((observer) => {
            this.ionic.sendRequest(encodeURI(url), {
                method: 'get',
                headers: headers,
                timeout: (timeout === null) ? 60000 : timeout,
                responseType: responseType ? responseType : 'json'
            })
            .then(response => {
                this.debuglog("[cordova] reply to url: " + url, response);
                if (response.status == 200){
                    observer.next(response.data);
                    observer.complete();
                }
                else {
                    this._HttpError(observer, response);
                    observer.complete();
                }
            })
            .catch(response => {
                this.debuglog("[cordova] exteption", response);
                this._HttpError(observer, response);
                observer.complete();
            });
        });
        return observable;
    }
        
    private __cordova_post(url, data, headers, timeout, responseType, serializer){
        this.debuglog("[cordova] post url: " + url, data); 
        let observable = new Observable((observer) => {
            this.ionic.setDataSerializer(serializer);
            this.ionic.sendRequest(encodeURI(url), {
                method: 'post',
                data: data,
                headers: headers,
                timeout: (timeout === null) ? 60000 : timeout,
                responseType: responseType ? responseType : 'json'
            })
            .then(response => {
                this.debuglog("[cordova] reply to url: " + url, response);
                if (response.status == 200){
                    observer.next(response.data);
                    observer.complete();
                }
                else {
                    this._HttpError(observer, response);
                    observer.complete();
                }
            })
            .catch(response => {
                this.debuglog("[cordova] exteption", response);
                this._HttpError(observer, response);
                
                console.error(response); 
                observer.complete();
            });
        });
        return observable;
    }
    
    private __cordova_check(url, headers){
        this.debuglog("[cordova] test url: " + url); 
        let observable = new Observable((observer) => {
            this.ionic.sendRequest(encodeURI(url), {
                method: 'get',
                headers: headers,
                timeout: 5
            })
            .then(response => {
                this.debuglog("[cordova] reply to url: " + url, response);
                if (response.status == 200){
                    observer.next(response.data);
                    observer.complete();
                }
                else {
                    observer.error(response);
                    observer.complete();
                }
            })
            .catch(response => {
                this.debuglog("[cordova] exteption", response);
                this._HttpError(observer, response);
                
                console.error(response); 
                observer.complete();
            });
        });
        return observable;
    }

    private __cordova_head(url, headers){
        this.debuglog("[cordova] head url: " + url); 
        let observable = new Observable((observer) => {
            this.ionic.sendRequest(encodeURI(url), {
                method: 'head',
                headers: headers,
                timeout: 5
            })
            .then(response => {
                this.debuglog("[cordova] reply to url: " + url, response);
                if (response.status == 200){
                    let _expectedContent = headers['Content-Type'];
                    let _responseContent = response.headers['Content-Type'];

                    observer.next(_expectedContent == _responseContent);
                    observer.complete();                    
                }
                else {
                    observer.next(false);
                    observer.complete();
                }
            })
            .catch(response => {
                this.debuglog("[cordova] exteption", response);
                this._HttpError(observer, response);
                
                console.error(response); 
                observer.complete();
            });
        });
        return observable;
    }

    /********************************************/
    /* Public methods (platform dependant)      */
    /********************************************/
    
    get offlineObservable(){
        let observable = new Observable((observer) => {
            setTimeout(() => {
                observer.error({ status: 0 });
                observer.complete();
            }, 0);
        });
        return observable;
    }
    
    get(url, withCredentials = true, headers = httpService.headers, responseType = null, timeout = null){
        if (this.online.isOnline){
            if (this.platform.is('cordova')){
                return this.__cordova_get(url, headers, timeout, responseType);
            }

            let __angular_headers = {
                withCredentials: withCredentials,
                headers: new HttpHeaders(headers),
                timeout: ((timeout === null) ? 60000 : timeout).toString(),
                responseType: responseType ? responseType : 'json'
            };

            return this.__angular_get(url, __angular_headers);
        }
            
        return this.offlineObservable;   // offline
    }
        
    post(url, data, headers = httpService.headers, responseType = null, serializer = null, timeout = null){
        if (!serializer){
            serializer = httpService.serializer.json;
        }

        if (this.online.isOnline){
            if (this.platform.is('cordova')){
                return this.__cordova_post(url, data, headers, timeout, responseType, serializer);
            }

            let __angular_headers = {
                withCredentials: true,
                headers: new HttpHeaders((serializer == httpService.serializer.multipart) ? {} : headers),
                timeout: ((timeout === null) ? 60000 : timeout).toString(),
                responseType: responseType ? responseType : 'json'
            };

            return this.__angular_post(url, data, __angular_headers);
        }

        return this.offlineObservable;   // offline
    }
    
    check(){
        let url = AppConstants.baseURL + 'base/online.php';
        
        if (this.platform.is('cordova')){
            return this.__cordova_check(url, httpService.headers);
        }

        let __angular_headers = {
            withCredentials: true,
            headers: new HttpHeaders(httpService.headers)
        };
        
        return this.__angular_check(url, __angular_headers);
    }

    head(url, headers = httpService.headers){
        if (this.platform.is('cordova')){
            return this.__cordova_head(url, headers);
        }

        let __angular_headers = {
            withCredentials: true,
            headers: new HttpHeaders(headers)
        };
        
        return this.__angular_head(url, __angular_headers);
    }
}

/********************************************/
/* Available serializers                    */
/********************************************/

export namespace httpService {
    export enum serializer {
        json = 'json',
        text = 'text',
        utf8 = 'utf8',
        urlencoded = 'urlencoded',
        multipart = 'multipart',
        raw = 'raw'
    };
}
