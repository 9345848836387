import { Component, OnInit, AfterViewInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { ChangeDetectorRef } from '@angular/core';

import { platformService } from '@app/modules/common/platform';
import { languageService } from '@app/modules/common/language';

@Component({
    selector: 'kb-input',
    templateUrl:'./kb-input.html',
    styleUrls: [ 
        './keyboard.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => KbInput),
            multi: true
        }            
    ]
})
export class KbInput implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input('placeholder') _placeholder = '';
    @Input('readonly') _readonly = false;
    @Input('formControlName') _name = 'none';
    @Input('kiosk') _usekiosk = true;
    @Input('type') _type = '';
    @Input('value') _value = '';
    @Input('disabled') _disabled = false;
    @Input('autocomplete') _autocomplete = 'do-not-autofill';

    @Output('change') _changed = new EventEmitter<any>(); 

    isDisabled: boolean;

    private _onTouchCallback: any = () => {};
    private _onChangeCallback: any = () => {};

    private _innerValue: any = '';  
    private _dirty = false;

    public _kiosk = false;
    public _focus = false;

    @Output('onFocus') _onFocus = new EventEmitter<any>(); 
    onFocus(value){
        this._focus = value;
        this._onFocus.emit();

        if (value){
            this._dirty = false;
        }
        else {
            if (this._dirty){
                this._changed.emit();
            }
        }
    }

    @ViewChild('input', {static: false}) _input: any;
    OnKeyPress(key){
        let _event = new KeyboardEvent("keyup", {
            key: key,
            shiftKey: false, // you don't need to include values
            ctrlKey: false,  // if you aren't going to use them.
            metaKey: false   // these are here for example's sake.
        })        

        this.onKeyUp(_event);
    }

    SetFocus(){
        setTimeout(() => {
            this._input.nativeElement.focus();
            this._input.nativeElement.click();
        }, 0);
    }

    get Price(){
        return this._innerValue ? Number(this._innerValue).toFixed(2) : ((this._focus) ? '' : this._placeholder)
    }

    constructor(private lang: languageService, private change: ChangeDetectorRef, private platform: platformService) {
        // nothing to do
    }

    private _kiosk_subscription = null;
    ngOnInit(){
        this.value = this._value;
    
        if ((this._type == 'price') || (this._type == 'number')){
            this.value = this.value ? this.value.toString().replace(",", ".") : null;
        }
    }

    ngAfterViewInit(){
        this.platform.ready().
        then(() => {
            this._kiosk = this._usekiosk && this.platform._isKiosk;
            this.change.markForCheck();
             
            if (this._usekiosk){    // do not subscribe if kiosk is disabled
                this._kiosk_subscription = this.platform.OnKioskChanged.subscribe(
                data => {
                    this._kiosk = this.platform._isKiosk;
                    this.change.markForCheck();                
                });
            }
        });
    }

    public _price = '';     // keep the complete price value

    ngOnChanges(changes: SimpleChanges) {
        if (('_value' in changes) && (this.value != this._value)){
            this.value = this._value;
        }

        if (this._type == 'price'){
            let _parts = this.value ? this.value.toString().split('.') : [ '0', '0' ];
            if (_parts.length == 2){
                this._price = _parts[0] + (_parts[1] + "00").slice(0, 2); 
            }
        }
    }

    ngOnDestroy(){
        if (this._kiosk_subscription){
            this._kiosk_subscription.unsubscribe();
        }
        this._kiosk_subscription = null;
    }

    onKeyUp(event){
        switch(this._type){
            case 'price':
                if (event.key == 'Backspace'){
                    this._price = this._price ? this._price.slice(0, -1) : '';
                }
                else {
                    if (isNaN(Number(event.key))){
                        return;     // ignore key if not a number
                    }

                    this._price = (this._price || '') + event.key;
                }

                this.value = (Number(this._price)/100).toString();
                if (isNaN(Number(this.value))){
                    this.value = this._price = null;
                }
                break;

            case 'number':
                if (event.key == 'Backspace'){
                    this._price = this._price ? this._price.slice(0, -1) : '';
                }
                else {
                    if (isNaN(Number(event.key)) && ([',', '.'].indexOf(event.key) == -1)){
                        return;     // ignore key if not a number, ',' or '.'
                    }
    
                    this._price = (this._price || '') + event.key;    
                }

                this.value = this._price.replace(",", ".");

                let _isnumber = this.value.endsWith('.') ? this.value.slice(0, this.value.length - 1) : this.value;
                if (isNaN(Number(_isnumber))){
                    this.value = this._price = null;
                }    
                break;
        }
    }

    onKiosk(event){
        this._value = event.target.value;

        switch(this._type){
            case 'price':
                this.value = this._value ? (Number(this._value)/100).toString() : null;
                if (isNaN(Number(this.value))){
                    this.value = null;
                }
                break;
            case 'number':
                this.value = this._value ? this._value.replace(",", ".") : null;
                if (isNaN(Number(this.value))){
                    this.value = null;
                }
                break;

            default:
                this.value = this._value;
                break;
        }
    }

    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    @Output('onChange') _onChange = new EventEmitter<any>(); 
    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = this._value = v;
            
            if (!v){
                this._price = '';
            }

            this._onChangeCallback(v);
            this._onTouchCallback();

            this._dirty = true;
            this._onChange.emit(v);
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }  
    
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }    
}

@Component({
    selector: 'kb-textarea',
    templateUrl:'./kb-textarea.html',
    styleUrls: [ 
        './keyboard.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => KbTextArea),
            multi: true
        }            
    ]
})
export class KbTextArea implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
    @Input('placeholder') _placeholder = '';
    @Input('readonly') _readonly = false;
    @Input('kiosk') _usekiosk = true;
    @Input('formControlName') _name = ''
    @Input('rows') _rows = 1;
    @Input('value') _value = '';
    @Input('disabled') _disabled = false;
    @Input('autocomplete') _autocomplete = 'do-not-autofill';

    @Output('change') _changed = new EventEmitter<any>(); 

    isDisabled: boolean;

    private _onTouchCallback: any = () => {};
    private _onChangeCallback: any = () => {};

    private _innerValue: any = '';  
    private _dirty = false;
    
    public _kiosk = false;
    public _focus = false;
   
    @Output('onFocus') _onFocus = new EventEmitter<any>(); 
    onFocus(value){
        this._focus = value;
        this._onFocus.emit();

        if (value){
            this._dirty = false;
        }
        else {
            if (this._dirty){
                this._changed.emit();
            }
        }
    }

    @ViewChild('elem', {static: false}) _domelem: ElementRef;    
    get nativeElement(){
        return this._domelem;
    }

    constructor(private lang: languageService, private change: ChangeDetectorRef, private platform: platformService) {
        // nothing to do
    }

    ngOnInit(){
        this.value = this._value;
    }
   
    private _kiosk_subscription = null;
    ngAfterViewInit(){
        this.platform.ready().
        then(() => {
            this._kiosk = this._usekiosk && this.platform._isKiosk;
            this.change.markForCheck();
             
            if (this._usekiosk){    // do not subscribe if kiosk is disabled
                this._kiosk_subscription = this.platform.OnKioskChanged.subscribe(
                data => {
                    this._kiosk = this.platform._isKiosk;
                    this.change.markForCheck();                
                });
            }
        });
    }

    ngOnDestroy(){
        if (this._kiosk_subscription){
            this._kiosk_subscription.unsubscribe();
        }
        this._kiosk_subscription = null;
    }

    onKeyUp(event){
        // nothing to do
    }

    onKiosk(event){
        this.value = event.target.value;
    }

    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    @Output('onChange') _onChange = new EventEmitter<any>(); 
    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = this._value = v;
            
            this._onChangeCallback(v);
            this._onTouchCallback();

            this._dirty = true;
            this._onChange.emit(v);
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }  
    
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }        
}

