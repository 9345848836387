import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { Waiter } from './waiter';
import { Session } from './session';
import { Address } from './address';
import { UserAddress } from './useraddress';

export interface _User {
    status: string;
    email: string;
    password?: string;
    firstname: string;
    lastname: string;
    fullname?: string;
    photo: {
        url: string;
        b64: any;
    },
    phone: string;
    lang: string;
    license: boolean;
};

interface _UserData extends _User {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class UserData extends DataObject {
    protected _user: _UserData = {
        status: null,
        email: null,
        firstname: null,
        lastname: null,
        fullname: null,
        photo: {
            url: null,
            b64: null
        },
        phone: null,
        lang: null,
        license: true
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._user.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._user.created;
    }

    get status(): string{
        return this._user.status;
    }

    set status(value: string){
        if (this.patchValue(this._user, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get email(): string{
        return this._user.email;
    }

    set email(value: string){
        if (this.patchValue(this._user, 'email', value)){
            this.ToUpdate = true;
        }
    }

    get firstname(): string{
        return this._user.firstname;
    }

    set firstname(value: string){
        if (this.patchValue(this._user, 'firstname', value)){
            this.ToUpdate = true;
        }
    }

    get lastname(): string{
        return this._user.lastname;
    }

    set lastname(value: string){
        if (this.patchValue(this._user, 'lastname', value)){
            this.ToUpdate = true;
        }
    }

    get fullname(): string {
        return this._user.fullname;
    }

    get photo(): string{
        return this._user.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._user.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }
   
    get base64(): any {
        return this._user.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._user.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get phone(): string{
        return this._user.phone;
    }

    set phone(value: string){
        if (this.patchValue(this._user, 'phone', value)){
            this.ToUpdate = true;
        }
    }

    get lang(): string{
        return this._user.lang;
    }

    set lang(value: string){
        if (this.patchValue(this._user, 'lang', value)){
            this.ToUpdate = true;
        }
    }

    get license(): boolean{
        return this._user.license;
    }

    set license(value: boolean){
        if (this.patchValue(this._user, 'license', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPlace(child: Place){
        this.AddChild('places', child, 'user');
    }

    DelPlace(child: Place){
        this.DelChild('places', child, 'user');
    }

    AddWaiter(child: Waiter){
        this.AddChild('waiter', child, 'user');
    }

    AddSession(child: Session){
        this.AddChild('session', child, 'user');
    }

    AddAddress(child: UserAddress){
        this.AddChild('address', child, 'user');
    }

    DelAddress(child: UserAddress){
        this.DelChild('address', child, 'user');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get places() : Array <Place> {
        return this._chldlist['places'] || [];
    }

    get sessions() : Array <Session> {
        return this._chldlist['session'] || [];
    }

    get waiters() : Array <Waiter> {
        return this._chldlist['waiter'] || [];
    }

    get address(): Array <UserAddress> {
        return this._chldlist['address'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                email: this._user.email,
                firstname: this._user.firstname,
                lastname: this._user.lastname,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                phone: this._user.phone,
                lang: this._user.lang,
                license: this._user.license ? '1': '0',
            };    
        })();
    }

    protected get Depend() {
        return { /* empty */ };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.places){
            _children.push(_item)
        }

        for(let _item of this.sessions){
            _children.push(_item)
        }

        for(let _item of this.waiters){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_user: _User){
        _user.fullname = this._fullname(_user);

        let _toUpdate = false;
        
        _toUpdate = this.patchValue(this._user, 'status', _user['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'email', _user['email']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'firstname', _user['firstname']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'lastname', _user['lastname']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'fullname', _user['fullname']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'photo', _user['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'phone', _user['phone']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'lang', _user['lang']) || _toUpdate;
        _toUpdate = this.patchValue(this._user, 'license', _user['license']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_user: _User){
        this.patchValue(this._user, 'created', _user['created']);
        
        if (this._patchData(_user)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._user;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private _fullname(_user){
        return _user.firstname ? _user.firstname + (_user.lastname ? (' ' + _user.lastname) : '') : null;
    }

    private DoPatchValues(_user: _User){
        this._patchData(_user);
    }

    private _ddbb(info): _UserData {
        let _user: _UserData = {
            objid: info['objid'],
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            email: info['email'],
            firstname: info['firstname'],
            lastname: info['lastname'],
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },             
            phone: info['phone'],
            lang: info['lang'],
            license: (info['license'] == '1'),
        };

        return _user;
    }

    protected _OnUpdate(info){
        let _user = this._ddbb(info);
        this.patchValue(this._user, 'objid', _user['objid']);
        this.patchValue(this._user, 'created', _user['created']);
        this.DoPatchValues(_user);

        if (info['places']){    // update children: 'places'
            this.SetChildren <Place> (info['places'], 'places', Place, 'user');
        }

        if (info['waiter']){    // update children: 'waiter'
            this.SetChildren <Waiter> (info['waiter'], 'waiter', Waiter, 'user');
        }

        if (info['address']){    // update children: 'address'
            this.SetChildren <UserAddress> (info['address'], 'address', UserAddress, 'user');
        }

        if (info['session']){    // update children: 'session'
            this.SetChildren <Session> (info['session'], 'session', Session, 'user');
        }
    }
}

export class User extends UserData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('USER', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): User {
        return this._Copy(store) as User;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsActive(){
        return (this._user.status == 'AC');
    }

    get IsPending(){
        return (this._user.status == 'PA');
    }

    /* delivery address */

    AddDelivery(child: Address){
        let _useraddress = new UserAddress(null, this.data);
        if (_useraddress){
            _useraddress.user = this;
            _useraddress.address = child;
            _useraddress.status = 'AC';
        }

        this.AddAddress(_useraddress);
    }

    DelDelivery(child: Address){
        let _address = this.address;
        for(let _idx = _address.length-1; _idx >= 0; _idx--){
            let _current = _address[_idx];
            if (_current.address == child){
                this.DelAddress(_current);
            }
        }
    }

    get delivery() : Array <Address> {
        let _addresses = [];
        for(let _useraddress of this.address){
            if (_useraddress.IsValid){
                _addresses.push(_useraddress.address);
            }
        }
        return _addresses;
    }
}