import { Injectable } from '@angular/core';

import { storeService } from '@app/modules/store';
import { syncService } from '@app/modules/sync';
import { viewService } from '@app/modules/view';

import { GenericUtils } from '@app/app.commonutils';

const enum LogsLevel {
    Error= "Error",
    Warn= "Warn",
    Info= "Info",
    Debug= "Debug",
    Trace= "Trace"
}

@Injectable()
export class logsService {
    private _loggerUrl = 'logger/writer.php';
    private _lsource: string = null;
    private _authtok: string = null;

    private _started = true;
    private _flush_subscription = null;
    private _queued: Array<any> = [];

    get Campaign(){
        return this._lsource;
    }

    private _session = GenericUtils.uuidv4();

    constructor(private store: storeService, private sync: syncService, private view: viewService){
        window.console = this.OverrideConsole();

        let _active = this.store.GetLoggerCampaign();
        if (_active){
            this.Start(_active);
        }
    }

    async Start(campaign){
        this._lsource = campaign;
        if (this._lsource === null){
            return;     // mnust be in a campaign
        }

        this._started = true;

        let _data = await this.sync.DoRequest(this._loggerUrl, null, { 
            command: 'INI',
            session: this._session,
            source: this._lsource
        }, false);

        if (_data['errorcode'] == 0){
            this._authtok = _data['authtok'];
        }
        
        if(this._flush_subscription != null){
            this._flush_subscription.unsubscribe();
            this._flush_subscription = null;
        }

        this._flush_subscription = this.sync.Clock.OnOneSecondTick.subscribe(
        data => {
            this.Flush();
        });

        this.store.SetLoggerCampaign(campaign);
    }

    async Stop(){
        if(this._flush_subscription != null){
            this._flush_subscription.unsubscribe();
            this._flush_subscription = null;
        }

        this._started = false;
        this._lsource = null;

        if (this._authtok === null){
            return;     // not initialized
        }

        let _data = await this.sync.DoRequest(this._loggerUrl, null, { 
            command: 'END', 
            session: this._session,
            authtok: this._authtok 
        }, false);

        if (_data['errorcode'] == 0){
            this._authtok = null;
        }
    }

    Queue(level: LogsLevel, message: string, extras: any){
        if (this._started){
            this._queued.push({
                level: level,
                message: message,
                extras: extras
            });    
        }
    }

    private async Flush(){
        if (!this.view.IsOnline){
            return;     // we are offline
        }

        if ((this._authtok !== null) && (this._queued.length > 0)){
            // first try to send the log with the authtoken
            let _first = await this.sync.DoRequest(this._loggerUrl, null, { 
                command: 'LOG', 
                session: this._session,
                authtok: this._authtok, 
                logs: this._queued
             }, false);

            // if authtoken is expired then refresh and send the log
            if ((_first['errorcode'] == 9) || (_first['errorcode'] == 10)) { 
                let _retry = await this.sync.DoRequest(this._loggerUrl, null, { 
                    command: 'INI', 
                    session: this._session,
                    source: this._lsource,
                    logs: this._queued
                }, false);

                if (_retry['errorcode'] != 0){
                    console.error("[LOGGER]: Could not resend log traces (errorcode: " + _retry['errorcode']+ ")");
                }
            }
        }

        this._queued = [];
    }

    private OverrideConsole(): Console {
        const _console = console;

        function send_report(service: logsService, level: LogsLevel, message: string, extras: any) {
            service.Queue(level, message, extras);
        }
    
        function process_args(args: any[]) {
            let args_named = {};
            for (let i = 0; i < args.length; i++) {
                switch (typeof args[i]) {
                    case 'object':
                        for (let key in args[i]) {
                            args_named[key] = args[i][key]?.toString();
                        }
                        break;
                    default:
                        args_named[`arg_${i}`] = args[i]?.toString();
                }
    
            }
            return args_named;
        }
    
        return {
            ..._console,
            log: (message: string, ...args: any[]) => {
                _console.log(message, ...args);
                send_report(this, LogsLevel.Trace,  message, process_args(args));
            },
            error: (message: string, ...args: any[]) => {
                _console.error(message, ...args);
                send_report(this, LogsLevel.Error, message, process_args(args));
            },
            warn: (message: string, ...args: any[]) => {
                _console.warn(message, ...args);
                send_report(this, LogsLevel.Warn, message, process_args(args));
            },
            info: (message: string, ...args: any[]) => {
                _console.info(message, ...args);
                send_report(this, LogsLevel.Info, message, process_args(args));
            },
            debug: (message: string, ...args: any[]) => {
                _console.log(message, ...args);
                send_report(this, LogsLevel.Debug, message, process_args(args));
            },
            trace: (message: string, ...args: any[]) => {
                _console.log(message, ...args);
                send_report(this, LogsLevel.Trace, message, process_args(args));
            }
        } as Console    
    }
}
