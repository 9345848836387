import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Ticket } from './ticket';
import { Business } from './business';

export interface _TicketInvoice {
    status: string,
    ticket: number,
    client: number
};

interface _TicketInvoiceData extends _TicketInvoice {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketInvoiceData extends DataObject {
    protected _invoice: _TicketInvoiceData = {
        status: null,
        ticket: null,
        client: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._invoice.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._invoice.created;
    }

    get updated(){
        return this._invoice.updated;        
    }

    get status(): string {
        return this._invoice.status;
    }

    set status(value: string) {
        if(this.patchValue(this._invoice, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get client(): Business {
        return this._children['client'] || null;
    }

    set client(value: Business ){
        if (this.SetChild('client', value, 'client')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._invoice.status,
            ticket: this._invoice.ticket,
            client: this._invoice.client
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'invoice', by: 'ticket' } },      // this[by -> 'ticket'][to -> 'invoice'] => this
            client: { item: this.client, relation_info: { to: null, by: 'client' } }            // no relation to this in this[by -> 'client']
        };
    }

    protected get Children(){
        let _children = [];

        if (this.client){
            _children.push(this.client);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_invoice: _TicketInvoice){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._invoice, 'status', _invoice['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'ticket', _invoice['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'client', _invoice['client']) || _toUpdate;
    
        return _toUpdate;
    }   

    set Data(_invoice: _TicketInvoice){
        this.patchValue(this._invoice, 'created', _invoice['created']);
        this.patchValue(this._invoice, 'updated', _invoice['updated']);
        
        if (this._patchData(_invoice)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._invoice;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_invoice: _TicketInvoice){
        this._patchData(_invoice);

        if (_invoice['ticket']){    // update children: 'ticket'
            let _objid = _invoice['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }

        if(_invoice['client']){      // update children: 'client'
            let _objid = _invoice['client'].toString();
            this.SetChild('client', new Business(_objid, this.data, this._objoptions), 'client')
        }  
        else {
            this.SetChild('client', null, 'client');
        }          
    }

    private _ddbb(info): _TicketInvoiceData {
        let _invoice: _TicketInvoiceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            client: info['client'] ? parseInt(info['client']) : null
        };
        return _invoice;
    }

    protected _OnUpdate(info){
        let _invoice = this._ddbb(info);
        this.patchValue(this._invoice, 'objid', _invoice['objid']);
        this.patchValue(this._invoice, 'created', _invoice['created']);
        this.patchValue(this._invoice, 'updated', _invoice['updated']);
        this.DoPatchValues(_invoice);
    }
}

export class TicketInvoice extends TicketInvoiceData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETINVOICE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketInvoice {
        return this._Copy(store) as TicketInvoice;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get business(): string {
        if (this.client){
            return this.client.name;
        }
        return null;
    }

    get taxid(): string {
        if (this.client){
            return this.client.taxid;
        }
        return null;        
    }

    get address(): string {
        if (this.client){
            return this.client.address;
        }
        return null;          
    }  

    get postalcode(): string {
        if (this.client){
            return this.client.postalcode;
        }
        return null;          
    }

    get region(): string {
        if (this.client){
            return this.client.region;
        }
        return null;          
    }

    /****************************/
    /* CUSTOM MEMBERS           */
    /****************************/

    get client(): Business {
        return super.client;
    }

    set client(value: Business ){
        let _business = value;
        if (_business != super.client){
            _business.last = new Date();
            super.client = _business;
        }
    }    

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE');
    }
}


