import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';

export interface _InvoiceMail {
    place: number,
    status: string,
    email: string,
    server: string,
    protocol: string,
    port: number,
    username: string,
    password: string,
    oauth: boolean,
    token: boolean,
    secure: string,
};

interface _InvoiceMailData extends _InvoiceMail {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class InvoiceMailData extends DataObject {
    protected _account: _InvoiceMailData = {
        place: null,
        status: null,
        email: null,
        server: null,
        protocol: null,
        port: null,
        username: null,
        password: null,
        oauth: false,
        token: null, 
        secure: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._account.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._account.created;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get status(): string {
        return this._account.status;
    }

    set status(value: string) {
        if (this.patchValue(this._account, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get email(): string {
        return this._account.email;
    }

    set email(value: string) {
        if (this.patchValue(this._account, 'email', value)){
            this.ToUpdate = true;
        }
    }

    get server(): string {
        return this._account.server;
    }

    set server(value: string) {
        if (this.patchValue(this._account, 'server', value)){
            this.ToUpdate = true;
        }
    }

    get protocol(): string {
        return this._account.protocol;
    }

    set protocol(value: string) {
        if (this.patchValue(this._account, 'protocol', value)){
            this.ToUpdate = true;
        }
    }

    get port(): number {
        return this._account.port;
    }

    set port(value: number) {
        if (this.patchValue(this._account, 'port', value)){
            this.ToUpdate = true;
        }
    }

    get username(): string {
        return this._account.username;
    }

    set username(value: string) {
        if (this.patchValue(this._account, 'username', value)){
            this.ToUpdate = true;
        }
    }

    get password(): string {
        return this._account.password;
    }

    set password(value: string) {
        if (this.patchValue(this._account, 'password', value)){
            this.ToUpdate = true;
        }
    }

    get oauth(): boolean {
        return this._account.oauth;
    }

    set oauth(value: boolean) {
        if (this.patchValue(this._account, 'oauth', value)){
            this.ToUpdate = true;
        }
    }

    get token(): boolean {
        return this._account.token;
    }

    set token(value: boolean) {
        if (this.patchValue(this._account, 'token', value)){
            this.ToUpdate = true;
        }
    }

    get secure(): string {
        return this._account.secure;
    }

    set secure(value: string) {
        if (this.patchValue(this._account, 'secure', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._account.place,
            status: this._account.status,
            email: this._account.email,
            server: this._account.server,
            protocol: this._account.protocol,
            port: this._account.port,
            username: this._account.username,
            password: this._account.password,
            oauth: this._account.oauth ? '1' : '0',
            secure: this._account.secure
        };
    }

    protected get Depend(){
        return {
            place: { item: this.place, relation_info: { to: 'emails', by: 'place' } }     // this[by -> 'place'][to -> 'emails'] => this
         };
     }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_account: _InvoiceMail){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._account, 'place', _account['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'status', _account['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'email', _account['email']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'server', _account['server']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'protocol', _account['protocol']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'port', _account['port']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'username', _account['username']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'password', _account['password']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'oauth', _account['oauth']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'token', _account['token']) || _toUpdate;
        _toUpdate = this.patchValue(this._account, 'secure', _account['secure']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_account: _InvoiceMail){
        this.patchValue(this._account, 'created', _account['created']);

        if (this._patchData(_account)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._account;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_account: _InvoiceMail){
        this._patchData(_account);
    }

    private _ddbb(info): _InvoiceMailData {
        let _account: _InvoiceMailData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            
            place: info['place'] ? parseInt(info['place']) : null,
            status: info['status'],
            email: info['email'] ? info['email'] : null,
            server: info['server'] ? info['server'] : null,
            protocol: info['protocol'] ? info['protocol'] : null,
            port: info['port'] ? parseInt(info['port']): 0,
            username: info['username'] ? info['username'] : null,
            password: info['password'] ? info['password'] : null,
            oauth: (info['oauth'] == '1') ?  true : false,
            token: info['rftok'] ?  true : false,
            secure: info['secure'] ? info['secure'] : null
        };
        return _account;
    }

    protected _OnUpdate(info){
        let _account = this._ddbb(info);
        this.patchValue(this._account, 'objid', _account['objid']);
        this.patchValue(this._account, 'created', _account['created']);
        this.DoPatchValues(_account);   
    }
}

export class InvoiceMail extends InvoiceMailData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('INVOICEMAIL', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : InvoiceMail {
        return this._Copy(store) as InvoiceMail;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.place){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.place.DelInvoiceMail(this);
            }
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        return this.status && (this.status == 'AC');
    }

    DoRefresh(){
        this._onRefresh.next();
    }
}
