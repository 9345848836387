import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from "ngx-img-cropper";

import { GoogleMapsModule } from '@angular/google-maps'
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { UiVisibleDirective, UiVisibleComponent } from './ui-visible/ui-visible';
import { InputErrors } from  './ui-errors/ui-errors';
import { UiKioskBoard, UiKioskInput } from './ui-kiosk/ui-kiosk';
import { KbInput } from './ui-keyboard/keyboard';
import { KbTextArea } from './ui-keyboard/keyboard';
import { TouchEvents } from './ui-touch/ui-touch';
import { UiInput } from  './ui-input/ui-input';
import { UiTextarea } from  './ui-textarea/ui-textarea';
import { UiCrop } from  './ui-crop/ui-crop';
import { UiImage, UiModalCrop } from  './ui-image/ui-image';
import { UiSearch } from './ui-search/ui-search';
import { UiPicker, UiModalPicker } from  './ui-picker/ui-picker';
import { UiAction } from  './ui-action/ui-action';
import { UiAddress, UiModalAddress } from  './ui-address/ui-address';
import { UiAway } from './ui-away/ui-away';
import { UiChart } from './ui-chart/ui-chart';
import { UiOnlinePaidIcon }  from './ui-stripe/ui-stripe';
import { UiPaymentLicense } from './ui-stripe/ui-stripe';
import { UiPaymentConnect } from './ui-stripe/ui-stripe';
import { UiPaymentTicket } from './ui-stripe/ui-stripe';
import { UiLanguage } from './ui-language/ui-language';
import { UiError } from './ui-error/ui-error';

@NgModule({
    declarations: [
        UiVisibleDirective,
        UiVisibleComponent,
        InputErrors,
        UiKioskBoard,
        UiKioskInput,
        KbInput,
        KbTextArea,
        TouchEvents,
        UiInput,
        UiTextarea,
        UiCrop,
        UiModalCrop,
        UiImage,
        UiSearch,
        UiPicker,
        UiModalPicker,
        UiAction,
        UiAddress,
        UiAway,
        UiChart,
        UiModalAddress,
        UiOnlinePaidIcon,
        UiPaymentLicense,
        UiPaymentConnect,
        UiPaymentTicket,
        UiLanguage,
        UiError
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ImageCropperModule
    ],
    entryComponents: [
        UiModalCrop,
        UiModalPicker,
        UiModalAddress
    ],
    exports: [
        UiVisibleComponent,
        UiKioskBoard,
        UiKioskInput,
        KbInput,
        KbTextArea,
        TouchEvents,
        UiInput,
        UiTextarea,
        UiImage,
        UiSearch,
        UiPicker,
        UiAction,
        UiAddress,
        UiAway,
        UiChart,
        UiOnlinePaidIcon,
        UiPaymentLicense,
        UiPaymentConnect, 
        UiPaymentTicket,
        UiLanguage,
        UiError
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]    
})
export class UiControls {}
