import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Device } from '@ionic-native/device/ngx';
import { Network } from '@ionic-native/network/ngx';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { RouteReuseStrategy } from '@angular/router';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Printer } from '@ionic-native/printer/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { UiControls } from '@app/components/common/ui-controls.module';

import { platformService } from '@app/modules/common/platform';
import { updateService } from '@app/modules/update';
import { preloadService } from '@app/modules/common/preload';
import { httpService } from '@app/modules/common/http';
import { kioskService } from '@app/modules/common/kiosk';
import { alertService } from '@app/modules/common/alert';
import { toastService } from '@app/modules/common/toast';
import { languageService } from '@app/modules/common/language';
import { uploadService } from '@app/modules/common/upload';
import { downloadService } from '@app/modules/common/upload';
import { geocodeService } from '@app/modules/common/geocode';

import { storeService } from '@app/modules/store';
import { syncService } from '@app/modules/sync';
import { logsService } from '@app/modules/logs';
import { dataService } from '@app/modules/data';
import { viewService } from '@app/modules/view';
import { deviceService } from './modules/sockets';
import { printService } from '@app/modules/print';

import { modalAlert } from '@app/modules/common/alert';
import { modalUpdate }  from '@app/modules/update';
import { modalAway }  from '@app/modules/common/geocode';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppConstants } from '@app/app.constants';

const routes = [];

@NgModule({
  declarations: [
    modalAlert,
    modalUpdate,
    modalAway,
    AppComponent
  ],
  
  entryComponents: [
    modalAlert,
    modalUpdate,
    modalAway
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,    
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    UiControls,

    IonicStorageModule.forRoot({
        name: '__uppdb',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    
    NgcCookieConsentModule.forRoot({
        cookie: {
            domain: AppConstants.domain
        },
        enabled: false
    }),

    // Register the ServiceWorker as soon as the app is stable or after DelaySW ms (whichever comes first).
    ServiceWorkerModule.register(AppConstants.TargetSW, {
        scope: AppConstants.ScopeSW,
        enabled: AppConstants.EnableSW,
        registrationStrategy: 'registerWhenStable:' + AppConstants.DelaySW
    }),
  ],

  providers: [
    SplashScreen,

    Network,
    HTTP,
    Device,
    Printer,
    AndroidPermissions,
    Geolocation,
    platformService,
    updateService,
    FileTransfer,
    File,
    FileOpener,
    languageService,
    preloadService,
    httpService,
    kioskService,
    alertService,
    toastService,
    uploadService,
    downloadService,
    geocodeService,
    storeService,
    syncService,
    logsService,
    dataService,
    viewService,
    deviceService,
    printService,
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy 
    }
  ],

  bootstrap: [
      AppComponent
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]    
})
export class AppModule {}
