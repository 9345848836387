import { Component } from '@angular/core';
import { languageService } from '@app/modules/common/language';

@Component({
    selector: 'ui-language-chooser',
    templateUrl:'./ui-language.html',
    styleUrls: [ 
        './ui-language.scss'
    ]
})
export class UiLanguage  {
    constructor(private lang: languageService) {
        // nothing to do
    }

    get Languages(){
        return this.lang.GetLanguages();
    }

    LanguageFlag(_lang){
        return "fi fi-" + _lang.flag.toLowerCase() + " fis";
    }

    OnChangeLanguage(_lang){
        this.lang.SetLanguage(_lang.lang);
    }
}
