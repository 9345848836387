import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

import Chart from 'chart.js/auto';

@Component({
    selector: 'ui-chart',
    templateUrl:'./ui-chart.html',
    styleUrls: [ 
        './ui-chart.scss'
    ]
})
export class UiChart implements OnInit, AfterViewInit, OnDestroy {
    @Input('print') _print = false;
    @Input('data') _data = null;
    @Input('update') _update = null;
    
    constructor(){
        // nothing to do
    }

    private _update_subscription = null;
    ngOnInit(){
        if (this._update){
            this._update_subscription = this._update.subscribe(
            (data) => {
                this.OnUpdate(data);
            });
        }
    }
    
    ngOnDestroy(){
        if (this._update_subscription){
            this._update_subscription.unsubscribe();
        }
        this._update_subscription = null;
    }
    
    @ViewChild('canvas', {static: false}) _canvas: ElementRef;    
    ngAfterViewInit(){
        this._drawChart();
    }
    
    private _chart = null;
    private _drawChart(){
        if (!this._canvas){
            setTimeout(() => {
                this._drawChart();
            }, 100);
        }
        else {
            if (this._data){
                if (this._print){
                    this._data.options['animation'] = false;
                }

                try {
                    this._chart = new Chart(this._canvas.nativeElement, this._data);
                    this._toDataUrl();                        
                } catch (error) {
                    console.error("[CHART] exception!", error);
                }
            }
        }
    }
    
    OnUpdate(chart){
        if (this._chart){
            this._chart.data.labels = chart.data.labels;
            for (let _idx=0; _idx < this._chart.data.datasets.length; _idx++){
                this._chart.data.datasets[_idx].data = chart.data.datasets[_idx].data; 
                this._chart.data.datasets[_idx].backgroundColor = chart.data.datasets[_idx].backgroundColor; 
                this._chart.data.datasets[_idx].borderColor = chart.data.datasets[_idx].borderColor; 
            }
            this._chart.update();
    
            this._toDataUrl();    
        }
    }
    
    private _dataUrl = null;

    @Output('ready') _onReady = new EventEmitter<any>();    
    private _toDataUrl(){
        if (this._print){
            let _htmlcanvas: HTMLCanvasElement = this._canvas.nativeElement;
            if (_htmlcanvas){
                this._dataUrl = _htmlcanvas.toDataURL();

                setTimeout(() => {
                    this._onReady.emit();
                }, 0);
            }
        }
    }
    
    get DataUrl(){
        return this._dataUrl;
    }
}
