import { DataObject } from './base';

export abstract class CatalogObject extends DataObject { 
    protected _catalog: CatalogObject = null;

    /********************************/
    /* CATALOG ITEM MANAGEMENT      */
    /********************************/

    get Catalog(): CatalogObject{
        return this._catalog;
    }

    set Catalog(value: CatalogObject){
        if (value != this._catalog){
            this._isVolatile = false;

            this._catalog = value;
            if (this._catalog){
                this._catalog.CopyOf = null;
            }
    
            if (this._isCatalog){
                this._isCatalog = false;  

                this.data.DelObject(this);
                this.data.AddObject(this);  
            }    
        }
    }

    // make sure that the original catalog item is stored on any change
    protected patchValue(dst, property, value){
        if ((this.IsCatalog) && (this.IsLoaded) && (!this.Catalog)){
            this.Catalog = this.Copy([]) as CatalogObject;
        }

        return super.patchValue(dst, property, value);
    }
}
