import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { Product } from './product';
import { TicketProduct } from './ticketproduct';
import { OfferPeriod } from './period';
import { OfferProduct } from './offerproduct';
import { ProductOption } from './productoption';

export interface _Offer {
    status: string;
    place: number;
    name: string;
    price: number;
    photo: {
        url: string;
        b64: any;
    },
    description: string;
    ismenu: boolean;
    allday: boolean;
};

interface _OfferData extends _Offer {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class OfferData extends DataObject {
    protected _offer: _OfferData = {
        status: null,
        place: null,
        name: null,
        price: null,
        photo: {
            url: null,
            b64: null
        },
        description: null,
        ismenu: false,
        allday: false
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._offer.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._offer.created;
    }

    get status(): string{
        return this._offer.status;
    }

    set status(value: string){
        if (this.patchValue(this._offer, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get name(): string{
        return this._offer.name;
    }

    set name(value: string){
        if (this.patchValue(this._offer, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get price(): number{
        return this._offer.price;
    }

    set price(value: number){
        if (this.patchValue(this._offer, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._offer.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._offer.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }
   
    get base64(): any {
        return this._offer.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._offer.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string{
        return this._offer.description;
    }

    set description(value: string){
        if (this.patchValue(this._offer, 'description', value)){
            this.ToUpdate = true;
        }
    }

    get ismenu(): boolean{
        return this._offer.ismenu;
    }

    set ismenu(value: boolean){
        if (this.patchValue(this._offer, 'ismenu', value)){
            this.ToUpdate = true;
        }
    }    

    get allday(): boolean{
        return this._offer.allday;
    }

    set allday(value: boolean){
        if (this.patchValue(this._offer, 'allday', value)){
            this.ToUpdate = true;
        }
    }    

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPeriod(child: OfferPeriod){
        this.AddChild('periods', child, 'offer');
    }

    DelPeriod(child: OfferPeriod){
        this.DelChild('periods', child, 'offer');
    }

    AddProduct(child: OfferProduct){
        this.AddChild('products', child, 'offer');       
    }

    DelProduct(child: OfferProduct){
        this.DelChild('products', child, 'offer');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get periods() : Array <OfferPeriod> {
        return this._chldlist['periods'] || [];
    }

    get products() : Array <OfferProduct> {
        return this._chldlist['products'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                place: this._offer.place,
                status: this._offer.status,
                name: this._offer.name,
                price: this._offer.price,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                description: this._offer.description,
                ismenu: this._offer.ismenu ? '1' : '0',
                allday: this._offer.allday ? '1' : '0'
            };    
        })();
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'offers', by: 'place' } }   // this[by -> 'place'][to -> 'offers'] => this
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.periods){
            _children.push(_item)
        }

        for(let _item of this.products){
            _children.push(_item)
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_offer: _Offer){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._offer, 'status', _offer['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'name', _offer['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'price', _offer['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'photo', _offer['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer.photo, 'b64', null) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'description', _offer['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'ismenu', _offer['ismenu']) || _toUpdate;
        _toUpdate = this.patchValue(this._offer, 'allday', _offer['allday']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_offer: _Offer){
        this.patchValue(this._offer, 'created', _offer['created']);
        
        if (this._patchData(_offer)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._offer;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_offer: _Offer){
        this._patchData(_offer);

        if (_offer['place']){     // update children: 'place'
            let _objid = _offer['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _OfferData {
        let _offer: _OfferData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            name: info['name'],
            price: info['price'] ? parseFloat(info['price']) : null,
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },            
            description: info['description'],
            ismenu: (info['ismenu'] == '1') ? true : false,
            allday: (info['allday'] == '1') ? true : false
        };
        return _offer;
    }

    protected _OnUpdate(info){
        let _offer = this._ddbb(info);
        this.patchValue(this._offer, 'objid', _offer['objid']);
        this.patchValue(this._offer, 'created', _offer['created']);
        this.DoPatchValues(_offer);

        if (info['periods']){    // update children: 'periods'
            this.SetChildren <OfferPeriod> (info['periods'], 'periods', OfferPeriod, 'offer');
        }
        
        if (info['products']) {     // update children: 'products'
            this.SetChildren <OfferProduct> (info['products'], 'products', OfferProduct, 'offer');
        }
    }
}

export class Offer extends OfferData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('OFFER', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Offer {
        return this._Copy(store) as Offer;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get type(){
        return null;    // required to work alog with discounts
    }

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.place){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.place.DelOffer(this);
            }
            else {
                this.place.DoRefresh('OFFER');
            }    
        }
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.name);
    }

    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    get products() : Array <OfferProduct>{
        let _products = [];
        for(let _product of super.products){
            if (_product.IsValid){
                _products.push(_product);
            }
        }
        return _products;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        let _valid = this.status && (this.status != 'DE');
        if (_valid){
            _valid = !this.products.some(
            (offerproduct) => {
                return !offerproduct.product.IsValid;
            });
        }
        return _valid;
    }

    get IsActive(){
        if (this._offer.status != 'AC'){
            return false;
        }

        if (this._offer.allday){
            return true;
        }

        for(let _period of this.periods){
            if (_period.IsActive){
                return true;
            }
        }
        
        return false;
    }

    get Price(){
        let _price = Number(this.price || 0);

        if (this.price !== null){
            let _parts = (Math.abs(_price)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _price.toFixed(2);
            let _neg = (_price < 0); 

            return {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        return null;
    }
    
    private _CheckApplies(_appliesto, _product: Product){
        return _appliesto.some(
        (applies) => {
            return applies == _product || (applies.isgroup && _product.IsChild(applies));
        });
    }

    Check(ticketproducts: Array <TicketProduct>, applyinfo: any = null){
        if (applyinfo == null){
            applyinfo = { applied: [], savings: 0};
        }

        // sort the ticketproducts by price (most expensive first)
        let _ticketproducts = ticketproducts.sort((a, b) => {
            let _aprice = (a.fixed) ? a.FixedPrice : a.TotalPrice;
            let _bprice = (b.fixed) ? b.FixedPrice : b.TotalPrice;

            return _bprice - _aprice;
        });

        // organize the offer products by the grouped field
        let _grouped = new Map();
        for(let _offerproduct of this.products){
            if (!(_offerproduct.grouped in _grouped)){
                _grouped[_offerproduct.grouped] = {
                    appliesto: [],
                    appliedto: null
                };
            }

            _grouped[_offerproduct.grouped].appliesto.push(_offerproduct.product);
        }

        // assign the suitable ticketproducts to their group
        for(let _ticketproduct of _ticketproducts){
            for(let _group in _grouped){
                if (_grouped[_group].appliedto != null){
                    continue;       // check next group
                }

                let _check = this._CheckApplies(_grouped[_group].appliesto, _ticketproduct.product);
                if (_check){
                    _grouped[_group].appliedto = _ticketproduct;
                    break;          // check next product                    
                }
            }
        }

        // check if all groups are satisffied
        let _applied = true;
        for(let _group in _grouped){
            if (_grouped[_group].appliedto == null){
                _applied = false;
            }
        }

        if (_applied){
            applyinfo.savings = -this._offer.price;
            applyinfo.applied = [];
    
            for(let _group in _grouped){
                let _ticketproduct: TicketProduct = _grouped[_group].appliedto
                if (_ticketproduct.fixed){
                    applyinfo.savings += (_ticketproduct.FixedPrice - _ticketproduct._OfferPrice(this));
                }
                else {
                    applyinfo.savings += (_ticketproduct.TotalPrice - _ticketproduct._OfferPrice(this));
                }
                applyinfo.applied.push(_ticketproduct);
            }                
        }

        return _applied && (applyinfo.savings >= 0);
    }

    SetProduct(oldproduct: Product, newproduct: Product){
        if (!this.IsValid){
            return null;
        }

        let _toUpdate = this.products.some(
        (offerproduct) => {
            return (offerproduct.product == oldproduct)
        });

        if (_toUpdate){
            // updating groups: only update the reference
            if (oldproduct.isgroup){
                for(let _offerproduct of this.products){
                    if (_offerproduct.product == oldproduct){
                        if (newproduct.IsValid){
                            _offerproduct.product = newproduct;
                        }
                        else {  // remove the product
                            this.DelProduct(_offerproduct);
                        }
                    }
                }

                return null;
            }

            // updating products: modify copy of offer
            else {
                let _offer: Offer = this.Copy();
                if (_offer){     // replace the product on the copy               
                    for(let _offerproduct of _offer.products){
                        if (_offerproduct.product == oldproduct){
                            if (newproduct.IsValid){
                                _offerproduct.product = newproduct;
                            }
                            else {  // remove the product
                                _offer.DelProduct(_offerproduct);
                            }
                        }
                    }
                    _offer.CopyOf = null;
                }
    
                return _offer;    
            }
        }

        return null;    // this offer is not affected
    }

    IncludesOption(option: ProductOption){
        for (let _offerproduct of this.products){
            if (!_offerproduct.IsValid){
                continue;
            }

            for (let _offeroption of _offerproduct.options){
                if (_offeroption.IsValid && (_offeroption.prodopt == option)){
                    return true;
                }
            }
        }

        return false;   // the product option is not included in this offer
    }
}


