
import { Component, Directive, AfterViewInit, OnDestroy } from '@angular/core';
import { Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
    selector: '[appDetectVisibility]'
})
export class UiVisibleDirective implements AfterViewInit, OnDestroy {
    private _observer: IntersectionObserver = null;

    constructor(private elementRef: ElementRef) {
        // nothing to do
    }
  
    @Output('OnVisible') _onvisible: EventEmitter<boolean> = new EventEmitter();
    ngAfterViewInit() {
        this._observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this._observer.disconnect();
                    this._observer = null;

                    this._onvisible.emit(true);
                } 
            });
      });
  
      this._observer.observe(this.elementRef.nativeElement);
    }

    ngOnDestroy() {
        if (this._observer){
            this._observer.disconnect();
            this._observer = null;
        }
    }
}
  
@Component({
    selector: 'ui-visible-trigger',
    template: '<div appDetectVisibility (OnVisible)="OnVisible($event)"></div>'
})
export class UiVisibleComponent {
    @Output('OnVisible') _onvisible: EventEmitter<boolean> = new EventEmitter();
    OnVisible(isVisible: boolean) {
        if (isVisible) {
            this._onvisible.emit(true);
        }
    }
}