import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Session } from './session';
import { Ticket } from './ticket';
import { TicketBAI } from './ticketbai';
import { TicketSII } from './ticketsii';
import { Business } from './business';

/*
    Reason:
    'F': First entry
    'P': Price change, 
    'I': Invoice, 
    'D': Destination change, 
    'C': Cancelation
*/

export interface _TicketChange {
    ticket: number,
    prev: number,
    session: number,
    reason: string,
    series: string,
    invoice: string,
    total: number,
    base: number,
    rate: number,
    taxes: number,
    client: number
};

interface _TicketChangeData extends _TicketChange {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketChangeData extends DataObject {
    protected _change: _TicketChangeData = {
        ticket: null,
        prev: null,
        session: null,
        reason: null,
        series: null,
        invoice: null,
        total: null,
        base: null,
        rate: null,
        taxes: null,
        client: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._change.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._change.created;
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate= true;
        }    
    }

    get prev(): TicketChange {
        return this._children['prev'] || null;
    }

    set prev(value: TicketChange){
        if (this.SetChild('prev', value, 'prev')){
            this.ToUpdate= true;
        }    
    }

    get session(): Session {
        return this._children['session'] || null;
    }

    set session(value: Session) {
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate= true;
        }    
    }

    get reason(): string {
        return this._change.reason;
    }

    set reason(value: string){
        if(this.patchValue(this._change, 'reason', value)){
            this.ToUpdate = true;
        }
    }

    get series(): string {
        return this._change.series;
    }

    set series(value: string){
        if(this.patchValue(this._change, 'series', value)){
            this.ToUpdate = true;
        }
    }

    get invoice(): string {
        return this._change.invoice;
    }

    set invoice(value: string){
        if(this.patchValue(this._change, 'invoice', value)){
            this.ToUpdate = true;
        }
    }

    get total(): number {
        return this._change.total;
    }

    set total(value: number){
        if(this.patchValue(this._change, 'total', value)){
            this.ToUpdate = true;
        }
    }

    get base(): number {
        return this._change.base;
    }

    set base(value: number){
        if(this.patchValue(this._change, 'base', value)){
            this.ToUpdate = true;
        }
    }

    get rate(): number {
        return this._change.rate;
    }

    set rate(value: number){
        if(this.patchValue(this._change, 'rate', value)){
            this.ToUpdate = true;
        }
    }

    get taxes(): number {
        return this._change.taxes;
    }

    set taxes(value: number){
        if(this.patchValue(this._change, 'taxes', value)){
            this.ToUpdate = true;
        }
    }

    get client(): Business {
        return this._children['client'] || null;
    }

    set client(value: Business){
        if (this.SetChild('client', value, 'client')){
            this.ToUpdate= true;
        }    
    }

    get ticketbai(): TicketBAI {
        let _ticketbai = this._chldlist['ticketbai'] || [];
        if (_ticketbai.length > 0){
            return _ticketbai[0];
        }
        return null;
    }

    get ticketsii(): TicketSII {
        let _ticketsii = this._chldlist['ticketsii'] || [];
        if (_ticketsii.length > 0){
            return _ticketsii[0];
        }
        return null;
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            ticket: this._change.ticket,
            prev: this._change.prev,
            session: this._change.session,
            reason: this._change.reason,
            series: this._change.series,
            invoice: this._change.invoice,
            total: this._change.total,
            base: this._change.base,
            rate: this._change.rate,
            taxes: this._change.taxes,
            client: this._change.client
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'changes', by: 'ticket' } },  // this[by -> 'ticket'][to -> 'changes'] => this
            prev: { item: this.prev, relation_info: { to: null, by: 'prev' } },             // no relation to this in this[by -> 'prev']
            client: { item: this.client, relation_info: { to: null, by: 'client' } },       // no relation to this in this[by -> 'client']
            session: { item: this.session, relation_info: { to: null, by: 'session' } }     // no relation to this in this[by -> 'session']
        };
    }

    protected get Children(){
        let _children = [];

        if (this.ticketbai){
            _children.push(this.ticketbai);
        }

        if (this.ticketsii){
            _children.push(this.ticketsii);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_change: _TicketChange){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._change, 'ticket', _change['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'prev', _change['prev']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'session', _change['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'reason', _change['reason']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'series', _change['series']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'invoice', _change['invoice']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'total', _change['total']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'base', _change['base']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'rate', _change['rate']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'taxes', _change['taxes']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'client', _change['client']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_change: _TicketChange){
        this.patchValue(this._change, 'created', _change['created']);

        if (this._patchData(_change)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._change;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_change: _TicketChange){
        this._patchData(_change);

        if (_change['ticket']){     // update children: 'ticket'
            let _objid = _change['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }

        if (_change['prev']){     // update children: 'prev'
            let _objid = _change['prev'].toString();
            this.SetChild('prev', new TicketChange(_objid, this.data, this._objoptions), 'prev')
        }
        else {
            this.SetChild('change', null, 'change');
        }

        if (_change['session']){     // update children: 'session'
            let _objid = _change['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session')
        }
        else {
            this.SetChild('session', null, 'session');
        }

        if (_change['client']){     // update children: 'client'
            let _objid = _change['client'].toString();
            this.SetChild('client', new Business(_objid, this.data, this._objoptions), 'client')
        }
        else {
            this.SetChild('client', null, 'client');
        }
    }

    private _ddbb(info): _TicketChangeData {
        let _update: _TicketChangeData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            prev: info['prev'] ? parseInt(info['prev']) : null,
            session: info['session'] ? parseInt(info['session']) : null,
            reason: info['reason'],
            series: info['series'],
            invoice: info['invoice'],
            total: info['total'] ? parseFloat(info['total']) : null,
            base: info['base'] ? parseFloat(info['base']) : null,
            rate: info['rate'] ? parseFloat(info['rate']) : null,
            taxes: info['taxes'] ? parseFloat(info['taxes']) : null,
            client: info['client'] ? parseInt(info['client']) : null
        };
        return _update;
    }

    protected _OnUpdate(info){
        let _change = this._ddbb(info);
        this.patchValue(this._change, 'objid', _change['objid']);
        this.patchValue(this._change, 'created', _change['created']);
        this.DoPatchValues(_change);   

        if (info['ticketbai']){   // update children: 'ticketbai'
            this.SetChildren <TicketBAI> (info['ticketbai'], 'ticketbai', TicketBAI, 'change');
        }

        if (info['ticketsii']){   // update children: 'ticketsii'
            this.SetChildren <TicketSII> (info['ticketsii'], 'ticketsii', TicketSII, 'change');
        }
    }
}

export class TicketChange extends TicketChangeData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETCHANGE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketChange {
        return this._Copy(store) as TicketChange;
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    async AddTicketBAI(){
        if (this.ticket.place.SendBAIEnabled){
            if (this.ticketbai){
                console.warn("[CHANGE] overwriting ticketbai instance will bave no effect");
            }
    
            let _ticketbai = new TicketBAI(null, this.data);
            if (_ticketbai){
                _ticketbai.change = this;
            }
    
            let _tbai = await _ticketbai.Refresh();
            if (_tbai != null){
                this.AddChild('ticketbai', _ticketbai, 'change')    
            }

            return _tbai;
        }
    }
    
    /****************************/
    /* CUSTOM METHODS           */
    /****************************/
    
    get isUpdated(){
        for(let _change of this.ticket.changes){
            if (_change.prev == this){
                return true;       // this change has been replaced
            }
        }
        return false;
    }

    get Reason(){
        if (this.ticket.IsCancelled || this.ticket.IsEmpty){
            if (this.reason != 'C'){
                return 'C';     // ticket cancellation
            }
        }

        if (this.ticket.price != this.total){
            return 'P';     // price has change
        }

        if (this.ticket.invceac == null){   // do not consider paid to account tickets
            if (this.ticket.Client != this.client){
                return (this.client) ? 'D' : 'I';  // client has changed : client has been set
            }    
        }

        return null;    // no reason (not found)
    }

    get isChange(){
        return (this.Reason != null);
    }
}

