import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PlaceService } from './svcplace';

export interface _ScaleDevice {
    rasppi: number;
    status: string;
    name: string;
    model: string;
    port: string;
};

interface _ScaleDeviceData extends _ScaleDevice {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class ScaleDeviceData extends DataObject {
    protected _scale: _ScaleDeviceData = {
        rasppi: null,
        status: null,
        name: null,
        model: null,
        port: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._scale.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._scale.created;
    }

    get updated(): Date {
        return this._scale.updated || this._scale.created;
    }

    get rasppi() : PlaceService {
        return this._children['rasppi'] || null;
    }

    set rasppi(value: PlaceService){
        if (this.SetChild('rasppi', value, 'rasppi')){
            this.ToUpdate = true;
        }
    }

    get status(): string{
        return this._scale.status;
    }

    set status(value: string){
        if (this.patchValue(this._scale, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get name(): string{
        return this._scale.name;
    }

    set name(value: string){
        if (this.patchValue(this._scale, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get model(): string{
        return this._scale.model;
    }

    set model(value: string){
        if (this.patchValue(this._scale, 'model', value)){
            this.ToUpdate = true;
        }
    }

    get port(): string{
        return this._scale.port;
    }

    set port(value: string){
        if (this.patchValue(this._scale, 'port', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            rasppi: this._scale.rasppi,
            status: this._scale.status,
            name: this._scale.name,
            model: this._scale.model,
            port: this._scale.port
        };
    }

    protected get Depend() {
        return {
            rasppi: { item: this.rasppi, relation_info: { to: 'scales', by: 'rasppi' } }   // this[by -> 'rasppi'][to -> 'scales'] => this
        };
    }

    protected get Children(){
        return [ /* empty */];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_scale: _ScaleDevice){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._scale, 'rasppi', _scale['rasppi']) || _toUpdate;
        _toUpdate = this.patchValue(this._scale, 'status', _scale['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._scale, 'name', _scale['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._scale, 'model', _scale['model']) || _toUpdate;
        _toUpdate = this.patchValue(this._scale, 'port', _scale['port']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_scale: _ScaleDevice){
        this.patchValue(this._scale, 'created', _scale['created']);
        this.patchValue(this._scale, 'updated', _scale['updated']);
        
        if (this._patchData(_scale)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._scale;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_scale: _ScaleDevice){
        this._patchData(_scale);

        if (_scale['rasppi']){     // update children: 'rasppi'
            let _objid = _scale['rasppi'].toString();
            this.SetChild('rasppi', new PlaceService(_objid, this.data, this._objoptions), 'rasppi')
        }
        else {
            this.SetChild('rasppi', null, 'rasppi');
        }
    }

    private _ddbb(info): _ScaleDeviceData {
        let _printer: _ScaleDeviceData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            rasppi: info['rasppi'] ? parseInt(info['rasppi']) : null,
            status: info['status'],
            name: info['name'],
            model: info['model'],
            port: info['port']
        };
        return _printer;
    }

    protected _OnUpdate(info){
        let _scale = this._ddbb(info);
        this.patchValue(this._scale, 'objid', _scale['objid']);
        this.patchValue(this._scale, 'created', _scale['created']);
        this.patchValue(this._scale, 'updated', _scale['updated']);
        this.DoPatchValues(_scale);
    }
}

export class ScaleDevice extends ScaleDeviceData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('SCALE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ScaleDevice {
        return this._Copy(store) as ScaleDevice;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.rasppi){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.rasppi.DelScale(this);
            }
            else {
                this.rasppi.DoRefresh('SCALE');
            }    
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status && this.status != 'DE');
    }

    get IsDeleted(){
        return (this.status == 'DE');
    }
}
