import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { InvoiceData } from './invoicedata';

export interface _InvoiceItem {
    invoice: number,
    name: string,
    amount: number,
    unit: number,
    total: number
};

interface _InvoiceItemData extends _InvoiceItem {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class InvoiceItemData extends DataObject {
    protected _item: _InvoiceItemData = {
        invoice: null,
        name: null,
        amount: null,
        unit: null,
        total: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._item.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._item.created;
    }

    get invoice() : InvoiceData {
        return this._children['invoice'] || null;
    }

    set invoice(value: InvoiceData){
        if (this.SetChild('invoice', value, 'invoice')){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._item.name;
    }

    set name(value: string) {
        if (this.patchValue(this._item, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._item.amount;
    }

    set amount(value: number) {
        if (this.patchValue(this._item, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    get unit(): number {
        return this._item.unit;
    }

    set unit(value: number) {
        if (this.patchValue(this._item, 'unit', value)){
            this.ToUpdate = true;
        }
    }

    get total(): number {
        return this._item.total;
    }

    set total(value: number) {
        if (this.patchValue(this._item, 'total', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            invoice: this._item.invoice,
            name: this._item.name,
            amount: this._item.amount,
            unit: this._item.unit,
            total: this._item.total
        };
    }

    protected get Depend(){
        return {
            invoice: { item: this.invoice, relation_info: { to: 'items', by: 'invoice' } }     // this[by -> 'invoice'][to -> 'items'] => this
         };
     }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_item: _InvoiceItem){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._item, 'invoice', _item['invoice']) || _toUpdate;
        _toUpdate = this.patchValue(this._item, 'name', _item['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._item, 'amount', _item['amount']) || _toUpdate;
        _toUpdate = this.patchValue(this._item, 'unit', _item['unit']) || _toUpdate;
        _toUpdate = this.patchValue(this._item, 'total', _item['total']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_item: _InvoiceItem){
        this.patchValue(this._item, 'created', _item['created']);

        if (this._patchData(_item)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._item;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_item: _InvoiceItem){
        this._patchData(_item);
    }

    private _ddbb(info): _InvoiceItemData {
        let _item: _InvoiceItemData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            
            invoice: info['invoice'] ? parseInt(info['invoice']) : null,
            name: info['name'],
            amount: info['amount'] ? parseFloat(info['amount']) : 0.0,
            unit: info['unit'] ? parseFloat(info['unit']) : 0.0,
            total: info['total'] ? parseFloat(info['total']) : 0.0
        };

        return _item;
    }

    protected _OnUpdate(info){
        let _item = this._ddbb(info);
        this.patchValue(this._item, 'objid', _item['objid']);
        this.patchValue(this._item, 'created', _item['created']);
        this.DoPatchValues(_item);   
    }
}

export class InvoiceItem extends InvoiceItemData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('INVOICEITEM', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : InvoiceItem {
        return this._Copy(store) as InvoiceItem;
    }
}
