import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { DiscountPeriod } from './period';

export interface _Discount {
    place: number;
    status: string;
    photo: {
        url: string;
        b64: any;
    },
    name: string;
    type: 'TICKETFIXED' | 'TICKETPRCNT';
    description: string;
    value: number;
    allday: boolean;
    cumulative: boolean;
};

interface _DiscountData extends _Discount {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class DiscountData extends DataObject {
    protected _discount: _DiscountData = {
        place: null,
        status: null,
        photo: {
            url: null,
            b64: null
        },
        name: null,
        type: null,
        description: null,
        value: null,
        allday: false,
        cumulative: false
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._discount.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._discount.created;
    }

    get updated(): Date {
        return this._discount.updated || this._discount.created;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get status(): string{
        return this._discount.status;
    }

    set status(value: string){
        if (this.patchValue(this._discount, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._discount.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._discount.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }
   
    get base64(): any {
        return this._discount.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._discount.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get name(): string{
        return this._discount.name;
    }

    set name(value: string){
        if (this.patchValue(this._discount, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get type(): 'TICKETFIXED' | 'TICKETPRCNT' {
        return this._discount.type;
    }

    set type(value: 'TICKETFIXED' | 'TICKETPRCNT'){
        if (this.patchValue(this._discount, 'type', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string{
        return this._discount.description;
    }

    set description(value: string){
        if (this.patchValue(this._discount, 'description', value)){
            this.ToUpdate = true;
        }
    }

    get value(): number{
        return this._discount.value;
    }

    set value(value: number){
        if (this.patchValue(this._discount, 'value', value)){
            this.ToUpdate = true;
        }
    }

    get allday(): boolean {
        return this._discount.allday;
    }

    set allday(value: boolean) {
        if (this.patchValue(this._discount, 'allday', value)){
            this.ToUpdate = true;
        }
    }

    get cumulative(): boolean {
        return this._discount.cumulative;
    }

    set cumulative(value: boolean){
        if (this.patchValue(this._discount, 'cumulative', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPeriod(child: DiscountPeriod){
        this.AddChild('periods', child, 'family');
    }

    DelPeriod(child: DiscountPeriod){
        this.DelChild('periods', child, 'family');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get periods() : Array <DiscountPeriod>{
        return this._chldlist['periods'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                place: this._discount.place,
                status: this._discount.status,
                name: this._discount.name,
                type: this._discount.type,
                value: this._discount.value,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                description: this._discount.description,
                allday: this._discount.allday ? '1' : '0',
                cumulative: this._discount.cumulative ? '1' : '0'
            };    
        })();
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'place', by: 'discount' } }   // this[by -> 'place'][to -> 'discount'] => this
        };
    }

    protected get Children(){
        let _children = [];
        
        for(let _item of this.periods){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_discount: _Discount){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._discount, 'place', _discount['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'status', _discount['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'photo', _discount['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount.photo, 'b64', null) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'name', _discount['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'type', _discount['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'value', _discount['value']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'description', _discount['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'allday', _discount['allday']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'cumulative', _discount['cumulative']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_discount: _Discount){
        this.patchValue(this._discount, 'created', _discount['created']);
        this.patchValue(this._discount, 'updated', _discount['updated']);
        
        if (this._patchData(_discount)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._discount;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_discount: _Discount){
        this._patchData(_discount);

        if (_discount['place']){     // update children: 'place'
            let _objid = _discount['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _DiscountData {
        let _discount: _DiscountData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            place: info['place'] ? parseInt(info['place']) : null,
            status: info['status'],
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },            
            name: info['name'],
            type: info['type'],
            value: info['value'] ? parseFloat(info['value']) : 0,
            description: info['description'],
            allday: (info['allday'] == '1') ? true : false,
            cumulative: (info['cumulative'] == '1') ?  true : false
        };
        return _discount;
    }

    protected _OnUpdate(info){
        let _discount = this._ddbb(info);
        this.patchValue(this._discount, 'objid', _discount['objid']);
        this.patchValue(this._discount, 'created', _discount['created']);
        this.patchValue(this._discount, 'updated', _discount['updated']);
        this.DoPatchValues(_discount);

        if (info['periods']){    // update children: 'periods'
            this.SetChildren <DiscountPeriod> (info['periods'], 'periods', DiscountPeriod, 'discount');
        }
    }
}

export class Discount extends DiscountData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('DISCOUNT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Discount {
        return this._Copy(store) as Discount;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.place){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.place.DelDiscount(this);
            }
            else {
                this.place.DoRefresh('DISCOUNT');
            }    
        }
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.name);
    }

    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status && this.status != 'DE');
    }

    get IsActive(){
        if (this._discount.status != 'AC'){
            return false;
        }

        if (this._discount.allday){
            return true;
        }

        for(let _period of this.periods){
            if (_period.IsActive){
                return true;
            }
        }
        
        return false;
    }

    CanApply(_applied: Array <Discount>){
        if (!this.IsValid || !this.IsActive){
            return false;
        }

        if (_applied.includes(this)){
            return false;
        }

        // if it is cummulative can be applied only if other cumulative are included
        if (this.cumulative){
            return !_applied.some(
            _discount => {
                return (_discount.cumulative == false);
            });
        }
        // otherwise can be applied only if no other discounts are applied
        else {
            return (_applied.length == 0);
        }
    }

    get Price(){
        let _price = Number(this.value || 0);

        if (this.value !== null){
            let _parts = (Math.abs(_price)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _price.toFixed(2);
            let _neg = (_price < 0); 

            return {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        return null;
    }
}
