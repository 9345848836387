import { Component, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'input-errors',
    templateUrl:'./ui-errors.html',
    styleUrls: [ 
        './ui-errors.scss'
    ],    
})
export class InputErrors implements OnChanges {
    @Input('modelnfo') _modelnfo = null 
    @Input('errornfo') _errornfo = null
    @Input('centered') _centered = false;
    
    public _errorkeys = []

    ngOnChanges() {
        this._errorkeys = this._errornfo ? Object.keys(this._errornfo) : [];
    }
}

