import { Injectable } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';

import { AppConstants} from '@app/app.constants'
import { HttpClient } from '@angular/common/http';

class __trString{
    private _id = null;
    private _args = [];
    private _tr = null;
    
    constructor(private lang: languageService, id: string, args: any){
        this._id = id;
        this._args = args;
    }

    get value() {
        if (!this._tr){
            this._tr = String(this.lang._translate(this._id, this._args));
        }
        return this._tr;
    }    
}

class __erString{
    private _str = null;
    
    constructor(str: string){
        this._str = str;    
    }
    
    get value() {
        return this._str;
    }
}

@Injectable()
export class languageService {
    
    private strings = new Map();
    private current = AppConstants.defaultLanguage;
    private modules = new Set();
    private trcache = new Map();

    get _strings(){
        return this.strings;
    }
    
    private _load_json(json, replace) {
        for(let i=0; i < json.length; i++){
            let entry = json[i];
            if (!replace && this.strings.has(entry.id)){
                console.warn("WARNING: existing string '" + entry.id + "'");
            }
            this.strings.set(entry.id, { args: entry.args, tr: entry.tr });
        }
    }
    
    private loading = 0;
    private _load_module(name, language, replace) {
        return new Promise((resolve) => {
            if (!this.modules.has(name)){
                this.loading++;            
                let _request = this.http.get('assets/lang/' + name + '/' + language + '.json');

                let _subscription = _request.subscribe(
                data => {
                    this._load_json(data, replace);
                    console.info("[LANGUAGE] Module '" + name + "' strings loaded for language '" + language + "'");
                    resolve(true);
                },
                error => {
                    console.error("LANGUAGE] Could not load strings for module '" + name + "'.");
                    console.error(error);
                },
                () => {
                    _subscription.unsubscribe();

                    this.modules.add(name);
                    this.loading--;
                    if (this.loading == 0){
                        this._onSetLanguage.next();
                    }
                });
            }
            else {
                resolve(false);  // already loaded
            }
        });
    }
    
    constructor(private http: HttpClient, private route: ActivatedRoute) { 
        let _set = false;
        
        // first option - user browser language
        let _userLang = navigator.language; 
        if (_userLang){
            _userLang = _userLang.substring(0, 2).toUpperCase();

            console.info("[BROWSER LANGUAGE]: " + _userLang);
            for (let _lang of this.GetLanguages()){
                if (_userLang == _lang.lang){
                    this.SetLanguage(_userLang);
                }
            }
        }

        // second option - user URL language
        if (!_set){
            let _urlLang = this.route.snapshot.queryParamMap.get("lang");
            if (_urlLang){  // use url language
                _set = true;
                this.SetLanguage(_urlLang);        
            }
        }
        
        this.LoadModule('base');
    }    
    
    public GetLanguages(){
        return [
            { lang: 'ES', flag: 'ES' },
            { lang: 'EU', flag: 'ES-PV' },
            { lang: 'EN', flag: 'GB' }
        ];
    }
    
    public LoadModule(name) {
        return this._load_module(name, this.current, false);
    }
    
    public GetLanguage(toUpper = false) {
        return (toUpper)? this.current.toUpperCase() : this.current;
    }
    
    private __supportedLanguage(language){
        switch(language){
            case 'es': return 'es';
        }
        return 'en';    // default to english
    }
        
    private RefreshCache(){
        this.trcache.clear()
    }

    private _onSetLanguage = new Subject <void> ();
    public OnSetLanguage = this._onSetLanguage.asObservable();
    
    public SetLanguage(language) {
        let _language = language ? language.substring(0,2).toLowerCase() : this.current; 
        if (_language == this.current){
            return;
        }
        
        this.current = _language;
        if (this.loading > 0){
            setTimeout(() => {
                this.SetLanguage(language);
            }, 100);
        }
        else {
            let _modules = new Set(this.modules);    // make a copy
            this.modules.clear();
            
            let _promises = [];
            _modules.forEach((_module) => {
                _promises.push(this._load_module(_module, _language, true));
            })

            Promise.all(_promises).then(
            data => {
                this.current = _language;
                this.RefreshCache();
                this._onSetLanguage.next();
            });
       }
    }

    private _trerror(id, args, errstr) {
        if(errstr) {
            console.error(errstr);
        }

        let tr = id + " [ ";
        for(let i=0; i < args.length; i++) {
            tr += args[i] + ", ";
        }
        if (args.length > 0) {
            tr = tr.slice(0, -2);
        }
        
        tr += " ]";
        return tr;
    }
    
    public _translate(id, args) {
        let trinfo = this.strings.get(id) || null;
        
        if (!trinfo.args) trinfo.args = [];     // add empty arguments list as default
        if (trinfo.args.length != args.length) {
            return this._trerror(id, args, "ERROR: invalid number of arguments for translation [" + id + "]");
        }
        
        let tr = trinfo.tr;
        for (let i=0; i < trinfo.args.length; i++) {
            let re = new RegExp("{{" + trinfo.args[i] + "}}", "g");
            tr = tr.replace(re, args[i]);
        }
        
        return tr;
    }

    public tr(id, args = []) {
        let _key = JSON.stringify({ id: id, args: args });
        let _str = this.trcache.get(_key) || null;
        if (!_str){
            _str = new __erString((this.loading > 0) ? '...' : this._trerror(id, args, null));
            if (this.strings.has(id)){
                this.trcache.set(_key, _str = new __trString(this, id, args));
            }
        }
        return _str.value;
    }
}

export class languageSupport {
    public language = null;    // to be used by the language pipe

    private _strcache = new Map();
    private _argcache = new Map();

    public _lang: languageService = null;
    public _chng: ChangeDetectorRef = null;

    private _language_subscription = null;
    constructor(lang: languageService, chng: ChangeDetectorRef){
        this._lang = lang;
        this._chng = chng;

        this.language = this._lang.GetLanguage();
        this._language_subscription = this._lang.OnSetLanguage.subscribe(
        data => {
            this.language = this._lang.GetLanguage();

            this._strcache = new Map();
            this._argcache = new Map();

            if (this._chng){
                this._chng.detectChanges();
            }
        });    
    }
    
    OnDestroy(){
        if (this._language_subscription){
            this._language_subscription.unsubscribe();
        }
        this._language_subscription = null;
    }

    private _translate(trinfo, args) {
        if (!trinfo.args) trinfo.args = [];     // add empty arguments list as default
        if (trinfo.args.length == args.length) {
            return null;
        }
        
        let tr = trinfo.tr;
        for (let i=0; i < trinfo.args.length; i++) {
            let re = new RegExp("{{" + trinfo.args[i] + "}}", "g");
            tr = tr.replace(re, args[i]);
        }
        
        return tr;
    }

    public tr(id, args = []): string {
        if (args.length == 0){
            let _str = this._strcache.get(id) || null;
            if (_str){
                return _str;
            }

            _str = this._lang.tr(id, args);
            if ((_str[0] != '@') && (args.length == 0)) {   // not translated or has arguments (do not cache)
                this._strcache.set(id, _str); 
            }
            return _str;
        }
        else {
            if (this._argcache.has(id)){
                let _str = this._translate(this._argcache.get(id), args);
                if (_str){
                    return _str;
                }
            }

            let _str = this._lang._strings.get(id) || null;
            if (_str){
                this._argcache.set(id, _str);
                _str = this._translate(_str, args);
                if (_str){
                    return _str;
                }
            }

            return this._lang.tr(id, args);
        }
    }
}

