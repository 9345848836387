import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { InvoiceItem } from './invoiceitem';

export interface _InvoiceData {
    place: number,
    issued: string,
    series: string,
    invoice: string,
    seller_name: string,
    seller_taxid: string,
    buyer_name: string,
    buyer_taxid: string,
    total: number,
    rate: number,
    base: number,
    taxes: number
};

interface _InvoiceDataData extends _InvoiceData {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class InvoiceDataData extends DataObject {
    protected _invoice: _InvoiceDataData = {
        place: null,
        issued: null,
        series: null,
        invoice: null,
        seller_name: null,
        seller_taxid: null,
        buyer_name: null,
        buyer_taxid: null,
        total: null,
        rate: null,
        base: null,
        taxes: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._invoice.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._invoice.created;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get issued(): string {
        return this._invoice.issued;
    }

    set issued(value: string) {
        if (this.patchValue(this._invoice, 'issued', value)){
            this.ToUpdate = true;
        }
    }

    get series(): string {
        return this._invoice.series;
    }

    set series(value: string) {
        if (this.patchValue(this._invoice, 'series', value)){
            this.ToUpdate = true;
        }
    }

    get invoice(): string {
        return this._invoice.invoice;
    }

    set invoice(value: string) {
        if (this.patchValue(this._invoice, 'invoice', value)){
            this.ToUpdate = true;
        }
    }

    get sellername(): string {
        return this._invoice.seller_name;
    }

    set sellername(value: string) {
        if (this.patchValue(this._invoice, 'seller_name', value)){
            this.ToUpdate = true;
        }
    }

    get sellertaxid(): string {
        return this._invoice.seller_taxid;
    }

    set sellertaxid(value: string) {
        if (this.patchValue(this._invoice, 'seller_taxid', value)){
            this.ToUpdate = true;
        }
    }

    get buyername(): string {
        return this._invoice.buyer_name;
    }

    set buyername(value: string) {
        if (this.patchValue(this._invoice, 'buyer_name', value)){
            this.ToUpdate = true;
        }
    }

    get buyertaxid(): string {
        return this._invoice.buyer_taxid;
    }

    set buyertaxid(value: string) {
        if (this.patchValue(this._invoice, 'buyer_taxid', value)){
            this.ToUpdate = true;
        }
    }

    get total(): number {
        return this._invoice.total;
    }

    set total(value: number) {
        if (this.patchValue(this._invoice, 'total', value)){
            this.ToUpdate = true;
        }
    }

    get rate(): number {
        return this._invoice.rate;
    }

    set rate(value: number) {
        if (this.patchValue(this._invoice, 'rate', value)){
            this.ToUpdate = true;
        }
    }

    get base(): number {
        return this._invoice.base;
    }

    set base(value: number) {
        if (this.patchValue(this._invoice, 'base', value)){
            this.ToUpdate = true;
        }
    }

    get taxes(): number {
        return this._invoice.taxes;
    }

    set taxes(value: number) {
        if (this.patchValue(this._invoice, 'taxes', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddItem(child: InvoiceItem){
        this.AddChild('items', child, 'invoice');
    }

    DelItem(child: InvoiceItem){
        this.DelChild('items', child, 'invoice');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get items() : Array <InvoiceItem> {
        return this._chldlist['items'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            place: this._invoice.place,
            issued: this._invoice.issued,
            series: this._invoice.series,
            invoice: this._invoice.invoice,
            seller_name: this._invoice.seller_name,
            seller_taxid: this._invoice.seller_taxid,
            buyer_name: this._invoice.buyer_name,
            buyer_taxid: this._invoice.buyer_taxid,
            total: this._invoice.total,
            rate: this._invoice.rate,
            base: this._invoice.base,
            taxes: this._invoice.taxes
        };
    }

    protected get Depend(){
        return {
            place: { item: this.place, relation_info: { to: 'invoices', by: 'place' } }     // this[by -> 'place'][to -> 'invoices'] => this
         };
     }

    protected get Children(){
        let _children = [];

        for(let _item of this.items){
            _children.push(_item);
        } 

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_invoice: _InvoiceData){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._invoice, 'place', _invoice['place']) || _toUpdate;

        _toUpdate = this.patchValue(this._invoice, 'issued', _invoice['issued']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'series', _invoice['series']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'invoice', _invoice['invoice']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'seller_name', _invoice['seller_name']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'seller_taxid', _invoice['seller_taxid']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'buyer_name', _invoice['buyer_name']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'buyer_taxid', _invoice['buyer_taxid']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'total', _invoice['total']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'rate', _invoice['rate']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'base', _invoice['base']) || _toUpdate;
        _toUpdate = this.patchValue(this._invoice, 'taxes', _invoice['taxes']) || _toUpdate;

        return _toUpdate;
    }

    set Data(_invoice: _InvoiceData){
        this.patchValue(this._invoice, 'created', _invoice['created']);

        if (this._patchData(_invoice)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._invoice;
    }

    set Info(value){
        this.DoPatchValues(value);   
    }

    private DoPatchValues(_invoice: _InvoiceData){
        this._patchData(_invoice);
    }

    private _ddbb(info): _InvoiceDataData {
        let _invoice: _InvoiceDataData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            
            place: info['place'] ? parseInt(info['place']) : null,
            issued: info['issued'],
            series: info['series'],
            invoice: info['invoice'],
            seller_name: info['seller_name'],
            seller_taxid: info['seller_taxid'],
            buyer_name: info['buyer_name'],
            buyer_taxid: info['buyer_taxid'],
            total: info['total'] ? parseFloat(info['total']) : 0.0,
            rate: info['rate'] ? parseFloat(info['rate']) : 0.0,
            base: info['base'] ? parseFloat(info['base']) : 0.0,
            taxes: info['taxes'] ? parseFloat(info['taxes']) : 0.0
        };
        return _invoice;
    }

    protected _OnUpdate(info){
        let _invoice = this._ddbb(info);
        this.patchValue(this._invoice, 'objid', _invoice['objid']);
        this.patchValue(this._invoice, 'created', _invoice['created']);
        this.DoPatchValues(_invoice);  
        
        if ('items' in info){     // update children 'items'
            this.SetChildren <InvoiceItem> (info['items'], 'items', InvoiceItem, 'invoice');
        }        
    }
}

export class InvoiceData extends InvoiceDataData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('INVOICEDATA', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : InvoiceData {
        return this._Copy(store) as InvoiceData;
    }
}
