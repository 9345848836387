import { Injectable, Component, OnInit  } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { Subject } from 'rxjs';

import { GenericUtils } from '@app/app.commonutils';

@Component({
    selector: 'modal-alert',
    templateUrl: './alert/modal-alert.html',
    styleUrls: [
        './alert/modal-alert.scss'
    ]
})
export class modalAlert implements OnInit {    
    constructor(private modalCtrl: ModalController, private params: NavParams) {
        // nothing to do
    }
    
    normalize(str){
        return GenericUtils.Normalize(str)
    }

    public _params: any = {};
    public _data: any = {};
    public _close: boolean = true;

    ngOnInit(){
        this._params = this.params.get('data');

        if (this._params && ('inputs' in this._params)){
            for(let _input of this._params['inputs']){
                if (_input.type == 'radiobutton'){
                    continue;   // get value from radiogroup
                }

                this._data[_input.name] = _input.value || null;
            }                
        }

        if (this._params && ('backdropDismiss' in this._params)){
            this._close = this._params['backdropDismiss'];
        }

    }

    OnClose(){
        this.modalCtrl.dismiss(null);
    }

    IsText(_input){
        return !this.IsCheck(_input) && !this.IsRadio(_input);
    }

    IsCheck(_input){
        return _input.type == 'checkbox';
    }

    IsRadio(_input){
        return _input.type == 'radiogroup' || _input.type == 'radiobutton';
    }

    public _radio: any = [];
    CheckRadio(name){
        if (this._radio.indexOf(name) != -1){
            return false;
        }

        this._radio.push(name);
        return true;
    }

    OnTextChange(value, input){
        this._data[input.name] = value;
    }

    OnCheckChange(value, input){
        // nothing to do
    }
}

export class AlertModalController {
    private _onDismiss = new Subject<any> ();
    private OnDismiss = this._onDismiss.asObservable();
    
    private _options = null;

    constructor(private modalCtrl: ModalController, options: any){
        this._options = options;
    }

    async present(){
        const _modal = await this.modalCtrl.create({
            component: modalAlert,
            backdropDismiss: ('backdropDismiss' in this._options) ?  this._options['backdropDismiss'] : true,
            cssClass: 'modal-alert',
            componentProps: { 
                data: this._options
            }
        });

        _modal.onDidDismiss().then(data => {
            this._onDismiss.next(data);
        });

        await _modal.present();
    }

    async dismiss(data){
        this.modalCtrl.dismiss(data);
    }

    onDidDismiss(){
        return new Promise(async (resolve) => {
            let _subscription = this.OnDismiss.subscribe(
            data => {
                _subscription.unsubscribe();
                resolve(data);
            });
        })
    }
}

@Injectable()
export class alertService {
    private _alert_present = false;
           
    constructor(private modalCtrl: ModalController) {
        // nothing to do
    }

    alert(options){
        return new AlertModalController(this.modalCtrl, options);
    }
}    
