import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Session } from './session';
import { Place } from './place';
import { Audit } from './audit';

export interface _CashChange {
    session: number,
    place: number,
    audit: number,
    concept: string,
    amount: number
};

interface _CashChangeData extends _CashChange {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class CashChangeData extends DataObject {
    protected _change: _CashChangeData = {
        session: null,
        place: null,
        audit: null,
        concept: null,
        amount: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._change.created || new Date();
    }

    get session(): Session{
        return this._children['session'] || null;
    }

    set session(value: Session){
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate = true;
        }            
    }

    get place() : Place{
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }    
    }

    get audit() : Audit{
        return this._children['audit'] || null;
    }

    set audit(value: Audit){
        if (this.SetChild('audit', value, 'audit')){
            this.ToUpdate = true;
        }    
    }

    get concept(): string {
        return this._change.concept;
    }

    set concept(value: string){
        if (this.patchValue(this._change, 'concept', value)){
            this.ToUpdate = true;
        }
    }

    get amount(): number {
        return this._change.amount;
    }

    set amount(value: number){
        if (this.patchValue(this._change, 'amount', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            session: this._change.session,
            place: this._change.place,
            audit: this._change.audit,
            concept: this._change.concept,
            amount: this._change.amount
        };
    }

    protected get Depend() {
        return {
            session: { item: this.session, relation_info: { to: null, by: 'session' } },    // no relation to this in this[by -> 'session']
            place: { item: this.place, relation_info: { to: 'cashchange', by: 'place' } },  // this[by -> 'place'][to -> 'cashchange'] => this
            audit: { item: this.audit, relation_info: { to: 'cashchange', by: 'audit' } }   // this[by -> 'audit'][to -> 'cashchange'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_change: _CashChange){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._change, 'session', _change['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'place', _change['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'audit', _change['audit']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'concept', _change['concept']) || _toUpdate;
        _toUpdate = this.patchValue(this._change, 'amount', _change['amount']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_change: _CashChange){
        this.patchValue(this._change, 'created', _change['created']);
        
        if (this._patchData(_change)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._change;
    }

    set Info(value){
        this.DoPatchValues(value);
    }
   
    private DoPatchValues(_change: _CashChange){
        this._patchData(_change);

        if (_change['session']){    // update children: 'session'
            let _objid = _change['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session')
        }
        else {
            this.SetChild('session', null, 'session');
        }

        if (_change['place']){    // update children: 'place'
            let _objid = _change['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }

        if (_change['audit']){    // update children: 'audit'
            let _objid = _change['audit'].toString();
            this.SetChild('audit', new Audit(_objid, this.data, this._objoptions), 'audit')
        }
        else {
            this.SetChild('audit', null, 'audit');
        }        
    }

    private _ddbb(info): _CashChangeData {
        let _change: _CashChangeData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            session: info['session'] ? parseInt(info['session']) : null,
            place: info['place'] ? parseInt(info['place']) : null,
            audit: info['audit'] ? parseInt(info['audit']) : null,
            concept: info['concept'] ? info['concept'] : null,
            amount: info['amount'] ? parseFloat(info['amount']) : null
        };
        return _change;
    }

    protected _OnUpdate(info){
        let _change = this._ddbb(info);
        this.patchValue(this._change, 'objid', _change['objid']);
        this.patchValue(this._change, 'created', _change['created']);
        this.DoPatchValues(_change);
    }
}

export class CashChange extends CashChangeData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('CASHCHANGE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): CashChange {
        return this._Copy(store) as CashChange;
    }
}


