import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { ModalController, NavParams } from '@ionic/angular';

import { AppConstants} from '@app/app.constants';
import { platformService } from '@app/modules/common/platform';
import { httpService } from '@app/modules/common/http';
import { alertService } from '@app/modules/common/alert';
import { toastService } from '@app/modules/common/toast';
import { languageService } from '@app/modules/common/language';

/************************************/
/* GOOGLE ADDRESS D                 */
/************************************/

export interface Address {
    formatted: string;
    location: {
        lat : number,
        lng : number
    };
    number: string;
    street: {
        route: string,
        locality: string,
    };
    postal_code: string;
    area: {
        level1: string,
        level2: string
    },
    timezone: string;
}

/************************************/
/* MODAL FAR AWAY                   */
/************************************/

@Component({
    selector: 'modal-away',
    templateUrl: './away/modal-away.html',
    styleUrls: [
        './away/modal-away.scss'
    ]    
})
export class modalAway implements OnInit {
    public _center = null;

    constructor(private modalCtrl: ModalController, private params: NavParams) {
        // nothing to do
    }

    ngOnInit(){
        this._center = this.params.get('center');
    }

    private inrange: number = 250;
    OnChange(data){
        if (data.distance < (this.inrange + data.accuracy)){
            this.modalCtrl.dismiss(true);
        }
    }

    OnClose(){
        this.modalCtrl.dismiss(false);
    }
}

/************************************/
/* GEOCODE SERVICE                  */
/************************************/

@Injectable()
export class geocodeService {
    private _mapsurl = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + AppConstants.googleApiKey;
    private _tmznurl = 'https://maps.googleapis.com/maps/api/timezone/json?key=' + AppConstants.googleApiKey;

    private _requestCache = new Map();
    private _resolveCache = new Map();
    private _timezneCache = new Map();

    private _requestAndroidPermissions() {
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION);
    }
    
    constructor(private androidPermissions: AndroidPermissions, private geolocation: Geolocation, private http: httpService, private lang: languageService, private modalCtrl: ModalController, private alertCtrl: alertService, private toast: toastService, private platform: platformService) { 
        this._requestAndroidPermissions();  // request location permission for android devices
    }
    
    // https://developers.google.com/maps/documentation/geocoding/start?hl=es
    private _parseGoogleResponse(response) {
        let address: Address = {
            formatted: '',
            location: {
                lat: 0.0,
                lng: 0.0
            },
            number: '',
            street: {
                route: '',
                locality: ''
            },
            postal_code: '',
            area: {
                level1: '',
                level2: ''
            },
            timezone: 'UTC'
        };
        
        // https://developers.google.com/maps/documentation/geocoding/intro?hl=es#GeocodingResponses
        if ((response.status != 'OK') || (response.results.length == 0)) {
            console.error("[GEOCODE] Parsing google response", response);
            if (response.status != 'ZERO_RESULTS'){
                this.toast.ShowAlert('danger', this.lang.tr('@gmaps_request_error', [ response.status, response.error_message ]));
            }
            return null;
        }
        
        let data = response['results'][0];
        for(let i=0; i < data['address_components'].length; i++) {
            if (data.address_components[i]['types'].indexOf('street_number') != -1) {
                address.number = data.address_components[i]['long_name'];
            }
            
            if (data.address_components[i]['types'].indexOf('route') != -1) {
                address.street.route = data.address_components[i]['long_name'];
            }

            if (data.address_components[i]['types'].indexOf('locality') != -1) {
                address.street.locality = data.address_components[i]['long_name'];
            }
            
            if (data.address_components[i]['types'].indexOf('postal_code') != -1) {
                address.postal_code = data.address_components[i]['long_name'];
            }

            if (data.address_components[i]['types'].indexOf('administrative_area_level_1') != -1) {
                address.area.level1 = data.address_components[i]['long_name'];
            }
            
            if (data.address_components[i]['types'].indexOf('administrative_area_level_2') != -1) {
                address.area.level2 = data.address_components[i]['long_name'];
            }
        }
        
        address.formatted = data['formatted_address'];
        address.location = {
            lat: parseFloat(data['geometry']['location']['lat']),
            lng: parseFloat(data['geometry']['location']['lng'])
        };
        
        return address;
    }
    
    StaticUrl(lat, lng, width, height, zoom=15) {
        let _url = 'https://maps.googleapis.com/maps/api/staticmap';
        _url += '?key=' + AppConstants.googleApiKey;
        _url += '&size=' + width + "x" + height; 
        _url += '&center=' + lat + "," + lng + '&zoom=' + zoom;
        _url += '&markers=color:red|'+ lat + ',' + lng;
        return _url;
    }

    RequestAddress(lat, lng) {
        return new Promise <any> ((resolve) => {
            let latlng =  lat + ',' + lng;
            if (this._requestCache.has(latlng)) {
                setTimeout(() => {
                    resolve(this._requestCache.get(latlng));
                }, 10);
            }
            else {
                let _request = this.http.get(this._mapsurl + '&latlng=' + latlng, false);
                let _subscription = _request.subscribe(
                data => {
                    let _address = this._parseGoogleResponse(data)
                    if (_address) {
                        this._requestCache.set(latlng, _address);
                    }
                    
                    resolve(_address);
                },
                error => {
                    if (error.status != 0){
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [ error.status ]));
                    }
                    else {
                        console.error("[HTTP] status = 0");
                    }
                    
                    resolve(null);
                },
                () => {
                    _subscription.unsubscribe();
                });
            }
        });
    }
    
    RequestTimezne(lat, lng) {
        return new Promise <string> ((resolve) => {
            let latlng =  lat.toFixed(4) + ',' + lng.toFixed(4);
            if (this._timezneCache.has(latlng)) {
                setTimeout(() => {
                    resolve(this._timezneCache.get(latlng));
                }, 10);
            }
            else {
                let tstamp = Math.floor(new Date().getTime() / 1000);

                let _request = this.http.get(this._tmznurl + '&location=' + latlng + '&timestamp=' + tstamp, false);
                let _subscription = _request.subscribe(
                data => {
                    if (data && data['timeZoneId']) {
                        this._timezneCache.set(latlng, data['timeZoneId']);
                    }                    
                    resolve(data['timeZoneId']);
                },
                error => {
                    if (error.status != 0){
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [ error.status ]));
                    }
                    else {
                        console.error("[HTTP] status = 0");
                    }
                    
                    resolve('UTC');     // fallback to UTC on error
                },
                () => {
                    _subscription.unsubscribe();
                });    
            }
        });        
    }

    ResolveAddress(address) {
        return new Promise((resolve) => {        
            if (this._resolveCache.has(address)) {
                setTimeout(() => {
                    resolve(this._resolveCache.get(address));
                }, 10);
            }
            else {
                let _request = this.http.get(this._mapsurl + '&address=' + address, false);
                let _subscription = _request.subscribe(
                data => {
                    let _address = this._parseGoogleResponse(data);
                    if (_address) {
                        this._resolveCache.set(address, _address);
                    }
                    resolve(_address);
                },
                error => {
                    if (error.status != 0){
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [ error.status ]));
                    }
                    else {
                        console.error("[HTTP] status = 0");
                    }
                    resolve(false);
                },
                () => {
                    _subscription.unsubscribe()
                });
            }
        });
    }
    
    get CanGeolocate(){
        if (this.platform.is('cordova')){
            return true;
        }
        else {
            return navigator.geolocation;
        }
    }
    
    GetCurrentPosition(resolve, reject) {
        if (this.platform.is('cordova')){
            this.geolocation.getCurrentPosition()
            .then((resp) => {
                resolve(resp)
            })
            .catch((error) => {
                reject(error);
            });
        }
        else {
            let options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };    

            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        }
    }

    /***************************/
    /* WATCH POSITION          */
    /***************************/

    private _positionChanged = new Subject<any> ();
    public OnPositionChanged = this._positionChanged.asObservable();

    private _watchpositionid = null;
    private _watchposition = {
        granted: false,
        latitude: 0,
        longitude: 0,
        accuracy: 0
    };

    get CurrentPosition(){
        return this._watchposition;
    }

    // https://stackoverflow.com/questions/365826/calculate-distance-between-2-gps-coordinates
    DistanceTo(coordinates){
        let _earthRadius = 6371;

        function degreesToRadians(degrees) {
          return degrees * Math.PI / 180;
        }
  
        let _dLat = degreesToRadians(this.CurrentPosition.latitude - coordinates.latitude);
        let _dLon = degreesToRadians(this.CurrentPosition.longitude - coordinates.longitude);

        let _lat1 = degreesToRadians(coordinates.latitude);
        let _lat2 = degreesToRadians(this.CurrentPosition.latitude);

        let _a = Math.sin(_dLat/2) * Math.sin(_dLat/2) + Math.sin(_dLon/2) * Math.sin(_dLon/2) * Math.cos(_lat1) * Math.cos(_lat2);
        let _c = 2 * Math.atan2(Math.sqrt(_a), Math.sqrt(1 - _a));

        return Math.abs(Math.round(_earthRadius * _c * 1000));
    }

    private StartWatch(){
        return new Promise <boolean> ((resolve) => {
            if (this._watchpositionid){
                resolve(true);
                this._positionChanged.next();   // already started
            }
            else {
                if (this.platform.is('cordova')){
                    this._watchpositionid = this.geolocation.watchPosition().subscribe(
                    data => {
                        this._positionChanged.next();
                        console.error("[GEOCODE] geolocation: ", data); // TODO
                    });
                }
                else {
                    let options = {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    };    
        
                    this._watchpositionid = navigator.geolocation.watchPosition(
                    (pos) => {
                        let _nextposition = {
                            granted: true,
                            latitude: pos.coords.latitude,
                            longitude: pos.coords.longitude,
                            accuracy: pos.coords.accuracy
                        }

                        if (JSON.stringify(this._watchposition) != JSON.stringify(_nextposition)){
                            this._watchposition = _nextposition;

                            resolve(true);
                            this._positionChanged.next();
                        }
                    },
                    (err) => {
                        this._watchpositionid = null;
                        
                        console.error("[GEOLOCATION] error on watchposition: ", err.message);
                        switch(err.code){
                            case 1:
                                this.toast.ShowAlert('danger', this.lang.tr('@geolocation_denied'));
                                break;
                            case 2:
                                this.toast.ShowAlert('danger', this.lang.tr('@geolocation_unavailable'));
                                break;
                            case 3:
                                this.toast.ShowAlert('danger', this.lang.tr('@geolocation_expired'));
                                break;
    
                            default:
                                this.toast.ShowAlert('danger', this.lang.tr('@geolocation_error', [ err.message, err.code ]))
                                break;
                        }
                        
                        resolve(false);
                    }, 
                    options);
                }    
            }    
        });
    }

    private ClearWatch(){
        if (this._watchpositionid){
            if (this.platform.is('cordova')){
                this._watchpositionid.unsubscribe();
            }
            else {
                navigator.geolocation.clearWatch(this._watchpositionid);
            }

            this._watchposition = {
                granted: this._watchposition.granted,
                latitude: 0,
                longitude: 0,
                accuracy: 0
            };

            this._watchpositionid = null;
        }
    }
  
    private _nearby_farawaymodal = null;        // keep control of away modal being displayed

    private ShowAway(center){
        return new Promise(async (resolve) => {
            const modal = await this.modalCtrl.create({ 
                component: modalAway,
                backdropDismiss: false,
                componentProps: { 
                    center: center,
                }
            });
            
            modal.onDidDismiss().then((detail) => {
                this._nearby_farawaymodal = null;
                resolve(detail.data);
            });
            
            if (this._nearby_farawaymodal){
                await modal.present();        
            }
        });
    }
    
    private AskPermission(){
        return new Promise <boolean> (async (resolve) => {
            const alert = await this.alertCtrl.alert({
                header: this.lang.tr('@require_geolocate_title'),
                message: this.lang.tr('@require_geolocate_message'),
                buttons: [{
                    text: this.lang.tr('@ok'),
                    handler: () => {
                        alert.dismiss(true);
                    }
                }]
            });
            
            alert.onDidDismiss().then(
            data => {
                setTimeout(async () => {
                    resolve(await this.StartWatch());
                }, 100);
            });                

            await alert.present();            
        });
    }

    private IsNear(center, radius){
        return (this.DistanceTo(center) <= radius + this.CurrentPosition.accuracy)
    }

    WaitForNear(center, radius = 250){
        return new Promise((resolve) => {
            if (this.CurrentPosition){
                if (this.IsNear(center, radius)){
                    resolve(true);
                }
                else {
                    if (!this._nearby_farawaymodal){
                        this._nearby_farawaymodal = true;
                        this.ShowAway(center).then(
                        data => { 
                            this._nearby_farawaymodal = false;
                            resolve(data ? true : false);
                        });
                    }
                }
            }
            else {
                let _subscription = this.OnPositionChanged.subscribe(
                data => {
                    _subscription.unsubscribe();   
                    resolve(this.WaitForNear(center, radius));
                });             
            }
        });
    }

    NearBy(center, radius = 250){
        return new Promise <boolean> (async (resolve) => {
            // use center = null to disable requirement
            if (center == null){
                console.info("[GEOLOCATE] disabling near-by restrinction")
                
                this.ClearWatch();

                if (this._nearby_farawaymodal){
                    this._nearby_farawaymodal = false;
                }

                resolve(true);    // disabled
            }
            else {
                if (!this._watchpositionid){     // we must start watching
                    console.info("[GEOLOCATE] near-by restrinction enabled to " + radius + " metres");

                    let _granted = this._watchposition.granted;
                    if (!_granted){
                        _granted = await this.AskPermission();
                    }
                    else {
                        _granted = await this.StartWatch();
                    }
    
                    if (!_granted){     // permission not granted
                        resolve(false);
                    }
                }

                if (this._watchpositionid){
                    this.WaitForNear(center, radius).then(
                    data => {
                        resolve(data ? true : false);
                    });        
                }
            }
        });
    }
}

