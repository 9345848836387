import { AppConstants } from '@app/app.constants';
import { GenericUtils } from '@app/app.commonutils';

import { dataService, dataStorage } from '@app/modules/data';

import { Subject } from 'rxjs';

/******************************/
/* BASE CLASS                 */
/******************************/

export interface ObjectOptions {
    volatile?: boolean;
    storage?: dataStorage;
}

export abstract class BaseObject {
    protected _toCommit = false;    // resolving changes to be commited 
    protected _inCommit = false;    // changes have been provided to server
    protected _toInsert = false;    // this instance must be added
    protected _toUpdate = false;    // this isntance must be updated
    protected _toDelete = false;    // this instance must be deleted (hard delete)
    protected _toNotify = false;    // this instance is for registry notification
    protected _isLoaded = false;    // this instance has been loaded from server

    protected _objoptions: ObjectOptions = null;
    protected _isVolatile = false;  // volatile object: do not insert in database
    protected _isCatalog = false;   // catalog object
    protected _storage = null;      // object volatile storage

    abstract get ToInsert();
    abstract get ToUpdate();

    get refid(){
        return this._uuid;
    }

    private _simplehash = null;
    get hash(){
        if (!this._simplehash){
            let _info = {};
    
            for (let key in this.Info) {
                if (['objid', 'created', 'updated'].includes(key)){
                    continue;
                }
                _info[key] = String(this.Info[key]);
            }

            this._simplehash = GenericUtils.simpleHash(JSON.stringify(_info));
        }

        return this._simplehash;
    }

    get objref(){
        return (this.objid && !isNaN(Number(this.objid))) ? this.objid : this._uuid;
    }

    get ctgref(){
        return null;
    }

    constructor(public table: string, public objid: string, protected data: dataService, objoptions: ObjectOptions){
        this._objoptions = objoptions || {};

        this._isVolatile = ('volatile' in this._objoptions) && (this._objoptions['volatile']);
        if (!this._isVolatile){
            this._toInsert = (objid == null);
        }
        else {
            this._storage = 'storage' in this._objoptions ? this._objoptions['storage'] : null;
        }
    }

    protected OnDestroy(){
        // nothing to do
    }

    ToStorage(storage){
        // check if trying to add to custom storage
        if (this._storage){
            return this._storage == storage;
        }

        // we are trying to add to the main storage
        if (this.IsVolatile){
            return false;
        }

        return (this.data.storage == storage)
    }

    get IsCatalog(): boolean{
        return this._isCatalog;
    }

    set IsCatalog(value: boolean){
        this._isCatalog = value;
    }

    get IsLoaded(){
        return this._isLoaded;
    }

    set IsLoaded(value: boolean){
        this._isLoaded = value;
    }

    get InCommit(){
        return this._inCommit;
    }

    get IsVolatile(){
        return this._isVolatile;
    }

    abstract set Data(value);
    abstract get Info();
    abstract set Info(value);

    abstract get CopyOf();

    abstract OnResolve();
    abstract DoRefresh(table: string);
    abstract UpRefresh();

    /********************************/
    /* REGISTER FOR UPDATES         */
    /********************************/

    protected _onUpdated = new Subject<any>();
    public OnUpdated = this._onUpdated.asObservable();

    public DoUpdate(){
        console.error("[ERROR] Can only force updates for session instances");
    }

    protected abstract OnUpdate(data: any);   
    public OnChange(change){
        if ((change['table'] == this.table) && this.OnUpdate(change)){
            this._simplehash = null;
            
            this.DoRefresh(this.table);

            this._toInsert = false;
            this._toUpdate = false;
            this._toDelete = false;

            this._onUpdated.next();
        }
    }

    /********************************/
    /* COMMIT CHANGES TO SERVER     */
    /********************************/
    
    protected get Relation() {
        let _info = {
            table: this.table
        }

        if (this.objid){
            _info['objid'] = this.objid;
        }
        else {
            _info['_uuid'] = this._uuid;
        }

        return _info;
    }

    protected get Requires() {
        let _requires = new Map();
        for(let field in this.Depend){
            if (this.Depend[field]){
                let _depend = this.Depend[field];
                if (!_depend.item){
                    continue;   // no item provided
                }

                _requires[field] = {
                    intofrom: _depend.relation_info,
                    relation: _depend.item.Relation,
                    requires: _depend.item.Requires,
                };        
            }
        }
        return _requires;
    }        

    private get Entrynfo(){
        return (async () => {
            let _entrynfo = await this.Change;

            if (_entrynfo){
                _entrynfo['table'] = this.table;

                if (this._toUpdate) {
                    _entrynfo['_actn'] = 'do_update';
                }

                if (this._toInsert){
                    _entrynfo['_actn'] = 'do_insert';
                }

                if (this._toDelete){
                    _entrynfo['_actn'] = 'do_delete';
                }            
    
                if (this._toNotify){
                    _entrynfo['_actn'] = 'do_regstr';
                }

                if (this.objid){
                    _entrynfo['objid'] = this.objid;
                }
                else {
                    _entrynfo['_uuid'] = this._uuid;
                }
            }
    
            return _entrynfo;    
        })();
    }

    protected abstract get Depend();
    protected abstract get Children();
    protected abstract get Change();

    private _CommitMap(_commits = new Map <BaseObject, any>()){

        // ensure that the item is in the storage
        if ((!this._isLoaded) && (this._toInsert)){
            this.data.AddObject(this);
        }

        if ((this._toInsert || this._isLoaded) && !this._isCatalog){
            // add this object changes and dependendencies
            if (this._toInsert || this._toUpdate || this._toDelete){
                this._toCommit = true;
                this._inCommit = true;

                if (!_commits.has(this)){   // avoid commiting the same entry
                    _commits.set(this, (async () => {
                        let _change = {
                            relation: await this.Relation,
                            entrynfo: await this.Entrynfo,
                            requires: await this.Requires    
                        }
    
                        // clear the update flags for this item
                        this._toInsert = false;
                        this._toUpdate = false;
                        this._toCommit = false;
    
                        return _change;
                    })())    
                }
            }

            // add any changes to the children
            for(let _child of this.Children){
                let _childcommits = _child._CommitMap(_commits);
                for (const [_item, _change] of _childcommits) {
                    _commits.set(_item, _change);
                }
            }
        }

        return _commits;    
    }

    private get Commit(){
        let _commits = this._CommitMap();
        
        if (GenericUtils.VerboseLogging()){
            let _logchanges = {};
            for (const [_item, _change] of _commits){
                _logchanges[_item.table] = (_item.table in _logchanges) ? _logchanges[_item.table] += 1 : 1;
            }

            console.info("[COMMIT] To be commited:");
            for(let _logchange in _logchanges){
                console.info(" - TABLE: " + _logchange + " (" + _logchanges[_logchange]+ " entries)");
            }
        }

        return Array.from(_commits.values());
    }

    async DoCommit(force: boolean = false){
        let _changes = this.Commit;
        if (_changes.length == 0){
            console.warn("[DATAOBJECT] Could not commit. Nothing to be commited.");
        }
        
        if (this.data.PushChange(_changes)){
            await this.data.CommitChanges(force);
        }
        else {
            console.error("[DATAOBJECT] Could not commit. Dependencies are not resolved.");
            return false;
        }            

        return true;
    }

    /********************************/
    /* INTERNAL METHODS             */
    /********************************/

    private __uuid = null;
    get _uuid(){
        if (!this.__uuid){
            this.__uuid = GenericUtils.uuidv4();
        }
        return this.__uuid;    
    }

    set _uuid(value: string){
        this.__uuid = value;
    }
}

/******************************/
/* RELATION INFORMATION       */
/******************************/

type RelationInfo = Map<RelatedObject, Set<string>>;

abstract class RelatedObject extends BaseObject {
    public _children: any = {};   // this object children (map by relation name)
    public _chldlist: any = {};   // the children in an array (for perfomance)

    protected _related: RelationInfo = new Map();
    constructor(public table: string, public objid: string, protected data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
    }

    /********************************/
    /* DETERMINE RELATIONS          */
    /********************************/

    private _AddChild(child: RelatedObject){
        let _child = this.data.GetObject(child);
        if (!_child){   // not in main storage
            let _storage = this._storage || child._storage;
            if (_storage){
                if (!_storage.AddObject(child)){
                    child = _storage.GetObject(child) as RelatedObject;
                }
            }
            else {
                this.data.AddObject(child);
            }
        }
        else {          // found in main storage
            child = _child as RelatedObject;
        }

        return child;
    }

    SetChild(target: string, child: RelatedObject, relation: string = null) {
        if (relation && (target != relation) && (relation in this.Info())){
            console.warn("[WARNING]: Field names mismatch for children ('" + target + "') and relation ('" + relation + "')");
        }

        let _change = false;

        if (!(target in this._children)){
            this._children[target] = null;
        }

        // remove previous existing relation 
        let _prev = this._children[target];
        if (_prev){
            _change = (this.DelRelation(this, target, _prev, relation)) && (!child);
            this._children[target] = null;
        }

        // create the relation with the new object
        if (child){
            child = this._AddChild(child);
            _change = this.AddRelation(this, target, child, relation);
            this._children[target] = child;
        }

        if (_change){
            this.DoRefresh(child ? child.table: this.table);
        }

        return _change;
    }

    AddChild(target: string, child: RelatedObject, relation: string) {
        let _change = false;

        if (!(target in this._children)){
            this._children[target] = {};    // TODO: better if Map()
        }

        if (child){
            child = this._AddChild(child);
            if (this._InsertChild(target, child)){
                _change = this.AddRelation(this, target, child, relation);
            }
        }

        if (_change){
            this.DoRefresh(child.table);    
        }
    }

    DelChild(target: string, child: RelatedObject, relation: string) { 
        let _change = false;

        if (this._children[target] instanceof DataObject){
            if (!child){
                child = this._children[target]
            }

            _change = this.DelRelation(this, target, child, relation);
            this._children[target] = null;
        }
        else {
            if (child){
                if (this._DeleleChild(target, child)){
                    _change = this.DelRelation(this, target, child, relation);
                }
            }
        }

        if (_change){
            this.DoRefresh(child.table);
        }
    }

    SetChildren <T extends RelatedObject> (objids: Array <string>, target: string, object: new(objid, data, options) => T, relation: string){
        if (objids){    // add new items into the list
            let _unique = new Set(objids);
            for(let _objid of _unique){  
                this.AddChild(target, new object(_objid, this.data, this._objoptions), relation);
            }
        }
    }

    DelChildren(target){
        let _chldlist = null;
        
        if (target in this._children){
            _chldlist = this._chldlist[target].slice(0);

            this._children[target] = {};
            this._chldlist[target] = [];
        }

        return _chldlist;
    }

    /********************************/
    /* RELATE OBJECTS               */
    /********************************/

    private _ChildKey(target, child){
        if (target in this._children){
            if (child.objid in this._children[target]){
                return child.objid;
            }
    
            if (child._uuid in this._children[target]){
                return child._uuid;
            }

            if (child.ctgref in this._children[target]){
                return child.ctgref;
            }
        }

        return null;
    }

    private _InsertChild(target: string, child: RelatedObject){
        let _key = this._ChildKey(target, child);
        if (!_key){
            this._children[target][child.objref] = child;
            this._chldlist[target] = Object.values(this._children[target] || []);    
            return true;
        }
        return false;
    }

    private _DeleleChild(target: string, child: RelatedObject){
        let _key = this._ChildKey(target, child);
        if (_key){
            delete this._children[target][_key];
            this._chldlist[target] = Object.values(this._children[target] || []);
            return true;
        }
        return false;
    }

    /* Object relations is -> map of (RelatedObject, set of RelationField) */
    private AddRelation(from: RelatedObject, target: string, to: RelatedObject, relation: string){
        let _change = false;

        if (!from.IsVolatile && to.IsVolatile){
            console.warn("[WARNING] Cannot add a relation to a volatile instance");
            return false;
        }

        // create the relation fields
        if (to){
            if (relation != null){
                // create the [from -> to] relation
                let _to_relation = "> " + relation;
                let _from_to = from._related.get(to);
                if (_from_to){
                    _from_to.add(_to_relation);
                }
                else {
                    from._related.set(to, new Set([_to_relation]));
                }
            }
            
            // create the [to -> from] relation
            if (target != null){
                let _fr_relation = "< " + target;
                let _to_from = to._related.get(from);
                if (_to_from){
                    _to_from.add(_fr_relation);
                } 
                else {
                    to._related.set(from, new Set([ _fr_relation ]))
                }    
            }
        }
        
        /*
            NOTE: 
            Applies when a related item is stored into the 'Info" fields for an item: 
            - The target (in where the object is stored) and the relation must match
            - Otherwise we cannot determine if it is an stored or a related item
        */

        if (target){  // establish the objid for the related field
            if (!(target in from.Info)){
                // from item do not stores the objids -> check if its valid as children
                if (!(target in from._children)){
                    console.warn("[WARNING]: field '" + target + "' not found in target item definition")
                }
            }
            else {
                let _relation = (to && to.Info.objid) ? to.Info.objid : null;
                if (_relation == null) {    // related object without objid (keep the instance)
                    _change = true;
                    
                    if (_change) {
                        from._children[target] = to;  
                        from.Info[target] = null;  
                    }
                }
                else {
                    _change = (from.Info[target] != _relation);
                    if (_change) {
                        from.Info[target] = _relation;
                    }    
                }
            }
        }

        return _change;
    }

    private DelRelation(from: RelatedObject, target: string, to: RelatedObject, relation: string){    
        let _change = false;

        // remove the [from -> to] relation
        let _from_to = from._related.get(to);
        if (_from_to){
            for(let _field of _from_to){
                if (_field.startsWith("> ")){
                    _field = _field.slice(2);

                    _change = (_field) && (from.Info[_field] != null);
                    if (_change) {  // clear the objid for the related field
                        from.Info[_field] = null;    
                    }    
                }
            }

            _from_to.delete("> " + relation);
            if (_from_to.size == 0){
                from._related.delete(to);
                if (from._related.size == 0){
                    from.OnDestroy();
                }    
            }
        }

        // remove the [to -> from] relation
        let _to_from = to._related.get(from);
        if (_to_from){

            _to_from.delete("< " + target);
            if (_to_from.size == 0){
                to._related.delete(from);
                if (to._related.size == 0){
                    to.OnDestroy();
                }
            }
        }

        return _change;
    }

    /********************************/
    /* RESOLVE OBJECTS              */
    /********************************/
    
    OnResolve(){
        let _refs = this.DelRelated();
        this.data.Resolve(this);
        this.AddRelated(_refs);

        this.DoRefresh(this.table);
        this.UpRefresh();
    }

    OnReplace(uuid){
        let _refs = this.DelRelated();
        this.data.Replace(this, uuid);
        this.AddRelated(_refs);

        this.DoRefresh(this.table);
        this.UpRefresh();
    }

    private _DelChild(from: RelatedObject, relation: string){
        let _fr = null;
        let _to = null;

        switch(relation.slice(0,1)){
            case '>':
                _fr = this;
                _to = from;
                break;

            case '<':
                _fr = from;
                _to = this;
                break;
        }

        let _deleteinfo = {
            from: _fr,              // the parent item
            from_field: null,       // the field from the parent in where the child is removed
            child_field: relation,  // the field from the child that references to the parent
            restore: null           // SetChild / AddChild 
        };

        for(let _relation in _fr._children){
            if (_fr._children[_relation] instanceof DataObject){
                if (_fr._children[_relation] == _to){
                    _deleteinfo.restore = 'SetChild';
                    _deleteinfo.from_field = _relation;

                    _fr.DelChild(_relation, this, relation.slice(2));
                    return _deleteinfo;         // child has been deleted
                }
            }
            else {
                for(let objref in _fr._children[_relation]){
                    if (_fr._children[_relation][objref] == _to){
                        _deleteinfo.restore = 'AddChild';
                        _deleteinfo.from_field = _relation;

                        _fr.DelChild(_relation, this, relation.slice(2));
                        return _deleteinfo;     // child has been deleted
                    }
                }
            }
        }

        return null;    // child not found
    }

    // old object is removed, and returns the deleted relations
    protected DelRelated(){
        let _deleted = [];

        for (let [object, fields] of this._related) {
            for(let _field of fields){
                if (_field.slice(0,1) == '>'){
                    continue;   // we are only deleting relations to this item
                }

                let _deleteinfo = this._DelChild(object, _field);
                if (_deleteinfo){
                    _deleted.push(_deleteinfo);
                }
            }
        }

        return _deleted;
    }

    // new object is created, and generates the previously deleted relations
    protected AddRelated(_deleted: any){
        let _fr = null;
        let _to = null;

        for(let _ref of _deleted){
            switch(_ref.child_field.slice(0, 1)){
                case '<':
                    _fr = _ref.from;
                    _to = this;
                    break;

                case '>':
                    _fr = this;
                    _to = _ref._from;
                    break;
            }

            // obtain the object from storage (in case has been replaced)
            _fr = this.data.GetObject(_fr) || _fr;
            _to = this.data.GetObject(_to) || _fr;

            let _child_field = _ref.child_field.slice(2);
            if (_ref.restore == 'SetChild'){
                _fr.SetChild(_ref.from_field, _to, _child_field)
            }

            if (_ref.restore == 'AddChild'){
                _fr.AddChild(_ref.from_field, _to, _child_field)
            }
        }
    }
}

/******************************/
/* BASE FOR MODEL INSTANCES   */
/******************************/

export abstract class DataObject extends RelatedObject {    
    protected _onRefresh = new Subject <Array<string>> ();
    public OnRefresh = this._onRefresh.asObservable();

    constructor(public table: string, public objid: string, protected data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
    }

    get ToInsert(){
        return !this._toCommit && !this._inCommit && !this._isCatalog && this._toInsert;
    }

    get ToUpdate(){
        return !this._toCommit && !this._inCommit && !this._isCatalog && this._toUpdate && !this._toInsert;
    }

    get ToCommit(){
        return this._toInsert || this._toCommit || this._inCommit;
    }

    get ToNotify(){
        return this._toNotify;
    }

    set ToUpdate(value){
        this._toUpdate = value;
        if (this._toUpdate && !this.objid){
            this._toInsert = true;
        }

        if (value){
            this.DoRefresh(this.table);
        }
    }

    set ToNotify(value){
        this._toNotify = value;
    }

    /********************************/
    /* CREATE DEEP COPIES           */
    /********************************/

    protected _copyof: DataObject = null;

    get CopyOf() {
        return this._copyof;
    }

    set CopyOf(value: DataObject){
        if (this._copyof != value){
            this._copyof = value;

            if (value == null){     // remove copy information from related objects
                this.data.AddObject(this);

                for(let relation in this._children){
                    if (this._children[relation]){
                        if (this._children[relation] instanceof DataObject){
                            this._children[relation].CopyOf = null;
                        }
                        else {
                            for(let child of this._chldlist[relation]){
                                child.CopyOf = null;
                            }        
                        }                    
                    }            
                }
            }
        }
    }

    IsCopyOf(object: DataObject){
        if (this == object){
            return true;    // same product
        }
        else {
            if ((this._copyof != null) && (this._copyof == object)){
                return true;
            }
    
            if ((this._copyof != null) && (this._copyof == object._copyof)){
                return true;
            }
    
            if ((object._copyof != null) && (object._copyof == this)){
                return true;
            }
    
            return false;    
        }
    }

    private _AnalyzeCopy(objlst: Map <DataObject, boolean>, docopy: boolean){
        let _objitem = objlst.get(this);
        if (_objitem){
            return;     // already analyzed: do not iterate
        }

        objlst.set(this, docopy);
        if (!docopy){
            return;     // this is not a copy: do not iterate
        }

        for(let relation in this._children){
            if (this._children[relation]){
                if (this._children[relation] instanceof DataObject){
                    let child = this._children[relation];
                    if (child){
                        child._AnalyzeCopy(objlst, false);
                    }
                }
                else {
                    for(let child of this._chldlist[relation]){
                        child._AnalyzeCopy(objlst, true);
                    }
                }
            }
        }
    }

    protected _FindCopy(target: DataObject, store: Array<DataObject>, objnfo: Map <DataObject, boolean> = null){
        if (!target){
            return null;
        }

        for(let _object of store){
            if ((_object == target) || (_object.IsCopyOf(target))) {
                return _object;     // already exists
            }
        }

        let _object = (objnfo.get(target)) ? target._Copy(store, objnfo) : target;         
        if (_object){
            store.push(_object);
        }

        return _object;    
    }

    private _ExecuteCopy(_copy: DataObject, objnfo: Map <DataObject, boolean>, store: Array<DataObject>){
        for(let relation in this._children){
            if (this._children[relation]){
                if (this._children[relation] instanceof DataObject){
                    let child = this._children[relation];
                    if (child){
                        _copy.SetChild(relation, this._FindCopy(child, store, objnfo), relation);
                    }
                }
                else {
                    for(let child of this._chldlist[relation]){
                        _copy.AddChild(relation, this._FindCopy(child, store, objnfo), null);
                    }
                }
            }
            else {
                _copy.SetChild(relation, null);
            }
        }
    }
    
    protected _Copy (store: Array<DataObject> = [], objnfo: Map <DataObject, boolean> = null) : DataObject{
        if (!objnfo){
            objnfo = new Map <DataObject, boolean> ();
        }

        let _copy = new (this.constructor as { new(objid: string, data: dataService): DataObject })(null, this.data);
        if (_copy) {
            _copy._copyof = this;                       // establish the relation with the original object
            _copy = this._FindCopy(_copy, store, objnfo);
            
            _copy.Data = GenericUtils.CopyOject(this.Info); 
            _copy._isCatalog = this._isCatalog;
            _copy._isLoaded = this._isLoaded;

            this._AnalyzeCopy(objnfo, true);             // determine the contained items to be copied
            this._ExecuteCopy(_copy, objnfo, store);     // add the contained items to the copied object
        }
        
        return _copy;
    }
    
    abstract Copy(store: Array<DataObject>) : DataObject;

    Overwrite(objinfo: Set<DataObject> = new Set()) {
        let _itemcopy = this.CopyOf;
        if (!_itemcopy){
            return;     // we cannot replace with copy
        }

        let _ischild = (objinfo.size > 0);

        // avoid recursive reentrancy
        if (objinfo.has(this)){
            return;     
        }
        objinfo.add(this);  

        // remove the original object from storage
        this.data.DelObject(_itemcopy);

        // replace the item identifiers
        this._uuid = _itemcopy._uuid || this._uuid;
        this.objid = _itemcopy.objid || this.objid;
        if (_itemcopy.objid){
            this._isLoaded = true;
            if (this._toInsert){
                this._toInsert = false;
                this._toUpdate = true;    
            }
        }

        this._copyof = null;
        this.data.AddObject(this);

        // maintain the references to the parent object
        if (_ischild){
            this.DelRelated();      // remove current incoming relations
        }

        // recursivelly replace all contained items
        for(let relation in this._children){
            if (!this._children[relation]){
                continue;
            }

            if (this._children[relation] instanceof DataObject){
                let child = this._children[relation];
                if (child){
                    child.Overwrite(objinfo);
                }
            }
            else {
                let _chldlist = this.DelChildren(relation);
                for(let child of _chldlist){
                    child.Overwrite(objinfo);
                }
            }
        }

        // add the references for the child object
        let _rel = _itemcopy.DelRelated();
        this.AddRelated(_rel);  // add object's incoming relations
    }

    /********************************/
    /* DATA UPDATE METHODS          */
    /********************************/

    protected abstract _OnUpdate(info);

    private _lastchange = null;     // keeps record of changes
    private _lastupdate = null;     // do not updated with same values
    
    private _updatelogs = false;
    private _ConsoleLog(log){
        if (this._updatelogs){
            console.info(log);
        }
    }

    protected get _CanUpdate() {
        return this._toInsert || !this._toUpdate;     
    }

    private _equal(info1, info2){
        let _equal = true;

        const keys1 = info1 ? Object.keys(info1).filter(item => !item.startsWith('_')) : [];
        const keys2 = info2 ? Object.keys(info2).filter(item => !item.startsWith('_')) : [];

        _equal = _equal && keys1.every(_key => {
            return ((['objid', 'created', 'updated'].includes(_key))) || ((keys2.includes(_key)) && (info1[_key] == info2[_key]));
        })

        _equal = _equal && keys2.every(_key => {
            return ((['objid', 'created', 'updated'].includes(_key))) || ((keys1.includes(_key)) && (info1[_key] == info2[_key]));
        })

        return _equal;
    }

    protected OnUpdate(info){
        this._inCommit = false;

        let _change = false;
        if (this._CanUpdate){
            _change = !this._equal(this._lastupdate, info);
            if (_change){   // update if changes are present
                this._lastupdate = Object.assign({}, info);
    
                this._OnUpdate(info);
    
                this._isLoaded = true;
                this._toUpdate = false;
    
                if (!this.objid || (info['_actn'] == 'do_insert')){
                    if (!this.objid){
                        this._ConsoleLog("[ITEM] resolve: " + info['table'] + "@" + info['objid']);
                        this.OnResolve();    
                    }
                    else {
                        this._ConsoleLog("[ITEM] replace: " + info['table'] + "@" + info['objid']);
                        this.OnReplace(info['_uuid']);
                    }
                }
                else {
                    this._ConsoleLog("[ITEM] update: " + info['table'] + "@" + info['objid']);
                }

                this.DoRefresh(this.table);  
                this.UpRefresh();

                this._lastchange = [];
            }
            else {
                this._ConsoleLog("[ITEM] ignore: " + info['table'] + "@" + info['objid']);
            }
        }

        return _change;
    }

    /****************************/
    /* BUILD FROM DATABASE      */
    /****************************/

    protected _FromData(data){
        this._OnUpdate(data);
        this._isLoaded = true;
    }

    FromData(data){  
        if (!this._isVolatile){
            console.error("ERROR: Only volatile instances can be built from data")
        }
        else {
            this._FromData(data);
        }
    }

    /********************************/
    /* FORCE COMMIT AND REFRESH     */
    /********************************/

    private _ForceCommit(){
        this._toUpdate = true;
        for (let _child of this.Children){
            _child._ForceCommit();
        }
    }

    public async ForceCommit(){
        this._ForceCommit();
        await this.DoCommit();
    }

    public ForceRefresh(){
        return new Promise((resolve) => {
            let _subscription = this.data.OnRefreshCompleted.subscribe(
            (data) => {
                _subscription.unsubscribe(); 

                this.data.FetchByObjid(this.table, this.objid).then(
                data => {
                    if (data['errorcode'] == 0){
                        this._OnUpdate(data['info']);
                    }
                    resolve(data['errorcode'] == 0);
                });        
            });
        });
    }

    /********************************/
    /* INTERNAL USE METHODS         */
    /********************************/

    protected _tick_subscription = null;

    DoRefresh(table:string, now:boolean = false){   
        if (now && this.data.Clock.Enable){
            this._onRefresh.next();
        }
        else {
            if (this._tick_subscription == null){
                this._tick_subscription = this.data.Clock.OnRefreshTick.subscribe(
                data => {
                    this._tick_subscription.unsubscribe();
                    this._tick_subscription = null;

                    this._onRefresh.next();
                });
            }        
        }
    }
   
    UpRefresh(){
        for (let _child in this._children){
            let _parent = this._children[_child];
            if (_parent && _parent instanceof DataObject){
                _parent.DoRefresh(this.table);
            }
        }
    }

    /************************************/
    /* METHODS FOR DERIVED CLASSES      */
    /************************************/

    avatarPhoto(name, bg='d3c79b', fg='000000'){
        if (!name){
            return null;
        }

        return AppConstants.baseURL + 'avatar/avatar.php?size=128&background=' + bg + '&color=' + fg + '&name=' + encodeURIComponent(name);
    }

    isAvatarPhoto(photo){
        return photo.startsWith(AppConstants.baseURL + 'avatar/avatar.php?');
    }

    protected mysqlToDateStr(date){
        return date ? date.replace(' ', 'T') + '.000Z' : null;
    }    

    protected dateStrToMysql(date){
        let _mysqldate = '';

        _mysqldate += date.getUTCFullYear() + '-';
        _mysqldate += ('00' + (date.getUTCMonth()+1)).slice(-2) + '-';
        _mysqldate += ('00' + date.getUTCDate()).slice(-2) + ' ';
        _mysqldate += ('00' + date.getUTCHours()).slice(-2) + ':'; 
        _mysqldate += ('00' + date.getUTCMinutes()).slice(-2) + ':'; 
        _mysqldate += ('00' + date.getUTCSeconds()).slice(-2);        

        return _mysqldate;
    }

    protected uploadToMysql(file){
        return file ? file.substring(file.lastIndexOf('/') + 1) : null;
    }

    protected mysqlToUpload(file){
        return file ? (AppConstants.baseURL + AppConstants.uploadPath + file) : null;
    }

    protected async uploadTobase64(url) {
        let _dataurl = await GenericUtils.imageTobase64(url);
        if (_dataurl){
            return _dataurl.split(',', 2)[1]
        }
        return null;
    }

    protected patchValue(dst, property, value){
        let _src = dst[property];
        let _dst = value;

        let _change = (_src != _dst) || (JSON.stringify(_src) != JSON.stringify(_dst));
        if (_change){
            dst[property] = (typeof(value) !== 'undefined') ? value : null;

            if (!this._lastchange){
                this._lastchange = [];
            }

            this._lastchange.push({
                property: property,
                value: dst[property]
            });
        }
        return _change;
    }
}

/************************************/
/* LIST OF DATA OBJECTS             */
/************************************/

export class DataList {
    constructor(private src: Array <DataObject>){
        // nothing to do
    }

    merge(dst: Array<DataObject>, sortfnc: any = null){
        for(let _idx = this.src.length-1; _idx >= 0; _idx--){
            if (dst.indexOf(this.src[_idx]) == -1){
                this.src.splice(_idx, 1);
            }
        }

        for(let _idx = dst.length-1; _idx >= 0; _idx--){
            if (this.src.indexOf(dst[_idx]) == -1){
                this.src.push(dst[_idx]);
            }
        }

        if (sortfnc){
            this.src = this.src.sort((a, b) => sortfnc(a, b));
        }

        return this.src;
    }
}
