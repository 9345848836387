import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { TicketProduct } from './ticketproduct';
import { ProductOption } from './productoption';

export interface _TicketOption {
    product: number;
    prodopt: number;
};

interface _TicketOptionData extends _TicketOption {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketOptionData extends DataObject {
    protected _option: _TicketOptionData = {
        product: null,
        prodopt: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._option.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._option.created;
    }

    get product(): TicketProduct {
        return this._children['product'] || null;
    }

    set product(child: TicketProduct){
        if (this.SetChild('product', child, 'product')){
            this.ToUpdate = true;
        }
    }

    get option(): ProductOption {
        return this._children['prodopt'] || null;
    }    

    set option(child: ProductOption){
        if (this.SetChild('prodopt', child, 'prodopt')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            product: this._option.product,
            prodopt: this._option.prodopt
        };
    }

    protected get Depend() {
        return {
            product: { item: this.product, relation_info: { to: 'options', by: 'product' } },   // this[by -> 'product'][to -> 'options'] => this
            prodopt: { item: this.option, relation_info: { to: null, by: 'prodopt' } }          // no relation to this in this[by -> 'prodopt']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_option: _TicketOption){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._option, 'product', _option['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'prodopt', _option['prodopt']) || _toUpdate;

        return _toUpdate;
    };

    set Data(_option: _TicketOption){
        this.patchValue(this._option, 'created', _option['created']);
        
        if (this._patchData(_option)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._option;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_option: _TicketOption){
        this._patchData(_option);

        if (_option['product']){   // update children: 'product'
            let _objid = _option['product'].toString();
            this.SetChild('product', new TicketProduct(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product');
        }

        if (_option['prodopt']){    // update children: 'option'
            let _objid = _option['prodopt'].toString();
            this.SetChild('prodopt', new ProductOption(_objid, this.data, this._objoptions), 'prodopt')
        }
        else {
            this.SetChild('prodopt', null, 'prodopt');
        }
    }

    private _ddbb(info): _TicketOptionData {
        let _option: _TicketOptionData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            product: info['product'] ? parseInt(info['product']) : null,
            prodopt: info['prodopt'] ? parseInt(info['prodopt']) : null
        };
        return _option;
    }
   
    protected _OnUpdate(info){
        let _option = this._ddbb(info);
        this.patchValue(this._option, 'objid', _option['objid']);
        this.patchValue(this._option, 'created', _option['created']);
        this.DoPatchValues(_option);
    }
}

export class TicketOption extends TicketOptionData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETOPTION', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketOption {
        return this._Copy(store) as TicketOption;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsAvailable(){
        return (this.option) && (this.option.status == 'AC');
    }
}
