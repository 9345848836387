import { Component, OnInit, OnChanges } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'ui-searchbar',
    templateUrl:'./ui-search.html',
    styleUrls: [ 
        './ui-search.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiSearch),
            multi: true
        }            
    ]
})
export class UiSearch implements ControlValueAccessor, OnInit, OnChanges {
    @Input('placeholder') _placeholder = '';
    @Input('value') _value = '';
    @Input('color') _color = 'default';
    @Input('disabled') _disabled = false;

    private _innerValue = '';
    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}

    // class methods
    constructor() {
        // nothing to do
    }

    ngOnInit() {
        this._innerValue = this._value;
        this.OnChange();
    }

    ngOnChanges(changes) {
        this.OnChange();
    }
    
    OnClear(){
        this.value = this._value = '';
    }

    @Output('onChange') _onChange = new EventEmitter<any>(); 
    OnChange() {
        this._onChange.emit(this._innerValue);
    }

    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = v;
            
            this._onChangeCallback(v);
            this._onTouchCallback(v);
        }
    }     
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
        
        this.OnChange();
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }    
}
