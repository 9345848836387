import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Ticket } from './ticket';
import { Discount } from './discount';

export interface _TicketDiscount {
    status: string;
    ticket: number;
    discount: number;
    charge: number;
};

interface _TicketDiscountData extends _TicketDiscount {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketDiscountData extends DataObject {
    protected _discount: _TicketDiscountData = {
        status: null,
        ticket: null,
        discount: null,
        charge: null,
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._discount.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._discount.created;
    }

    get updated(): Date {
        return this._discount.updated || this._discount.created;
    }

    get status(): string{
        return this._discount.status;
    }

    set status(value: string){
        if (this.patchValue(this._discount, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get ticket() : Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get discount() : Discount {
        return this._children['discount'] || null;
    }

    set discount(value: Discount){
        if (this.SetChild('discount', value, 'discount')){
            this.ToUpdate = true;
        }
    }

    get charge() : number {
        return this._discount.charge;
    }

    set charge(value: number){
        if (this.patchValue(this._discount, 'charge', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._discount.status,
            ticket: this._discount.ticket,
            discount: this._discount.discount,
            charge: this._discount.charge
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'ticket', by: 'discount' } },     // this[by -> 'ticket'][to -> 'discount'] => this
            discount: { item: this.discount, relation_info: { to: null, by: 'discount' } }      // no relation to this in this[by -> 'discount']
        };
    }

    protected get Children(){
        return [ /* empty */];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_discount: _TicketDiscount){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._discount, 'status', _discount['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'ticket', _discount['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'discount', _discount['discount']) || _toUpdate;
        _toUpdate = this.patchValue(this._discount, 'charge', _discount['charge']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_discount: _TicketDiscount){
        this.patchValue(this._discount, 'created', _discount['created']);
        this.patchValue(this._discount, 'updated', _discount['updated']);
        
        if (this._patchData(_discount)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._discount;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_discount: _TicketDiscount){
        this._patchData(_discount);

        if (_discount['ticket']){     // update children: 'ticket'
            let _objid = _discount['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }

        if (_discount['discount']){   // update children: 'discount'
            let _objid = _discount['discount'].toString();
            this.SetChild('discount', new Discount(_objid, this.data, this._objoptions), 'discount')
        }
        else {
            this.SetChild('discount', null, 'discount');
        }

    }

    private _ddbb(info): _TicketDiscountData {
        let _discount: _TicketDiscountData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            discount: info['discount'] ? parseInt(info['discount']) : null,
            charge: info['charge'] ? parseFloat(info['charge']) : null
        };

        return _discount;
    }

    protected _OnUpdate(info){
        let _discount = this._ddbb(info);
        this.patchValue(this._discount, 'objid', _discount['objid']);
        this.patchValue(this._discount, 'created', _discount['created']);
        this.patchValue(this._discount, 'updated', _discount['updated']);
        this.DoPatchValues(_discount);
    }
}

export class TicketDiscount extends TicketDiscountData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETDISCOUNT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketDiscount {
        return this._Copy(store) as TicketDiscount;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.ticket){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.ticket.DelDiscount(this);
            }
            else {
                this.ticket.DoRefresh('TICKETDISCOUNT');
            }    
        }
    }

    private _refund = null;   // this is the pending refund
    get refund(){
        return this._refund;
    }

    set refund(value: number){
        this._refund = value;
    }

    private _percent = null;    // this is the applied percent
    get percnt(){
        return this._percent;
    }

    set percnt(value: number){
        this._percent = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return (this.status && this.status != 'DE');
    }

    ChargePrice() {
        let _price = 0;

        switch(this.discount?.type){
            case 'TICKETPRCNT':
                _price = (this.ticket._charge * this.discount.value) / 100;
                break;
            case 'TICKETFIXED':
                _price = this.discount.value;
                break;
        }

        return Math.round(Math.min(this.ticket._charge, _price) * 100) / 100;
    }

    RefundPrice() {
        let _ticketcharge = this.ticket._charge;   // this is the ticket charge
        let _discntcharge = this.charge;           // this is the applied discount
        let _ticketrefund = this.ticket._refund;   // this is the ticket refund
        
        let _ticketadjust = _ticketcharge - _ticketrefund;
        let _discntadjust = (_ticketadjust * _discntcharge) / _ticketcharge;
        let _discntrefund = _discntcharge - _discntadjust;
   
        return Math.round(_discntrefund * 100) / 100;
    }

    private _strPrice(price){
        let _price = {
            Str: "0,00",
            Int: "0",
            Dec: "00",
            Val: 0.0,
            Neg: false
        };

        if (price !== null){
            let _parts = Math.abs(price).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _int + ',' + _dec;
            let _val = price;
            let _neg = (price < 0); 

            _price = {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Val: _val,
                Neg: _neg
            }    
        }
        return _price;
    }

    get Price(){
        return this._strPrice(this.charge);
    }
}
