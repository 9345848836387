import { Component, OnDestroy  } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';

import { languageService, languageSupport } from '@app/modules/common/language';
import { viewService } from '@app/modules/view';

/************************************/
/* GENERIC ERROR MODAL              */
/************************************/

@Component({
    selector: 'ui-error',
    templateUrl:'./ui-error.html',
    styleUrls: [ 
        './ui-error.scss'
    ]
})
export class UiError extends languageSupport implements OnDestroy {
    @Input('title') _title: string = null;
    @Input('header') _header: Array <string> = [];
    @Input('reason') _reason: Array <string> = [];
    
    constructor(private lang: languageService, public view: viewService) {
        super(lang, null);
    }

    ngOnDestroy(){
        super.OnDestroy();
    }

    @Output('onClose') _onClose = new EventEmitter<any>();    
    OnClose(){
        this._onClose.emit();
    }
}
