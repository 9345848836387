import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Ticket } from './ticket';
import { Product } from './product';
import { TicketOption } from './ticketoption';
import { ProductOption } from './productoption';
import { TicketOffer } from './ticketoffer';

export interface _TicketProduct {
    status: string,
    product: number,
    offer: number,
    price: number,
    charge: number,
    fixed: boolean,     // true if it has been manually repriced
    market: number,
    weight: number,
    taxrate: number,
    ticket: number,
    flags: string,
    comments: string,
    sort: number,
    group?: number      // calculated based on sort and separators
};

interface _TicketProductData extends _TicketProduct {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketProductData extends DataObject {
    protected _product: _TicketProductData = {
        status: null,
        product: null,
        offer: null,
        price: null,
        charge: null,
        fixed: false,
        market: null,
        weight: null,
        taxrate: null,
        ticket: null,
        flags: null,
        comments: null,
        sort: null,
        group: null,
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._product.created = new Date();
    }

    /****************************/
    /* TICKET PRODUCT FLAGS     */
    /****************************/

    protected TicketProductStatus = Object.freeze({
        IsAC: 0,    // has 'AC' status
        IsUN: 1,    // has 'UN' status
        IsRD: 2,    // has 'RD' status
        IsPP: 3,    // has 'PP' status
        IsPD: 4,    // has 'PD' status
        IsCC: 5,    // has 'CC' status
        IsDE: 6     // has 'DE' status
    });

    protected get flags(): Array<any> {
        let _array = [ false, false, false, false, false, false, false ];

        if (this._product.flags){
            let _flags = Array.from(this._product.flags);
            for(let i=0; i < _flags.length; i++){
                _array[i] = (_flags[i] == '1');
            }        
        }

        return _array;
    }

    protected set flags(value: Array<any>) {
        let _array = [];
        for(let i=0; i < value.length; i++){
            _array[i] = (value[i] === true) ? '1' : '0';
        }

        let _flags = _array.join(''); 
        if(this.patchValue(this._product, 'flags', _flags)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date {
        return this._product.created;
    }

    get updated(): Date {
        return this._product.updated || this._product.created;;
    }

    get status(): string{
        return this._product.status;
    }

    set status(value: string){
        if (this.patchValue(this._product, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get product(): Product {
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get offer(): TicketOffer {
        return this._children['offer'] || null;
    }

    set offer(value: TicketOffer){
        if (this.SetChild('offer', value, 'offer')){
            this.ToUpdate = true;
        }
    }

    get price(): number {
        return this._product.price;
    }

    set price(value: number){
        if (this.patchValue(this._product, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get charge(): number {
        return this._product.charge;
    }

    set charge(value: number){
        if (this.patchValue(this._product, 'charge', value)){
            this.ToUpdate = true;
        }
    }

    get fixed(): boolean {
        return this._product.fixed;
    }

    set fixed(value: boolean){
        if (this.patchValue(this._product, 'fixed', value)){
            this.ToUpdate = true;
        }
    }

    get market(): number{
        return this._product.market;
    }

    set market(value: number){
        if (this.patchValue(this._product, 'market', value)){
            this.ToUpdate = true;
        }
    }

    get weight(): number{
        return this._product.weight;
    }

    set weight(value: number){
        if (this.patchValue(this._product, 'weight', value)){
            this.ToUpdate = true;
        }
    }

    get taxrate(): number{
        return this._product.taxrate || this.ticket.place.TaxRate;
    }

    set taxrate(value: number){
        if (this.patchValue(this._product, 'taxrate', value)){
            this.ToUpdate = true;
        }
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }    

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get comments(): string {
        return this._product.comments;
    }

    set comments(value: string){
        if (this.patchValue(this._product, 'comments', value)){
            this.ToUpdate = true;
        }
    }

    get sort(): number {
        return this._product.sort;
    }

    set sort(value: number){
        if (this.patchValue(this._product, 'sort', value)){
            this.ToUpdate = true;
        }
    }

    get events(): { [key: string]: boolean; } {
        let _flags = this.flags;        

        return {
            AC: _flags[this.TicketProductStatus.IsAC],
            UN: _flags[this.TicketProductStatus.IsUN],
            RD: _flags[this.TicketProductStatus.IsRD],
            PP: _flags[this.TicketProductStatus.IsPP],
            PD: _flags[this.TicketProductStatus.IsPD],
            CC: _flags[this.TicketProductStatus.IsCC],
            DE: _flags[this.TicketProductStatus.IsDE],
        };
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: TicketOption){
        this.AddChild('options', child, 'product');
    }

    DelOption(child: TicketOption){
        this.DelChild('options', child, 'product');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <TicketOption> {
        return this._chldlist['options'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._product.status,
            product: this._product.product,
            offer: this._product.offer,
            price: this._product.price,
            charge: this._product.charge,
            fixed: this._product.fixed ? '1':'0',
            market: this._product.market,
            weight: this._product.weight,
            taxrate: this._product.taxrate,
            ticket: this._product.ticket,
            flags: this._product.flags,
            comments: this._product.comments,
            sort: this._product.sort
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'products', by: 'ticket' } },     // this[by -> 'ticket'][to -> 'products'] => this
            offer: { item: this.offer, relation_info: { to: null, by: 'offer' } },              // no relation to this in this[by -> 'offer']
            product: { item: this.product, relation_info: { to: null, by: 'product' } }         // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.options){
            _children.push(_item)
        }

        if (this.offer){
            _children.push(this.offer);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_product: _TicketProduct){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._product, 'status', _product['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'product', _product['product']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'offer', _product['offer']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'price', _product['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'charge', _product['charge']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'taxrate', _product['taxrate']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'fixed', _product['fixed']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'market', _product['market']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'weight', _product['weight']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'ticket', _product['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'flags', _product['flags']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'comments', _product['comments']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'sort', _product['sort']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_product: _TicketProduct){
        this.patchValue(this._product, 'created', _product['created']);
        this.patchValue(this._product, 'updated', _product['updated']);  
        
        if (this._patchData(_product)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._product;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_product: _TicketProduct){
        this._patchData(_product);

        if (_product['product']){   // update children: 'product'
            let _objid = _product['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product');
        }

        if (_product['ticket']){    // update children: 'ticket'
            let _objid = _product['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }

        if (_product['offer']){   // update children: 'offer'
            let _objid = _product['offer'].toString();
            this.SetChild('offer', new TicketOffer(_objid, this.data, this._objoptions), 'offer')
        }
        else {
            this.SetChild('offer', null, 'offer');
        }
    }

    private _ddbb(info): _TicketProductData {
        let _product: _TicketProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            product: info['product'] ? parseInt(info['product']) : null,
            offer: info['offer'] ? parseInt(info['offer']) : null,
            price: info['price'] ? parseFloat(info['price']) : null,
            charge: info['charge'] ? parseFloat(info['charge']) : null,
            fixed: (info['fixed'] == '1'),
            market: info['market'] ? parseFloat(info['market']) : null,
            weight: info['weight'] ? parseFloat(info['weight']) : null,
            taxrate: info['taxrate'] ? parseFloat(info['taxrate']) : null,
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            flags: info['flags'],
            comments: info['comments'],
            sort: info['sort'] ? parseInt(info['sort']) : null
        };
        return _product;
    }

    protected _OnUpdate(info){
        let _product = this._ddbb(info);
        this.patchValue(this._product, 'objid', _product['objid']);
        this.patchValue(this._product, 'created', _product['created']);
        this.patchValue(this._product, 'updated', _product['updated']);        
        this.DoPatchValues(_product);
        
        if (info['options']){   // update children: 'options'
            this.SetChildren <TicketOption> (info['options'], 'options', TicketOption, 'product');
        }
    }
}

export class TicketProduct extends TicketProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETPRODUCT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketProduct {
        return this._Copy(store) as TicketProduct;
    }

    /****************************/
    /* BEHAVIOUR UPDATE         */
    /****************************/

    UpRefresh(){    // do not refresh products or offers when updated
        for (let _child in this._children){
            if ([ 'product', 'offer' ].indexOf(_child) != -1){
                continue;
            }

            let _parent = this._children[_child];
            if (_parent && _parent instanceof DataObject){
                _parent.DoRefresh('TICKETPRODUCT');
            }
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE' || (this.status == value)){
            return;     // cannot modify deleted items
        }

        if (this.status == 'SP' && (value != 'DE')){
            return;     // sepator items can only be deleted
        }

        // if status is 'PR' or 'AC' delete paid information
        if ([ 'PR', 'AC' ].includes(value)){
            this.DelEvent('PP');
            this.DelEvent('PD');
        }

        // avoid repeating events (ignore PR events)
        if (!['PR', 'SP'].includes(value)){
            this.AddEvent(value);
        }

        // opened ticket cancelation (keep 'CC' and 'DE' flags)
        if (value == 'CC'){
            this.status = (this.ticket.IsPaid) && (!this.ticket.IsToPay) ? 'UN' : 'DE';
        }

        // update status (if suitable)
        if (['AC', 'UN', 'DE', 'SP'].includes(value)){
            super.status = value;
        }

        if (this.ticket){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.ticket.DelProduct(this);
            }
            else {
                this.ticket.DoRefresh('TICKETPRODUCT');
            }    
        }
    }

    get weight(): number{
        return super.weight;
    }

    set weight(value: number){
        if (this.product.IsWeighted){
            super.weight = value;     // cannot set weight if its not weighted
        }
    }

    get group(): number {
        return this._product.group;
    }

    set group(value: number){
        this._product.group = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        if (!this.status){
            return false;
        }
        
        return (this.status != 'DE') || (this.HasEvent('PD') && this.HasEvent('UN'));
    }

    get Separator(){
        return this.status == 'SP' || ((this.status == 'DE') && (this.product == null));
    }

    get ToRefund() {
        return (this.status == 'UN');
    }

    private _flagIndex(status){
        switch(status){
            case 'AC': return this.TicketProductStatus.IsAC;
            case 'UN': return this.TicketProductStatus.IsUN;
            case 'RD': return this.TicketProductStatus.IsRD;
            case 'PP': return this.TicketProductStatus.IsPP;
            case 'PD': return this.TicketProductStatus.IsPD;
            case 'CC': return this.TicketProductStatus.IsCC;
            case 'DE': return this.TicketProductStatus.IsDE;
        }
        
        return -1;
    }

    HasEvent(event){
        let _flag = this._flagIndex(event);
        if (_flag != -1){
            return (this.flags[_flag]);
        }

        return false;   // flag not found
    }

    AddEvent(event){
        let _flag = this._flagIndex(event);
        if (_flag != -1){
            let _flags = this.flags;
            _flags[_flag] = true;
            this.flags = _flags;
        }
    }

    DelEvent(event){
        let _flag = this._flagIndex(event);
        if (_flag != -1){
            let _flags = this.flags;
            _flags[_flag] = false;
            this.flags = _flags;
        }
    }

    get TotalPrice(){
        if (!this.product){
            return 0;
        }

        let _price: number = this.product.price ? this.product.price : 0;;
        
        // check to use the market price instead of the product price
        if (this.product.MarketPrice){
            _price = this.market;   
        }

        // calculate the price based on the weight and the current price per kg
        if (this.product.IsWeighted){
            _price = Math.round(_price * (this.weight/1000) * 100) / 100;
        }

        for(let _option of this.options){
            _price += _option.option ? _option.option.price : 0;
        }
        
        return _price;
    }

    _OfferPrice(offer){
        let _price: number = 0;
        for(let _option of this.options){
            if (!offer.IncludesOption(_option.option)){
                _price += _option.option ? Math.max(_option.option.price, 0) : 0;
            }
        }
        return _price;
    }

    get OfferPrice(){
        let _offer = (this.offer && this.offer.IsValid) ? this.offer.offer : null;
        if (_offer){
            return this._OfferPrice(_offer)
        }

        return 0;   // not in offer
    }

    get FixedPrice(){
        if (this.fixed){
            return this.charge;
        }
        return 0;
    }

    OfferCharge(offerprice: number){
        let _charge: number = this.TotalPrice;
        if (this.fixed){
            _charge = this.charge;
        }

        if (this.offer && this.offer.IsValid){
            let _ofprice = offerprice || this.offer.fixed ? this.offer.price : this.offer.offer.price;

            let _ttprice = 0;   // price for all products in this offer
            for (let _ticketproduct of this.ticket.products){
                if (_ticketproduct.IsValid){
                    if ((_ticketproduct.offer == this.offer)){
                        _ttprice += _ticketproduct.TotalPrice;
                    }    
                }
            }  

            if (_ttprice == 0){
                return 0;
            }

            // proportional price for the product for the applied offer price
            _charge = this.OfferPrice + ((this.TotalPrice * _ofprice) / _ttprice);
        }
        
        return _charge;
    }

    get ChargePrice(){
        if (this.status == 'DE'){
            return 0;   // removed product (do not charge)
        }

        return this.OfferCharge(null);
    }

    get RefundPrice(){
        if ((this.status != 'DE') || (!this.HasEvent('PD'))){
            return 0;   // not removed or not paid product (not a refund)
        }

        return this.OfferCharge(null);
    }

    CartAdd(option: ProductOption){
        let _ticketoption: TicketOption = new TicketOption(null, this.data);
        if (_ticketoption){
            _ticketoption.product = this;
            _ticketoption.option = option;
            
            this.AddOption(_ticketoption);
        }
    }
}


