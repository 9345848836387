import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  
  /***********************************************/
  /* SUPPORT FOR PREVIOUS VERSIONS (BASE-HREF)   */
  /***********************************************/

  { path: 'upp-ionic/', redirectTo: 'index', pathMatch: 'full' },   // support to older version with base-href

  { path: 'upp-ionic/index', redirectTo: 'index', pathMatch: 'full' },
  { path: 'upp-ionic/update', redirectTo: 'update', pathMatch: 'full' },
  { path: 'upp-ionic/qr-access', redirectTo: 'qr-access', pathMatch: 'full' },
  { path: 'upp-ionic/delivery', redirectTo: 'delivery', pathMatch: 'full' },

  { path: 'upp-ionic/on-password', redirectTo: 'on-password', pathMatch: 'full' },
  { path: 'upp-ionic/on-activate', redirectTo: 'on-activate', pathMatch: 'full' },
  { path: 'upp-ionic/on-mailchng', redirectTo: 'on-mailchng', pathMatch: 'full' },
  { path: 'upp-ionic/on-connect', redirectTo: 'on-connect', pathMatch: 'full' },
  { path: 'upp-ionic/on-payment', redirectTo: 'on-payment', pathMatch: 'full' },
  
  /***********************************************/
  /* IMPORT MODULES STARTS HERE                  */
  /***********************************************/

  { path: 'update', loadChildren: () => import('./pages/update/update.module').then(m => m.UpdatePageModule) },

  { path: 'index', loadChildren: () => import('./pages/index/index.module').then(m => m.IndexPageModule) },
  { path: 'qr-access', loadChildren: () => import('./pages/qraccess/qraccess.module').then(m => m.AccessPageModule) },
  { path: 'delivery', loadChildren: () => import('./pages/delivery/delivery.module').then(m => m.DeliveryPageModule) },

  { path: 'on-password', loadChildren: () => import('./pages/static/on-password/on-password.module').then(m => m.OnPasswordPageModule) },
  { path: 'on-activate', loadChildren: () => import('./pages/static/on-activate/on-activate.module').then(m => m.OnActivatePageModule) },
  { path: 'on-mailchng', loadChildren: () => import('./pages/static/on-mailchange/on-mailchg.module').then(m => m.OnMailChangePageModule) },
  { path: 'on-connect', loadChildren: () => import('./pages/static/on-connect/on-connect.module').then(m => m.OnConnectPageModule) },
  { path: 'on-payment', loadChildren: () => import('./pages/static/on-payment/on-payment.module').then(m => m.OnPaymentPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
