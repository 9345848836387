import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { PlaceService } from './svcplace';

export interface _ServiceConnect {
    status: string,
    rasppi: number;
    device: string;
    connect: string;
    hassvc: boolean;
};

interface _ServiceConnectData extends _ServiceConnect {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class ServiceConnectData extends DataObject {
    protected _connect: _ServiceConnectData = {
        status: null,
        rasppi: null,
        device: null,
        connect: null,
        hassvc: false
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._connect.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date {
        return this._connect.created;
    }

    get updated(): Date {
        return this._connect.updated || this._connect.created;
    }

    get status(): string {
        return this._connect.status;
    }

    set status(value: string){
        if (this.patchValue(this._connect, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get rasppi() : PlaceService {
        return this._children['rasppi'] || null;
    }

    set rasppi(value: PlaceService){
        if (this.SetChild('rasppi', value, 'rasppi')){
            this.ToUpdate = true;
        }
    }

    get device(): string {
        return this._connect.device;
    }

    set device(value: string){
        if (this.patchValue(this._connect, 'device', value)){
            this.ToUpdate = true;
        }
    }

    get connect(): string {
        return this._connect.connect;
    }

    set connect(value: string) {
        if (this.patchValue(this._connect, 'connect', value)){
            this.ToUpdate = true;
        }
    }

    get hassvc(): boolean {
        return this._connect.hassvc;
    }

    set hassvc(value: boolean){
        if (this.patchValue(this._connect, 'hassvc', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._connect.status,
            rasppi: this._connect.rasppi,
            device: this._connect.device,
            connect: this._connect.connect,
            hassvc: this._connect.hassvc ? '1' : '0'
        };
    }

    protected get Depend() {
        return {
            rasppi: { item: this.rasppi, relation_info: { to: 'connect', by: 'rasppi' } }   // this[by -> 'rasppi'][to -> 'connect'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_connect: _ServiceConnect){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._connect, 'status', _connect['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'rasppi', _connect['rasppi']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'device', _connect['device']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'connect', _connect['connect']) || _toUpdate;
        _toUpdate = this.patchValue(this._connect, 'hassvc', _connect['hassvc']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_connect: _ServiceConnect){
        this.patchValue(this._connect, 'created', _connect['created']);
        this.patchValue(this._connect, 'updated', _connect['updated']);
        
        if (this._patchData(_connect)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._connect;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_connect: _ServiceConnect){
        this._patchData(_connect);

        if (_connect['rasppi']){     // update children: 'rasppi'
            let _objid = _connect['rasppi'].toString();
            this.SetChild('rasppi', new PlaceService(_objid, this.data, this._objoptions), 'rasppi')
        }
        else {
            this.SetChild('rasppi', null, 'rasppi');
        }
    }

    private _ddbb(info): _ServiceConnectData {
        let _connect: _ServiceConnectData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            rasppi: info['rasppi'] ? parseInt(info['rasppi']) : null,
            device: info['device'],
            connect: info['connect'],
            hassvc: (info['hassvc'] == '1')
        };
        return _connect;
    }

    protected _OnUpdate(info){
        let _connect = this._ddbb(info);
        this.patchValue(this._connect, 'objid', _connect['objid']);
        this.patchValue(this._connect, 'created', _connect['created']);
        this.patchValue(this._connect, 'updated', _connect['updated']);
        this.DoPatchValues(_connect);
    }
}

export class ServiceConnect extends ServiceConnectData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('RASPPICONNECT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): ServiceConnect {
        return this._Copy(store) as ServiceConnect;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get rasppi() : PlaceService {
        return super.rasppi;
    }

    set rasppi(value: PlaceService){
        if (this.rasppi){
            this.rasppi.DelConnect(this);
        }
        
        super.rasppi = value;
        
        if (this.rasppi){
            this.rasppi.AddConnect(this);
        }
    }

    /****************************/
    /* CUSTOM MEMBERS           */
    /****************************/

    get connecthost(){
        return this.connect.split(':')[0];
    }

    get connectport(){
        return this.connect.split(':')[1];
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status == 'AC');
    }

    get IsPosDevice(){
        let _place = this.rasppi?.place;
        if (!_place){
            return false;
        }

        let _option = _place.GetConfigList('optionPosTerminal');
        if (_option){
            let _devices = _option.split('|');
            for(let _device of _devices){
                if (this.device == _device){
                    return true;    // this device is included in the list of POS devices
                }
            }    
        }

        return false;
    }
}
