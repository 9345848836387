import { Component, OnInit, OnChanges } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { Output, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

// refs: https://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

@Component({
    selector: 'ui-textarea',
    templateUrl:'./ui-textarea.html',
    styleUrls: [ 
        './ui-textarea.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiTextarea),
            multi: true
        }            
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTextarea implements ControlValueAccessor, OnInit, OnChanges {
    @Input('placeholder') _placeholder = '';
    @Input('formControlName') _name = '';
    @Input('readonly') _readonly = false;
    @Input('disabled') _disabled = false;
    @Input('kiosk') _kiosk = true;    
    @Input('rows') _rows = 1;
    @Input('title') _title = '';
    @Input('value') _value = '';

    @Input('formgroup') _formgroup = null
    @Input('errornfo') _errornfo = {} 

    private _innerValue: any = '';  
    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}
    
    public _data = {
        title: '',
        showTitle: false,
    }
    
    constructor(private change: ChangeDetectorRef) {
        // nothing to do
    }

    ngOnInit() {
        this._innerValue = this._value;
        this.onChange();
    }

    ngOnChanges(changes) {
        this.onChange();
    }
    
    @Output('onChange') _onChange = new EventEmitter<any>(); 
    onChange() {
        this._data.title = (this._title == '')?this._placeholder:this._title;
        this._data.showTitle = (this._innerValue && this._innerValue != '');
        this._onChange.emit();
    }

    @Output('change') _changed = new EventEmitter<any>(); 
    isChanged(){
        this._changed.emit();
    }

    @Output('onFocus') _onFocus = new EventEmitter<any>(); 
    onFocus() {
        this._onFocus.emit();
    }

    /********************************************/
    /* disable control                          */
    /********************************************/

    get disabled(){
        // if directly disabled, return true
        if (this._disabled){
            return true;
        }

        // check if the the form control is disabled
        if (this._formgroup && this._name){
            return this._formgroup.get(this._name).disabled;
        }

        // not disabled
        return false;
    }

    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = v;
            
            this.change.markForCheck();
            this._onChangeCallback(v);
            this._onTouchCallback(v);
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
        
        this.onChange();
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }    
}