import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { platformService } from '@app/modules/common/platform';
import { languageService} from '@app/modules/common/language';
import { httpService } from '@app/modules/common/http';
import { syncService } from '@app/modules/sync';

/************************************/
/* VIEW SERVICE                     */
/************************************/

@Injectable()
export class viewService implements OnDestroy {
    private _subscriptions = [];

    /******************************/
    /* DEVICE CONSIDERATIONS      */
    /******************************/

    get CanZoom(){  // do not apply zoom on ios safari (keeps crashing)
        return !(this.platform.LegacyMobile) || (this.platform.Browser != 'Safari');
    }

    get Theme(){
        return this.platform._theme;
    }

    set Theme(value){
        if (this.Access == 'GUEST'){
            return;     // guest mode only supports dark theme
        }

        this.platform._theme = value;
    }

    get LightTheme(){
        return (this.Theme == 'light');
    }

    get DarkTheme(){
        return (this.Theme == 'dark');
    }

    get OnTheme(){
        return this.platform.OnThemeChanged;
    }

    get Scrollbar(){
        return this.platform._scrollbar;
    }

    set Scrollbar(value){
        this.platform._scrollbar = value;
    }

    get Mobile(){
        return this.platform._isMobile;
    }

    get Desktop(){
        return this.platform._isDesktop;
    }

    get Legacy(){
        return (this.platform._isMobile && this.platform.LegacyMobile) || (this.platform._isDesktop && this.platform.LegacyDesktop);
    }

    get Kiosk(){
        return this.platform._isKiosk;
    }

    set Kiosk(value: boolean){
        this.platform._isKiosk = value;
    }

    get OnKiosk(){
        return this.platform.OnKioskChanged;
    }

    private CheckDevice(){
        this._subscriptions.push(this.OnKiosk.subscribe(
        data => {
            this.Kiosk = this.platform._isKiosk;
        }));
    }

    /******************************/
    /* ACCESS / VIEW              */
    /******************************/

    /*
        LOGIN: View login screen
        USER: View user screen (select place)
        PLACE: View place screen
    */

    public _View = null;        // 'LOGIN', 'USER', 'PLACE'
    public _Mode = null;        // 'VIEW', 'EDIT', 'CART

    get Access(){
        return this.View ? this.sync.Access : null;
    }

    set Access(value){
        this.sync.Access = value;

        switch(value){
            case 'GUEST':
                this.View = 'PLACE';
                break;
            case 'LOGIN':
                this.View = 'LOGIN';
                break;
            case 'DEVRY':
                this.View = 'LOGIN';
                break;
        }
    }

    private _onViewChanged = new Subject <void> ();
    public OnViewChanged = this._onViewChanged.asObservable();

    get View(){
        return this._View;
    }

    get Mode(){
        return this._Mode;
    }

    set Mode(value){
        if (this.Mode != value){
            this._Mode = value;
            this._onViewChanged.next()    
        }
    }

    async Refresh(){
        this._onViewChanged.next();
    }

    set View(value){
        if (this._View != value){
            console.info("[VIEW] New view '" + value + "' requested")
            this._View = value;
            this._onViewChanged.next();    
        }
    }

    private _onRightChanged = new Subject <void> ();
    public OnRightChanged = this._onRightChanged.asObservable();
   
    private _right = false;
    get RightPanel() {
        return this._right;
    }

    set RightPanel(value){
        if (value != this._right){
            this._right = value;
            this._onRightChanged.next();
        }
    }

    private _onTabChanged = new Subject <any> ();
    public OnTabChanged = this._onTabChanged.asObservable();

    private _viewtab = null;
    get Tab(){
        return this._viewtab;
    }

    set Tab(value){
        this._viewtab = value;
        this._onTabChanged.next(value);
    }

    private _onCloseRequest = new Subject <any> ();
    public OnCloseRequest = this._onCloseRequest.asObservable();
    private _onGrantChange = new Subject <any> ();
    private OnGrantChange = this._onGrantChange.asObservable();

    OnCloseResponse(allow){
        this._onGrantChange.next(allow);
    }

    async GrantViewChange(){
        return new Promise((resolve) => {
            let _subscriptors = this._onCloseRequest.observers;
            if (_subscriptors.length > 0){
                let _responses = [];   // hold responses for each subscriptor

                let _subscription = this.OnGrantChange.subscribe(
                allow => {
                    _responses.push(allow);
                    if (_responses.length == _subscriptors.length){
                        _subscription.unsubscribe();
                        resolve(_responses.every((allow) => { 
                            return allow 
                        }));
                    }
                });

                this._onCloseRequest.next();
            }
            else {
                resolve(true);
            }
        })
    }

    /******************************/
    /* PLACE PANEL INFORMATION    */
    /******************************/

    private _placepanel = null;
    get Panel(){
        return this._placepanel;
    }

    set Panel(value){
        this._placepanel = value;
    }

    /******************************/
    /* ONLINE/OFFLINE CONTROL     */
    /******************************/

    public get OnOnline(){
        return this.http.OnOnline;
    }

    get IsOnline(){
        return this.http.IsOnline;
    }

    /******************************/
    /* SERVICE WORKER UPDATES     */
    /******************************/

    private _swupdate = false;
    
    get SwUpdate(){
        return this._swupdate;
    }

    set SwUpdate(value){
        if (value != this._swupdate){
            if (value){
                console.info("[UNPISPAS] Detected new service worker version!");
            }

            this._swupdate = value;
            this._onViewChanged.next();    
        }
    }
    
    /******************************/
    /* MAIN ENTRY POINT           */
    /******************************/

    private async _LoadLanguages(){
        await this.lang.LoadModule('main');
        this._onViewChanged.next();
    }

    constructor(private lang: languageService, private sync: syncService, private platform: platformService, private http: httpService){
        this.platform.ready().then(
        () => {
            this.CheckDevice();
        })

        this._subscriptions.push(this.sync.OnExpired.subscribe(
        data => {
            this.View = null;
        }));

        this._LoadLanguages();
    }

    ngOnDestroy(){
        for(let _subscription of this._subscriptions){
            _subscription.unsubscribe();
        }
        this._subscriptions = [];
    }
}


  