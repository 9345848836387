import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { ProductSelect } from './productselect';
import { ProductOption } from './productoption';

export interface _SelectOption {
    status: string;
    preselect: number;
    prodopt: number;
};

interface _SelectOptionData extends _SelectOption {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class SelectOptionData extends DataObject {
    protected _option: _SelectOptionData = {
        status: null,
        preselect: null,
        prodopt: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._option.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._option.created;
    }

    get status(): string {
        return this._option.status;
    }

    set status(value: string) {
        if(this.patchValue(this._option, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get select(): ProductSelect {
        return this._children['preselect'] || null;
    }

    set select(value: ProductSelect){
        if (this.SetChild('preselect', value, 'preselect')){
            this.ToUpdate = true;
        }
    }

    get option(): ProductOption {
        return this._children['prodopt'] || null;
    }

    set option(value: ProductOption){
        if (this.SetChild('prodopt', value, 'prodopt')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._option.status,
            preselect: this._option.preselect,
            prodopt: this._option.prodopt
        };
    }

    protected get Depend() {
        return {
            preselect: { item: this.select, relation_info:  { to: 'options', by: 'preselect' } },   // this[by -> 'preselect'][to -> 'options'] => this
            prodopt: { item: this.option, relation_info: { to: null, by: 'prodopt' } }              // no relation to this in this[by -> 'prodopt']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_option: _SelectOption){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._option, 'status', _option['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'preselect', _option['preselect']) || _toUpdate;
        _toUpdate = this.patchValue(this._option, 'prodopt', _option['prodopt']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_option: _SelectOption){
        this.patchValue(this._option, 'created', _option['created']);
        
        if (this._patchData(_option)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._option;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_option: _SelectOption){
        this._patchData(_option);

        if (_option['preselect']){       // update children 'preselect'
            let _objid = _option['preselect'].toString();
            this.SetChild('preselect', new ProductSelect(_objid, this.data, this._objoptions), 'preselect');
        }
        else {
            this.SetChild('preselect', null, 'preselect');
        }

        if (_option['prodopt']){       // update children 'prodopt'
            let _objid = _option['prodopt'].toString();
            this.SetChild('prodopt', new ProductOption(_objid, this.data, this._objoptions), 'prodopt');
        }
        else {
            this.SetChild('prodopt', null), 'prodopt';
        }
    }

    private _ddbb(info): _SelectOptionData {
        let _select: _SelectOptionData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            preselect: info['preselect'] ? parseInt(info['preselect']) : null,
            prodopt: info['prodopt'] ? parseInt(info['prodopt']) : null
        };
        return _select;
    }

    protected _OnUpdate(info){
        let _option = this._ddbb(info);
        this.patchValue(this._option, 'objid', _option['objid']);
        this.patchValue(this._option, 'created', _option['created']);
        this.DoPatchValues(_option);
    }
}

export class SelectOption extends SelectOptionData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRESELECTOPT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): SelectOption {
        return this._Copy(store) as SelectOption;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/
    
    get IsValid(){
        return (this.status && (this.status != 'DE')) && (this.option.IsValid);
    }

    get IsActive(){
        return this.option.active;
    }
}
