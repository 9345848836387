import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';
import { Session } from './session';

export interface _FCM {
    session: number;
    lang: string;
    token: string;
};

interface _FCMData extends _FCM {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

export abstract class FCMData extends DataObject {
    protected _fcm: _FCMData = {
        session: null,
        lang: null,
        token: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._fcm.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._fcm.created;
    }

    get session(): Session {
        return this._children['session'] || null;
    }

    set session(value: Session){
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate = true;
        }
    }

    get lang(): string {
        return this._fcm.lang;
    }

    set lang(value: string){
        if (this.patchValue(this._fcm, 'lang', value)){
            this.ToUpdate = true;
        }
    }

    get token(): string {
        return this._fcm.token;
    }

    set token(value: string){
        if (this.patchValue(this._fcm, 'token', value)){
            this.ToUpdate = true;
        }
    }
   
    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            session: this._fcm.session,
            lang: this._fcm.lang,
            token: this._fcm.token
        };
    }

    protected get Depend() {
        return {
            session: { item: this.session, relation_info: { to: 'fcm', by: 'session' } }    // this[by -> 'session'][to -> 'fcm'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_fcm: _FCM){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._fcm, 'session', _fcm['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._fcm, 'lang', _fcm['lang']) || _toUpdate;
        _toUpdate = this.patchValue(this._fcm, 'token', _fcm['token']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_fcm: _FCM){
        this.patchValue(this._fcm, 'created', _fcm['created']);
        
        if (this._patchData(_fcm)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._fcm;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private _ddbb(info): _FCMData {
        let _fcm: _FCMData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            session: info['session'] ? parseInt(info['session']) : null,
            lang: info['lang'],
            token: info['token']
        };
        return _fcm;
    }

    private DoPatchValues(_fcm: _FCM){
        this._patchData(_fcm);

        if(_fcm['session']){      // update children: 'session'
            let _objid = _fcm['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session')
        }      
        else {
            this.SetChild('session', null, 'session');
        }
    }

    protected _OnUpdate(info){
        let _fcm = this._ddbb(info);
        this.patchValue(this._fcm, 'objid', _fcm['objid']);
        this.patchValue(this._fcm, 'created', _fcm['created']);
        this.DoPatchValues(_fcm);
    }
}

export class FCM extends FCMData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('FCM', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : FCM {
        return this._Copy(store) as FCM;
    }
}