import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";

import { viewService } from '@app/modules/view';

@Component({
    selector: 'ui-action',
    templateUrl:'./ui-action.html',
    styleUrls: [ 
        './ui-action.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiAction),
            multi: true
        }            
    ]
})
export class UiAction implements OnInit, ControlValueAccessor {
    @Input('display') _mode = 'header';
    @Input('menu') _menu = false;
    
    @Input('formControlName') _name = ''    
    @Input('title') _title = ''
    @Input('icon') _icon = 'chevron-forward';
    @Input('color') _color = 'default';
    @Input('lines') _lines = 'full';
    
    @Input('formgroup') _formgroup = null
    @Input('errornfo') _errornfo = {} 

    private _innerValue: any = '';  
    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}
    
    public _data = {
        title: '',
    }

    // class methods
    constructor(public view: viewService) {
        // nothing to do
    }

    public _inline = false;
    public _header = false;
    
    ngOnInit(){
        this._inline = (this._mode == 'inline');
        this._header = (this._mode == 'header');
    }
    
    @Output('onClick') _onClick = new EventEmitter<any>();    
    async doAction() {
        this._onClick.emit()
    }
    
    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = v;
            
            this._onChangeCallback(v);
            this._onTouchCallback(v);
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }    
}
