import { environment } from "../environments/environment"

export class AppConstants {

    /********************************************/
    /* SERVICE WORKER CONSIDERATIONS            */
    /********************************************/

    private static get IsGuest(){
        return document.URL.includes('qr-access');
    }

    private static get IsStatic(){
        if (document.URL.includes('qr-access')){
            return false;
        };

        if (document.URL.includes('index')){
            return false;
        };

        return true;
    }

    public static get EnableSW(){
        if (this.IsStatic){
            return false;
        }

        if (document.URL.startsWith('http://localhost/')){
            console.info("[UNPISPAS] Running on native environment");
            return false;
        }

        return true;
    }

    public static get TargetSW(){
        if (AppConstants.IsGuest){   
            console.info("[UNPISPAS] Install FCM push service worker");
            return 'firebase-messaging-sw.js';
        }
        else {
            console.info("[UNPISPAS] Install WPA service worker");
            return 'unpispas-application-sw.js';    
        }
    }
    
    public static get ScopeSW(){
        return (AppConstants.IsGuest) ? '/qr-access' : '/';
    }

    public static get DelaySW(){
        return (AppConstants.IsGuest) ? '2000' : '30000';
    }

    /********************************************/
    /* STATIC VALUES (CONSTANTS)                */
    /********************************************/

    public static get TicketBaiSandbox(): boolean { return environment.ticketbai_sandbox; }
    public static get TicketSiiSandbox(): boolean { return environment.ticketsii_sandbox; }

    private static get TicketBaiRegistry() {
        return {
            PRD: {
                'Bizkaia': {
                    license: 'TBAIBIjWPDMtTEyW1aA+',
                    appname: 'Unpispas',
                    developer: 'GEKKOTECH SL',
                    nif: 'B70659198'                    
                },
                'Gipuzkoa': {
                    license: 'TBAIGI3A40424979E2F0',
                    appname: 'Unpispas',
                    developer: 'GEKKOTECH SL',
                    nif: 'B70659198'
                },
                'Araba': {
                    license: 'TBAIARCdjdCgKHF00204',
                    appname: 'Unpispas',
                    developer: 'GEKKOTECH SL',
                    nif: 'B70659198'    
                }
            },
            DEV: {
                'Bizkaia': {
                    license: 'TBAIBI00000000PRUEBA',
                    appname: 'UnPisPas',
                    developer: 'SOFTWARE GARANTE TICKETBAI PRUEBA',
                    nif: 'A99800005'                    
                },
                'Gipuzkoa': {
                    license: 'TBAIGIPRE00000000988',
                    appname: 'Unpispas www.unpispas.com',
                    developer: 'Jorge Alejando Duran Royo, ATM tiendas y mulsitervicio SLu',
                    nif: 'B99278319'
                },
                'Araba': {
                    license: 'TBAIARCjHMggMlH00868',
                    appname: 'Unpispas',
                    developer: 'Tienda y multiservicios ATM S.L',
                    nif: 'B99278319'     
                }
            }
        }
    }

    public static get isProduction(): boolean { return environment.production; }
    public static get Appname(): string { return 'UnPisPas' }
    public static get Version(): string { return environment.appVersion; }
    public static get Build(): string { return environment.build.split('.')[1] }

    public static get TaxRate1(): number { return 4; }
    public static get TaxRate2(): number { return 10; }
    public static get TaxRate3(): number { return 21; }

    public static get recentMs(): number { return 8 * 3600 * 1000; }
    public static get defaultTaxRate(): number { return this.TaxRate2; }
    public static get InvoiceMaxPrice(): number { return 3000; }
    public static get defaultPrintWidth(): number { return 58; }
    public static get defaultPrintFont(): number { return 12; }
    public static get thumbnailWidth(): number { return 140; };
    public static get thumbnailColumns(): number { return 24; }
    
    public static TicketBaiAppname(region) : string { return AppConstants.TicketBaiRegistry[AppConstants.TicketBaiSandbox ? 'DEV' : 'PRD'][region]['appname']; }
    public static TicketBaiAppvers(region) : string { return AppConstants.Version }
    public static TicketBaiLicense(region): string { return AppConstants.TicketBaiRegistry[AppConstants.TicketBaiSandbox ? 'DEV' : 'PRD'][region]['license']; }
    public static TicketBaiDeveloper(region): string { return AppConstants.TicketBaiRegistry[AppConstants.TicketBaiSandbox ? 'DEV' : 'PRD'][region]['developer']; }
    public static TicketBaiNIF(region): string { return AppConstants.TicketBaiRegistry[AppConstants.TicketBaiSandbox ? 'DEV' : 'PRD'][region]['nif']; }

    public static get defaultLanguage(): string { return 'es'; }
    public static get MainRefresh(): number { return 500; } // ms
    public static get apppath(): string { return environment.appPath; }
    public static get domain(): string { return environment.appDomain; }   

    public static get baseURL(): string { return environment.wwwProt + '://' + environment.wwwPath + environment.wwwDomain + (environment.wwwPort ? ':' + environment.wwwPort : '') + environment.wwwRoute; }
    public static get thisURL(): string { return environment.appProt + '://' + environment.appPath + environment.appDomain + (environment.appPort ? ':' + environment.appPort : '') + environment.appRoute; }
    public static get restURL(): string { return environment.wsrProt + '://' + environment.wsrPath + environment.wsrDomain + (environment.wsrPort ? ':' + environment.wsrPort : '') + environment.wsrRoute; }
  
    public static get uploadPath(): string { return 'upload/'; }
    public static get qrcodePath(): string { return 'qrcode/'; }
    public static get googleApiKey(): string { return 'AIzaSyDLbnVeXUMsJEu6CtgjM5s92ht5xji0Ulk'; }
    public static get googleOAuth2Key(): string { return '404259737376-8t1cgibk48q5jji5sb8b8nc1b5a726hs.apps.googleusercontent.com'; }
    public static get GoogleAnalitics(): string { return environment.googleAnalytics; }    
    public static get FCMVapidKey(): string { return 'BOZ-hqmu5VZKdrjUC06uqqmheRjM4zv4CWZXeahLUcXONW8B1s-DeZXf-2AJ0JqtwWuoK6lJAo0f2K-Ks-HvI3A'; };
}
