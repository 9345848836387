import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Extra } from './extra';
import { ExtraProductOption } from './extraoption';
import { Product } from './product';

export interface _ExtraProduct {
    status: string;
    extra: number;
    product: number;
};

interface _ExtraProductData extends _ExtraProduct {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ExtraProductData extends DataObject {
    protected _extraproduct: _ExtraProductData = {
        status: null,
        extra: null,
        product: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._extraproduct.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._extraproduct.created;
    }

    get status(): string {
        return this._extraproduct.status;
    }

    set status(value: string) {
        if(this.patchValue(this._extraproduct, 'status', value)){
            this.ToUpdate = true;
        }        
    }

    get extra() : Extra {
        return this._children['extra'] || null;
    }

    set extra(value: Extra){
        if (this.SetChild('extra', value, 'extra')){
            this.ToUpdate = true;
        }
    }

    get product() : Product {
        return this._children['product'] || null;
    }

    set product(value: Product){
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddOption(child: ExtraProductOption){
        this.AddChild('options', child, 'product');
    }

    DelOption(child: ExtraProductOption){
        this.DelChild('options', child, 'product');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get options() : Array <ExtraProductOption> {
        return this._chldlist['options'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._extraproduct.status, 
            extra: this._extraproduct.extra,
            product: this._extraproduct.product
        };
    }

    protected get Depend() {
        return {
            extra: { item: this.extra, relation_info: { to: 'products', by: 'extra' } },     // this[by -> 'extra'][to -> 'products'] => this
            product: { item: this.product, relation_info: { to: null, by: 'product' } }      // no relation to this in this[by -> 'product']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.options){
            _children.push(_item);
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_extraproduct: _ExtraProduct){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._extraproduct, 'status', _extraproduct['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._extraproduct, 'extra', _extraproduct['extra']) || _toUpdate;
        _toUpdate = this.patchValue(this._extraproduct, 'product', _extraproduct['product']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_extraproduct: _ExtraProduct){
        this.patchValue(this._extraproduct, 'created', _extraproduct['created']);
        
        if (this._patchData(_extraproduct)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._extraproduct;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_extraproduct: _ExtraProduct){
        this._patchData(_extraproduct);
        
        if (_extraproduct['product']){   // update children: 'product'
            let _objid = _extraproduct['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product')
        }

        if (_extraproduct['extra']){     // update children: 'extra'
            let _objid = _extraproduct['extra'].toString();
            this.SetChild('extra', new Extra(_objid, this.data, this._objoptions), 'extra')
        }
        else {
            this.SetChild('extra', null, 'extra')
        }
    }

    private _ddbb(info): _ExtraProductData {
        let _extraproduct: _ExtraProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            extra: info['extra'] ? parseInt(info['extra']) : null,
            product: info['product'] ? parseInt(info['product']) : null
        };
        return _extraproduct;
    }

    protected _OnUpdate(info){
        let _extraproduct = this._ddbb(info);
        this.patchValue(this._extraproduct, 'objid', _extraproduct['objid']);
        this.patchValue(this._extraproduct, 'created', _extraproduct['created']);
        this.DoPatchValues(_extraproduct);

        if (info['options']){     // update children: 'options'
            this.SetChildren <ExtraProductOption> (info['options'], 'options', ExtraProductOption, 'product');
        }
    }
}

export class ExtraProduct extends ExtraProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions= null){
        super('EXTRAPRODUCT', objid, data, objoptions);
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/

    Copy(store: Array<DataObject> = []): ExtraProduct {
        return this._Copy(store) as ExtraProduct;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status() : string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (value == 'DE'){
            if ((this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.extra.DelProduct(this);
            }
            this.extra.ToUpdate = true;
        }                

        super.status = value;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid(){
        return this.status && (this.status != 'DE') && this.product.IsValid;
    }    
}