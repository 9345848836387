import { Injectable } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';

import { AppConstants} from '@app/app.constants'
import { HttpClient } from '@angular/common/http';

class __trString{
    private _id = null;
    private _args = [];
    private _tr = null;
    
    constructor(private lang: _languageService, id: string, args: any){
        this._id = id;
        this._args = args;
    }

    get value() {
        if (!this._tr){
            this._tr = String(this.lang._translate(this._id, this._args));
        }
        return this._tr;
    }    
}

class __erString{
    private _str = null;
    
    constructor(str: string){
        this._str = str;    
    }
    
    get value() {
        return this._str;
    }
}

class _languageService {    
    private strings = new Map();
    private current = null;
    private modules = new Set();
    private trcache = new Map();

    get _strings(){
        return this.strings;
    }
    
    private _trerror(id, args, errstr) {
        if(errstr) {
            console.error(errstr);
        }
    
        let tr = id + " [ ";
        for(let i=0; i < args.length; i++) {
            tr += args[i] + ", ";
        }
        if (args.length > 0) {
            tr = tr.slice(0, -2);
        }
        
        tr += " ]";
        return tr;
    }
    
    constructor(private http: HttpClient) { 
        // nothing to do (initialized when language is set)
    }
    
    public async SetLanguage(language, modules){
        if (language != this.current){
            this.current = language;

            let _promises =  [];
            for(let module of modules){
                _promises.push(this.LoadModule(module));
            }
            const results = await Promise.all(_promises);

            const allok = results.every(result => result === true);
            return allok;
        }
    }

    public LoadModule(name) {
        return this._load_module(name, this.current, false);
    }

    public _translate(id, args) {
        let trinfo = this.strings.get(id) || null;
        
        if (!trinfo.args) trinfo.args = [];     // add empty arguments list as default
        if (trinfo.args.length != args.length) {
            return this._trerror(id, args, "ERROR: invalid number of arguments for translation [" + id + "]");
        }
        
        let tr = trinfo.tr;
        for (let i=0; i < trinfo.args.length; i++) {
            let re = new RegExp("{{" + trinfo.args[i] + "}}", "g");
            tr = tr.replace(re, args[i]);
        }
        
        return tr;
    }

    public tr(id, args = []) {
        let _key = JSON.stringify({ id: id, args: args });
        let _str = this.trcache.get(_key) || null;
        if (!_str){
            _str = new __erString((this.loading > 0) ? '...' : this._trerror(id, args, null));
            if (this.strings.has(id)){
                this.trcache.set(_key, _str = new __trString(this, id, args));
            }
        }
        return _str.value;
    }

    private _load_json(json, replace) {
        for(let i=0; i < json.length; i++){
            let entry = json[i];
            if (!replace && this.strings.has(entry.id)){
                console.warn("WARNING: existing string '" + entry.id + "'");
            }
            this.strings.set(entry.id, { args: entry.args, tr: entry.tr });
        }
    }
    
    private loading = 0;
    private _load_module(name, language, replace) {
        return new Promise((resolve) => {
            if (!this.modules.has(name)){
                this.loading++;            
                let _request = this.http.get('assets/lang/' + name + '/' + language.toLowerCase() + '.json');

                let _subscription = _request.subscribe(
                data => {
                    this._load_json(data, replace);
                    console.info("[LANGUAGE] Module '" + name + "' strings loaded for language '" + language + "'");
                    this.modules.add(name);
                    resolve(true);
                },
                error => {
                    console.error("LANGUAGE] Could not load strings for module '" + name + "'.");
                    console.error(error);
                    resolve(false);
                },
                () => {
                    _subscription.unsubscribe();
                    this.loading--;
                });
            }
            else {
                resolve(false);  // already loaded
            }
        });
    }    
}

@Injectable()
export class languageService {
    private _lngsets = {};
    private modules = new Set();
    private _current = null; 

    constructor(private http: HttpClient, private route: ActivatedRoute) { 

        // first option - user browser language
        let _userLang = navigator.language || AppConstants.defaultLanguage.toUpperCase(); 
        if (_userLang){
            _userLang = _userLang.substring(0, 2).toUpperCase();
            
            console.info("[BROWSER LANGUAGE]: " + _userLang);
            let _supported = null;
            for (let _lang of this.GetLanguages()){
                if (_userLang == _lang.lang){
                    _supported = _lang.lang;
                }
            }

            _supported = _supported || AppConstants.defaultLanguage.toUpperCase();
            if (_supported){
                this.AddLanguage(_supported).then(
                () => {
                    this.SetLanguage(_supported, true);
                });
            }
        }

        // second option - user URL language
        let _urlLang = this.route.snapshot.queryParamMap.get("lang");
        if (_urlLang){  // use url language
            console.info("[URL GET LANGUAGE]: " + _urlLang);
            let _supported = null;
            for (let _lang of this.GetLanguages()){
                if (_userLang == _lang.lang){
                    _supported = _lang.lang;
                }
            }

            if (_supported){
                this.AddLanguage(_supported).then(
                () => {
                    this.SetLanguage(_supported, true);
                });       
            }
        }

        this.LoadModule('base');
    }    

    public StrInfo(id){
        let _lang = this._lngsets[this._current];
        if (!_lang){
            console.error("[TRANSLATE]: No current language is set")
            return null;
        }

        return _lang._strings.get(id);
    }

    public GetLanguages(){
        return [
            { lang: 'ES', flag: 'ES' },
            { lang: 'EU', flag: 'ES-PV' },
            { lang: 'EN', flag: 'GB' },
            { lang: 'ZH', flag: 'CN' }
        ];
    }

    private _onSetLanguage = new Subject <void> ();
    public OnSetLanguage = this._onSetLanguage.asObservable();

    public async LoadModule(name) {
        this.modules.add(name);

        let _promises = [];        
        for (let _language in this._lngsets){
            _promises.push(this._lngsets[_language].LoadModule(name));
        }

        await Promise.all(_promises);
        this._onSetLanguage.next();
    }

    private async AddLanguage(language) {
        if (language in this._lngsets){
            return;     // already included
        }

        this._lngsets[language] = new _languageService(this.http);
        if (this._lngsets[language]){
            return await this._lngsets[language].SetLanguage(language, this.modules);
        }
    }

    public async SetLanguage(language, global = true) {
        if (this._current == language){
            return;
        }

        let _lang = this._lngsets[language];
        if (!_lang){
            let success = await this.AddLanguage(language);
            if (!success){
                return false;
            }
        }

        if (global){
            this._current = language;
            this._onSetLanguage.next();
        }

        return true;
    }

    public GetLanguage(toUpper = false) {
        return (toUpper)? this._current.toUpperCase() : this._current;
    }

    public tr(id, args = [], language = null) {
        let _lang = this._lngsets[language || this._current];
        if (!_lang){
            return "...";   // strings are being loaded
        }

        return _lang.tr(id, args);
    }
}

export class languageSupport {
    public language = null;

    private _strcache = new Map();
    private _argcache = new Map();

    public _lang: languageService = null;
    public _chng: ChangeDetectorRef = null;

    private _language_subscription = null;
    constructor(lang: languageService, chng: ChangeDetectorRef){
        this._lang = lang;
        this._chng = chng;

        this.language = this._lang.GetLanguage();
        this._language_subscription = this._lang.OnSetLanguage.subscribe(
        data => {
            this.language = this._lang.GetLanguage();

            this._strcache = new Map();
            this._argcache = new Map();

            if (this._chng){
                this._chng.detectChanges();
            }
        });    
    }
    
    OnDestroy(){
        if (this._language_subscription){
            this._language_subscription.unsubscribe();
        }
        this._language_subscription = null;
    }

    private _translate(trinfo, args) {
        if (!trinfo.args) trinfo.args = [];     // add empty arguments list as default
        if (trinfo.args.length == args.length) {
            return null;
        }
        
        let tr = trinfo.tr;
        for (let i=0; i < trinfo.args.length; i++) {
            let re = new RegExp("{{" + trinfo.args[i] + "}}", "g");
            tr = tr.replace(re, args[i]);
        }
        
        return tr;
    }

    public async SetLanguage(language){
        if ((language != this.language) && await this._lang.SetLanguage(language, false)){
            this.language = language;
            this._strcache.clear();
            this._chng.detectChanges();    
        }
    }

    public tr(id, args = []): string {
        if (args.length == 0){
            let _str = this._strcache.get(id) || null;
            if (_str){
                return _str;
            }

            _str = this._lang.tr(id, args, this.language);
            if ((_str[0] != '@') && (args.length == 0)) {   // not translated or has arguments (do not cache)
                this._strcache.set(id, _str); 
            }
            return _str;
        }
        else {
            if (this._argcache.has(id)){
                let _str = this._translate(this._argcache.get(id), args);
                if (_str){
                    return _str;
                }
            }

            let _str = this._lang.StrInfo(id);
            if (_str){
                this._argcache.set(id, _str);
                _str = this._translate(_str, args);
                if (_str){
                    return _str;
                }
            }

            return this._lang.tr(id, args, this.language);
        }
    }
}

