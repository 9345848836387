import { dataService } from '@app/modules/data';
import { DataObject } from './base';

import { PrintDevice } from './printer';
import { Place } from './place';
import { QrCode } from './qrcode';

export interface _Event {
    created: Date,
    target: string,
};

abstract class RegistryEvent extends DataObject {

    constructor(data: dataService){
        super('EVENT', null, data, null);
    }

    /****************************/
    /* DATA OBJECT ABSTRACTS    */
    /****************************/

    Copy(store: Array<DataObject> = []): RegistryEvent {
        return this._Copy(store) as RegistryEvent;
    }

    set Data(value){
        /* nothing to do */
    }

    set Info(value){
        /* nothing to do */
    }

    protected _OnUpdate(info){
        /* nothing to do */
    }

    /****************************/
    /* SERVER NOTIFICATION      */
    /****************************/

    Notify(){
        if (this.ToNotify){
            this.DoCommit(true);
        }
        else {
            console.warn("[NOTIFY] Event not ready for notification!")
        }
    }
}

/**************************************/
/* OPEN DRAWER EVENT                  */
/**************************************/

export interface _DrawerEventData extends _Event {
    printer: number
}

export class DrawerEvent extends RegistryEvent {
    protected _event: _DrawerEventData = {
        created: null,
        target: null,
        printer: null
    };

    constructor(data: dataService){
        super(data);

        this._event.created = new Date();
        this._event.target = 'DRAWER';
    }    

    get Info(){
        return this._event;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get printer(): PrintDevice {
        return this._children['printer'] || null;
    }

    set printer(value: PrintDevice){
        if (this.SetChild('printer', value, 'printer')){
            this.ToNotify = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            created: this._event.created ? this.dateStrToMysql(this._event.created) : null,
            target: this._event.target,
            printer: this._event.printer
        };
    }

    protected get Depend() {
        return {
            printer: { item: this.printer, relation_info: { to: null, by: 'printer' } }         // no relation to this in this[by -> 'printer']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }
}

/**************************************/
/* USER LOGIN/LOGOUT EVENT            */
/**************************************/

export interface _LoginEventData extends _Event {
    place: number,
    login: boolean
}

export class LoginEvent extends RegistryEvent {
    protected _event: _LoginEventData = {
        created: null,
        target: null,
        place: null,
        login: null,
    };

    constructor(data: dataService){
        super(data);

        this._event.created = new Date();
        this._event.target = 'LOGIN';
    }    

    get Info(){
        return this._event;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    private get _isReady(){
        if (this._event.place === null){
            return false;
        }

        if (this._event.login === null){
            return false;
        }

        return true;
    }

    get place(): Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToNotify = this._isReady;
        }
    }

    get login(): boolean {
        return this._event.login;
    }

    set login(value: boolean) {
        if (this.patchValue(this._event, 'login', value)){
            this.ToNotify = this._isReady;
        }        
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            created: this._event.created ? this.dateStrToMysql(this._event.created) : null,
            target: this._event.target,
            place: this._event.place,
            login: this._event.login ? '1' : '0',
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: null, by: 'place' } }         // no relation to this in this[by -> 'place']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }
}

/**************************************/
/* GUEST LOGIN/LOGOUT EVENT           */
/**************************************/

export interface _GuestEventData extends _Event {
    qrcode: number,
    login: boolean
}

export class GuestEvent extends RegistryEvent {
    protected _event: _GuestEventData = {
        created: null,
        target: null,
        qrcode: null,
        login: null,
    };

    constructor(data: dataService){
        super(data);

        this._event.created = new Date();
        this._event.target = 'GUEST';
    }    

    get Info(){
        return this._event;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    private get _isReady(){
        if (this._event.qrcode === null){
            return false;
        }

        if (this._event.login === null){
            return false;
        }

        return true;
    }

    get qrcode(): QrCode {
        return this._children['qrcode'] || null;
    }

    set qrcode(value: QrCode){
        if (this.SetChild('qrcode', value, 'qrcode')){
            this.ToNotify = this._isReady;
        }
    }

    get login(): boolean {
        return this._event.login;
    }

    set login(value: boolean) {
        if (this.patchValue(this._event, 'login', value)){
            this.ToNotify = this._isReady;
        }        
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            created: this._event.created ? this.dateStrToMysql(this._event.created) : null,
            target: this._event.target,
            qrcode: this._event.qrcode,
            login: this._event.login ? '1' : '0',
        };
    }

    protected get Depend() {
        return {
            qrcode: { item: this.qrcode, relation_info: { to: null, by: 'qrcode' } }         // no relation to this in this[by -> 'qrcode']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }
}


