import { OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { DataObject } from '@app/modules/model/base';
import { Session } from '@app/modules/model/session';
import { Ticket } from '@app/modules/model/ticket';
import { Business } from '@app/modules/model/business';
import { TicketChange } from '@app/modules/model/ticketchange';
import { TicketAudit } from '@app/modules//model/ticketaudit';
import { TicketBAI } from './model/ticketbai';
import { TicketSII } from './model/ticketsii';
import { TicketExtra } from '@app/modules/model/ticketextra';
import { TicketInvoice } from '@app/modules/model/ticketinvoice';
import { TicketOffer } from '@app/modules/model/ticketoffer';
import { TicketDiscount } from '@app/modules/model/ticketdiscount';
import { TicketOption } from '@app/modules/model/ticketoption';
import { TicketProduct } from '@app/modules/model/ticketproduct';
import { Product } from '@app/modules/model/product';
import { ProductOption } from '@app/modules/model/productoption';
import { Offer } from '@app/modules/model/offer';
import { Discount } from '@app/modules/model/discount';
import { Extra } from '@app/modules/model/extra';
import { Payment } from '@app/modules/model/payment';
import { PayAccount } from '@app/modules/model/payaccount';
import { AccountInvoice } from '@app/modules/model/accountinvoice';

import { languageService} from '@app/modules/common/language';
import { toastService } from '@app/modules/common/toast';
import { dataService } from '@app/modules/data';
import { syncService } from '@app/modules/sync';

/************************************/
/* PERIOD DEFINITION                */
/************************************/

export interface Step {
    date: Date,
    text: String
}

export interface Period {
    ini: Date,              // DD/MM/YYYY HH:MM (GMT+0)
    end: Date,              // DD/MM/YYYY HH:MM (GMT+0)
    steps: Array <Step>     // Steps array
}

/************************************/
/* TICKETS SERVICE                  */
/************************************/

export class reportService {

    /******************************/
    /* MAIN ENTRY POINT           */
    /******************************/

    private _subscriptions = [];

    private _ResetReports(){
        this._tickets = this.TicketsReport;
        this._products = this.ProductsReport;
    }

    constructor(private lang: languageService, private toast: toastService, private data: dataService, private sync: syncService){
        this._ResetReports();
        
        this._subscriptions.push(this.sync.OnExpired.subscribe(
        data => {
            this._ResetReports();
        }));

        this._subscriptions.push(this.data.OnReloadRequested.subscribe(
        data => {
            this._ResetReports();
        }));    
    }

    OnDestroy(){
        for(let _subscription of this._subscriptions){
            _subscription.unsubscribe();
        }
        this._subscriptions = [];
    }

    /************************************/
    /* QUERY PERIOD                     */
    /************************************/

    private _onPeriodChanged = new Subject<any>();
    public OnPeriodChanged = this._onPeriodChanged.asObservable();

    private _config = null;
    get Config(){
        return this._config;
    }

    set Config(value){
        this._config = value;
    }

    private _timestamp_ini = 0;     // provide unix timestamp
    private _timestamp_end = 0;     // provide unix timestamp

    private _period = null;
    get Period() : Period{
        return this._period;
    }

    set Period(value: Period){
        this._period = value;

        let _timestamp_ini = this._period.ini.getTime() / 1000;
        let _timestamp_end = this._period.end.getTime() / 1000;

        if ((_timestamp_ini != this._timestamp_ini) || (_timestamp_end != this._timestamp_end)){
            this._timestamp_ini = _timestamp_ini;
            this._timestamp_end = _timestamp_end;

            this._ResetReports();
        }

        // notify period updated signal
        this._onPeriodChanged.next();
    }

    /************************************/
    /* VOLATILE OBJECT CREATION         */
    /************************************/

    private _CreateObject(change, storage){
        if (!('objid' in change) || isNaN(change['objid'])){
            return null;    // must provide valid objid
        }

        let _options = {
            volatile: true,
            storage: storage
        };

        let _table = change['table'];
        let _objid = change['objid'];

        switch(_table){
            case 'SESSION': return new Session(_objid, this.data);
            case 'TICKET': return new Ticket(_objid, this.data, _options);
            case 'TICKETCHANGE': return new TicketChange(_objid, this.data, _options);
            case 'TICKETAUDIT': return new TicketAudit(_objid, this.data, _options);
            case 'TICKETBAI': return new TicketBAI(_objid, this.data, _options);
            case 'TICKETSII': return new TicketSII(_objid, this.data, _options);
            case 'TICKETPRODUCT': return new TicketProduct(_objid, this.data, _options);
            case 'TICKETOPTION': return new TicketOption(_objid, this.data, _options);
            case 'TICKETOFFER': return new TicketOffer(_objid, this.data, _options);
            case 'TICKETDISCOUNT': return new TicketDiscount(_objid, this.data, _options);
            case 'TICKETEXTRA': return new TicketExtra(_objid, this.data, _options);
            case 'TICKETINVOICE': return new TicketInvoice(_objid, this.data, _options);
            case 'PRODUCT': return new Product(_objid, this.data, _options);
            case 'PRODUCTOPT': return new ProductOption(_objid, this.data, _options);
            case 'OFFER': return new Offer(_objid, this.data, _options);
            case 'DISCOUNT': return new Discount(_objid, this.data, _options);
            case 'EXTRA': return new Extra(_objid, this.data, _options);
            case 'PAYMENT': return new Payment(_objid, this.data, _options);
            case 'INVOICEBUSINESS': return new Business(_objid, this.data, _options);
            case 'PAYACCOUNT': return new PayAccount(_objid, this.data, _options);
            case 'ACCOUNTINVOICE': return new AccountInvoice(_objid, this.data, _options);
        }

        console.error("[TICKETS] Could not create object '" + _table + "@" + _objid + "'");
        return null;
    }

    private CreateObject(change, storage) {
        let _object = this._CreateObject(change, storage);
        if (_object){
            storage.AddObject(_object);
        }

        return _object;
    }

    /************************************/
    /* INCOMES REPORT                   */
    /************************************/

    private _doTicketListUrl = 'place/ticketlst.php';
    private _doInvoiceListUrl = 'place/invoicelst.php';
    private _doTicketLoadUrl = 'place/ticketnfo.php';

    private _tickets = null;
    private get TicketsReport(){
        return {
            timestamp: 0,
            tickets: {}    
        }
    }

    private ParseTicket(_ddbb){
        let _ticket = this.data.storage.GetByRef('TICKET', _ddbb['objid'], null) as Ticket;
        if (!_ticket){
            _ticket = new Ticket(_ddbb['objid'], this.data, { volatile: true });
            if (_ticket){
                _ticket.FromData(_ddbb);
            }    
        }

        return _ticket;
    }

    private ParseInvoice(_ddbb){
        let _invoice = this.data.storage.GetByRef('ACCOUNTINVOICE', _ddbb['objid'], null) as AccountInvoice;
        if (!_invoice){
            _invoice = new AccountInvoice(_ddbb['objid'], this.data, { volatile: true });
            if (_invoice){
                _invoice.FromData(_ddbb);
            }
        }

        return _invoice;
    }

    private ConcatTickets(newtickets){
        for(let _ticket of newtickets){
            this._tickets.tickets[_ticket.objid] = _ticket;
        }

        return this._tickets.tickets;
    }

    LoadIncome(place){
        return new Promise <any[]> ((resolve) => {
            if (!place){
                resolve([]);
            }
            else {
                let _getparams = {
                    place: place.objid, 
                    timestamp: this._tickets.timestamp,
                    dateini: this._timestamp_ini,
                    dateend: this._timestamp_end
                };

                this.sync.DoRequest(this._doTicketListUrl, _getparams).then(
                data => {
                    if (data['errorcode'] == 0){
                        let _tickets = [];
                        
                        for (let _ticket of data['tickets']){
                            _tickets.push(this.ParseTicket(_ticket));
                        }

                        this._tickets = {
                            timestamp: data['timestamp'],
                            tickets: this.ConcatTickets(_tickets) 
                        };

                        resolve(Object.values(this._tickets.tickets));    
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doTicketListUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        
                        resolve([]);    // return empty tickets list on error
                    }
                })
            }
        });
    }

    private CreateTickets(_updates){
        this.data.Clock.ClockEnable('create-tickets', false);     // pause object refresh
        let _tickets = [];

        let _storage = this.data.CreateStorage();
        if (_storage){
            let _changes = this.sync.SortUpdates(_updates);
            for(let _change of _changes){
                // find the object in the main / volatile storages
                let _update = this.data.storage.GetByRef(_change['table'], _change['objid'], null) as DataObject;
                if (!_update){  
                    _update = _storage.GetByRef(_change['table'], _change['objid'], null) as DataObject;
                }

                // object not found: create a new one
                if (!_update){  
                    _update = this.CreateObject(_change, _storage);
                }

                // call to the update method of the target object
                if (_update.IsVolatile){
                    _update.FromData(_change);
                }
    
                // keep the ticket instances to be returned
                if (_update instanceof Ticket){
                    _tickets.push(_update);
                }
            }
    
            this.data.ReleaseStorage(_storage);
        }

        this.data.Clock.ClockEnable('create-tickets', true);      // resume object refresh
        return _tickets;
    }

    TicketObjects(objids){
        return new Promise <Ticket[]> ((resolve) => {
            if (!objids || (objids.length == 0)){
                resolve([]);  // no objids
            }
            else {
                this.sync.DoRequest(this._doTicketLoadUrl, {}, objids).then(
                data => {
                    if (data['errorcode'] == 0){
                        resolve(this.CreateTickets(data['updates']));
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doTicketLoadUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        resolve([]);
                    }
                });
            }
        });
    }

    /************************************/
    /* PRODUCTS REPORT                  */
    /************************************/

    private _doProductListUrl = 'place/productlst.php';
    private _doProductLoadUrl = 'place/productnfo.php';

    private _products = null;
    private get ProductsReport(){
        return {
            timestamp: 0,
            products: {}    
        }
    }

    private UpdateProduct(_newproduct){
        let _objid = _newproduct['objid'];

        let _oldproduct = this._products.products[_objid];
        if (_oldproduct){
            let _indexed = new Map();  // index all the ticketproducts by objid
            for(let _item of _oldproduct['items']){
                _indexed[_item['objid']] = _item;
            }
    
            for(let _item of _newproduct['items']){
                _indexed[_item['objid']] = _item;
            }

            _newproduct['items'] = Object.values(_indexed);
        }

        return _newproduct;
    }

    private ConcatProducts(newproducts){
        for(let _newproduct of newproducts){
            this._products.products[_newproduct['objid']] = this.UpdateProduct(_newproduct);
        }
        return this._products.products;
    }

    LoadProducts(place){
        return new Promise <any[]> ((resolve) => {
            if (!place){
                resolve([]);
            }
            else {
                let _getparams = {
                    place: place.objid, 
                    timestamp: this._products.timestamp,
                    dateini: this._timestamp_ini,
                    dateend: this._timestamp_end
                };

                this.sync.DoRequest(this._doProductListUrl, _getparams).then(
                data => {
                    if (data['errorcode'] == 0){
                        this._products = {
                            timestamp: data['timestamp'],
                            products: this.ConcatProducts(data['products']) 
                        };

                        resolve(Object.values(this._products.products));
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doProductListUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        resolve([]);
                    }
                })
            }
        });
    }

    private CreateProducts(_updates){
        this.data.Clock.ClockEnable('create-products', false);      // pause object refresh
        let _ticketproducts = [];

        let _storage = this.data.CreateStorage();
        if (_storage){
            let _changes = this.sync.SortUpdates(_updates);
            for(let _change of _changes){
                // find the object in the main / volatile storages
                let _update = this.data.storage.GetByRef(_change['table'], _change['objid'], null) as DataObject;
                if (!_update){  
                    _update = _storage.GetByRef(_change['table'], _change['objid'], null) as DataObject;
                }

                // object not found: create a new one
                if (!_update){  
                    _update = this.CreateObject(_change, _storage);
                }

                // call to the update method of the target object
                if (_update.IsVolatile){
                    _update.FromData(_change);  
                }
    
                // keep the ticket instances to be returned
                if (_update instanceof TicketProduct){
                    _ticketproducts.push(_update);
                }
            }
    
            this.data.ReleaseStorage(_storage);
        }

        this.data.Clock.ClockEnable('create-products', true);      // resume object refresh
        return _ticketproducts;
    }

    ProductObjects(objids){
        return new Promise <TicketProduct[]> ((resolve) => {
            if (!objids || (objids.length == 0)){
                resolve([]);  // no objids
            }
            else {
                let _getparams = {
                    dateini: this._timestamp_ini,
                    dateend: this._timestamp_end
                };

                this.sync.DoRequest(this._doProductLoadUrl, _getparams, objids).then(
                data => {
                    if (data['errorcode'] == 0){
                        resolve(this.CreateProducts(data['updates']));
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doProductLoadUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        resolve([]);
                    }
                });
            }
        });
    }

    /************************************/
    /* MONTHLY / CLIENTS REPORT         */
    /************************************/   
    
    private _DoLoadTickets(place, ini, end, updated){
        return new Promise <any[]> ((resolve, reject) => {
            if (!place){
                reject([]);
            }
            else {
                let _getparams = {
                    place: place.objid, 
                    timestamp: 0,
                    dateini: ini,
                    dateend: end,
                    updated: updated
                };

                this.sync.DoRequest(this._doTicketListUrl, _getparams).then(
                data => {
                    if (data['errorcode'] == 0){
                        let _tickets = [];

                        this.data.Clock.ClockEnable('load-tickets', false);     // pause object refresh
                        for (let _ticket of data['tickets']){
                            _tickets.push(this.ParseTicket(_ticket));
                        }
                        this.data.Clock.ClockEnable('load-tickets', true);      // resume object refresh

                        resolve(_tickets);    
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doTicketListUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        
                        reject([]);    // return empty tickets list on error
                    }
                })
                .catch(err => {
                    reject([]);        // return empty tickets list on error
                });
            }
        });
    }

    private _DoLoadInvoices(place, ini, end){
        return new Promise <any[]> ((resolve, reject) => {
            if (!place){
                reject([]);
            }
            else {
                let _getparams = {
                    place: place.objid, 
                    timestamp: 0,
                    dateini: ini.getTime() / 1000,
                    dateend: end.getTime() / 1000
                };

                this.sync.DoRequest(this._doInvoiceListUrl, _getparams).then(
                data => {
                    if (data['errorcode'] == 0){
                        let _invoices = [];

                        this.data.Clock.ClockEnable('load-invoices', false);     // pause object refresh
                        for (let _invoice of data['invoices']){
                            _invoices.push(this.ParseInvoice(_invoice));
                        }
                        this.data.Clock.ClockEnable('load-invoices', true);      // resume object refresh

                        resolve(_invoices);    
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doInvoiceListUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        
                        reject([]);    // return empty tickets list on error
                    }
                })
                .catch(err => {
                    reject([]);        // return empty tickets list on error
                });
            }
        });        
    }

    private _lru_tickets_requests: { [key: string]: Promise<any> } = {};

    private _LoadTickets(place, ini, end, updated){
        let _ini = Math.floor(ini.getTime() / 1000);
        let _end = Math.floor(end.getTime() / 1000);
        let _key = JSON.stringify({ 
            place: place.objid, 
            ini: _ini, 
            end: _end 
        });
        
        if (!(_key in this._lru_tickets_requests)){
            this._lru_tickets_requests[_key] = this._DoLoadTickets(place, _ini, _end, updated).catch(err => {
                delete(this._lru_tickets_requests[_key]);   // remove inmediatelly 
            });

            setTimeout(() => {
                delete(this._lru_tickets_requests[_key]);   // remove after 10 seconds
            }, 10000);  
        }

        return this._lru_tickets_requests[_key];
    }

    LoadCreatedTickets(place, ini, end){
        return this._LoadTickets(place, ini, end, false);
    }

    LoadUpdatedTickets(place, ini, end){
        return this._LoadTickets(place, ini, end, true);
    }

    private _lru_invces_requests: { [key: string]: Promise<any> } = {};

    private _LoadInvoices(place, ini, end){
        let _key = JSON.stringify({ 
            place: place.objid, 
            ini: Math.floor(ini.getTime() / 10000), 
            end: Math.floor(end.getTime() / 10000) 
        });
        
        if (!(_key in this._lru_invces_requests)){
            this._lru_invces_requests[_key] = this._DoLoadInvoices(place, ini, end).catch(err => {
                delete(this._lru_invces_requests[_key]);   // remove inmediatelly 
            });

            setTimeout(() => {
                delete(this._lru_invces_requests[_key]);   // remove after 10 seconds
            }, 10000);  
        }

        return this._lru_invces_requests[_key];
    }

    LoadAccountInvoices(place, ini, end){
        return this._LoadInvoices(place, ini, end);
    }

    /************************************/
    /* PLACE ACCOUNT TICKETS            */
    /************************************/   

    private _accounttickets = {};

    async AccountTickets(account){
        if (!(account.objid in this._accounttickets)){
            let _acnttickets = []

            let _ini = new Date();
            _ini.setFullYear(_ini.getFullYear() - 1);
            let _end = new Date();    

            let _yeartickets = await this.LoadUpdatedTickets(account.place, _ini, _end);
            for(let _ticket of _yeartickets){
                if (_ticket.IsValid && (_ticket.account == account)){
                    _acnttickets.push(_ticket);
                }
            }

            this._accounttickets[account.objid] = _acnttickets;
        }

        return this._accounttickets[account.objid];
    }

    /************************************/
    /* USER NAME FROM SESSION           */
    /************************************/   

    private _doSessionUsersUrl = 'place/sessionusers.php';

    LoadSessionUsers(place, objids){
        return new Promise <any> ((resolve, reject) => {
            if (objids.length == 0){
                resolve({});
            }
            else {
                this.sync.DoRequest(this._doSessionUsersUrl, { place: place.objid }, objids).then(
                data => {
                    if (data['errorcode'] == 0){
                        resolve(data['usernames']);
                    }
                    else {
                        console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doSessionUsersUrl + "'");
                        this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                        reject({});    // return empty users map on error
                    }
                })
                .catch(err => {
                    reject({});        // return empty users map on error
                });
            }
        });
    }
}


  