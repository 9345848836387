import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Extra } from './extra';
import { Ticket } from './ticket';

export interface _TicketExtra {
    status: string,
    extra: number,
    ticket: number,
    charge: number,
    refund: number
};

interface _TicketExtraData extends _TicketExtra {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketExtraData extends DataObject {
    protected _extra: _TicketExtraData = {
        status: null,
        extra: null,
        ticket: null,
        charge: null,
        refund: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._extra.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._extra.created;
    }

    get status(): string{
        return this._extra.status;
    }

    set status(value: string){
        if (this.patchValue(this._extra, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get extra(): Extra {
        return this._children['extra'] || null;
    }

    set extra(value: Extra){
        if (this.SetChild('extra', value, 'extra')){
            this.ToUpdate = true;
        }
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(value: Ticket){
        if (this.SetChild('ticket', value, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get charge(): number{
        return this._extra.charge;
    }

    set charge(value: number){
        if (this.patchValue(this._extra, 'charge', value)){
            this.ToUpdate = true;
        }
    }
    
    get refund(): number{
        return this._extra.refund;
    }   
    
    set refund(value: number){
        if (this.patchValue(this._extra, 'refund', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._extra.status,
            extra: this._extra.extra,
            ticket: this._extra.ticket,
            charge: this._extra.charge,
            refund: this._extra.refund
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: 'extras', by: 'ticket' } },   // this[by -> 'ticket'][to -> 'extras'] => this
            extra: { item: this.extra, relation_info: { to: null, by: 'extra' } }           // no relation to this in this[by -> 'extra']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_extra: _TicketExtra){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._extra, 'status', _extra['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'extra', _extra['extra']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'ticket', _extra['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'charge', _extra['charge']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'refund', _extra['refund']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_extra: _TicketExtra){
        this.patchValue(this._extra, 'created', _extra['created']);
        
        if (this._patchData(_extra)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._extra;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_extra: _TicketExtra){
        this._patchData(_extra);

        if (_extra['extra']){     // update children: 'extra'
            let _objid = _extra['extra'].toString();
            this.SetChild('extra', new Extra(_objid, this.data, this._objoptions), 'extra')
        }
        else {
            this.SetChild('extra', null, 'extra');
        }

        if (_extra['ticket']){    // update children: 'ticket'
            let _objid = _extra['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }
    }

    private _ddbb(info): _TicketExtraData {
        let _extra: _TicketExtraData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            extra: info['extra'] ? parseInt(info['extra']) : null,
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            charge: info['charge'] ? parseFloat(info['charge']) : null,
            refund: info['refund'] ? parseFloat(info['refund']) : null
        };
        return _extra;
    }

    protected _OnUpdate(info){
        let _extra = this._ddbb(info);
        this.patchValue(this._extra, 'objid', _extra['objid']);
        this.patchValue(this._extra, 'created', _extra['created']);
        this.DoPatchValues(_extra);
    }
}

export class TicketExtra extends TicketExtraData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETEXTRA', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketExtra {
        return this._Copy(store) as TicketExtra;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.ticket){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.ticket.DelExtra(this);
            }
            else {
                this.ticket.DoRefresh('TICKETEXTRA');
            }    
        }
    }

    get taxrate(){
        return this.ticket.place.TaxRate;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        if (!this.status){
            return false;
        }
        
        return (this.status != 'DE');
    }

    private _extraprice_forticket(extra: Extra, ticket: Ticket, prstatus: string){
        return extra.price;
    }

    private _extraprice_forpcnt(extra: Extra, ticket: Ticket, prstatus: string){
        let _price = 0;

        for(let _product of ticket.products){
            if ((_product.status == prstatus) && extra._CheckApplies(_product.product)){
                _price += (_product.ChargePrice * extra.price) / 100;
            }
        }

        return Math.round(_price * 100) / 100;
    }

    private _extraprice_forfixed(extra: Extra, ticket: Ticket, prstatus: string){
        let _price = 0;

        for(let _product of ticket.products){
            if ((_product.status == prstatus) && extra._CheckApplies(_product.product)){
                _price += extra.price;
            }
        }

        return Math.round(_price * 100) / 100;
    }

    get price(){
        switch(this.extra.type){
            case 'ITEMFIXED': return this._extraprice_forfixed(this.extra, this.ticket, 'AC');
            case 'TICKET': return this._extraprice_forticket(this.extra, this.ticket, 'AC');
            case 'ITEMPCNT': return this._extraprice_forpcnt(this.extra, this.ticket, 'AC');
            
            default:
                console.warn("[WARNING] not recognized extra type: '" + this.extra.type + "'");
                break;
        }        

        return 0;
    }

    get ChargePrice() {
        if (this.IsValid && ((this.ticket.IsValid) || (this.ticket.status == 'PR')) && ((this.status != 'UN') || (this.ticket.status == 'CC'))){
            return this.price;
        }

        return 0;
    }

    get RefundPrice(){
        if (!this.IsValid || !this.ticket.IsPaid || (this.status != 'UN')) {
            return 0;   // no refund if its not paid or manually removed
        }
                
        switch(this.extra.type){
            case 'ITEMFIXED': return this._extraprice_forfixed(this.extra, this.ticket, 'UN');
            case 'TICKET': return this._extraprice_forticket(this.extra, this.ticket, 'UN');
            case 'ITEMPCNT': return this._extraprice_forpcnt(this.extra, this.ticket, 'UN');
            
            default:
                console.warn("[WARNING] not recognized extra type: '" + this.extra.type + "'");
                break;
        }    
        
        return 0;
    }
}

