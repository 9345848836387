/**
    refs: 
        https://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel
**/

import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { ModalController, NavParams } from '@ionic/angular';

import { languageSupport, languageService } from '@app/modules/common/language';
import { viewService } from '@app/modules/view';

import { GenericUtils } from '@app/app.commonutils';

/**

    Implementation notes:
    
    When defining the picker entry following fields are mandatory:
    - text: The text to be displayed
    - value: The value to be returned with the text when selected
    
    Icon can be configured through css class or ionic:
    - cssicon: name of the class tht loads te icon
    - ionicon: name of the ionicon for the entry
    
    Aditionally, if html field is provided:
    - the html content will be displayed for the entry
    - the text content will be returned on selection
    
**/

@Component({
    selector: 'ui-modal-picker',
    templateUrl:'./ui-modal.html',
    styleUrls: [ 
        './ui-modal.scss'
    ]
})
export class UiModalPicker extends languageSupport implements OnInit, OnDestroy {
    public _title = '';
    public _multiple = false;
    public _options = [];
    public _value = null;
    
    normalize(str){
        return GenericUtils.Normalize(str);
    }

    // class methods
    constructor(private lang: languageService, private modalCtrl: ModalController, private params: NavParams, public view: viewService) {
        super(lang, null);
    }
                
    private _allopts = [];
    
    private SetSelectedOption(val) {
        this._value = (this._multiple)?[]:null;
        if (val) {
            this._allopts.forEach(option => {
                if (this._multiple) {
                    option.isChecked = val.value.indexOf(option.value) != -1;
                    if (option.isChecked) {
                        this._value.push(option);
                    }
                }
                else {
                    option.isChecked = (option.value == val.value);
                    if (option.isChecked) {
                        this._value = option;
                    }
                }
            });
        }
    }
    
    private _stepsize = 55;     // height (in pixels) of every item (defined in CSS)
    ngOnInit() {
        this._title = this.params.get('title');
        this._multiple = this.params.get('multiple');
        this._allopts = this.params.get('options');
        this.SetSelectedOption(this.params.get('value'));
     
        this._options = [];
        
        let _itemsInWindow = window.innerHeight/this._stepsize;
        for(let i=0; i < this._allopts.length; i++){
            if (i < _itemsInWindow + 5) {
                this._options.push(this._allopts[i])
            }
        }
    }
    
    ngOnDestroy(){
        super.OnDestroy();
    }

    private checkVisibleOption(i, element){
        return (((i+1) * this._stepsize) >= element.scrollTop) && (((i-1) * this._stepsize) <= (element.scrollTop + element.offsetHeight));
    }
        
    OnScroll(event){
        for(let i=0; i < this._allopts.length; i++){
            if (this.checkVisibleOption(i, event.srcElement) && (this._options.indexOf(this._allopts[i]) == -1)){
                this._options.push(this._allopts[i])
            }
        }
    }
    
    private _searchString = "";
    get searchString(){
        return this._searchString;
    }

    set searchString(value){
        this._searchString = (value == null) ? "" : value;
    }

    OnSearch() {
        let _fttopts = []
        for(let i=0; i < this._allopts.length; i++) {
            if (this._allopts[i].text.toLowerCase().includes(this.searchString.toLowerCase())) {
                _fttopts.push(this._allopts[i])
            }
        }
        
        this._options = _fttopts;
    }
    
    private _onrecursivecheck = false;  // avoid recursive calls on single mode
    async OnCheck(ev, option) {
        // multiple select enabled
        if (this._multiple) {
            let idx = this._value.indexOf(option);
            if (idx == -1) {
                this._value.push(option);
                option.isChecked = true;
            }
            else {
                this._value.splice(idx, 1);
                option.isChecked = false;
            }
        }
        
        // multiple select disabled
        else {
            if (this._onrecursivecheck) {
                this._onrecursivecheck = false;
            }
            else {
                if ((this._value) && (this._value.isChecked)) {
                    this._onrecursivecheck = true;
                    this._value.isChecked = false;    
                }

                option.isChecked = ev.detail.checked;
                this._value = (option.isChecked)?option:null;
            }
        }
    }
    
    async CloseModal() {
        let result = null
        
        if (this._value) {
            if (this._multiple) {
                result = {
                    text: '',
                    value: []
                };
                
                for (let opt of this._value){
                    result.text += opt.text + ", ";
                    result.value.push(opt.value);
                }                
                result.text = result.text.slice(0, -2);
            }
            else {
                result = {
                    text: this._value.text,
                    value: this._value.value
                };
            }
        }
                
        await this.modalCtrl.dismiss((!result || (result.text == ''))?null:result);
    }    
}

@Component({
    selector: 'ui-picker',
    templateUrl:'./ui-picker.html',
    styleUrls: [ 
        './ui-picker.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiPicker),
            multi: true
        }            
    ]
})
export class UiPicker extends languageSupport implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    @Input('placeholder') _placeholder = '';
    @Input('formControlName') _name = '';
    @Input('title') _title = '';
    @Input('multiple') _multiple = false;
    @Input('options') _options = [];
    @Input('value') _value = '';
    
    @Input('formgroup') _formgroup = null;
    @Input('errornfo') _errornfo = {};

    private _innerValue: any = null;
    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}

    public _data = {
        title: '',
        showTitle: false,
        text: '',
        value: ''
    }
        
    // class methods
    constructor(private lang: languageService, private modalCtrl: ModalController) {
        super(lang, null);
    }

    ngOnInit() {
        this.onChange();
    }
    
    private get hasInnerValue(){
        return (this._innerValue != null) && (this._innerValue.value != null);
    }

    ngOnChanges(){
        this._data.title = (this._title == '') ? this._placeholder : this._title;
        this._data.showTitle = this.hasInnerValue;
        this._data.text = this.hasInnerValue ? this._innerValue.text : this._placeholder;
        this._data.value = this.hasInnerValue ? this._innerValue.value : null;
    }

    ngOnDestroy(){
        super.OnDestroy();
    }

    @Output('onChange') _onChange = new EventEmitter<any>();     
    onChange() {
        this._data.title = (this._title == '') ? this._placeholder : this._title;
        this._data.showTitle = (this._innerValue != null);
        this._data.text = (this._innerValue == null) ? this._placeholder : this._innerValue.text;
        this._data.value = (this._innerValue == null) ? null : this._innerValue.value;
        
        this._onChange.emit();
    }
    
    async showPicker() {
        const modal = await this.modalCtrl.create({ 
            component: UiModalPicker,
            componentProps: { 
                title: this._data.title,
                multiple: this._multiple,
                options: this._options,
                value: this._innerValue
            }
        });
        
        modal.onDidDismiss().then((detail) => {
            if (detail !== null) {
                this.value = detail.data;
            }
            
            this.onChange();
        });
        
        return await modal.present();        
    }
    
    
    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    set value(v: any) {
        if (v !== this._innerValue) {
            this._innerValue = v;
            
            this._onChangeCallback(v);
            this._onTouchCallback(v);
        }
    }    
        
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
            this.onChange();
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }    
}
