import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Audit } from './audit';
import { PlaceService } from './svcplace';

export interface _AuditTill {
    status: string;
    audit: number;
    rasppi: number;
};

interface _AuditTillData extends _AuditTill {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class AuditTillData extends DataObject {
    protected _audit: _AuditTillData = {
        status: null,
        audit: null,
        rasppi: null,
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._audit.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._audit.created;
    }

    get status(): string{
        return this._audit.status;
    }

    set status(value: string){
        if(this.patchValue(this._audit, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get audit(): Audit {
        return this._children['audit'] || null;
    }    

    set audit(child: Audit){
        if (this.SetChild('audit', child, 'audit')){
            this.ToUpdate = true;
        }
    }

    get till(): PlaceService {
        return this._children['rasppi'] || null;
    }

    set till(child: PlaceService){
        if (this.SetChild('rasppi', child, 'rasppi')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._audit.status,
            audit: this._audit.audit,
            rasppi: this._audit.rasppi
        };
    }

    protected get Depend() {
        return {
            audit: { item: this.audit, relation_info: { to: null, by: 'audit' } },   // no relation to this in this[by -> 'audit']
            rasppi: { item: this.till, relation_info: { to: null, by: 'rasppi' } }   // no relation to this in this[by -> 'rasppi']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_audit: _AuditTill){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._audit, 'status', _audit['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'audit', _audit['audit']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'rasppi', _audit['rasppi']) || _toUpdate;

        return _toUpdate;
    };

    set Data(_option: _AuditTill){
        this.patchValue(this._audit, 'created', _option['created']);
        
        if (this._patchData(_option)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._audit;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_audit: _AuditTillData){
        this._patchData(_audit);

        if (_audit['audit']){   // update children: 'audit'
            let _objid = _audit['audit'].toString();
            this.SetChild('audit', new Audit(_objid, this.data, this._objoptions), 'audit')
        }
        else {
            this.SetChild('audit', null, 'audit');
        }

        if (_audit['rasppi']){    // update children: 'rasppi'
            let _objid = _audit['rasppi'].toString();
            this.SetChild('rasppi', new PlaceService(_objid, this.data, this._objoptions), 'rasppi')
        }
        else {
            this.SetChild('rasppi', null, 'rasppi');
        }
    }

    private _ddbb(info): _AuditTillData {
        let _audit: _AuditTillData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            audit: info['audit'] ? parseInt(info['audit']) : null,
            rasppi: info['rasppi'] ? parseInt(info['rasppi']) : null
        };
        return _audit;
    }
   
    protected _OnUpdate(info){
        let _audit = this._ddbb(info);
        this.patchValue(this._audit, 'objid', _audit['objid']);
        this.patchValue(this._audit, 'created', _audit['created']);
        this.DoPatchValues(_audit);
    }
}

export class AuditTill extends AuditTillData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('AUDITTILL', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): AuditTill {
        return this._Copy(store) as AuditTill;
    }
}
