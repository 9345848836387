import { Directive, AfterViewInit  } from '@angular/core';
import { ElementRef, NgZone } from '@angular/core';
import { Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[appTouchEvents]'
})
export class TouchEvents implements AfterViewInit {

    constructor(private el: ElementRef, private zone: NgZone) {
        // nothing to do
    }

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener('click', (event) => {
                this.onTouchClick(event);
            }, { passive: true });

            this.el.nativeElement.addEventListener('touchstart', (event) => {
                this.onTouchStart(event);
            }, { passive: true });

            this.el.nativeElement.addEventListener('touchend', (event) => {
                this.onTouchLeave(event);
            }, { passive: true });

            this.el.nativeElement.addEventListener('touchcancel', (event) => {
                this.onTouchLeave(event);
            }, { passive: true });

            this.el.nativeElement.addEventListener('touchleave', (event) => {
                this.onTouchLeave(event);
            }, { passive: true });

            this.el.nativeElement.addEventListener('touchmove', (event) => {
                this.onTouchLeave(event);
            }, { passive: true });

        });
    }

    @Output('onTouchClick') _onTouchClick = new EventEmitter<any>();  
    onTouchClick(event: MouseEvent) {
        this.zone.run(() => {
            this._onTouchClick.emit(event);
        });        
    }

    @Output('onTouchStart') _onTouchStart = new EventEmitter<any>();  
    onTouchStart(event: TouchEvent) {
        this.zone.run(() => {
            this._onTouchStart.emit(event);
        });        
    }

    @Output('onTouchLeave') _onTouchLeave = new EventEmitter<any>();  
    onTouchLeave(event: TouchEvent) {
        this.zone.run(() => {
            this._onTouchLeave.emit(event);
        });        
    }
}