import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { PlaceArea } from './placearea';
import { PlaceService } from './svcplace';
import { DrawItem } from './draw';
import { Session } from './session';
import { AskWaiter } from './askwaiter';
import { Ticket } from './ticket';
import { ExtraQrCode } from './extratable';

export interface _QrCode {
    status: string;
    place: number;
    prefix: string;
    number: string;
    area: number;
    draw: number,
    till: number, 
    authorization: string;
};

interface _QrCodeData extends _QrCode {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class QrCodeData extends DataObject {
    protected _qrcode: _QrCodeData = {
        status: null,
        place: null,
        prefix: null,
        number: null,
        area: null,
        draw: null,
        till: null,
        authorization: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._qrcode.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._qrcode.created;
    }

    get status(): string{
        return this._qrcode.status;
    }

    set status(value: string){
        if (this.patchValue(this._qrcode, 'status', value)){
            this.ToUpdate = true;
        }
    }
  
    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get prefix(): string{
        return this._qrcode.prefix;        
    }

    set prefix(value: string){
        if (this.patchValue(this._qrcode, 'prefix', value)){
            this.ToUpdate = true;
        }
    }

    get number(): string{
        return this._qrcode.number;        
    }

    set number(value: string){
        if (this.patchValue(this._qrcode, 'number', value)){
            this.ToUpdate = true;
        }
    }

    get authorization(): string{
        return this._qrcode.authorization;                
    }

    set authorization(value: string){
        if (this.patchValue(this._qrcode, 'authorization', value)){
            this.ToUpdate = true;
        }
    }

    get area() : PlaceArea {
        return this._children['area'] || null;
    }

    set area(value: PlaceArea){
        if (this.SetChild('area', value, 'area')){
            this.ToUpdate = true;
        }
    }

    get draw() : DrawItem {
        return this._children['draw'] || null;
    }

    set draw(value: DrawItem){
        if (this.SetChild('draw', value, 'draw')){
            this.ToUpdate = true;
        }
    }

    get till(): PlaceService {
        return this._children['till'] || null;
    }

    set till(value: PlaceService){
        if (this.SetChild('till', value, 'till')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddSession(child: Session){
        this.AddChild('sessions', child, 'qrcode');
    }

    DelSession(child: Session){
        this.DelChild('sessions', child, 'qrcode');
    }

    AddRequest(child: AskWaiter){
        this.AddChild('requests', child, 'qrcode');
    }

    DelRequest(child: AskWaiter){
        this.DelChild('requests', child, 'qrcode');
    }

    AddTicket(child: Ticket){
        this.AddChild('tickets', child, 'qrcode');
    }

    DelTicket(child: Ticket){
        this.DelChild('tickets', child, 'qrcode');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get sessions() : Array <Session> {
        return this._chldlist['sessions'] || [];
    }

    get requests() : Array <AskWaiter> {
        return this._chldlist['requests'] || [];
    }

    get tickets() : Array <Ticket> {
        return this._chldlist['tickets'] || [];
    }

    get extras(): Array <ExtraQrCode> {
        return this._chldlist['extras'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._qrcode.status,
            place: this._qrcode.place,
            prefix: this._qrcode.prefix,
            number: this._qrcode.number,
            area: this._qrcode.area,
            draw: this._qrcode.draw,
            till: this._qrcode.till,
            authorization: this._qrcode.authorization
        };
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'tables', by: 'place' } },  // this[by -> 'place'][to -> 'tables'] => this
            area: { item: this.area, relation_info: { to: 'tables', by: 'area' } },     // this[by -> 'area'][to -> 'tables'] => this
            draw: { item: this.draw, relation_info: { to: null, by: 'draw' } },         // no relation to this in this[by -> 'draw']
            till: { item: this.till, relation_info: { to: null, by: 'till' } }          // no relation to this in this[by -> 'till']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.sessions){
            _children.push(_item)
        }

        for(let _item of this.requests){
            _children.push(_item)
        }

        for(let _item of this.tickets){
            _children.push(_item)
        }

        if (this.draw){
            _children.push(this.draw);
        }

        if (this.till){
            _children.push(this.till);
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_qrcode: _QrCode){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._qrcode, 'status', _qrcode['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'place', _qrcode['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'prefix', _qrcode['prefix']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'number', _qrcode['number']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'area', _qrcode['area']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'draw', _qrcode['draw']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'till', _qrcode['till']) || _toUpdate;
        _toUpdate = this.patchValue(this._qrcode, 'authorization', _qrcode['authorization']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_qrcode: _QrCode){
        this.patchValue(this._qrcode, 'created', _qrcode['created']);
        
        if (this._patchData(_qrcode)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._qrcode;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_qrcode: _QrCode){
        this._patchData(_qrcode);

        if (_qrcode['place']){         // update children: 'place'
            let _objid = _qrcode['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }

        if(_qrcode['area']){         // update children: 'area'
            let _objid = _qrcode['area'].toString();
            this.SetChild('area', new PlaceArea(_objid, this.data, this._objoptions), 'area')
        }
        else {
            this.SetChild('area', null, 'area');
        }

        if(_qrcode['draw']){         // update children: 'draw'
            let _objid = _qrcode['draw'].toString();
            this.SetChild('draw', new DrawItem(_objid, this.data, this._objoptions), 'draw')
        }
        else {
            this.SetChild('draw', null, 'draw');
        }

        if(_qrcode['till']){         // update children: 'till'
            let _objid = _qrcode['till'].toString();
            this.SetChild('till', new PlaceService(_objid, this.data, this._objoptions), 'till')
        }
        else {
            this.SetChild('till', null, 'till');
        }

    }

    private _ddbb(info): _QrCodeData {
        let _qrcode: _QrCodeData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            prefix: info['prefix'],
            number: info['number'],
            area: info['area'] ? parseInt(info['area']) : null,
            draw: info['draw'] ? parseInt(info['draw']) : null,
            till: info['till'] ? parseInt(info['till']) : null,
            authorization: info['authorization']
        };
        return _qrcode;
    }

    protected _OnUpdate(info){
        let _qrcode = this._ddbb(info);
        this.patchValue(this._qrcode, 'objid', _qrcode['objid']);
        this.patchValue(this._qrcode, 'created', _qrcode['created']);
        this.DoPatchValues(_qrcode);

        if ('sessions' in info){       // update children 'sessions'
            this.SetChildren <Session> (info['sessions'], 'sessions', Session, 'qrcode');
        }

        if ('requests' in info){       // update children 'requests'
            this.SetChildren <AskWaiter> (info['requests'], 'requests', AskWaiter, 'qrcode');
        }

        if ('tickets' in info) {        // update children: 'tickets'
            this.SetChildren <Ticket> (info['tickets'], 'tickets', Ticket, 'qrcode');
        }

        if ('extras' in info) {          // update children: 'extras'     
            this.SetChildren <ExtraQrCode> (info['extras'], 'extras', ExtraQrCode, 'qrcode');
        }
    }
}

export class QrCode extends QrCodeData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('QRCODE', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): QrCode {
        return this._Copy(store) as QrCode;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get qrcode(): string {
        return AppConstants.baseURL + AppConstants.qrcodePath + "qr_" + this._qrcode.objid + "_code.png";
    }

    get tickets() : Array <Ticket> {
        let _tickets = [];

        // do not return other table tickets (moved from table)
        for(let _ticket of super.tickets){
            if (_ticket.qrcode == this){
                _tickets.push(_ticket)
            }
        }

        return _tickets;
    }

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if (super.status != value){
            super.status = value;

            if (this.place){
                if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                    this.place.DelTable(this);
                }
                else {
                    this.place.DoRefresh('QRCODE');
                }        
            }
        }
    }

    get area() : PlaceArea {
        return super.area;
    }

    set area(value: PlaceArea){
        super.area = value;
        
        if (this.draw){
            this.draw.area = value;
        }
    }

    get till(): PlaceService {
        return super.till;
    }

    set till(value: PlaceService){
        if (value != super.till){
            super.till = value;
            this.place.DoRefresh('TILL');
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsDelivery(){
        return false;
    }

    get IsValid() {
        if (!this.status){
            return false;
        }
        
        return (this.status != 'DE');
    }

    get IsError(){
        if (!this.IsValid){
            return true;    // invalid table status
        }

        if (this.place.MultiplePOS){
            if ((this.till == null) || !this.till.IsValid){
                return true;        // invalid till mapping
            }
            else {
                let _connected = this.till.connected.some(_connect => {
                    return _connect.IsValid && (_connect.rasppi.name == this.till.name);
                });

                if (!_connected){
                    return true;    // till has no connections    
                }
            }
        }

        return false;
    }

    get IsBusy(){
        return this.tickets.some(
        (_ticket) => {
            return ((_ticket.qrcode == this) && (_ticket.IsValid) && (_ticket.IsRecent) && (_ticket.ToPrepare || _ticket.ToPayment));
        });
    }

    get Attention(){
        return this.requests.some(
        (_request) => {
            return ((_request.IsValid) && (_request.IsRecent));
        });
    }

    get IsOnline(){
        return this.sessions.some(
        (session) => {
            return session.IsActive;
        });
    }
}
