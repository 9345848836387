import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';

export interface _PlaceLink {
    place: number;
    link: string;
    url?: string;
    b64?: string;
};

interface _PlaceLinkData extends _PlaceLink {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class PlaceLinkData extends DataObject {
    protected _link: _PlaceLinkData = {
        place: null,
        link: null,
        url: null,
        b64: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._link.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._link.created;
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get link(): string {
        return this._link.link;
    }

    set link(value: string) {
        if (this.patchValue(this._link, 'link', value)){
            this.ToUpdate = true;
        }
    }

    get url(): string {
        return this._link.url;
    }

    set url(value: string) {
        if (this.patchValue(this._link, 'url', value)){
            this.ToUpdate = true;
        }
    }

    get base64(): any {
        return this._link.b64;        
    }

    set base64(value: any){
        if (this.patchValue(this._link, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        let _change = {
            place: this._link.place,
            link: this._link.link,
        }

        if ('url' in this._link){
            _change['url'] = this._link.url;
        }

        if (('b64' in this._link) && (this._link.b64)){
            _change['b64'] = this._link.b64;
        }

        return _change;
    }

    protected get Depend() {
        return {
           place: { item: this.place, relation_info: { to: 'links', by: 'place' } }     // this[by -> 'place'][to -> 'links'] => this
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_link: _PlaceLink){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._link, 'place', _link['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._link, 'link', _link['link']) || _toUpdate;
        _toUpdate = this.patchValue(this._link, 'url', _link['url']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_link: _PlaceLink){
        this.patchValue(this._link, 'created', _link['created']);
        
        if (this._patchData(_link)){
            this.ToUpdate = true;
        }
   }

    get Info(){
        return this._link;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_link: _PlaceLink){
        this._patchData(_link);

        if(_link['place']){      // update children: 'place'
            let _objid = _link['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }   
        else {
            this.SetChild('place', null, 'place');
        }
    }

    private _ddbb(info): _PlaceLinkData {
        let _link: _PlaceLinkData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            place: info['place'] ? parseInt(info['place']) : null,
            link: info['link'],
            url: info['url']
        };
        return _link;
    }

    protected _OnUpdate(info){
        let _link = this._ddbb(info);
        this.patchValue(this._link, 'objid', _link['objid']);
        this.patchValue(this._link, 'created', _link['created']);
        this.DoPatchValues(_link);
    }
}

export class PlaceLink extends PlaceLinkData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PLACELINK', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : PlaceLink {
        return this._Copy(store) as PlaceLink;
    }
}
