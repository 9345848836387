import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { AppConstants } from '@app/app.constants';

import { Session } from './session';
import { Place } from './place';
import { Audit } from './audit';
import { QrCode } from './qrcode';
import { AskWaiter } from './askwaiter';
import { Product } from './product';
import { Offer } from './offer';
import { Extra } from './extra';
import { TicketProduct } from './ticketproduct';
import { ProductOption } from './productoption';
import { TicketOffer } from './ticketoffer';
import { TicketExtra } from './ticketextra';
import { TicketInvoice } from './ticketinvoice';
import { TicketDiscount } from './ticketdiscount';
import { TicketChange } from './ticketchange';
import { TicketMixedPayment } from './ticketmixed';
import { TicketBAI } from './ticketbai';
import { TicketSII } from './ticketsii';
import { TicketAudit } from './ticketaudit';
import { Payment } from './payment';
import { PayAccount } from './payaccount';
import { AccountInvoice } from './accountinvoice';

import { Subject } from 'rxjs';

/*******************************/
/* GLOBAL MUTEX                */
/*******************************/

import { GlobalMutex } from '@app/app.commonutils';
const _globalmutex = new GlobalMutex();

/*******************************/
/* TICKET INSTANCE             */
/*******************************/

export interface _Ticket {
    status: string;
    place: number;
    session: number;
    paidby: number;
    paidon: Date;
    paidin: number;
    qrcode: number;
    price: number;
    refund: number;
    taxes: number;
    orderno: string;
    series: string;
    invceno: string;
    elapsed: number;
    comments: string;
    payment: string;
    transaction: number;
    account: number;
    invceac: number;
    given: number;
    flags: string;
    tmcommit: Date;
    duedays: number;

    client?: number;
    sentto?: string;
    intill?: number;
    pmixed?: string;
    device?: string;
};

interface _TicketData extends _Ticket {
    objid?: number;
    _uuid?: string;
    created?: Date;
    updated?: Date;
};

abstract class TicketData extends DataObject {
    protected _ticket: _TicketData = {
        status: null,
        place: null,
        session: null,
        paidby: null,
        paidon: null,
        paidin: null,
        qrcode: null,
        price: null,
        refund: null,
        taxes: null,
        orderno: null,
        series: null,
        invceno: null,
        elapsed: null,
        comments: null,
        payment: null,
        transaction: null,
        account: null,
        invceac: null,
        given: null,
        flags: null,        // see TicketFlags definition below
        tmcommit: null,
        duedays: null,

        client: null,
        sentto: null,
        intill: null,
        pmixed: null,
        device: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._ticket.created = new Date();
    }
   
    /****************************/
    /* TICKET FLAGS             */
    /****************************/

    protected TicketFlags = Object.freeze({
        NewTicketPrinted: 0,        // the 'new ticket' has been sent to printer
        TicketReadyNotified: 1,     // the 'ticket ready' has been notified to client
        TicketReady: 2,             // the ticket is ready
        TicketToPay: 3,             // ticket payment has been requested
        TicketPaid: 4               // ticket has been paid
    });

    protected abstract _UpdateFlags();
    protected get flags(): Array<any> {
        let _array = [];

        // backwards compatibility for old tickets
        if (this._isLoaded && !this._ticket.flags){
            this._UpdateFlags();     
        }

        if (!this._ticket.flags){
            return [];  // not loaded
        }

        let _flags = Array.from(this._ticket.flags);
        for(let i=0; i < _flags.length; i++){
            _array[i] = (_flags[i] == '1');
        }

        return _array;
    }

    protected set flags(value: Array<any>) {
        let _array = [];
        for(let i=0; i < value.length; i++){
            _array[i] = (value[i] === true) ? '1' : '0';
        }

        let _flags = _array.join(''); 
        if(this.patchValue(this._ticket, 'flags', _flags)){
            this.ToUpdate = true;
        }
    }

    protected _GetFlagValue(flag){
        return !!this.flags[flag];
    }

    protected _SetFlagValue(flag, value){
        let _flags = this.flags;
        _flags[flag] = value;
        this.flags = _flags;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(): Date{
        return this._ticket.created;
    }

    set created(value: Date){
        if(this.patchValue(this._ticket, 'created', value ? value : new Date())){
            this.ToUpdate = true;
        }
    }

    get updated(): Date {
        return this._ticket.updated || this._ticket.created;
    }

    get printed(): boolean {
        return this._GetFlagValue(this.TicketFlags.NewTicketPrinted);
    }

    set printed(value: boolean){
        this._SetFlagValue(this.TicketFlags.NewTicketPrinted, value);
    }

    get ready(): boolean {
        return this._GetFlagValue(this.TicketFlags.TicketReadyNotified);
    }

    set ready(value: boolean) {
        this._SetFlagValue(this.TicketFlags.TicketReadyNotified, value);
    }

    get status(): string{
        return this._ticket.status;
    }

    set status(value: string){
        if(this.patchValue(this._ticket, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place(): Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get session(): Session {
        return this._children['session'] || null;
    }

    set session(value: Session){
        if (this.SetChild('session', value, 'session')){
            this.ToUpdate= true;
        }
    }

    get paidby(): Session {
        return this._children['paidby'] || null;
    }

    set paidby(value: Session) {
        if (this.SetChild('paidby', value, 'paidby')){
            this.ToUpdate= true;
        }
    }

    get paidon(): Date{
        return this._ticket.paidon;
    }

    set paidon(value: Date){
        if(this.patchValue(this._ticket, 'paidon', value)){
            this.ToUpdate = true;
        }
    }

    get paidin(): Session {
        return this._children['paidin'] || null;
    }

    set paidin(value: Session) {
        if (this.SetChild('paidin', value, 'paidin')){
            this.ToUpdate= true;
        }
    }

    get qrcode() : QrCode {
        return this._children['qrcode'] || null;
    }

    set qrcode(value: QrCode){
        if (this.SetChild('qrcode', value, 'qrcode')){
            this.ToUpdate = true;
        }
    }

    get price(): number{
        return this._ticket.price;
    }

    set price(value: number){
        if(this.patchValue(this._ticket, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get refund(): number{
        return this._ticket.refund;
    }

    set refund(value: number){
        if(this.patchValue(this._ticket, 'refund', value)){
            this.ToUpdate = true;
        }
    }

    get taxes(): number {
        if (this._ticket.taxes === null){   // backwards compatibility: no taxes information
            return this._ticket.price - (this._ticket.price / ((100 + Number(this.place.TaxRate))/100));
        }

        return this._ticket.taxes;
    }

    set taxes(value: number){
        if(this.patchValue(this._ticket, 'taxes', value)){
            this.ToUpdate = true;
        }
    }

    get orderno(): string{
        return this._ticket.orderno;
    }

    set orderno(value: string){
        if(this.patchValue(this._ticket, 'orderno', value)){
            this.ToUpdate = true;
        }
    }

    get elapsed(): number{
        return this._ticket.elapsed;
    }

    set elapsed(value: number){
        if(this.patchValue(this._ticket, 'elapsed', value)){
            this.ToUpdate = true;
        }
    }

    get comments(): string{
        return this._ticket.comments;
    }

    set comments(value: string){
        if(this.patchValue(this._ticket, 'comments', value)){
            this.ToUpdate = true;
        }
    }

    get payment(): string{
        return this._ticket.payment;
    }

    set payment(value: string){
        if(this.patchValue(this._ticket, 'payment', value)){
            this.ToUpdate = true;
        }
    }

    get transaction(): Payment {
        return this._children['transaction'] || null;
    }

    get tmcommit(): Date{
        return this._ticket.tmcommit;
    }

    set tmcommit(value: Date){
        if(this.patchValue(this._ticket, 'tmcommit', value)){
            this.ToUpdate = true;
        }
    }

    get account(): PayAccount {
        return this._children['account'] || null;
    }

    set account(value: PayAccount) {
        if (this.SetChild('account', value, 'account')){
            this.ToUpdate= true;
        }
    }

    get invceac(): AccountInvoice {
        return this._children['invceac'] || null;
    }

    set invceac(value: AccountInvoice) {
        if (this.SetChild('invceac', value, 'invceac')){
            this.ToUpdate= true;
        }
    }

    get given(): number{
        return this._ticket.given;
    }

    set given(value: number){
        if(this.patchValue(this._ticket, 'given', value)){
            this.ToUpdate = true;
        }
    }

    get duedays(): number {
        return this._ticket.duedays;
    }

    set duedays(value: number){
        if(this.patchValue(this._ticket, 'duedays', value)){
            this.ToUpdate = true;
        }
    }

    protected get audits(): Array <TicketAudit> {
        return this._chldlist['audit'] || [];
    }

    protected get intill(): Audit {
        return this._children['intill'] || null;
    }

    /* read only properties */

    get series(): string {
        return this._ticket.series;
    }

    get invceno(): string {
        return this._ticket.invceno;
    }

    get client(): number {
        return this._ticket.client;
    }
 
    get sentto(): Array<string> {
        let _sentto = [];

        if (this._ticket.sentto){
            let _srvlst = [ 'TICKETSII', 'TICKETBAI' ];

            let _server = Array.from(this._ticket.sentto);
            for(let i=0; i < _server.length; i++){
                if (_server[i] == '1'){
                    _sentto.push(_srvlst[i]);
                }
            }    
        }
        else {  // this ticket is active
            let _changes = this.changes.sort((a, b) => {
                return b.created.getTime() - a.created.getTime();
            });

            let _lastchange = _changes[0];
            if (_lastchange.ticketsii?.IsValid){
                _sentto.push('TICKETSII');
            }

            if (_lastchange.ticketbai?.IsValid){
                _sentto.push('TICKETBAI');
            }
        }

        return _sentto;
    }

    get device(){
        if (!this._ticket.device){
            return this.session.deviceid;
        }

        return this._ticket.device;
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddInvoice(child: TicketInvoice){
        this.AddChild('invoice', child, 'ticket');
    }

    AddProduct(child: TicketProduct){
        this.AddChild('products', child, 'ticket');
    }

    DelProduct(child: TicketProduct){
        this.DelChild('products', child, 'ticket');
    }

    AddOffer(child: TicketOffer){
        this.AddChild('offers', child, 'ticket');
    }

    DelOffer(child: TicketOffer){
        this.DelChild('offers', child, 'ticket');
    }

    AddExtra(child: TicketExtra){
        this.AddChild('extras', child, 'ticket');
    }

    DelExtra(child: TicketExtra){
        this.DelChild('extras', child, 'ticket');
    }

    AddDiscount(child: TicketDiscount){
        this.AddChild('discount', child, 'ticket');
    }

    DelDiscount(child: TicketDiscount){
        this.DelChild('discount', child, 'ticket');
    } 

    AddChange(child: TicketChange){
        this.AddChild('changes', child, 'ticket');
    }

    DelChange(child: TicketChange){
        this.DelChild('changes', child, 'ticket');
    }

    AddMixed(child: TicketMixedPayment){
        this.AddChild('mixed', child, 'ticket');
    }

    DelMixed(child: TicketMixedPayment){
        this.DelChild('mixed', child, 'ticket');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get products() : Array <TicketProduct> {
        return this._chldlist['products'] || [];
    }

    get offers() : Array <TicketOffer> {
        return this._chldlist['offers'] || [];
    }

    get extras() : Array <TicketExtra> {
        return this._chldlist['extras'] || [];
    }

    get invoices(): Array<TicketInvoice> {
        return this._chldlist['invoice'] || [];
    }

    get discounts(): Array <TicketDiscount> {
        return this._chldlist['discount'] || [];
    }

    get changes(): Array<TicketChange> {
        return this._chldlist['changes'] || [];
    }

    get mixed(): Array<TicketMixedPayment> {
        return this._chldlist['mixed'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    abstract get requests() : Array<AskWaiter>;
    abstract get request() : AskWaiter;
    abstract set request(value: AskWaiter);

    protected get Change() {
        let _change = {
            created: this._ticket.created ? this.dateStrToMysql(this._ticket.created) : null,
            series: this._ticket.series,
            invceno: this._ticket.invceno,
            place: this._ticket.place,
            status: this._ticket.status,
            session: this._ticket.session,
            paidby: this._ticket.paidby,
            paidon: this._ticket.paidon ? this.dateStrToMysql(this._ticket.paidon) : null,
            paidin: this._ticket.paidin,
            qrcode: this._ticket.qrcode || 0,
            price: this._ticket.price,
            refund: this._ticket.refund || 0,
            taxes: this._ticket.taxes,
            elapsed: this._ticket.elapsed,
            comments: this._ticket.comments,
            payment: this._ticket.payment,
            transaction: this._ticket.transaction,
            account: this._ticket.account,
            invceac: this._ticket.invceac,
            given: this._ticket.given,
            tmcommit: this._ticket.tmcommit ? this.dateStrToMysql(this._ticket.tmcommit) : null,
            duedays: this._ticket.duedays,
            flags: this._ticket.flags
        };

        if (this.IsVolatile){   // avoid refreshing the isolated item
            _change['updated'] = this._ticket.updated ? this.dateStrToMysql(this._ticket.updated) : null;
        }

        return _change;
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info: { to: 'tickets', by: 'place' } },     // this[by -> 'place'][to -> 'tickets'] => this
            session:{ item: this.session, relation_info: { to: null, by: 'session' } },     // no relation to this in this[by -> 'session']
            qrcode: { item: this.qrcode, relation_info: { to: 'tickets', by: 'qrcode' } },  // this[by -> 'qrcode'][to -> 'tickets'] => this
            account: { item: this.account, relation_info: { to: null, by: 'account' } },    // no relation to this in this[by -> 'account']
            invceac: { item: this.invceac, relation_info: { to: null, by: 'invceac' } }     // no relation to this in this[by -> 'invceac']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.products){
            _children.push(_item)
        }

        for(let _item of this.requests){
            _children.push(_item);
        }

        for(let _item of this.offers){
            _children.push(_item)
        }

        for(let _item of this.extras){
            _children.push(_item)
        }

        for(let _item of this.discounts){
            _children.push(_item)
        }

        for(let _item of this.invoices){
            _children.push(_item)
        }

        for(let _item of this.changes){
            _children.push(_item);
        }

        for(let _item of this.mixed){
            _children.push(_item);
        }

        for(let _item of this.audits){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_ticket: _Ticket){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._ticket, 'place', _ticket['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'session', _ticket['session']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'paidby', _ticket['paidby']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'paidon', _ticket['paidon']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'paidin', _ticket['paidin']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'qrcode', _ticket['qrcode']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'price', _ticket['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'refund', _ticket['refund']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'taxes', _ticket['taxes']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'orderno', _ticket['orderno']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'series', _ticket['series']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'invceno', _ticket['invceno']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'elapsed', _ticket['elapsed']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'comments', _ticket['comments']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'payment', _ticket['payment']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'transaction', _ticket['transaction']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'account', _ticket['account']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'invceac', _ticket['invceac']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'given', _ticket['given']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'flags', _ticket['flags']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'tmcommit', _ticket['tmcommit']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'duedays', _ticket['duedays']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'status', _ticket['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'client', _ticket['client']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'sentto', _ticket['sentto']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'intill', _ticket['intill']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'pmixed', _ticket['pmixed']) || _toUpdate;
        _toUpdate = this.patchValue(this._ticket, 'device', _ticket['device']) || _toUpdate;

        return _toUpdate;
    }   

    set Data(_ticket: _Ticket){
        this.patchValue(this._ticket, 'created', _ticket['created']);
        this.patchValue(this._ticket, 'updated', _ticket['updated']);
        
        if (this._patchData(_ticket)){
            this.ToUpdate = true;
        }
    }

    get Info() :_TicketData {
        return this._ticket;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_ticket: _Ticket){
        this._patchData(_ticket);
        
        if (_ticket['session']){   // update children: 'session'
            let _objid = _ticket['session'].toString();
            this.SetChild('session', new Session(_objid, this.data), 'session')
        }
        else {
            this.SetChild('session', null, 'session');
        }

        if (_ticket['paidby']){   // update children: 'paidby'
            let _objid = _ticket['paidby'].toString();
            this.SetChild('paidby', new Session(_objid, this.data), 'paidby')
        }
        else {
            this.SetChild('paidby', null, 'paidby');
        }

        if (_ticket['paidin']){   // update children: 'paidin'
            let _objid = _ticket['paidin'].toString();
            this.SetChild('paidin', new Session(_objid, this.data), 'paidin')
        }
        else {
            this.SetChild('paidin', null, 'paidin');
        }

        if (_ticket['place']){     // update children: 'place'
            let _objid = _ticket['place'].toString();        
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place');
        }

        if (_ticket['qrcode']){     // update children: 'table' (null for bar tickets)
            let _objid = _ticket['qrcode'].toString();        
            this.SetChild('qrcode', new QrCode(_objid, this.data, this._objoptions), 'qrcode')
        }
        else {
            this.SetChild('qrcode', null, 'qrcode');
        }

        if (_ticket['transaction']) {    // update children: 'transaction'
            let _objid = _ticket['transaction'].toString();        
            this.SetChild('transaction', new Payment(_objid, this.data, this._objoptions), 'transaction')
        }
        else {
            this.SetChild('transaction', null, 'transaction');
        }  
	
        if (_ticket['account']){    // update children: 'account'
            let _objid = _ticket['account'].toString();        
            this.SetChild('account', new PayAccount(_objid, this.data, this._objoptions), 'account')
        }
        else {
            this.SetChild('account', null, 'account');
        }

        if (_ticket['invceac']){    // update children: 'invceac'
            let _objid = _ticket['invceac'].toString();        
            this.SetChild('invceac', new AccountInvoice(_objid, this.data, this._objoptions), 'invceac')
        }
        else {
            this.SetChild('invceac', null, 'invceac');
        }

        if (_ticket['intill']){     // update children: 'intill'
            let _objid = _ticket['intill'].toString();        
            this.SetChild('intill', new Audit(_objid, this.data, this._objoptions), 'intill')
        }
        else {
            this.SetChild('intill', null, 'intill');
        }        
    }

    private _ddbb(info): _TicketData {
        let _ticket: _TicketData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            updated: new Date(Date.parse(this.mysqlToDateStr(info['updated']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            session: info['session'] ? parseInt(info['session']) : null,
            paidby: info['paidby'] ? parseInt(info['paidby']) : null,
            paidon: info['paidon'] ? new Date(Date.parse(this.mysqlToDateStr(info['paidon']))) : null,
            paidin: info['paidin'] ? parseInt(info['paidin']) : null,            
            qrcode: info['qrcode'] ? parseInt(info['qrcode']) : null,
            price: info['price'] ? parseFloat(info['price']) : null,
            refund: info['refund'] ? parseFloat(info['refund']) : 0,
            taxes: info['taxes'] ? parseFloat(info['taxes']) : null,
            orderno: info['orderno'],
            series: info['series'],
            invceno: info['invceno'],
            elapsed: info['elapsed'] ? parseInt(info['elapsed']) : 0,
            comments: info['comments'],
            payment: info['payment'],
            account: info['account'] ? parseInt(info['account']) : null,
            invceac: info['invceac'] ? parseInt(info['invceac']) : null,
            given: info['given'] ? parseFloat(info['given']) : null,
            transaction: info['transaction'] ? parseInt(info['transaction']) : null,
            tmcommit: info['tmcommit'] ? new Date(Date.parse(this.mysqlToDateStr(info['tmcommit']))) : null,
            duedays: info['duedays'] ? parseInt(info['duedays']) : 30,
            flags: info['flags'],

            client: ('client' in info) ? info['client'] : null,
            sentto: ('sentto' in info) ? info['sentto'] : null,
            intill: ('intill' in info) ? info['intill'] : null,
            pmixed: ('pmixed' in info) ? info['pmixed'] : null,
            device: ('device' in info) ? info['device'] : null,
        };

        return _ticket;
    }

    protected _OnUpdate(info){
        if (info['status'] == 'PA'){
            console.log("[WARNING] Receiving ticket in pending activation state");
            return;     // do not accept tickets if pending activation (should not get into here)
        }

        let _ticket = this._ddbb(info);
        
        this.patchValue(this._ticket, 'objid', _ticket['objid']);
        this.patchValue(this._ticket, 'created', _ticket['created']);
        this.patchValue(this._ticket, 'updated', _ticket['updated']);
        this.DoPatchValues(_ticket);

        if (info['products']){   // update children: 'products'
            this.SetChildren <TicketProduct> (info['products'], 'products', TicketProduct, 'ticket');
        }

        if (info['offers']){   // update children: 'offers'
            this.SetChildren <TicketOffer> (info['offers'], 'offers', TicketOffer, 'ticket');
        }

        if (info['extras']){   // update children: 'extras'
            this.SetChildren <TicketExtra> (info['extras'], 'extras', TicketExtra, 'ticket');
        }

        if (info['discounts']){   // update children: 'discounts'
            this.SetChildren <TicketDiscount> (info['discounts'], 'discount', TicketDiscount, 'ticket');
        }

        if (info['changes']){   // update children: 'invoice'
            this.SetChildren <TicketChange> (info['changes'], 'changes', TicketChange, 'ticket');
        }

        if (info['mixed']){   // update children: 'mixed'
            this.SetChildren <TicketMixedPayment> (info['mixed'], 'mixed', TicketMixedPayment, 'ticket');
        }

        if (info['audit']){   // update children: 'audit'
            this.SetChildren <TicketAudit> (info['audit'], 'audit', TicketAudit, 'ticket');
        }

        if (info['invoice']){   // update children: 'invoice'
            this.SetChildren <TicketInvoice> (info['invoice'], 'invoice', TicketInvoice, 'ticket');
        }
    }
}

function sameArray(a1, a2){
    if (a1.length == a2.length){
        return a1.every((item) => {
            return a2.includes(item);
        });
    }
    return false;
}

/*******************************/
/* TICKET GROUPED PRODUCTS     */
/*******************************/

export interface CartProduct {
    requested: Array<TicketProduct>;
    status: string;
    product: Product;
    issep: boolean;
    sort: number;
    group: number;
    offer: Offer;
    fixed: boolean;
    charge: number;
    taxrate: number;
    market: number,
    weight: number,
    comments: string;
    events: { [key: string] : boolean; };
    options: Array<ProductOption>;
    totalprice: number;             // the total product price
    fixedprice: number;             // the fixed product price (when updated for the ticket)
    offerprice: number;             // the product price when offer is applied

    onRefresh: Subject<any>;        // notifies changes on this cart product
};

class CartProducts {
    private _cartproducts: Array<CartProduct> = [];
    get Products(){
        return this._cartproducts;
    }

    constructor(_cartitems : Array <CartItem> = null){
        if (!_cartitems){
            return;
        }

        for(let _item of _cartitems){
            if (_item.product){
                this._cartproducts.unshift(_item.product);
            }
        }
    }
        
    OnRefresh(_ticketproducts){
        this._updateProducts(this._GetCartProducts(_ticketproducts));
    }
    
    private _findCartProduct(needle: CartProduct, haystack: Array <CartProduct>){
        for(let _idx = 0; _idx < haystack.length; _idx++){
            let _same = true;

            if (needle.product != haystack[_idx].product){
                _same = false;  // not same product
            }

            if (needle.group != haystack[_idx].group){
                _same = false;  // not same grouping
            }

            if (needle.taxrate !=  haystack[_idx].taxrate){
                _same = false;  // not same tax rate
            }

            if (needle.market !=  haystack[_idx].market){
                _same = false;  // not same market price
            }
            
            if (needle.weight !=  haystack[_idx].weight){
                _same = false;  // not same weight
            }

            if (needle.offer != haystack[_idx].offer){
                _same = false;  // not same offer
            }

            if ((needle.fixed != haystack[_idx].fixed) || (needle.fixedprice != haystack[_idx].fixedprice)) {
                _same = false;  // not same fixed
            }

            if (_same){
                for(let _event in needle.events){
                    if (needle.events[_event] != haystack[_idx].events[_event]){
                        _same = false;  // not same events
                    }
                }
            }

            // check for one requested
            if (_same){
                _same = (needle.requested.some(
                (_requested) => {
                    return (haystack[_idx].requested.includes(_requested));
                }));        
            }
            
            if (_same){    // the requested item found in this cart product
                return _idx;    
            }
        }

        return -1;  // not found
    }

    private _updateProducts(_cartproducts){
        for(let _idx = this._cartproducts.length - 1; _idx >= 0; _idx--){
            if (this._findCartProduct(this._cartproducts[_idx], _cartproducts) == -1){
                this._cartproducts.splice(_idx, 1);     // delete
            }
        }

        for(let _idx = _cartproducts.length - 1; _idx >= 0; _idx--){
            let _pos = this._findCartProduct(_cartproducts[_idx], this._cartproducts);
            if (_pos == -1) {   // insert
                this._cartproducts.push(_cartproducts[_idx]);
            }
            else {  // update
                let _dst = this._cartproducts[_pos];
                let _src = _cartproducts[_idx];

                let _same = (_src.requested.length == _dst.requested.length)

                _dst.requested = _src.requested;
                if (!_same){
                    _dst.onRefresh.next();
                }
            }
        }

        // sort the cartproducts (separator on top) 
        this._cartproducts.sort((a, b) => {
            if (a.sort == b.sort){
                return (a.issep) ? -1 : 1;
            }

            return a.sort - b.sort;
        });

        let _sort = 0;
        for (let _cartproduct of this._cartproducts){
            _cartproduct.sort = _sort;
            for (let _ticketproduct of _cartproduct.requested){
                _ticketproduct.sort = _sort;
            }
            _sort = _sort + 1;
        }

        // remove separator from list top (allow on bottom)
        if ((this._cartproducts.length > 0) && (this._cartproducts[0].issep)){
            this._cartproducts.splice(0, 1);
        }

        // calculate final price, and price to be charged (if offers are applied)
        for (let _cartproduct of this._cartproducts){
            _cartproduct.fixedprice = 0;
            _cartproduct.totalprice = 0;
            _cartproduct.offerprice = 0;

            for(let requested of _cartproduct.requested){
                _cartproduct.fixedprice += requested.FixedPrice;
                _cartproduct.totalprice += requested.TotalPrice;
                _cartproduct.offerprice += requested.OfferPrice;
            }

            _cartproduct.fixedprice = Math.round(_cartproduct.fixedprice * 100) / 100;
            _cartproduct.totalprice = Math.round(_cartproduct.totalprice * 100) / 100;
            _cartproduct.offerprice = Math.round(_cartproduct.offerprice * 100) / 100;
        }        
    }

    MoveCartProduct(target, delta){
        this._cartproducts.sort((a, b) => {
            return a.sort - b.sort;
        });

        let _idx = this._cartproducts.indexOf(target);
        if (_idx === -1) {
            return; // target not found
        }

        let _tsort = _idx + delta;
        _tsort = Math.min(this._cartproducts.length - 1, _tsort);
        _tsort = Math.max(0, _tsort);

        this._cartproducts.splice(_idx, 1);
        this._cartproducts.splice(_tsort, 0, target);

        // sort the cart products
        let _sort = 0;
        for (let _cartproduct of this._cartproducts){
            _cartproduct.sort = _sort;
            for (let _ticketproduct of _cartproduct.requested){
                _ticketproduct.sort = _sort;
            }
            _sort = _sort + 1;
        }
    }

    private _findProduct(needle, haystack){
        for (let _cartproduct of haystack){
            if (needle.product){
                if (needle.product.objid != _cartproduct.product?.objid){
                    continue;   // not same product
                }    

                if (needle.product.IsWeighted){
                    continue;   // do not join weighted products
                }    
            }
            else {
                if (_cartproduct.product){
                    continue;   // do not join with separators
                }
            }

            if (needle.group != _cartproduct.group){
                continue;   // not same group
            }

            if (needle.fixed != _cartproduct.fixed){
                continue;   // not same fixed
            }

            if (needle.market != _cartproduct.market){
                continue;   // not same market price
            }

            if (needle.taxrate != _cartproduct.taxrate){
                continue;   // not same tax rate
            }

            if (needle.status != _cartproduct.status){
                continue;   // not same status
            }
            
            if (needle.comments != _cartproduct.comments){
                continue;   // not same comments
            }

            if ((needle.offer ? needle.offer.offer : null) != _cartproduct.offer){
                continue;   // not same offer applied
            }

            let _pr1_options = [];
            for(let _option of needle.options){
                _pr1_options.push(_option.option);
            }

            let _pr2_options = [];
            for(let _option of _cartproduct.requested[0].options){
                _pr2_options.push(_option.option);
            }

            if (!sameArray(_pr1_options, _pr2_options)){
                continue;   // not same options
            }

            let _sameevents = true;
            for(let _event in needle.events){
                if (_event == 'CC'){
                    continue;   // do not consider 'CC' status
                }

                if (needle.events[_event] != _cartproduct.requested[0].events[_event]){
                    _sameevents = false;   
                }
            }

            if (!_sameevents){
                continue;   // not same events
            }

            return _cartproduct;
        }
        
        return null;    // not found
    }

    private _GetCartProducts(ticketproducts){
        let _products: Array<CartProduct> = [];

        ticketproducts.sort((a, b) => {
            if ((a.sort !== null) && (b.sort !== null)){
                return a.sort - b.sort;
            }

            if (a.sort !== null){
                return -1;
            }

            if (b.sort !== null){
                return 1;
            }

            return (a.created.getTime() - b.created.getTime());
        });

        let _ltsep = 0;     // group the separators
        let _group = 0;     // separate the products

        for (let _ticketproduct of ticketproducts){
            
            // increase the belonging group whenever a separator is found
            if (_ticketproduct.Separator){
                if (_ltsep == 0){
                    _group = _group + 1;
                }

                _ltsep = _ltsep + 1; 
            }
            else {
                _ltsep = 0;     // last is not a separator
            }

            _ticketproduct.group = _group;

            let _cartproduct: CartProduct = this._findProduct(_ticketproduct, _products);
            if (_cartproduct){    // this product is already available
                _cartproduct.requested.push(_ticketproduct);
            }
            else {  // this is a new product (create and add to list)
                let _lastsort = _products.length;

                _cartproduct = {
                    product: _ticketproduct.product,
                    offer: _ticketproduct.offer ? _ticketproduct.offer.offer : null,
                    fixed: _ticketproduct.fixed,
                    charge: _ticketproduct.charge,
                    market: _ticketproduct.market,
                    weight: _ticketproduct.weight,
                    taxrate: _ticketproduct.taxrate,
                    status: _ticketproduct.status,
                    comments: _ticketproduct.comments,
                    events: _ticketproduct.events,
                   
                    requested: [ _ticketproduct ],
                    issep: _ticketproduct.Separator,
                    sort: (_ticketproduct.sort === null) ? _lastsort : _ticketproduct.sort,
                    group: _ticketproduct.group,
                    options: [],
                    fixedprice: 0,
                    totalprice: 0,
                    offerprice: 0,

                    onRefresh: new Subject<any>()
                };

                for(let _ticketoption of _ticketproduct.options){
                    _cartproduct.options.push(_ticketoption.option);
                }
                                
                _products.push(_cartproduct);                      
            }
        }

        // calculate final price, and price to be charged (if offers are applied)
        for (let _cartproduct of _products){
            _cartproduct.totalprice = 0;
            _cartproduct.offerprice = 0;

            for(let requested of _cartproduct.requested){
                _cartproduct.totalprice += requested.TotalPrice;
                _cartproduct.offerprice += requested.OfferPrice;
            }

            _cartproduct.totalprice = Math.round(_cartproduct.totalprice * 100) / 100;
            _cartproduct.offerprice = Math.round(_cartproduct.offerprice * 100) / 100;
        }

        return _products;
    }
}

/*******************************/
/* TICKET GROUPED OFFERS       */
/*******************************/

export interface CartOfferProduct {
    product: Product,
    active: Array<TicketProduct>,
    refund: Array<TicketProduct>,
    chargeprice: number,
    refundprice: number
}

export interface CartOffer {
    requested: Array<TicketOffer>;
    status: string;
    offer: Offer;
    fixed: boolean;
    products: Array<CartOfferProduct>
    offerprice: number;             // the original offer price
    fixedprice: number;             // the updated offer price (when updated for this ticket)
    singleprice: number;            // the price for one offer contined in this group
    totalprice: number;             // the offer price with products
    chargeprice: number;            // the final offer charge price (when updated for this ticket)
    refundprice: number;            // the offer refund price (when some products are returned)

    onRefresh: Subject<any>;        // notifies changes on this cart product
};

class CartOffers {
    private _cartoffers: Array<CartOffer> = [];
    get Offers(){
        return this._cartoffers;
    }

    constructor(_cartitems : Array <CartItem> = null){
        if (!_cartitems){
            return;
        }

        for(let _item of _cartitems){
            if (_item.offer){
                this._cartoffers.unshift(_item.offer);
            }
        }
    }
    
    OnRefresh(_ticketoffers){
        let _activeoffers = [];
        
        for(let _offer of _ticketoffers){
            if (_offer.IsValid){
                _activeoffers.push(_offer);
            }
        }

        this._updateOffers(this._GetCartOffers(_activeoffers));        
    }

    private _findCartOffer(needle: CartOffer, haystack: Array <CartOffer>){
        for(let _idx = 0; _idx < haystack.length; _idx++){
            let _same = true;

            if (needle.offer != haystack[_idx].offer) {
                _same = false;      // not same offer
            }

            if (_same){
                _same = (needle.requested.length == haystack[_idx].requested.length) && (needle.requested.every(
                (_requested) => {
                    return (_requested.IsValid) && (haystack[_idx].requested.indexOf(_requested) != -1);
                }));        
            }
            
            if (_same){    // the requested item found in this cart offer
                return _idx;    
            }
        }

        return -1;  // not found
    }

    private _updateOffers(_cartoffers){
        for(let _idx = this._cartoffers.length - 1; _idx >= 0; _idx--){
            if (this._findCartOffer(this._cartoffers[_idx], _cartoffers) == -1){
                this._cartoffers.splice(_idx, 1);     // delete
            }
        }

        for(let _idx = _cartoffers.length - 1; _idx >= 0; _idx--){
            let _pos = this._findCartOffer(_cartoffers[_idx], this._cartoffers);
            if (_pos == -1) {   // insert
                this._cartoffers.push(_cartoffers[_idx]);
            }
            else {  // update
                let _src = _cartoffers[_idx];
                let _dst = this._cartoffers[_pos];

                let _same = true;
                for(let _property of Object.keys(_dst)){
                    if (_dst[_property] != _src[_property]){
                        _same = false;
                    }

                    _dst[_property] = _src[_property];
                }

                if (!_same){
                    this._cartoffers[_pos].onRefresh.next();
                }
            }
        }

        // calculate final price, and price to be charged (if offers are applied)
        for (let _cartoffer of this._cartoffers){
            _cartoffer.offerprice = 0;
            _cartoffer.totalprice = 0;
            _cartoffer.singleprice = 0;
            _cartoffer.chargeprice = 0;            
            _cartoffer.refundprice = 0;

            if (_cartoffer.requested.length > 0){
                _cartoffer.singleprice = _cartoffer.requested[0].ChargePrice;
            }

            for(let requested of _cartoffer.requested){
                _cartoffer.offerprice += requested.OfferPrice;
                _cartoffer.totalprice += requested.TotalPrice;
                _cartoffer.chargeprice += requested.ChargePrice;
                _cartoffer.refundprice += requested.refund;
            }

            _cartoffer.offerprice = Math.round(_cartoffer.offerprice * 100) / 100;
            _cartoffer.totalprice = Math.round(_cartoffer.totalprice * 100) / 100;
            _cartoffer.chargeprice = Math.round(_cartoffer.chargeprice * 100) / 100;
            _cartoffer.refundprice = Math.round(_cartoffer.refundprice * 100) / 100;
        }
    }
    
    private _findOffer(needle, haystack){
        if (!needle.offer){
            return null;   // offer load is pending
        }

        for (let _cartoffer of haystack){
            let _ticketoffer = _cartoffer.requested[0];

            if (needle.offer.objid != _ticketoffer.offer.objid){
                continue;   // not same offer
            }

            if (needle.fixed != _ticketoffer.fixed){
                continue;   // not same offer
            }

            if (needle.status != _ticketoffer.status){
                continue;   // not same status
            }

            if (needle.charge != _ticketoffer.charge){
                continue;   // not same charge
            }

            return _cartoffer;
        }
                                      
        return null;    // not found
    }
 
    private _AddTicketOffer(_cartoffer, _ticketoffer){
        for(let _ticketproduct of _ticketoffer.ticket.products){
            if (_ticketproduct.offer == _ticketoffer){
                let _cartofferproduct = null;
                
                for(let _product of _cartoffer.products){
                    if (_product.product == _ticketproduct.product){
                        if (_ticketproduct.status == 'AC'){
                            _product.active.push(_ticketproduct);
                            _product.chargeprice += _ticketproduct.OfferPrice;
                        }

                        if (_ticketproduct.status == 'UN'){
                            _product.refund.push(_ticketproduct);
                            _product.refundprice += _ticketproduct.OfferPrice;
                        }

                        _cartofferproduct = _product;
                    }
                }

                if (!_cartofferproduct){    // new product
                    _cartofferproduct = {
                        product: _ticketproduct.product,
                        active: [],
                        refund: [],
                        chargeprice: 0,
                        refundprice: 0
                    };

                    if (_ticketproduct.status == 'AC'){
                        _cartofferproduct.active.push(_ticketproduct);
                        _cartofferproduct.chargeprice += _ticketproduct.OfferPrice;
                    }

                    if (_ticketproduct.status == 'UN'){
                        _cartofferproduct.refund.push(_ticketproduct);
                        _cartofferproduct.refundprice += _ticketproduct.OfferPrice;
                    }

                    _cartoffer.products.push(_cartofferproduct);
                }
            }
        }

        _cartoffer.requested.push(_ticketoffer);
    }

    private _GetCartOffers(ticketoffers){
        let _offers : Array<CartOffer> = [];
        
        let _ticketoffers = ticketoffers.sort((a, b) => {
            return (a.created.getTime() - b.created.getTime());
        });

        for (let _ticketoffer of _ticketoffers.reverse()){
            let _cartoffer: CartOffer = this._findOffer(_ticketoffer, _offers);
            if (!_cartoffer){   
                _cartoffer = {
                    offer: _ticketoffer.offer,
                    fixed: _ticketoffer.fixed,
                    status: _ticketoffer.status,
                    requested: [],
                    products: [],
                    offerprice: 0,
                    fixedprice: _ticketoffer.price,
                    totalprice: 0,
                    singleprice: 0,
                    chargeprice: 0,
                    refundprice: 0,

                    onRefresh: new Subject<any>()
                };

                _offers.push(_cartoffer); 
            }

            this._AddTicketOffer(_cartoffer, _ticketoffer);
        }

        // calculate final price, and price to be charged (if offers are applied)
        for (let _cartoffer of _offers){
            _cartoffer.chargeprice = 0;
            _cartoffer.refundprice = 0;

            for(let _offerproduct of _cartoffer.products){
                _cartoffer.chargeprice += _offerproduct.chargeprice;
                _cartoffer.refundprice += _offerproduct.refundprice;
            }

            _cartoffer.chargeprice = Math.round(_cartoffer.chargeprice * 100) / 100;
            _cartoffer.refundprice = Math.round(_cartoffer.refundprice * 100) / 100;
        }
        
        return _offers;
    }
}

/****************************************/
/* TICKET ITEMS                         */
/****************************************/

export interface CartItem {
    product?: CartProduct;
    offer?: CartOffer;
};

class CartItems {
    constructor(private _cartitems : Array <CartItem>){
        if (!_cartitems){
            this._cartitems = [];
        }
    }

    Merge(_cartitems: Array <CartItem>){
        for(let _idx = this._cartitems.length - 1; _idx >= 0; _idx--){
            if (this._indexOf(this._cartitems[_idx], _cartitems) == -1){
                this._delete(this._cartitems[_idx]);
            }
        }
        
        for(let _item of _cartitems){
            let _idx = this._indexOf(_item, this._cartitems);
            if (_idx == -1) { // insert ordered
                this._insert(_item);
            }
            else {  // update
                this._update(_item);
            }
        }

        // order the resulting list
        this._cartitems.sort((a, b) => { 
            return this._compare(a, b);
        });  

        return this._cartitems;
    }

    private _delete(_item){
        let _idx = this._indexOf(_item, this._cartitems);
        if (_idx != -1){
            this._cartitems.splice(_idx, 1);
        }
    }
    
    private _insert(_item){
        let _idx = 0;
        while((_idx < this._cartitems.length) && (this._compare(_item, this._cartitems[_idx]) > 0)){
            _idx++;
        }
        this._cartitems.splice(_idx, 0, _item);
    }
    
    private _update(_item){
        let _idx = this._indexOf(_item, this._cartitems);
        if (_idx != -1){
            let _toupdate = this._cartitems[_idx];

            if (_toupdate.product){
                _toupdate.product.requested = _item.product.requested;
                _toupdate.product.totalprice = _item.product.totalprice;
                _toupdate.product.offerprice = _item.product.offerprice;
            }

            if (_toupdate.offer){
                _toupdate.offer.requested = _item.offer.requested;
                _toupdate.offer.products = _item.offer.products;
                _toupdate.offer.chargeprice = _item.offer.chargeprice;
                _toupdate.offer.refundprice = _item.offer.refundprice;
            }
        }
    }

    private _compare(a, b){
        let _res = 0;
        
        if (!!a.product != !!b.product){
            return (a.product) ? -1 : 1;    // products before offers
        }
        
        if (_res == 0){   // order by creation date desc
            let _oa = (a.product) ? a.product.requested[0].created.getTime() : a.offer.requested[a.offer.requested.length - 1].created.getTime();
            let _ob = (b.product) ? b.product.requested[0].created.getTime() : b.offer.requested[b.offer.requested.length - 1].created.getTime();
            
            _res = (_oa == _ob) ? 0 : (_oa > _ob) ? -1 : 1;
        }
        
        return _res;
    }
    
    private _equal(o1, o2){
        if (!o1 || !o2){
            return false;       // not same type of item
        }

        for(let _property in o1) {
            if (_property == 'requested'){
                continue;   // exclude this properties from comparison
            }

            if ((o1[_property] == o2[_property])){
                continue;
            }

            if (o1[_property] instanceof Array){
                let a1 = o1[_property];
                let a2 = o2[_property];

                if (a1.length == a2.length){
                    let _every = a1.every((a1it) => {       // for each element in a1 array
                        let _some = a2.some((a2it) => {     // at least one element in a2 equals a1 item;
                            return this._equal(a1it, a2it);    
                        });
                        return _some; 
                    });

                    if (_every){
                        continue;
                    }
                }
            }            

            return false;
        }
        
        return true;
    }

    private _indexOf(needle, haystack){
        for(let _idx = 0; _idx < haystack.length; _idx++){
            if (needle.product){
                if ((haystack[_idx].product) && (this._equal(needle.product, haystack[_idx].product))){
                    return _idx;
                }    
            }

            if (needle.offer){
                if ((haystack[_idx].offer) && (this._equal(needle.offer, haystack[_idx].offer))){
                    return _idx;
                }    
            }
        }
        return -1;
    }
}

class TicketItems {
    private _cartitems: Array<CartItem> = [];
    
    get Items(){    // all cart items
        return this._cartitems;
    }
    
    private _cartproducts: CartProducts = null;    
    get CartProducts(){
        return this._cartproducts;
    }

    private _cartoffers: CartOffers = null;
    get CartOffers(){
        return this._cartoffers;
    }

    constructor(){
        this._cartproducts = new CartProducts();
        this._cartoffers = new CartOffers();
    }
    
    OnRefresh(_products, _offers){
        let _cartitems: Array<CartItem> = [];
        
        // add products
        this._cartproducts.OnRefresh(_products);
        for(let _item of this._cartproducts.Products){
            _cartitems.push({
                product: _item
            });
        }

        // add offers
        this._cartoffers.OnRefresh(_offers);
        for(let _item of this._cartoffers.Offers){
            _cartitems.push({
                offer: _item
            });
        }
        
        this._cartitems = new CartItems(this._cartitems).Merge(_cartitems);
    }
}

/*******************************/
/* TICKET UPDATES              */
/*******************************/

class TicketUpdate {
    constructor(private _ticket: Ticket, private data: dataService){
        // nothing to do
    }

    OnDestroy(){
        // nothing to do
    }

    private _onupdate = false;  // avoid reentrancy when ticket is updated

    OnUpdate(force = false){
        if (this._onupdate && !force){
            return false;   // up to date 
        }
        
        this._onupdate = true;
        setTimeout(() => {
            this._onupdate = false;
        }, 0);

        if (this._ticket.place){
            this._ApplyOffers(this._ticket.place.offers);
            this._ApplyExtras(this._ticket.place.extras);        
        }

        let _charge = 0;     // calculate the ticket charge price
        let _refund = 0;     // calculate the ticekt refund price   

        for(let _product of this._ticket.products){
            let _prcharge = Number(_product.ChargePrice)
            if (!_product.offer || !_product.offer.IsValid){
                _charge += _prcharge;
            }

            if (_product.status == 'UN'){
                _refund += _prcharge;
            }
        }

        for(let _offer of this._ticket.offers){
            let _ofcharge = Number(_offer.charge) 
            if (_offer.IsValid){
                _charge += _ofcharge;
            }

            if (_offer.status == 'UN'){
                _refund += _ofcharge;
            }
        }

        for(let _extra of this._ticket.extras){
            let _xtcharge =  Number(_extra.charge);
            if (_extra.IsValid){
                _charge += _xtcharge;
            }

            if (_extra.status == 'UN'){
                _refund += _xtcharge;
            }
        }

        this._ticket._charge = _charge;   // store the current charge in the ticket (pending charges)
        this._ticket._refund = _refund;   // store the current refund in the ticket (pending refunds)

        for(let _discount of this._ticket.discounts){
            _discount.charge = Math.min(_charge, _discount.ChargePrice()); 
            _discount.percnt = Math.round((_discount.charge / this._ticket._charge) * 100);
            _discount.refund = Math.min(_refund, _discount.RefundPrice());

            if (_discount.IsValid){
                _charge -= _discount.charge;
            }
        }

        this._ticket.price = Math.round(_charge * 100) / 100;

        _refund = 0;    // calculate ticket refund price
        if (this._ticket.IsPaid){
            for(let _product of this._ticket.products){
                if (!_product.offer || !_product.offer.IsValid){
                    _refund += Number(_product.RefundPrice);
                }
            }
    
            for(let _offer of this._ticket.offers){
                if (_offer.IsValid){
                    _refund += Number(_offer.refund);
                }
            }
    
            for(let _extra of this._ticket.extras){
                if (_extra.IsValid){
                    _refund += Number(_extra.refund);
                }
            }

            this._ticket._refund = _refund;   // store the current refund in the ticket (applied refunds)

            for(let _discount of this._ticket.discounts){
                if (_discount.IsValid){
                    _refund -= Number(_discount.RefundPrice());
                }
            }    
        }

        this._ticket.refund =  Math.round(_refund * 100) / 100;

        return true;    // has been updated
    }

    private _AddOffer(offer: Offer, products: Array <TicketProduct>, status: string = 'AC'){
        // create the ticketoffer instance
        let _ticketoffer = new TicketOffer(null, this.data)
        if (_ticketoffer){
            _ticketoffer.ticket = this._ticket;
            _ticketoffer.offer = offer;
            _ticketoffer.status = status;
            _ticketoffer.charge = 0;
            _ticketoffer.refund = 0;
        }

        // add the offer to the ticket products 
        for(let _ticketproduct of products){
            _ticketproduct.offer = _ticketoffer;
        }

        // recalculate charge price for ticket products
        for(let _ticketproduct of products){
            if (!_ticketproduct.fixed){
                _ticketproduct.charge = _ticketproduct.ChargePrice;
            }
        }

        // recalculate charge and refund prices for ticket offers
        _ticketoffer.charge = _ticketoffer.ChargePrice;
        _ticketoffer.refund = _ticketoffer.RefundPrice;

        // add the ticket offer to the ticket
        this._ticket.AddOffer(_ticketoffer);
    }

    private _SetOffer(offer: Offer, products: Array <TicketProduct>, applied: Array <TicketOffer>){
        for(let _idx = applied.length - 1; _idx >= 0; _idx--) {
            let _ticketoffer = applied[_idx];                

            if ((_ticketoffer.IsValid) && (_ticketoffer.offer == offer) && offer.Check(this._ticket.products)){
                applied.splice(_idx, 1);

                // remove the old offer from the ticket products
                for(let _ticketproduct of this._ticket.products){
                    if (_ticketproduct.offer == _ticketoffer){
                        _ticketproduct.offer = null;
                    }
                }

                // reuse the ticket offer with the new products list
                for(let _ticketproduct of products){
                    _ticketproduct.offer = _ticketoffer;
                }

                // recalculate charge price for ticket products
                for(let _ticketproduct of this._ticket.products){
                    if (!_ticketproduct.fixed){
                        _ticketproduct.charge = _ticketproduct.ChargePrice;
                    }        
                }

                // recalculate charge and refund prices for ticket offers
                for(let _ticketoffer of this._ticket.offers){
                    _ticketoffer.charge = _ticketoffer.ChargePrice;
                    _ticketoffer.refund = _ticketoffer.RefundPrice;
                }

                return true;
            }
        }

        return false;
    }

    private _SetExtra(extra: Extra, applied: Array <TicketExtra>){
        for(let _idx = applied.length - 1; _idx >= 0; _idx--){
            let _ticketextra = applied[_idx];
            if ((_ticketextra.IsValid) && (_ticketextra.extra == extra)){
                applied.splice(_idx, 1);

                // reuse the ticket extra with the current price
                _ticketextra.charge = _ticketextra.ChargePrice;
                _ticketextra.refund = _ticketextra.RefundPrice;
                
                return true;
            }
        }

        return false;
    }

    private _AddExtra(extra: Extra){
        // create the ticketextra instance
        let _ticketextra = new TicketExtra(null, this.data)
        if (_ticketextra){
            _ticketextra.extra = extra;
            _ticketextra.ticket = this._ticket;
            _ticketextra.status = 'AC';
            _ticketextra.charge = 0;
            _ticketextra.refund = 0;
        }

        _ticketextra.charge = _ticketextra.ChargePrice;
        _ticketextra.refund = _ticketextra.RefundPrice;

        // add the ticket extra to the ticket
        this._ticket.AddExtra(_ticketextra);
    }

    private _ApplyOffers(_offers: Array <Offer>){
        if (this._ticket.isopen){
            let _ticketproducts = [];
            for(let _product of this._ticket.products){
                if (_product.IsValid && !_product.Separator){
                    _ticketproducts.push(_product);
                }
            }
    
            let _toapplyoffers = [];
    
            // get the list of currently active offers
            let _active: Array<Offer> = []; 
            for(let _offer of _offers){
                if (_offer.IsActive){
                    _active.push(_offer);
                }
            }
    
            if (_active.length > 0){
                // get all offers that can apply to the ticket products
                let _candidates = [];
                do {
                    _candidates = [];
                    for(let _candidate of _active){
                        let _applyinfo = { applied: [], savings: 0};
                        if (_candidate.Check(_ticketproducts, _applyinfo)){
                            _candidates.push({
                                offer: _candidate,
                                status: 'AC',
                                applied: _applyinfo.applied,
                                savings: _applyinfo.savings
                            });
                        }
                    }
    
                    // apply the best candidate to the ticket products
                    if (_candidates.length > 0){
                        _candidates = _candidates.sort((a, b) => {
                            if (b.savings == a.savings){
                                return (b.offer.price - a.offer.price);
                            }
                            return b.savings - a.savings;
                        });
    
                        let _best = _candidates[0];
    
                        // remove the applied products from the products list
                        for(let _ticketproduct of _best.applied){
                            let _idx = _ticketproducts.indexOf(_ticketproduct);                    
                            if (_idx != -1){
                                _ticketproducts.splice(_idx, 1);
                            }
                        }
    
                        _toapplyoffers.push(_best);
                    }
                } while(_candidates.length > 0);
            }
    
            // remove the previous offer information
            for(let _ticketproduct of this._ticket.products){
                _ticketproduct.offer = null;
                
                if (!_ticketproduct.fixed){
                    _ticketproduct.charge = _ticketproduct.ChargePrice;
                }                  
            }
    
            // merge the applying offers to the current offers
            let _appliedoffers = this._ticket.offers.slice(0);
            for(let _applyoffer of _toapplyoffers){
                if (!this._SetOffer(_applyoffer.offer, _applyoffer.applied, _appliedoffers)){
                    this._AddOffer(_applyoffer.offer, _applyoffer.applied, 'AC');
                }
            }
    
            // remove the non-reused applied offers from the ticket
            for(let _ticketoffer of _appliedoffers){
                _ticketoffer.status = 'DE';
            }    
        }

        // recalculate offers charge and refund prices
        for(let _ticketoffer of this._ticket.offers){
            _ticketoffer.charge = _ticketoffer.ChargePrice;
            _ticketoffer.refund = _ticketoffer.RefundPrice;
        }  
    }

    private _ApplyExtras(_extras: Array <Extra>){
        if (this._ticket.isopen){
            // get the list of currently applying extras
            let _toapplyextras: Array<Extra> = []; 
            for(let _extra of _extras){
                if (_extra.IsActive && _extra.ApplyToTicket(this._ticket)){
                    _toapplyextras.push(_extra);
                }
            }

            // merge the applying extras to the current extras
            let _appliedextras = this._ticket.extras.slice(0);
            for(let _extra of _toapplyextras){
                if (!this._SetExtra(_extra, _appliedextras)){
                    this._AddExtra(_extra);
                }
            }

            // remove the non-reused applied extras from the ticket
            for(let _ticketextra of _appliedextras){
                _ticketextra.status = 'DE';
            }
        }

        // recalculate extras charge and refund prices
        for(let _ticketextra of this._ticket.extras){
            _ticketextra.charge = _ticketextra.ChargePrice;
            _ticketextra.refund = _ticketextra.RefundPrice;        
        }
    }
}

/*******************************/
/* TICKET CONTROL              */
/*******************************/

class TicketControl {
    private _ticket_update: TicketUpdate = null;

    private _refresh_subscription = null;
    private _update_subscription = null;

    constructor(private _ticket: Ticket, private data: dataService){
        this._ticket_update = new TicketUpdate(this._ticket, this.data);

        this._update_subscription = this._ticket.OnModify.subscribe(
        data => {
            this._updateticketitems = true;
        });
    }

    private _items: TicketItems = null;
    private get Items(){
        if (this._items == null){
            this._items = new TicketItems();

            if (this._refresh_subscription){
                this._refresh_subscription.unsubscribe();
                this._refresh_subscription = null;
            }

            this.OnUpdate(true);
            this._refresh_subscription = this._ticket.OnRefresh.subscribe(
            data => {
                this.OnUpdate();
            });            
        }

        return this._items.Items;
    }

    DoUpdate(force = false){
        this.OnUpdate(force);
    }

    private _updateticketitems: boolean = true; 
    private _updateprtoprepare: boolean = true;
    private _updateprtorefund: boolean = true;
    private _updateprdelivered: boolean = true;
    private _updateprcancelled: boolean = true;
    private _updateprtoseparate: boolean = true;
    private _updateoftorefund: boolean = true;
    private _updateoftodeliver: boolean = true;
    private _updatexttoprepare: boolean = true;
    private _updatexttorefund: boolean = true;
    private _updatextodeliver: boolean = true;

    public _onProductsUpdated = new Subject<any>();
    private OnUpdate(force = false){
        if (!this._updateticketitems && !force){
            return;     // ticket is up to date  
        }

        if (this._ticket_update.OnUpdate(force)){
            if (this.Items){
                let _prev = this._items.CartProducts.Products.length;
                this._items.OnRefresh(this._ticket.products, this._ticket.offers);
                let _next = this._items.CartProducts.Products.length;

                if (_prev != _next){
                    setTimeout(() => {
                        this._onProductsUpdated.next();
                    }, 50);
                }
            }
    
            this._updateprtoprepare = true;
            this._updateprtorefund = true;
            this._updateprdelivered = true;
            this._updateprcancelled = true;
            this._updateprtoseparate = true;
            this._updateoftorefund = true;
            this._updateoftodeliver = true;
            this._updatexttoprepare = true;
            this._updatexttorefund = true;
            this._updatextodeliver = true;
            this._updateticketitems = false;    
        }
    }

    OnDestroy(){
        if (this._ticket_update){
            this._ticket_update.OnDestroy();
            this._ticket_update = null;
        }

        if (this._update_subscription){
            this._update_subscription.unsubscribe();
            this._update_subscription = null;
        }

        if (this._refresh_subscription){
            this._refresh_subscription.unsubscribe();
        }
        this._refresh_subscription = null;
    }

    private _productstoprepare = [];
    get ProductsToPrepare() {
        if (this._updateprtoprepare){
            this._updateprtoprepare = false;

            let _products = [];
            for(let _item of this.Items){
                if (_item.product){
                    if (['UN', 'DE', 'SP'].includes(_item.product.requested[0].status)){
                        continue;
                    }

                    let _ispd = _item.product.requested[0].HasEvent('PD');
                    let _isrd = _item.product.requested[0].HasEvent('RD');
                    if (this._ticket.IsDirect){
                        if (!_ispd){
                            _products.push(_item);
                        }
                    }
                    else {
                        if (!_isrd) {
                            _products.push(_item);
                        }    
                    }
                }
            }

            this._productstoprepare = new CartItems(this._productstoprepare).Merge(_products);
            this._productstoprepare.sort((a, b) => {
                return a.product.sort - b.product.sort;
            });
        }

        return this._productstoprepare;
    }

    private _productstoseparate = [];
    get ProductsToSeparate(){
        if (this._updateprtoseparate){
            this._updateprtoseparate = false;

            let _products= [];
            for(let _item of this.Items){
                if (_item.product){
                    if (['UN', 'DE'].includes(_item.product.requested[0].status)){
                        continue;
                    }

                    let _ispd = _item.product.requested[0].HasEvent('PD');
                    let _isrd = _item.product.requested[0].HasEvent('RD');
                    if (this._ticket.IsDirect){
                        if (!_ispd){
                            _products.push(_item);
                        }
                    }
                    else {
                        if (!_isrd) {
                            _products.push(_item);
                        }    
                    }
                }
            }

            let _productstoseparate = new CartItems(this._productstoseparate).Merge(_products);
            _productstoseparate.sort((a, b) => {
                return a.product.sort - b.product.sort;
            });

            this._productstoseparate = _productstoseparate;
        }

        return this._productstoseparate;        
    }

    private _productstorefund = [];
    get ProductsToRefund() { 
        if (this._updateprtorefund){
            this._updateprtorefund = false;

            let _products = [];
            for (let _item of this.Items){
                if (_item.product && ['SP', 'UN'].includes(_item.product.requested[0].status)){
                    _products.push(_item);
                }
            }

            this._productstorefund = new CartItems(this._productstorefund).Merge(_products);
            this._productstorefund.sort((a, b) => {
                return a.product.sort - b.product.sort;
            });
        }

        return this._productstorefund;
    }

    private _productsdelivered = [];
    get ProductsDelivered() {
        if (this._updateprdelivered){
            this._updateprdelivered = false;

            let _products = [];
            for(let _item of this.Items){
                if (_item.product){
                    if (['UN', 'DE', 'SP'].includes(_item.product.requested[0].status)){
                        continue;
                    }

                    if (this._ticket.IsDirect){
                        let _ispd = _item.product.requested[0].HasEvent('PD');
                        if (_ispd){
                            _products.push(_item);
                        }
                    }
                    else {
                        let _isrd = _item.product.requested[0].HasEvent('RD');  
                        if (_isrd){
                            _products.push(_item);
                        }                        
                    }                    
                }
            }

            this._productsdelivered = new CartItems(this._productsdelivered).Merge(_products);
            this._productsdelivered.sort((a, b) => {
                return a.product.sort - b.product.sort;
            });
        }

        return this._productsdelivered;
    }

    private _productscancelled = [];
    get ProductsCancelled(){
        if (this._updateprcancelled){
            this._updateprcancelled = false;

            let _products= [];
            for(let _item of this.Items){
                if (_item.product && ['DE', 'SP'].includes(_item.product.requested[0].status)){
                    let _iscc = _item.product.requested[0].HasEvent('CC');
                    if (_iscc){
                        _products.push(_item);
                    }
                }
            }

            this._productscancelled = new CartItems(this._productscancelled).Merge(_products);
            this._productscancelled.sort((a, b) => {
                return a.product.sort - b.product.sort;
            });
        }

        return this._productscancelled;        
    }

    private _offerstorefund = [];
    get OffersToRefund() {
        if (this._updateoftorefund){
            this._updateoftorefund = false;

            let _offers = [];
            for (let _item of this.Items){
                if (_item.offer){
                    let _torefund = _item.offer.products.some(
                    (_product) => {
                        return (_product.refund.length > 0);
                    });

                    if (_torefund){
                        _offers.push(_item);
                    }
                }
            }

            this._offerstorefund = new CartItems(this._offerstorefund).Merge(_offers);
        }
        
        return this._offerstorefund;    
    }

    private _offerstodeliver = [];
    get OffersToDeliver() {
        if (this._updateoftodeliver){
            this._updateoftodeliver = false;

            let _offers = [];
            for (let _item of this.Items){
                if (_item.offer){
                    let _todeliver = _item.offer.products.some(
                    (_product) => {
                        return (_product.active.length > 0);
                    });

                    if (_todeliver){
                        _offers.push(_item);
                    }
                }
            }
            this._offerstodeliver = new CartItems(this._offerstodeliver).Merge(_offers);
        }
        
        return this._offerstodeliver;    
    }

    private _extrastoprepare = [];
    get ExtrasToPrepare() {
        if(this._updatexttoprepare){
            this._updatexttoprepare = false;

            let _extras = [];
            for (let _extra of this._ticket.extras){
                if ((_extra.status == 'AC') && (_extra.charge > 0)){
                    _extras.push(_extra);
                }
            }
            this._extrastoprepare = _extras;
        }

        return this._extrastoprepare;
    }

    private _extrastorefund = [];
    get ExtrasToRefund() {
        if (this._updatexttorefund){
            this._updatexttorefund = false;

            let _extras = [];
            for (let _extra of this._ticket.extras){
                if ((['AC', 'UN'].includes(_extra.status)) && (_extra.refund > 0)){
                    _extras.push(_extra);
                }
            }
            this._extrastorefund = _extras;
        }
        
        return this._extrastorefund;    
    }

    private _extrastodeliver = [];
    get ExtrasToDeliver() {
        if (this._updatextodeliver){
            this._updatextodeliver = false;

            let _extras = [];
            for (let _extra of this._ticket.extras){
                if (['AC', 'UN'].includes(_extra.status)){
                    _extras.push(_extra);
                }
            }
            this._extrastodeliver = _extras;
        }
        
        return this._extrastodeliver;    
    }

    get DiscountsToApply(){
        let _discounts = [];

        for (let _discount of this._ticket.discounts){
            if (_discount.IsValid){
                _discounts.push(_discount);
            }
        }

        return _discounts;
    }

    get DiscountsToRefund(){
        let _discounts = [];

        for (let _discount of this._ticket.discounts){
            if (_discount.IsValid && (_discount.refund > 0)){
                _discounts.push(_discount);
            }
        }

        return _discounts;
    }

    get ToPrepare(){
        let _result = this._ticket.IsValid;

        if (_result){   // if direct ticket do not prepare (only payment)
            _result = !this._ticket.IsDirect;
        }

        if (_result){   // if prepay ticket, ticket must have been paid
            if (this._ticket.IsPrepay){
                _result = this._ticket.IsPaid;
            }
        }

        if (_result){   // it is to prepare if it is not ready
            _result = !this._ticket.IsReady;
        }

        return _result;
    }

    get ToPayment(){
        return this._ticket.IsValid && !this._ticket.IsPaid;
    }

    get refund(){
        if (!this._ticket.IsPaid){
            return 0;
        }

        let _refund: number = 0;

        for(let _ticketproduct of this._ticket.products){
            if ((_ticketproduct.status == 'UN') && (!_ticketproduct.offer)) {
                _refund += _ticketproduct.charge;
            }
        }

        for(let _ticketoffer of this._ticket.offers){
            if (_ticketoffer.IsValid){
                _refund += _ticketoffer.refund;
            }
        }

        for(let _ticketextra of this._ticket.extras){
            if (_ticketextra.IsValid){
                _refund += _ticketextra.refund;
            }
        }

        for (let _ticketdiscount of this._ticket.discounts){
            if (_ticketdiscount.IsValid){
                _refund -= _ticketdiscount.refund;
            }
        }

        return Math.abs(Math.round(_refund * 100) / 100);
    }

    get ToRefund(){
        let _refund = this.refund;
        if (_refund > 0){
            return _refund.toFixed(2);
        }

        return false;
    }

    ItemSort(cartproduct, delta, cartitems){
        let _cartproducts = new CartProducts(cartitems);
        if (_cartproducts){
            _cartproducts.MoveCartProduct(cartproduct, delta);
        }

        if (this._items){
            this._items.OnRefresh(this._ticket.products, this._ticket.offers);
        }

        this._updateprtoprepare = true;
        this._updateprtoseparate = true;      
        this._updateprtorefund = true;
        this._updateprdelivered = true;
        this._updateprcancelled = true;  
    }
}

export class Ticket extends TicketData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKET', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []) : Ticket {
        return this._Copy(store) as Ticket;
    }

    protected OnDestroy(){
        if (this._ticketcontrol){
            this._ticketcontrol.OnDestroy();
            this._ticketcontrol = null;
        }
    }

    private _ticketcontrol: TicketControl = null;
    get TicketControl(){
        if (this._ticketcontrol == null){
            this._ticketcontrol = new TicketControl(this, this.data);
        }
        
        return this._ticketcontrol;
    }

    get OnProducts(){
        return this.TicketControl._onProductsUpdated.asObservable();
    }

    /****************************/
    /* BASE OVERLOAD           */
    /****************************/

    protected get _CanUpdate(){
        return true;    // overwrite local changes with server changes
    }

    private _lastproducts = new Map <TicketProduct, string> ();

    protected _OnUpdate(info){
        super._OnUpdate(info);

        let _update = (this._lastproducts == null);
        if (!_update){
            _update = this._lastproducts.size != this.products.length;
        }

        if (!_update){
            _update = !this.products.every(
            (_ticketproduct) => {
                let _lastproducthash = this._lastproducts.get(_ticketproduct);
                if (_lastproducthash){
                    return (_ticketproduct.hash == _lastproducthash);
                }
                return false;   // new ticketproduct
            });
        }

        if (_update){
            this.DoChange("set", null);     // trigger the ticket change

            // update the last products list
            this._lastproducts.clear();
            for (let _ticketproduct of this.products){
                this._lastproducts.set(_ticketproduct, _ticketproduct.hash);
            }
        }
    }

    /****************************/
    /* TICKET PRINTING          */
    /****************************/

    private _onPrint = new Subject<any>();
    public OnPrint = this._onPrint.asObservable();
    private _onEmail = new Subject<any>();
    public OnEmail = this._onEmail.asObservable();
    private _onDrawer = new Subject<any>();
    public OnDrawer = this._onDrawer.asObservable();

    private _onModify = new Subject<any>();
    public OnModify = this._onModify.asObservable();
    private _onCancel = new Subject<any>();
    public OnCancel = this._onCancel.asObservable();
    private _onCommit = new Subject<any>();
    public OnCommit = this._onCommit.asObservable();

    private _print_requested = false;   // printing has been requested
    private _dopen_requested = false;   // drawer opening has been requested
    private _onpay_requested = false;   // print on ticket pay requested

    ToPrinter(_printer){
        if (!_printer){
            console.warn("[TICKET] No printer has been provided");
        }
        else {
            this._onPrint.next({
                printer: _printer,
                isauto: false,
                drawer: false
            });     
        }
    }

    DoPrint(drawer: boolean = false, isauto: boolean = false, oncommit: boolean = false){
        if (oncommit){
            this._print_requested ||= true;
            this._dopen_requested ||= drawer;    
            this._onpay_requested ||= isauto;
        }
        else {
            this._onPrint.next({
                printer: null,
                isauto: isauto,
                drawer: drawer
            });        
        }
    }

    DoEmail(){
        this._onEmail.next();
    }

    private _printing = false;
    get printing(): boolean {
        return this._printing;
    }

    private _printCompleted = null;
    set printing(value: boolean){
        this._printing = value;
        if (value == true){
            this._printCompleted = new Subject<any>();
        }
        else {
            if (this._printCompleted){
                this._printCompleted.next();
                this._printCompleted = null;    
            }
        }
    }

    async LogTicketChange(){
        let _last = this.change;    // last ticket change

        // determine if the ticket has been changed
        let iscancel = this.IsCancelled;
        let ischange = (_last == null);
        if (!ischange){
            ischange = _last.isChange;
        }

        let _reason = _last ? _last.Reason : 'F';

        if (this.status == 'PR'){
            _reason = 'F';  // always first ticket until ticket is commited
        }

        // generate the serial and invoice numbers
        if ((ischange && !iscancel) || (this._ticket.invceno == null)){
            if (_reason != null){
                switch(_reason){
                    case 'F':   // this is the first change
                        if (!this.invoice){  
                            this._ticket.series = 'S' + this.data.serial;
                            if (this._ticket.invceno == null){
                                this._ticket.invceno = await this.data.NextInvoice('T');
                            }
                        }
                        else {  // the first change is an invoice
                            this._ticket.series = 'C' + this.data.serial;
                            this._ticket.invceno = await this.data.NextInvoice('C');
                        }
                        break;
    
                    case 'I':   // this is an invoice creation
                        this._ticket.series = 'F' + this.data.serial;
                        this._ticket.invceno =  await this.data.NextInvoice('F');
                        break;
    
                    case 'C':   // this is s a cancelation
                        break;

                    default:    // this is a ticket change
                        this._ticket.series = 'R' + this.data.serial;
                        this._ticket.invceno =  await this.data.NextInvoice('R');
                        break;
                }

                this._toUpdate = true;
                this.DoRefresh('TICKET', true);
            }
        }

        console.info("[TICKET] " + this._ticket.series + " " + this._ticket.invceno);

        // log the action for this device
        this.data.LogTicketAction(this);
        if (!ischange){
            return;     // not a relevant change 
        }

        // create the change entry
        let _change = new TicketChange(null, this.data);
        if (_change) {
            let _price = this.TotalTaxes;

            _change.session = this.data.session;
            _change.ticket = this;
            _change.prev = _last;
            _change.reason = _reason;
            _change.series = this._ticket.series;
            _change.invoice = this._ticket.invceno;
            _change.total = this._ticket.price;
            _change.total = _price.total;
            _change.base = _price.base;
            _change.taxes = _price.taxes;
            _change.client = this.Client;
        }

        this.AddChange(_change);

        let _lockms = performance.now();
        try {
            console.info("[GLOBAL MUTEX] - waiting for mutex..")
            await _globalmutex.lock("__ticketbai");       // lock until last ticket is written

            // create ticketbai information
            await _change.AddTicketBAI();

            // update the last served ticket
            this.data.SetLastInvoice(_change);                       
        }
        finally {
            _globalmutex.unlock("__ticketbai");          // its safe to continue here
            console.info("[GLOBAL MUTEX] - mutex is released (" + (performance.now() - _lockms).toFixed(3) + " ms)");
        }   
    }

    private _ticketInCommit = null;     // serialize this ticket commit operations
    private _waitForLastCommit(){
        if (this._ticketInCommit){
            return new Promise((resolve) => {
                console.log("[TICKET] Waiting for previous commit to be completed..");

                let _subscription = this._ticketInCommit.asObservable().subscribe(
                data => {   // ticket has been commited
                    _subscription.unsubscribe();
                    console.log("[TICKET] Commit is completed. Continue.");
                    resolve(true);  
                });
            });
        }

        return false;   // no pending commits
    }

    async DoCommit(force: boolean = true){
        let _ini = performance.now();

        // open the drawer before commiting        
        if (this._dopen_requested){
            this._dopen_requested = false;
            
            this._onDrawer.next({
                isauto: this._onpay_requested
            });

            console.info("[TICKET] On drawer check completed in " + (performance.now() - _ini).toFixed(2) + " ms")
        }

        // lock concurrent ticket commits over this ticket
        if (this._ticketInCommit){
            await this._waitForLastCommit();
        }
        this._ticketInCommit = new Subject<any>();

        console.info("[TICKET] Wait for last commit completed in " + (performance.now() - _ini).toFixed(2) + " ms")
        
        // pre-create ticket information
        if (this.ToInsert){
            let _ticket = await this.data.CreateTicket(this);
            if (_ticket){
                this._toInsert = false;

                this.patchValue(this._ticket, 'objid', _ticket['objid']);
                this.patchValue(this._ticket, 'orderno', _ticket['orderno']);
                this.patchValue(this._ticket, 'series', _ticket['series']);
                this.patchValue(this._ticket, 'invceno', _ticket['invceno']);

                this._isLoaded = true;
                this._toUpdate = true;

                this.OnResolve();

                console.info("[TICKET] Created ticket [" + this.objid + "] completed in " + (performance.now() - _ini).toFixed(2) + " ms");
            }
            else {
                console.warn("WARNING: Could not pre-create ticket!")
            }
        }

        // create the ticket change instance
        await this.LogTicketChange();
        console.info("[TICKET] Keep ticket changes completed in " + (performance.now() - _ini).toFixed(2) + " ms")

        // print the ticket before commiting
        if (this._print_requested){
            this.DoRefresh('TICKET', true);

            this._print_requested = false;
            this._onPrint.next({            
                isauto: this._onpay_requested,
                drawer: this._dopen_requested
            });
        }

        // save the current list of products (to avoid refresh on update)
        this._lastproducts.clear();
        for (let _ticketproduct of this.products){
            this._lastproducts.set(_ticketproduct, _ticketproduct.hash);
        }

        // calculate the ticket taxes (just beffore commit)
        let _taxes = 0;
        for (let _splittax of this.SplitTaxes){
            _taxes += _splittax['taxes'];
        }
        this.taxes = _taxes;

        // commit the ticket
        let _result = await super.DoCommit(force);
        if (_result){
            console.info("[TICKET] Commited ticket [" + this.objid + "] with flags [" + this._ticket.flags + "] completed in " + (performance.now() - _ini).toFixed(2) + " ms");
            this._onCommit.next();
        }

        this._dopen_requested = false;
        this._onpay_requested = false;

        // unlock other commits on this ticket (after this one is commited)
        if (this._ticketInCommit){
            setTimeout(() => {
                this._ticketInCommit.next();
                this._ticketInCommit = null;  
            }, 100)
        }

        return _result;
    }

    /****************************/
    /* TICKET PAID CONTROL      */
    /****************************/

    private _onPayment = new Subject<any>();
    public OnPayment = this._onPayment.asObservable();

    protected patchValue(dst, property, value){
        let _status = (property == 'status') ? dst[property] : null;
        let _change = super.patchValue(dst, property, value);

        if (_change && (property == 'status') && (_status != null) && (dst[property] == 'PD')){
            this._onPayment.next();     // notify ticket payment
        }

        return _change;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    protected _UpdateFlags(){
        this.DoChange("set", null);     // trigger the ticket change

        let _flags = this._ticket.flags ? this.flags : [];

        // update the ticket product status
        for(let _ticketproduct of this.products){
            _ticketproduct.status = this.status;
        }

        // remove the ticket extras (for unwanted ones) - on ticket payment
        if (this.status == 'PD'){
            for(let _ticketextra of this.extras){
                if (_ticketextra.status == 'UN'){
                    _ticketextra.status = 'DE';
                }
            }
        }

        // remove the ticket extras (non-product dependant) - on ticket cancelation
        if (this.status == 'CC'){
            for(let _ticketextra of this.extras){
                if (_ticketextra.extra.type == 'TICKET'){
                    _ticketextra.status = this.IsPaid ? 'UN' : 'DE';
                }
            }
        }

        // remove the ticket extras (non-product dependant) - on ticket deletion
        if (this.status == 'DE'){
            for(let _ticketextra of this.extras){
                if (_ticketextra.extra.type == 'TICKET'){
                    _ticketextra.status = 'DE';
                }
            }
        }

        // ready: all products are ready
        _flags[this.TicketFlags.TicketReady] = this.products.every(
        _ticketproduct => {
            return (_ticketproduct.IsValid == false) || _ticketproduct.HasEvent('RD');
        });            
 
        // payment pending: at least one product is payment pending 
        _flags[this.TicketFlags.TicketToPay] = this.products.some(
        _ticketproduct => {
            return (_ticketproduct.IsValid == true) && _ticketproduct.HasEvent('PP') && !_ticketproduct.HasEvent('PD');
        });
        
        // paid: all products are paid
        _flags[this.TicketFlags.TicketPaid] = this.products.every(
        _ticketproduct => {
            return (_ticketproduct.IsValid == false) || _ticketproduct.HasEvent('PD');
        });

        this.flags = _flags;
    }

    protected _RemoveRequests(){
        if (this.qrcode){
            // remove all pending waiter requests
            if (this.request && ([ 'PAYCASH', 'PAYCARD', 'PAYMIXED', 'REFUND' ].includes(this.request.reason))){
                this.request.status = 'DE';     // remove any pending payment requests
            }
            
            this.qrcode.DoRefresh('TICKET');    
        }
    }

    get status(): string {
        return super.status;
    }

    set status(value: string){
        // add the ready status for direct tickets
        if (value == 'PD' && this.IsDirect){
            this.status = 'RD';
        }

        if (value != this._ticket.status){
            let _reopen = (['PR', 'AC'].includes(value) && (this.status != 'PR'));

            // remove any pending waiter requests (before changing status)
            if (['AC', 'PD', 'DE', 'CC'].includes(value)){
                this._RemoveRequests();
            }

            super.status = value;

            // update the status for the ticket products and flags
            this._UpdateFlags();

            // paid timestamp considerations
            if (value == 'PD'){
                this.paidon = new Date();
                this.paidin = this.data.session;
            }

            if (_reopen){
                this.payment = null;
                this.given = null;
                this.paidby = null;
                this.paidon = null;
                this.paidin = null;
            }

            // payment considerations
            if (value == 'PP'){
                this.paidby = this.data.session;
            }
            
            this.DoRefresh('TICKET', true);
            if (this.place){     // force place refresh on status change
                this.place.DoRefresh('TICKET', true); 
            }
        }
    }

    get payment(): string {
        let _payment = super.payment;
        
        switch(_payment){   // backwards compatibility
            case 'FORCEPAY':
            case 'CASHPAY':
                return 'PAYCASH';
            case 'CARDPAY':
                return 'PAYCARD';
            case 'PAYPAL':
                return 'PAYLINE';
        }

        if (_payment == null){
            return (this.IsPaid) ? 'UNKNOWN' : null;
        }

        return _payment; 
    }

    set payment(value: string){
        if (value && (!this.IsPaid || this.IsToPay) && (this.invceac == null)){
            let _oncash = (value == 'PAYCASH') && this.place.OpenOnCash;
            let _oncard = (value == 'PAYCARD') && this.place.OpenOnCard;

            this.DoPrint(_oncash || _oncard, true, true);
        }

        if (value != this._ticket.payment){
            super.payment = value;
        }

        if (value != null){
            this.status = (value == 'PAYACCOUNT') ? 'PP': 'PD';
        }
        else {  // payment has been removed
            this.paidby = null;
            this.given = null;
            this.paidon = null;
            this.paidin = null;
        }
    }

    get account() : PayAccount {
        return super.account;
    }

    set account(value: PayAccount) {
        super.account = value;
        if (value){
            super.payment = 'PAYACCOUNT';
        }
    }

    get comments(): string{
        return super.comments;
    }

    set comments(value: string){
        if (this.comments != value){
            super.comments = value;
            this.DoChange("cmt", null);       // trigger the comment change (ticket-change)
        }
    }

    get source(): string {  // 'BR': BAR, 'SF': STAFF, 'QR': QRCODE (CLIENT), 'HM': HOME (WEB)
        let _source = 'BR';
        if (this.table && this.session){
            _source = (this.session.user) ? 'SF' : 'QR';
        }
        return _source;
    }

    get products() : Array <TicketProduct> {
        let _ticketproducts = [];

        // do not return other ticket products (divided order)
        for(let _product of super.products){
            if (_product.ticket == this){
                _ticketproducts.push(_product)
            }
        }

        return _ticketproducts;
    }

    get change(): TicketChange {
        for(let _change of this.changes){
            if ((_change.ToInsert || _change.InCommit || _change.IsLoaded) && !_change.isUpdated){
                return _change;     // this is the last change (as it has no updates)
            }
        }
        return null;
    }

    get ticketbai(): TicketBAI {
        // ticketbai entry for the last change
        let _change = this.change;
        if (_change){
            return _change.ticketbai;   
        }

        return null;    // no changes
    }

    get ticketsii(): TicketSII {
        // ticketsii entry for the last autit
        let _changes = this.changes.sort((a, b) => {
            return b.created.getTime() - a.created.getTime();
        });

        for(let _change of _changes){
            if (_change.reason == 'T'){
                return _change.ticketsii;
            }
        }

        return null;    // no till changes
    }

    get client(): number {
        if (super.client){
            return super.client;
        }

        if (this.Client){
            return Number(this.Client.objid);
        }

        return null;
    }

    /****************************/
    /* CUSTOM MEMBERS           */
    /****************************/

    private _isopen: boolean = false;
    get isopen(){
        return this._isopen;
    }

    set isopen(value: boolean){
        this._isopen = value;
    }

    get request(): AskWaiter {
        if (this.qrcode){
            for(let _askwaiter of this.qrcode.requests){
                if ((_askwaiter.ticket == this) && (_askwaiter.IsValid)) {
                    return _askwaiter;
                }
            }
        }
        return null;
    }

    get requests(): Array<AskWaiter> {
        let _requests = [];
        
        if (this.qrcode){
            for(let _askwaiter of this.qrcode.requests){
                if (_askwaiter.ticket == this) {
                    _requests.push(_askwaiter);
                }
            }
        }
        return _requests;
    }

    set request(value: AskWaiter){
        if (this.qrcode){
            let _askwaiter = this.request;
            if (_askwaiter){
                if (!value){    // remove the existing request
                    _askwaiter.status = 'DE';
                }
            }
    
            if (value){
                this.qrcode.AddRequest(value);
            }
        }
    }

    get invoice() : TicketInvoice {
        for(let _invoice of this.invoices){
            if (_invoice.IsValid){
                return _invoice;
            }
        }

        return null;
    }

    set invoice(value: TicketInvoice){
        for(let _invoice of this.invoices){
            _invoice.status = 'DE';
        }

        if (value != null){
            this.AddInvoice(value);
        }

        this.DoRefresh('TICKET', true);
    }

    private _pending = null;
    get pendpay(){
        return this._pending;
    }

    set pendpay(value){
        this._pending = value;
    }

    get pendrf(){   // this is the ticket refund without discounts
        return this.TicketControl.refund;
    }

    private _tocharge: number = null;
    get _charge(){
        return this._tocharge || 0;
    }

    set _charge(value){
        this._tocharge = value;
    }

    private _torefund: number = null;
    get _refund(){
        return this._torefund || 0;
    }

    set _refund(value){
        this._torefund = value;
    }
        
    get audit(): Audit {
        // for old tickets
        if (this.intill){
            return this.intill;
        }

        // for new tickets
        let _audit = this.audits;
        if (_audit.length > 0){
            return _audit[0].audit;
        }
        return null;
    }

    set audit(value: Audit){
        let _audit = this.audit;
        if (_audit){
            console.warn("[TICKET] Cannot change this ticket's audit! Operation ignored");
        }
        else {
            let _ticketaudit = new TicketAudit(null, this.data);
            if (_ticketaudit){
                _ticketaudit.ticket = this;
                _ticketaudit.audit = value;
                _ticketaudit.status = 'AC';
            }

            this.AddChild('audit', _ticketaudit, 'ticket');
        }
    }

    get duedate(): Date {
        if (this.IsCancelled || (this.IsPaid && (this.payment != 'PAYACCOUNT'))){
            return null;
        }

        let _date = null;
        if (this.payment == 'PAYACCOUNT'){
            _date = new Date(this._ticket.created);
            _date.setDate(1);
            _date.setMonth(_date.getMonth() + 1);

            return _date;
        }
        else {
            if (this.invoice){
                _date = new Date(this.invoice.created);
            }
            else {
                _date = new Date(this._ticket.created);
            }
            
            _date.setDate(_date.getDate() + this.duedays);
            return _date;    
        }
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get BarTicket(){
        return (this.qrcode == null);
    }

    get IsRecent(){
        let _recent = false;

        if (!_recent){                  // check by creation time
            _recent = (this.created.getTime() > ((new Date()).getTime() - AppConstants.recentMs));
        }

        if (!_recent && this.paidon){   // check by paidon time
            _recent = (this.paidon.getTime() > ((new Date()).getTime() - AppConstants.recentMs));
        }

        return _recent
    }

    get IsDirect(){
        if (!this.place.IsDirect){
            return false;   // the place has track ticket enabled (ticket is not direct)
        }

        if (this.source == 'QR'){
            return !this.place.GetConfigOption('optionQrTrackTicket');
        }

        return true;
    }

    get IsPrepay(){
        if (this.place.IsPrepay){
            return true;
        }

        if (this.source == 'QR'){
            return this.place.GetConfigOption('optionQrPrePayment');
        }

        return false;
    }

    get IsEmpty(){  // same as (Length == 0)
        return !this.products.some(
        (_ticketproduct) => {
            return _ticketproduct.IsValid;
        });
    }

    get Length(){
        let _length: number = 0;

        for(let _ticketproduct of this.products){
            if (_ticketproduct.IsValid && !_ticketproduct.Separator){
                _length++;
            }
        }

        return _length;
    }

    get IsActive(){
        switch(this._ticket.status){
            case 'PR':
                return false;

            case 'CC':
                return !!this.ToRefund;

            case 'DE':
                return false;
        }

        return true;    
    }

    get IsValid(){
        let _valid = this.IsLoaded;

        if (!_valid){    // check if insert is pending
            _valid = !this._isCatalog && (this._toInsert || this._toCommit || this._inCommit);
        }

        if (_valid) {
            return this.IsActive;
        }

        return false;
    }

    get IsReady(){
        return (this.IsDirect) || (this.status == 'RD') || this._GetFlagValue(this.TicketFlags.TicketReady);
    }

    get IsPaid(){
        return (this.status == 'PD') || (this.account != null) || this._GetFlagValue(this.TicketFlags.TicketPaid);
    }

    get IsToPay(){
        return (this.status == 'PP') || this._GetFlagValue(this.TicketFlags.TicketToPay);
    }

    get IsCancelled(){
        if ((this.products.length == 0) && !this.IsVolatile){
            return this.IsLoaded || (this._toInsert || this._toCommit || this._inCommit);
        }

        switch(this.status){
            case 'DE':
            case 'CC':
                return !(this.ToRefund) && (this.ProductsCancelled.length > 0);
        }

        return false;
    }

    get IsWebPay(){
        return (this.IsToPay) && (this.payment == 'PAYLINE'); 
    }

    get IsInvoice(){
        return (this.invoice != null);
    }
    
    get ToInvoice(){
        return !this.IsCancelled && (this.price >= AppConstants.InvoiceMaxPrice);
    }

    InSession(session){
        return (this.session == session) && (this.IsValid) && (this.IsRecent)
    }

    InAudit(ini, end, audit){
        let _inaudit = (this.paidon != null);

        if (_inaudit){
            // check if already in some other audit
            if (this.audit && (this.audit != audit)){
                return false;
            }

            // paid tickets and cancelled in the reports period 
            if (['CC', 'DE'].includes(this.status)){
                let _updated = this.updated;
                if ((_updated < ini) || (_updated > end)){
                    _inaudit = false;
                }
            }

            // not cancelled tickets and paid in the reports period
            else {
                let _paidon = this.paidon;
                if ((_paidon < ini) || (_paidon > end)){
                    _inaudit = false;
                }
            }

            if (_inaudit && this.place.MultiplePOS){
                let _included = false;

                // check if it has been paid on a till (include if paid on this one)
                if (!_included && this.paidin) {
                    let _tillpaid = this.place.services.some(_till => {
                        return _till.PaidIn(this);
                    });

                    if (_tillpaid){     // only include if it has been paid on this till
                        return (this.place.till.PaidIn(this));   
                    }
                }

                // check if the ticket belongs to this till tables
                if (!_included){
                    if (this.qrcode != null){
                        _included = (this.place.till.tables.includes(this.qrcode))
                    }
                }

                // check if its a bar ticket created on this till
                if (!_included){
                    if (this.qrcode == null){
                        _included = this.place.till.connects.some(_connect => {
                            return _connect.IsValid && (_connect.device == this.device);
                        });
                    }
                }

                _inaudit = _included;
            }

            return _inaudit;
        }

        return true;    // not multiplepos (the ticket belongs to this audit)
    }

    get OrderNo(){
        if (this._ticket.orderno){
            let _number_pos = this._ticket.orderno.indexOf('-');
            if (_number_pos != -1){
                return this._ticket.orderno.substring(_number_pos + 1); 
            }
        }
        return this._ticket.orderno;               
    }

    get TableNo(){
        if (this.qrcode){
            return parseInt(this.qrcode.number);
        }
        return null;    // bar ticket
    }

    private _strPrice(price){
        let _price = {
            Str: "0,00",
            Int: "0",
            Dec: "00",
            Val: 0.0,
            Neg: false
        };

        if (price !== null){
            let _parts = Math.abs(price).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _int + ',' + _dec;
            let _val = price;
            let _neg = (price < 0); 

            _price = {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Val: _val,
                Neg: _neg
            }    
        }
        return _price;
    }

    get Price(){
        return this._strPrice(this._ticket.price);
    }

    private _taxesfor(target){
        let _taxrates = {};

        switch(target){
            // split the total for products (not in offer)            
            case 'TP':    
                for (let _ticketproduct of this.products){
                    if (!_ticketproduct.IsValid || (_ticketproduct.status == 'DE') || _ticketproduct.offer){
                        continue;
                    }

                    let _taxrate = _ticketproduct.taxrate;
                    if (!(_taxrate in _taxrates)){
                        _taxrates[_taxrate] = 0;
                    }

                    _taxrates[_taxrate] += _ticketproduct.charge;
                }
                break;

            // split the total for offers (aplying place tax rate)
            case 'TO':
                for (let _ticketoffer of this.offers){
                    if (!_ticketoffer.IsValid){
                        continue;
                    }

                    let _taxrate = _ticketoffer.taxrate;
                    if (!(_taxrate in _taxrates)){
                        _taxrates[_taxrate] = 0;
                    }
        
                    _taxrates[_taxrate] += _ticketoffer.charge;
                }
                break;

            // split the total for extras (aplying place tax rate)
            case 'TE':
                for (let _ticketextra of this.extras){
                    if (!_ticketextra.IsValid){
                        continue;
                    }

                    let _taxrate = _ticketextra.taxrate;
                    if (!(_taxrate in _taxrates)){
                        _taxrates[_taxrate] = 0;
                    }
        
                    _taxrates[_taxrate] += _ticketextra.charge;
                }
                break;

            // split each discount by the aplying tax rates
            case 'TD':
                for (let _ticketdiscount of this.discounts){
                    if (!_ticketdiscount.IsValid){
                        continue;
                    }

                    let _dsctaxes = _ticketdiscount.SplitTaxes;
                    for (let _dsctax of _dsctaxes){
                        let _taxrate = _dsctax.rate;
                        if (!(_taxrate in _taxrates)){
                            _taxrates[_taxrate] = 0;
                        }

                        _taxrates[_taxrate] -= _dsctax.total;
                    }
                }
                break;
        }

        return _taxrates;
    }

    _combinedtaxes(...targets: ('TP' | 'TO' | 'TE' | 'TD')[]){
        let taxrates = [];
        for(let _target of targets){
            taxrates.push(this._taxesfor(_target));
        }

        const _combined = {};
        for (const obj of taxrates) {
            for (const [key, value] of Object.entries(obj)) {
                if (key in _combined) {
                    _combined[key] += value;
                } 
                else {
                    _combined[key] = value;
                }
            }
        }
        return _combined;
    }

    get SplitTaxes(){
        let _taxrates = this._combinedtaxes('TP', 'TO', 'TE', 'TD');

        let _totaxes = [];
        
        for (let _taxrate in _taxrates){
            let _total = _taxrates[_taxrate];
            let _base = _total / ((100 + Number(_taxrate))/100);
            let _taxes = _total- _base;
    
            _totaxes.push({
                rate: Number(_taxrate),
                total: Math.round(_total * 100)/100,
                base: Math.round(_base * 100)/100,
                taxes: Math.round(_taxes * 100)/100
            })
        }

        return _totaxes;
    }

    get TotalTaxes(){
        let _taxes = {
            total: 0,
            base: 0,
            taxes: 0
        };

        let _split = this.SplitTaxes;
        for (let _tax of _split){
            _taxes.total += _tax.total;
            _taxes.base += _tax.base;
            _taxes.taxes += _tax.taxes;
        }

        return _taxes;
    }

    get Client(){
        if (this.invoice){
            return this.invoice.client;
        }

        if (this.invceac){
            return this.invceac.account.client;
        }

        if (this.account){
            return this.account.client;
        }

        let _client = this._ticket.client;
        if (_client){
            for(let _business of this.place.clients){
                if (parseInt(_business.objid) == _client){
                    return _business;
                }
            }

            return null;    // not found?
        }

        return null;
    }

    payAmount(payment){
        if (this.payment == payment){
            return this.price;
        }

        if (this.payment == 'PAYMIXED'){
            if (this._ticket.pmixed){
                let _mixed = [];

                let _payments = this._ticket.pmixed.split('|');
                for(let _payment of _payments){
                    let _parts = _payment.split(':');
                    _mixed[_parts[0]] = parseFloat(_parts[1]);
                }

                if ((payment in _mixed) && !isNaN(_mixed[payment])) {
                    return _mixed[payment];
                }

                return 0;
            }

            for(let _mixed of this.mixed){
                if (_mixed.payment == payment){
                    return _mixed.amount;
                }
            }
        }

        return 0;
    }

    /******************************/
    /* ORGANIZED TICKET ITEMS     */
    /******************************/

    get ProductsToSeparate(){
        return this.TicketControl.ProductsToSeparate;
    }

    get ProductsToPrepare(){
        return this.TicketControl.ProductsToPrepare;
    }

    get ProductsDelivered(){
        return this.TicketControl.ProductsDelivered;
    }

    get ProductsToRefund(){
        return this.TicketControl.ProductsToRefund;
    }

    get ProductsCancelled(){
        return this.TicketControl.ProductsCancelled;
    }

    get OffersToRefund(){
        return this.TicketControl.OffersToRefund;
    }

    get OffersToDeliver(){
        return this.TicketControl.OffersToDeliver;
    }

    get ExtrasToPrepare(){
        return this.TicketControl.ExtrasToPrepare;
    }

    get ExtrasToRefund(){
        return this.TicketControl.ExtrasToRefund;
    }

    get ExtrasToDeliver(){
        return this.TicketControl.ExtrasToDeliver;
    }

    get ToPrepare(){
        return this.TicketControl.ToPrepare;
    }

    get ToPayment(){
        return this.TicketControl.ToPayment;
    }

    get ToRefund(){
        return this.TicketControl.ToRefund;
    }

    get DiscountsToApply(){
        return this.TicketControl.DiscountsToApply;
    }

    get DiscountsToRefund(){
        return this.TicketControl.DiscountsToRefund;
    }

    /******************************/
    /* TICKET ACTIONS             */
    /******************************/

    private DoChange(operation, ticketproduct){
        this._onModify.next({
            operation: operation,
            product: ticketproduct
        })
    }

    get ToCancel(){
        return ['CC', 'DE'].includes(this.status);
    }

    DoCancel(silent: boolean = false){
        // send notification before cancelling
        if (!silent){
            this._onCancel.next();
        }            

        if (this.IsEmpty){
            this.status = 'DE';
        }
        else {
            this.status = 'CC';
        }

        this.account = null;
    }

    DoUpdate(force = false){
        this._UpdateFlags();
        this.TicketControl.DoUpdate(force);

        this.DoRefresh('TICKET', true);     
    }

    ToCart(product: Product, options: Array <ProductOption>, info = null,  comments: string = null){
        let _pritem = null;
        let _fixedp = null;
        let _market = null;
        let _weight = null;  

        if (info != null){
            _pritem = 'pritem' in info ? info['pritem'] : null;
            _fixedp = 'fixedp' in info ? info['fixedp'] : null;
            _market = 'market' in info ? info['market'] : null;
            _weight = 'weight' in info ? info['weight'] : null;
        }

        let _ticketproduct: TicketProduct = new TicketProduct(null, this.data);
        if (_ticketproduct){
            _ticketproduct.ticket = this;
            _ticketproduct.sort = (_pritem) ? _pritem.sort : null;
            _ticketproduct.product = product;
            _ticketproduct.comments = comments;
            _ticketproduct.fixed = (_fixedp != null);
            _ticketproduct.charge = _fixedp;
            _ticketproduct.market = _market;
            _ticketproduct.weight = _weight;
            _ticketproduct.taxrate = product.taxrate;
            _ticketproduct.status = 'AC';

            for(let option of options){
                _ticketproduct.CartAdd(option);
            }

            _ticketproduct.price = _ticketproduct.TotalPrice;
            _ticketproduct.charge = _ticketproduct.ChargePrice
        }

        return _ticketproduct;
    }

    CartAdd(ticketproduct: TicketProduct){
        if (ticketproduct){
            this.AddProduct(ticketproduct);
            
            // notify the ticket change
            this.DoChange("add", ticketproduct);    
        }

        this.DoUpdate();
    }

    CartDel(ticketproduct: TicketProduct, todelete: boolean = true){
        if (ticketproduct){
            if (todelete){
                ticketproduct.status = 'DE';
            }

            // notify the ticket change
            this.DoChange("del", ticketproduct);    
        }

        this.DoUpdate();
    }

    CartUdt(ticketproduct: TicketProduct){
        this.DoChange("set", ticketproduct);
    }

    CartSet(oldticketproduct: TicketProduct, newticketproduct: TicketProduct){
        if (oldticketproduct){
            oldticketproduct.status = 'DE';
            this.DoChange("del", oldticketproduct);
        }

        if (newticketproduct){
            this.AddProduct(newticketproduct);
            this.DoChange("add", newticketproduct);
        }

        this.DoUpdate();
    }

    CartClear(){
        for(let _ticketproduct of this.products){
            this.CartDel(_ticketproduct);
        }

        for (let _ticketdiscount of this.discounts){
            _ticketdiscount.status = 'DE';
        }

        this.DoUpdate();
    }

    CartSort(cartitem, delta, cartitems){
        let _cartproduct = cartitem.product;
        if (!_cartproduct){
            return;     // we can only sort product cart items
        }

        this.TicketControl.ItemSort(_cartproduct, delta, cartitems);    
        for (let _ticketproduct of this.products){
            this.CartUdt(_ticketproduct);
        }
    }

    DiscountAdd(ticketdiscount: TicketDiscount){
        this.AddDiscount(ticketdiscount);

        // notify the ticket change
        if (ticketdiscount){
            /* nothing to do: discounts are not shown in the order */
        }

        this.DoUpdate();
    }

    DiscountDel(ticketdiscount: TicketDiscount, todelete: boolean = true){
        if (todelete){
            ticketdiscount.status = 'DE';
        }

        // notify the ticket change
        if (ticketdiscount){
            /* nothing to do: discounts are not shown in the order */
        }

        this.DoUpdate();
    }
}
