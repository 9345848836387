import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { Output, EventEmitter} from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';

import { languageSupport, languageService } from '@app/modules/common/language';

@Component({
    selector: 'ui-crop-image',
    templateUrl:'./ui-crop.html',
    styleUrls: [ 
        './ui-crop.scss'
    ]
})
export class UiCrop extends languageSupport implements OnInit, AfterViewInit, OnDestroy {
    @Input('image') _image = null;
    @Input('height') _height = 0;
    @Input('width') _width = 0;
    
    @ViewChild('cropbox', {static: false}) _imagecropbox: ElementRef;
    @ViewChild('cropper', {static: false}) _imagecropper: ImageCropperComponent;    

    public _cropperSettings = null;
    public _croppedHeight = 0;
    public _croppedWidth = 0;
    public _croppedImage = {};
        
    constructor(private lang: languageService, private change: ChangeDetectorRef) {
        super(lang, null);
    }
    
    ngOnInit(){
        this._cropperSettings = new CropperSettings();
        this._cropperSettings.noFileInput = true;
        this._cropperSettings.cropOnResize = true;
        this._cropperSettings.preserveSize = false;
        this._cropperSettings.fileType = 'image/jpeg';
        this._cropperSettings.imageQuality = 75;

        this._cropperSettings.keepAspect = (this._width != 0) && (this._height != 0);
        if (this._cropperSettings.keepAspect){
            this._cropperSettings.croppedHeight = this._cropperSettings.height = this._height;
            this._cropperSettings.croppedWidth = this._cropperSettings.width = this._width;
        }
        
        this._cropperSettings.canvasWidth = 0;        
    }
    
    private _initialize_interval = null;
    ngAfterViewInit(){
        this._initialize_interval = setInterval(() => {
            if ((this._imagecropbox) && (this._imagecropbox.nativeElement.clientWidth != 0)){
                // clear the interval (view already available)
                clearInterval(this._initialize_interval);
                this._initialize_interval = null;

                // configure the crooper with the native element
                this._cropperSettings.canvasWidth = this._imagecropbox.nativeElement.clientWidth;
                this._cropperSettings.canvasHeight = this._imagecropbox.nativeElement.clientHeight;

                this.change.detectChanges();
                if (this._image){
                    this._imagecropper.setImage(this._image);
                }
            }
        }, 50);
    }
    
    ngOnDestroy(){
        super.OnDestroy();

        if (this._initialize_interval != null){
            clearInterval(this._initialize_interval);
            this._initialize_interval = null;
        }
    }
    
    
    private _bounds = null;
    public OnCrop(bounds: Bounds){
       this._bounds = bounds;
    }

    private _cropImageToBase64(img, bounds) {
        let cnv = document.createElement('canvas');
        let ctx = cnv.getContext('2d');

        cnv.height = Math.min(bounds.height, this._height || 150);
        cnv.width = cnv.height * (bounds.width / bounds.height);

        ctx.drawImage(img, bounds.left, bounds.top, bounds.width, bounds.height, 0, 0, cnv.width, cnv.height);

        let _type = this._cropperSettings.fileType;
        let _size = this._cropperSettings.imageQuality / 100;
        
        return cnv.toDataURL(_type, _size);
    }

    @Output('onCrop') _onCrop = new EventEmitter<any>();      
    public OnSave(){
        this._onCrop.emit(this._cropImageToBase64(this._croppedImage['original'], this._bounds));
    }
}

