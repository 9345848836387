import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { syncService } from '@app/modules/sync';
import { viewService } from '@app/modules/view';

@Injectable({
    providedIn: 'root'
})
export class preloadService {
    private _queue = [];
    private _ready = new Map <string, any> ();
    private _loading = false;
    private _loaded = false;

    private _stage_subscription = null;
    constructor(private sync: syncService, private sanitizer: DomSanitizer, private view: viewService) {
        if (this.sync.IsStageReady){
            this.Start();
        }
        else {
            this._stage_subscription = this.sync.OnStageReady.subscribe(
            data => {
                this.Start();
            });        
        }
    }

    OnDestroy(){
        for(let _url in this._ready){
            let _queued = this._ready.get(_url);
            if (_queued){
                URL.revokeObjectURL(_queued.objturl)
            }
        }

        if (this._stage_subscription){
            this._stage_subscription.unsubscribe();
            this._stage_subscription = null;
        }
    }

    private Start(){
        this._loaded = true;
        console.info("[PRELOAD] Loading images in background..")
        this._LoadImage();    
    }

    Recover(url: string){
        let _queued = this._ready.get(url);
        if (_queued){
            return _queued.safeurl;
        }

        return null;    // not loaded yet
    }

    Enqueue(url: string, onload: any) {
        let _queued = this._ready.get(url);
        if (_queued){
            if (onload){
                onload(_queued.safeurl);
            }
        }

        let _included = this._queue.some(_queued => {
            return _queued.image == url;
        });

        if (!_included){
            this._queue.push({ image: url, onload: onload });
            if (this._loaded && !this._loading) {
                this._LoadImage();
            }    
        }
    }

    private _addToReady(url, blob){
        let _objturl = null;
        let _safeurl = null;
        
        _objturl = URL.createObjectURL(blob);
        if (_objturl){
            _safeurl = this.sanitizer.bypassSecurityTrustUrl(_objturl);
        }

        this._ready.set(url, {
            objturl: _objturl,
            safeurl: _safeurl
        });

        return _safeurl;
    }

    private async _addToCache(_url) {
        if  (/^data:image\/.*;base64,/.test(_url)){
            return _url;    // this is a base64 image
        }

        let _cachesearh = _url.startsWith('/assets') ? 'assets:assets:cache' : 'data:images:cache'; 

        let _keylist = await caches.keys();
        if (_keylist){
            let _cachenam = null;
            for (let _key of _keylist) {
                if (_key.indexOf(_cachesearh) != -1) {
                    _cachenam = _key;
                }
            }

            if (!_cachenam){
                return false;
            }

            // check if already in the cache
            let _rdpromise = new Promise(
            async (resolve) => {
                caches.open(_cachenam).then((cache) => {
                    cache.match(_url, { ignoreMethod: true, ignoreVary: true }).then((response) => {
                        if (response && response.ok) {
                            response.blob().then((_blob) => {
                                resolve(this._addToReady(_url, _blob));
                            });
                        } 
                        else {
                            resolve(null);     // not found in cache 
                        }    
                    });
                });                    
            });

            let _safeurl = await _rdpromise;
            if (_safeurl){
                return _safeurl;
            }

            let _wrpromise = new Promise(
            async (resolve) => {
                let response = await fetch(_url);
                if (response.ok) {
                    caches.open(_cachenam).then(async (cache) => {
                        await cache.put(_url, response.clone());
                        response.blob().then((_blob) => {
                            resolve(this._addToReady(_url, _blob));
                        });
                    });
                }
                else {
                    resolve(null);      // image fetch failed
                }    
            });

            return await _wrpromise;
        }

        return null;
    }

    private _LoadImage(): void {
        if (this._queue.length === 0) {
            this._loading = false;
            return;
        }

        if (this.view.IsOnline == false){
            let _subscription = this.view.OnOnline.subscribe(
            data => {
                _subscription.unsubscribe();
                this._LoadImage();      // load next image (when online)
            });
        }
        else {
            this._loading = true;

            let _queued = this._queue[0];
            if (_queued){
                let _image = _queued.image;
                let _ready = this._ready.get(_image);
                if (_ready){
                    if (_queued.onload){
                        _queued.onload(_ready.safeurl);
                    }

                    this._queue.shift();        // remove from queued
                    this._LoadImage();          // load next image                    
                } 
                else {
                    this._addToCache(_image).then(
                    safeurl => {
                        if (safeurl){     
                            if (_queued.onload){
                                _queued.onload(safeurl);
                            }
                        }    

                        this._queue.shift();    // remove from queued
                        this._LoadImage();      // load next image                    
                    });        
                }
            }    
        }
    }
}
