import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

/**********************************/
/* SERVICE TO STORE KEYBOARD      */
/**********************************/

@Injectable({ providedIn: 'root' })
export class kioskService {
    private _OnKeyboardShow = new Subject<any>();
    public OnKeyBoardShow = this._OnKeyboardShow.asObservable();

    private _OnKeyboardHide = new Subject<any>();
    public OnKeyBoardHide = this._OnKeyboardHide.asObservable();

    constructor() {
        // nothing to do
    }

    private _input: HTMLInputElement = null;

    show(input: HTMLInputElement, type: 'num' | 'price' | 'all') {
        this._input = input;
        if (!this._input.disabled){
            this._OnKeyboardShow.next((this._input.type == 'password') ? 'password' : type);
        }
    }
  
    hide() {
        this._OnKeyboardHide.next();
        this._input = null;
    }

    get value(){
        if (this._input){
            return this._input.value;
        }
        return null;
    }

    keyPress(key: string) {
        if (this._input && !this._input.disabled) {
            let _value = this._input.value;

            let _inipos = this._input.selectionStart;
            let _endpos = this._input.selectionEnd;
    
            // dispatch the 'keydown' event
            let event = new KeyboardEvent('keydown', {
                key: key,
                bubbles: true,
                cancelable: true
            });

            this._input.dispatchEvent(event);
    
            // if event was not cancelled, we can continue
            if (!event.defaultPrevented) {
                switch (key) {
                    case 'backspace':
                        if (_inipos === _endpos) {
                            this._input.value = _value.substring(0, _inipos - 1) + _value.substring(_endpos);
                            _inipos -= 1;   // move the cursor backwards
                        } else {
                            this._input.value = _value.substring(0, _inipos) + _value.substring(_endpos);
                        }
                        break;
    
                    case 'breakline':
                        if (this._input.tagName.toLowerCase() === 'textarea') {
                            this._input.value = _value.substring(0, _inipos) + "\n" + _value.substring(_endpos);
                            _inipos += 1;   // move the cursor after the inserted character
                        }
                        break;
    
                    default:
                        this._input.value = _value.substring(0, _inipos) + key + _value.substring(_endpos);
                        _inipos += 1;   // move the cursor after the inserted character
                        break;
                }
                
                this._input.setSelectionRange(_inipos, _inipos);    // update the cursor position   
                this._input.dispatchEvent(new Event('input'));      // trigger for angular forms
                this._input.dispatchEvent(new Event('change'));     // trigger the change event
            }
        }
    }

    ClearInput(){
        if (this._input && !this._input.disabled) {
            this._input.value = '';
        }

        this._input.dispatchEvent(new Event('input'));    // trigger for angular forms
        this._input.dispatchEvent(new Event('change'));   // trigger the change event        
    }  
}