import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Device } from '@ionic-native/device/ngx';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { Place } from './model/place';
import { User } from './model/user';
import { QrCode } from './model/qrcode';

import { AppConstants } from '@app/app.constants';
import { GenericUtils } from '@app/app.commonutils';

import { httpService } from '@app/modules/common/http';
import { storeService, SyncChange } from '@app/modules/store';
import { languageService } from '@app/modules/common/language';
import { alertService } from '@app/modules/common/alert';
import { toastService } from '@app/modules/common/toast';

/*
    TODO:
        - En ocasiones hay que esperar los 20 segundos del refresco para la primera carga de datos

    PENDING:
        - Incluir los horarios de apertura y cierre del local cada día de la semana
            - Esto se tiene que mostrar en la pantalla de bienvenida del guest
        - Hacer algo TBD para facilitar el scroll en pantallas tactiles

        - Añadir tipo de impresora para imprimir las comandas (no vincular a impresora de barra)
            - Especifica de productos: para todos los productos

        - Permitir añadir las mesas con el prefijo incluido

        - Poder indicar que una opcion de producto es de cocina / barra independientemente del producto
            - Opcion 1: Al seleccionar productos en la impresora especifica, mostrar tb las opciones
            - Opcion 2: En la propia configuración de la opción (prefiero)

        - Poder indicar número de comensales por mesa
        - Poder configurar un suplemento de mesa en base al número de comensales

        - La fecha de los pagos de la licencia por paypal esta mal
        - Pago de licencia: Desde otra vista: se queda recuperando información del pago
        - No se recupera el buffer de teclado en el precio del producto en todos los casos
        - Actualizacion de la version de android (en caliente, si que funciona tras reinicio)
            No sale la fecha de publicacion ni la nueva version
            No funciona el boton de actualizar

    WISHLIST:
        - Añadir campo de estilo 'style' a la BBDD de una printer, pero sin reflejo en el interfaz
            - Se aplica ad-hoc (por ejemplo bold)
            - ALTER TABLE `PRINTER` ADD `style` TEXT NULL DEFAULT NULL COMMENT 'Custom CSS rules to apply to html body' AFTER `copies`;
*/

/************************************************/
/* HASH CALCULATION                             */
/************************************************/

let _global_crcTable = null;

// https://stackoverflow.com/questions/18638900/javascript-crc32
export class CRC32 {
    private _crc = null;

    constructor(str){
        if (!str){
            str = '';
        }
        
        // build the CRC lookup table
        if (!_global_crcTable){
            _global_crcTable = [];
            for(let n=0; n < 256; n++) {
                let c = n;
                for(let k=0; k < 8; k++) {
                    c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
                }
                _global_crcTable[n] = c;
            }
        }

        // calculate the CRC value
        let crc = 0 ^ (-1);
        for (let i=0; i < str.length; i++ ) {
            crc = (crc >>> 8) ^ _global_crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
        }
        this._crc = (crc ^ (-1)) >>> 0;        
    }

    get value(){
        return this._crc;
    }
}

/************************************************/
/* CLIENT SESSION                               */
/************************************************/

interface ISession {
    device: string,
    session: string;
    token: string;
    user: User,
    place: Place;
    qrcode: QrCode
};

class Session implements ISession {
    private _session: ISession = {
        device: null,
        session: null,
        token: null,
        user: null,
        place: null,
        qrcode: null            
    }

    constructor(){
        // nothing to do
    }

    Clear(){
        this._session = {
            device: this._session.device,
            session: null,
            token: null,
            user: null,
            place: null,
            qrcode: null               
        }
    }

    /* getters */

    get serial(): string {      // device serial number is the CRC32 for the stored value
        if (this._session.place){
            return new CRC32(this._session.device + this._session.place.objid).value.toString(16).toUpperCase();
        }
        else {  // no place: this session comes from a client device
            if (this._session.qrcode){
                return new CRC32(this._session.qrcode.place.objid).value.toString(16).toUpperCase();
            }

            return "00000000";  // default value (this is an error in the session information)
        }
    }

    get device(): string  {
        return this._session.device;
    }

    set device(value: string){
        this._session.device = value;
    }

    get session(): string {
        return this._session.session;
    }

    get token(): string {
        return this._session.token;
    }

    get user(): User {
        return this._session.user;
    }

    get place(): Place {
        return this._session.place;
    }

    get qrcode(): QrCode {
        return this._session.qrcode;
    }

    /* setters */

    set session(value: string){
        this._session.session = value;
    }

    set token(value: string){
        this._session.token = value;
    }

    set user(value: User){
        this._session.user = value;
    }

    set place(value: Place){
        this._session.place = value;
    }

    set qrcode(value: QrCode){
        this._session.qrcode = value;
    }
}

/************************************/
/* PERIODIC REFRESH CONTROL         */
/************************************/

class MainClock {
    private _tickms = 250;

    private _refreshTick = new Subject<any>();
    public OnRefreshTick = this._refreshTick.asObservable();

    private _onesecondTick = new Subject<any>();
    public OnOneSecondTick = this._onesecondTick.asObservable();

    private _tensecondTick = new Subject<any>();
    public OnTenSecondTick = this._tensecondTick.asObservable();

    private _oneminuteTick = new Subject<any>();
    public OnOneMinuteTick = this._oneminuteTick.asObservable();

    private _clock_interval = null;
    constructor(){
        this._clock_interval = setInterval(() => {
            this._tick()
        }, this._tickms);
    }

    OnDestroy(){
        if (this._clock_interval){
            clearInterval(this._clock_interval);
        }
        this._clock_interval = null;
    }

    private _enabled = new Set();
    get Enable(){
        return (this._enabled.size == 0)
    }

    ClockEnable(source, value){
        if (value == false){    // add source for clock disable
            this._enabled.add(source);
        }
        else {                  // remove source for clock enable
            this._enabled.delete(source);
            if (this._enabled.size == 0){
                this._tick();
            }
        }
    }

    private _second_count = 0;
    private _tensec_count = 0;
    private _minute_count = 0;

    private _tick(){
        this._second_count++;
        if (this._second_count >= (1000 / this._tickms)){
            this._second_count = 0;
            this._onesecondTick.next();
            this._tensec_count++;
            this._minute_count++;
        }

        if (this._tensec_count >= 10){
            this._tensec_count = 0;
            this._tensecondTick.next();
        }

        if (this._minute_count >= 60){
            this._minute_count = 0;
            this._oneminuteTick.next();
        }

        let _skip = false;
        if (this.Enable){
            if (!_skip){
                _skip = true;
                this._refreshTick.next();
                _skip = false;
            }
        }
    }
}

/************************************/
/* PRIORITIZED PENDING UPDATES      */
/************************************/

class Pending {
    private _pending = [];
    
    private _refreshStage = new Subject<any> ();
    public OnRefreshStage = this._refreshStage.asObservable();

    private NotifyStage(){
        this._refreshStage.next(this._stage);
    }

    private _notify = false;
    set DoNotify(value){
        this._notify = value;
    }

    get Length(){
        return this._pending.length;
    }

    private _stage = 0;
    get Stage(){
        return this._stage;
    }

    set Stage(value){
        if (value == 0) {
            this.Reset();
        }

        let _next = Math.min(9, value);
        if (_next > this._stage){
            this._stage = _next

            this.NotifyStage();
            setTimeout(() => {
                this.NotifyPercent();
            }, 0);
        }
    }

    private _refreshPercent = new Subject<any> ();
    public OnRefreshPercent = this._refreshPercent.asObservable();

    /* total items progress */
    private _totalitm = 0;
    private _progritm = 0;

    /* active items progress */
    private _totalact: number = 0;
    private _progract: number = 0;

    /* old / past items progress */
    private _totalpst: number = 0;
    private _progrpst: number = 0;
    
    private LoadPcnt(progr, total){
        if ((this._stage == 0) || (!this._notify)){
            return 0;   // loading not started
        }

        if (this._stage >= 9){
            return 1;   // completelly loaded
        }

        return (total == 0) ? 0 : Math.floor((progr / total) * 100) / 100;
    }

    get LoadPercent(){
        let _percent = {
            tot: this.LoadPcnt(this._progritm, this._totalitm),
            act: this.LoadPcnt(this._progract, this._totalact),
            pst: this.LoadPcnt(this._progrpst, this._totalpst)    
        };

        return _percent;
    }

    private NotifyPercent(){
        if (!this._notify){
            return;     // notifications are disabled
        }

        this._refreshPercent.next(this.LoadPercent);
    }

    private get StageReady(){
        return (this.sync.LoadStage > 6);
    }

    private _blockProcessed = new Subject<any> ();
    public OnBlockProcessed = this._blockProcessed.asObservable();

    private _onblock_subscription = null;
    private _ProcessQueue(){
        let _stage = this.Stage;
        if (this._pending.length > 0){
            _stage = this._pending[0]['__priority'];
        }

        let _todo = Math.min(this._pending.length, this.StageReady ? 5000 : 500);
        this.sync.Clock.ClockEnable('queue', (_todo == 0) || this.StageReady);

        let _starttime = performance.now();  // max 50ms execution inside loop
        while ((_todo-- > 0) && ((performance.now() - _starttime) < 50)){
            let _oldstage = this.Stage;
            this.Dequeue(this._pending.shift());

            if (_oldstage != this.Stage){
                break;  // can change the max evaluation size
            }
        }
        
        this.sync.Clock.ClockEnable('queue', true);

        if ((this.Stage > 0) && (this._pending.length == 0)){
            this.Stage = 9;     // ensure that stage 9 is reached
        }

        // trigger block processed to continue with next block
        if (this._pending.length > 0){
            setTimeout(() => {
                this._blockProcessed.next();
            }, this.StageReady ? 0 : 50);
        }
        else {  // no more items, remove the subscription
            if (this._onblock_subscription != null){
                this._onblock_subscription.unsubscribe();
                this._onblock_subscription = null;
            }
        }
    }

    private ProcessQueue(){
        if (this._onblock_subscription == null){
            this._onblock_subscription = this.OnBlockProcessed.subscribe(
            data => {
                this._ProcessQueue();
            });
    
            this._blockProcessed.next();
        }
    }

    constructor(private sync: syncService){
        // nothing to do
    }

    OnDestroy(){
        if (this._onblock_subscription){
            this._onblock_subscription.unsubscribe();
            this._onblock_subscription = null;
        }
    }

    /*********************************/
    /* LOAD PROGRESS                 */
    /*********************************/

    Reset(){
        this._totalitm = this._progritm = 0;
        this._totalact = this._progract = 0;
        this._totalpst = this._progrpst = 0;

        this._stage = 0;
        this._pending = [];

        this.NotifyStage();
    }

    private IsActive(_item){
        return _item['__priority'] < 7;
    }

    private Enqueue(_items){
        this._totalitm += _items.length;

        for(let _item of _items){
            this.IsActive(_item) ? this._totalact++ : this._totalpst++;
        }
    }

    private Dequeue(_item){
        let _update = false; 

        let _tot_pcnt = [ 0, 0 ];
        let _act_pcnt = [ 0, 0 ];
        let _pst_pcnt = [ 0, 0 ];

        // total items counter
        _tot_pcnt[0] = this.LoadPcnt(this._progritm, this._totalitm);
        this._progritm++;
        _tot_pcnt[1] = this.LoadPcnt(this._progritm, this._totalitm);

        // active items counter
        if (this.IsActive(_item)){
            _act_pcnt[0] = this.LoadPcnt(this._progract, this._totalact);
            this._progract++;
            _act_pcnt[1] = this.LoadPcnt(this._progract, this._totalact);
        }

        // past items counter
        else {
            _pst_pcnt[0] = this.LoadPcnt(this._progrpst, this._totalpst);
            this._progrpst++;
            _pst_pcnt[1] = this.LoadPcnt(this._progrpst, this._totalpst);
        }

        this.sync.DoRefresh(_item);
        
        _update = _update || (_tot_pcnt[1] > _tot_pcnt[0])
        _update = _update || (_act_pcnt[1] > _act_pcnt[0])
        _update = _update || (_pst_pcnt[1] > _pst_pcnt[0])

        this.Stage = parseInt(_item['__priority']);
        if (_update){
            this.NotifyPercent();
        }
    }

    /*********************************/
    /* CONCAT UPDATES                */
    /*********************************/

    protected mysqlToDateStr(date){
        return date ? date.replace(' ', 'T') + '.000Z' : null;
    }    

    private _insert_recent(_recent, table, objid){
        if (!_recent[table]){
            _recent[table] = new Set();
        }
        _recent[table].add(objid);
    }

    private _build_recents(_changes){
        let _recent : {[key: string]: Set<any>} = {};

        // obtain the recent tickets with the related session
        if ('TICKET' in _changes){
            for(let _ticket of _changes['TICKET']){
                let _date = new Date(Date.parse(this.mysqlToDateStr(_ticket['updated'])));
                if (_date.getTime() > ((new Date()).getTime() - AppConstants.recentMs)){
                    this._insert_recent(_recent, 'TICKET', _ticket['objid']);       // add the recent ticket entry
                    this._insert_recent(_recent, 'SESSION', _ticket['session']);    // add the related session entry
                } 
            }    

            // obtain the related entries
            let _related = [ 'TICKETPRODUCT', 'TICKETOPTION', 'TICKETEVENT', 'TICKETOFFER', 'TICKETEXTRA', 'TICKETINVOICE', 'TICKETDISCOUNT', 'TICKETAUDIT', 'TICKETCHANGE', 'TICKETBAI', 'TICKETSII' ];
            for(let _table of _related){
                // invoice: invoice.ticket == ticket.objid
                if ((_table == 'TICKETINVOICE') && (_table in _changes) && ('TICKET' in _recent)){           
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }
                }

                // ticketchange: tikcetchange.ticket == ticket.objid
                if ((_table == 'TICKETCHANGE') && (_table in _changes) && ('TICKET' in _recent)){           
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }
                }

                // ticketbai: ticketbai.change == ticketchange.objid            
                if ((_table == 'TICKETBAI') && (_table in _changes) && ('TICKETCHANGE' in _recent)){      
                    for(let _item of _changes[_table]){
                        if (_recent['TICKETCHANGE'].has(_item['ticketbai'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                
                }                

                // ticketsii: ticketsii.change == ticketchange.objid            
                if ((_table == 'TICKETSII') && (_table in _changes) && ('TICKETCHANGE' in _recent)){      
                    for(let _item of _changes[_table]){
                        if (_recent['TICKETCHANGE'].has(_item['ticketsii'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                
                }   

                // ticketproduct: ticketproduct.ticket == ticket.objid
                if ((_table == 'TICKETPRODUCT') && (_table in _changes) && ('TICKET' in _recent)){     
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                
                }

                // ticketoption: ticketoption.product == ticketproduct.objid            
                if ((_table == 'TICKETOPTION') && (_table in _changes) && ('TICKETPRODUCT' in _recent)){      
                    for(let _item of _changes[_table]){
                        if (_recent['TICKETPRODUCT'].has(_item['product'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                
                }

                // ticketoffer: ticketoffer.ticket == ticket.objid
                if ((_table == 'TICKETOFFER') && (_table in _changes) && ('TICKET' in _recent)){          
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                                
                }

                // ticketextra: ticketextra.ticket == ticket.objid
                if ((_table == 'TICKETEXTRA') && (_table in _changes) && ('TICKET' in _recent)){       
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                                
                }

                // ticketdiscount: ticketdiscount.ticket == ticket.objid
                if ((_table == 'TICKETDISCOUNT') && (_table in _changes) && ('TICKET' in _recent)){       
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                                
                }

                // ticketaudit: ticketaudit.ticket == ticket.objid
                if ((_table == 'TICKETAUDIT') && (_table in _changes) && ('TICKET' in _recent)){       
                    for(let _item of _changes[_table]){
                        if (_recent['TICKET'].has(_item['ticket'])){
                            this._insert_recent(_recent, _table, _item['objid']);
                        }
                    }                                
                }
            }
        }

        return _recent;
    }

    private _filter_recents(_updates){
        let _changes = {};

        for(let _change of _updates){
            let _table = _change['table'];
            if (!(_table in _changes)){
                _changes[_table] = [];
            }
            _changes[_table].push(_change);
        } 

        return this._build_recents(_changes);
    }

    private _isrecent(_update, _recent){
        return (_update['table'] in _recent) && (_recent[_update['table']].has(_update['objid']));
    }

    private _priority(_update, _recents){
        // update synchronously
        switch(_update['table']){
            case 'SESSION': 
                if (this.sync.Session.session == _update['id']){
                    return 1.1;
                }
                break;

            case 'FCM': return 1.2;

            case 'USER': return 1.3;
            case 'STAFF': return 1.4;

            case 'PLACE': 
                if (!this.sync.Session.place){
                    return 1.5;
                }
                break;
        }    

        // instances without graphical component
        switch(_update['table']){
            case 'ADDRESS': return 2.1;
            case 'STRIPE': return 2.1;
            case 'RASPPI': return 2.1;
            case 'USERADDRESS': return 2.2;
            case 'PLACEOPT': return 2.2;
            case 'PLACELINK': return 2.2;
            case 'INVOICEBUSINESS': return 2.2;
            case 'ACCOUNTINVOICE': return 2.3;
            case 'PAYACCOUNT': return 2.4;
            case 'CASHCHANGE': return 2.5;
            case 'AUDITINFO': return 2.6;
            case 'AUDITTILL': return 2.6;
            case 'AUDIT': return 2.7;
        }

        // first order instances
        switch(_update['table']){
            case 'DRAWITEM':
                return 3.1;

            case 'PLACEAREA':
            case 'RASPPICONNECT':
                return 3.2;
    
            case 'QRCODE': 
            case 'PRINTER':
            case 'SCALE':
                if (_update.status != 'DE'){
                    return 3.3;
                }
                break;
    
            case 'OFFERPRODUCTOPT':
            case 'EXTRAPRODUCTOPT':
            case 'PRINTERPRODUCTOPT':
                    return 3.4;

            case 'PRODUCTOPT':
            case 'OFFERPRODUCT': 
            case 'OFFERPERIOD': 
            case 'DISCOUNTPERIOD':
            case 'EXTRATABLE': 
            case 'EXTRAPRODUCT':
            case 'EXTRAPERIOD':
            case 'PRINTERPRODUCT': 
                return 3.5;

            case 'CATEGORYDEP':
            case 'PRESELECTOPT':
                return 3.6;
    
            case 'CATEGORY':
            case 'PRESELECT':
                return 3.7;

            case 'PRODUCT':
            case 'OFFER':
            case 'EXTRA':
            case 'DISCOUNT':
                return 3.8;
        }

        // product families
        switch(_update['table']){
            case 'FAMILYPERIOD':
            case 'FAMILYPRODUCT':
                return 4.1;

            case 'FAMILY':
                return 4.2;
        }

        // active tickets & members
        switch(_update['table']){
            case 'TICKETOFFER': 
                if (this._isrecent(_update, _recents)) {
                    return 5.1; 
                }
                break;
            case 'TICKETEXTRA': 
                if (this._isrecent(_update, _recents)) {
                    return 5.1; 
                }
                break;
            case 'TICKETOPTION': 
                if (this._isrecent(_update, _recents)) {
                    return 5.3; 
                }
                break;      
            case 'TICKETBAI': 
                if (this._isrecent(_update, _recents)) {
                    return 5.4;     
                }
                break;
            case 'TICKETSII': 
                if (this._isrecent(_update, _recents)) {
                    return 5.4;     
                }
                break;
            case 'TICKETPRODUCT': 
                if (this._isrecent(_update, _recents)) {
                    return 5.4; 
                }
                break;                
            case 'TICKETDISCOUNT': 
                if (this._isrecent(_update, _recents)) {
                    return 5.4; 
                }
                break;                
            case 'TICKETCHANGE': 
                if (this._isrecent(_update, _recents)) {
                    return 5.5;     
                }
                break;
            case 'TICKETINVOICE': 
                if (this._isrecent(_update, _recents)) {
                    return 5.5;     
                }
                break;                
            case 'TICKETAUDIT': 
                if (this._isrecent(_update, _recents)) {
                    return 5.5;     
                }
                break;                
            case 'SESSION': 
                if (this._isrecent(_update, _recents)) {
                    return 5.6;     
                }
                break;                
            case 'TICKET': 
                if (this._isrecent(_update, _recents)) {
                    return 5.7; 
                }
                break;
        }

        // waiter requests
        switch(_update['table']){
            case 'ASKWAITER':
                if (_update['status'] == 'AC'){
                    return 5.9;
                }
                break;
        }
        
        // place information (this is a place update)
        switch(_update['table']){
            case 'QRCODE': 
            case 'PRINTER':
            case 'SCALE':
                return 6.1;

            case 'PAYMENT': return 6.3;
            case 'PLACE': return 6.4;
        }

        // older tickets & members
        switch(_update['table']){
            case 'QRCODE': return 8.1;
            case 'TICKETOFFER': return 8.1;
            case 'TICKETEXTRA': return 8.1;
            case 'TICKETDISCOUNT': return 8.1;
            case 'TICKETOPTION': return 8.3;
            case 'TICKETPRODUCT': return 8.4;
            case 'TICKETBAI': return 8.4;
            case 'TICKETSII': return 8.4;
            case 'TICKETAUDIT': return 8.4;
            case 'ASKWAITER': return 8.5;
            case 'TICKETINVOICE': return 8.5;
            case 'TICKETCHANGE': return 8.5;
            case 'SESSION': return 8.6;
            case 'TICKET': return 8.7;
        }

        // other instance information
        console.warn("WARNING: table '" + _update['table'] + "' not prioritized!");
        return 9;
    }

    private _ChangeKey(change){
        return change['table'] + '@' + change['objid'];
    }

    private _MergeChange(chng1, chng2){
        let _updated1 = new Date(Date.parse(chng1['updated'].replace(' ', 'T') + '.000Z'));
        let _updated2 = new Date(Date.parse(chng2['updated'].replace(' ', 'T') + '.000Z'));

        if (_updated1.getTime() > _updated2.getTime()){
            return Object.assign(chng2, chng1);     // source is the recent item
        }
        else {
            return Object.assign(chng1, chng2);     // source is the recent item
        }
    }

    SortUpdates(_updates){
        for(let _update of _updates){
            _update['__priority'] = this._priority(_update, {});
        }

        _updates.sort((a, b) => {
            return (a['__priority'] - b['__priority']);
        });

        return _updates;
    }

    async Concat(_updates, _synchronous){
        await new Promise(resolve => setTimeout(resolve, 0));   // release the event loop

        if (_updates.length > 0){
            let _brief = [];    // only for debugging purposes

            // set the priority for all updates
            let _recents = this._filter_recents(this._pending.concat(_updates));
            for(let _update of _updates){
                _update['__priority'] = this._priority(_update, _recents);

                // reprioritize inserted entries (in range [-9 .. -1])
                if (_update['_actn'] == 'do_insert'){
                    _update['__priority'] -= 10.0;
                }

                if (!(_update['table'] in _brief)){
                    _brief[_update['table']] = 0;
                }
                _brief[_update['table']]++;
            }
    
            _updates.sort((a, b) => {
                return (a['__priority'] - b['__priority']);
            });

            // merge the udpates in the pending list
            if (this._pending.length > 0){
                let _pndmap = new Map();
                for(let _pending of this._pending){
                    _pndmap.set(this._ChangeKey(_pending), _pending);
                }
    
                for(let _idx = _updates.length - 1; _idx >= 0; _idx--){
                    let _update = _updates[_idx];
                    let _pnditm = _pndmap.get(this._ChangeKey(_update));
                    let _assign = null;   

                    if (_pnditm){
                        _assign = this._MergeChange(_update, _pnditm);      // merge this entry;    
                        _pndmap.set(this._ChangeKey(_update), _assign);     // replace this entry   
                        _updates.splice(_idx, 1);                           // remove from updates
                    }
                }
    
                this._pending = [..._pndmap.values()];
            }

            // enqueue all other updates (not in pending list)
            this.Enqueue(_updates);

            // release the synchronous entries      
            if (_updates.length > 0){
                this.sync.Clock.ClockEnable('synchronous', this.StageReady);
                while(_updates.length > 0){
                    if ((!_synchronous) && (this.Stage > 3)) {
                        break;
                    }

                    this.Dequeue(_updates.shift());
                }
                this.sync.Clock.ClockEnable('synchronous', true);
            }
            
            if (_updates.length > 0){
                this._pending = this._pending.concat(_updates);

                this._pending.sort((a, b) => {
                    return (a['__priority'] - b['__priority']);
                });
            }

            this.ProcessQueue();
        }
    }
}

/************************************/
/* CONNECTED INFO                   */
/************************************/

class ConnectedServer {
    private _isconnected = false;

    private _doCancelUrl = 'model/conn/cancel.php';
    private _doCreateUrl = 'model/conn/create.php';
    private _doDeleteUrl = 'model/conn/delete.php';
    private _doUpdateUrl = 'model/conn/update.php';

    private _triggers = null;

    private _trgrcust = {};

    AddTriggers(triggers){
        for(let _key in triggers){
            this._trgrcust[_key] = triggers[_key];
        }
    }

    DelTriggers(triggers){
        for(let _key in triggers){
            delete(this._trgrcust[_key]);
        }
    }
    
    get Triggers(){
        if (this.sync.Session.qrcode && this.sync.Session.qrcode.objid){
            let _triggers = {
                QRCODE: this.sync.Session.qrcode.objid,
                PLACE: this.sync.Session.qrcode.place.objid,
            };

            return Object.assign({}, this._trgrcust, _triggers);
        }

        if (this.sync.Session.place && this.sync.Session.place.objid){
            let _triggers = {
                PLACE: this.sync.Session.place.objid,
                USER: []    // will be completed below
            }

            // include the place user updates
            if (this.sync.Session.place.user){
                _triggers['USER'].push(this.sync.Session.place.user.objid);
            }

            // include the place waiter users updates
            for(let _waiter of this.sync.Session.place.waiters){
                if (_waiter.IsValid){
                    _triggers['USER'].push(_waiter.user.objid);
                }
            }

            return Object.assign({}, this._trgrcust, _triggers);
        }

        if (this.sync.Session.user && this.sync.Session.user.objid){
            let _triggers = {
                USER: this.sync.Session.user.objid
            }

            return Object.assign({}, this._trgrcust, _triggers);
        }

        // default: create a connection with the session
        return {
            SESSION: this.sync.Session.session
        };
    }

    private _onExpired = new Subject<any>();
    private OnExpired = this._onExpired.asObservable();

    private _expired_subscription = null;
    constructor(private sync: syncService){
        this._expired_subscription = this.OnExpired.subscribe(
        data => {
            this.sync._OnSessionExpired();
        });
    }

    OnDestroy(){
        if (this._expired_subscription){
            this._expired_subscription.unsubscribe();
            this._expired_subscription = null;
        }
    }

    CreateConnection(){
        return new Promise((resolve) => {
            if (!this._isconnected && this.Triggers){
                this._isconnected = true;

                this.sync.DoRequest(this._doCreateUrl, null, this.Triggers, false)
                .then(data => {
                    if (data['errorcode'] == 0){
                        this._triggers = this.Triggers;
                        console.info("[CONNECTED] Connected to server (id: " + data['connection'] + ")");
                    }
                    else {
                        if ((data['errorcode'] == 2) || (data['errorcode'] == 3)){
                            this._onExpired.next();
                        }
                        else {
                            console.error("[CONNECTED] Error [" + data['errorcode'] + "] creating connection");
                        }
                    }

                    this._isconnected = (data['errorcode'] == 0);
                    resolve(this._isconnected);
                }, error => {
                    if (error.status != 0){
                        console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doCreateUrl + "'");
                    }
    
                    resolve(false);
                });       
            }
            else {
                resolve(false);
            }
        });    
    }

    DeleteConnection(){
        return new Promise((resolve) => {
            if (this._isconnected){
                this._isconnected = false;

                this.sync.DoRequest(this._doDeleteUrl, null, null, false)
                .then(data => {
                    if (data['errorcode'] == 0){
                        console.info("[CONNECTED] Disconnected from service")
                    }
                    else {
                        console.error("[CONNECTED] Error [" + data['errorcode'] + "] deleting connection");
                    }

                    this._triggers = null;
                    
                    this._isconnected = !((data['errorcode'] == 0) || (data['errorcode'] == 2))
                    resolve(!this._isconnected);
                }, error => {
                    if (error.status != 0){
                        console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doDeleteUrl + "'");
                    }
    
                    resolve(false);
                });        
            }
            else {
                resolve(false);
            }
        });
    }

    private _UpdateConnection(timestamp){
        return new Promise((resolve) => {
            let _toupdate = (JSON.stringify(this._triggers) != JSON.stringify(this.Triggers));
            if (this.sync._started && this._isconnected && _toupdate){
                this.sync.DoRequest(this._doUpdateUrl, { timestamp: timestamp }, this.Triggers, false)
                .then(data => {
                    if (data['errorcode'] == 0){
                        this._triggers = this.Triggers;
                        console.info("[CONNECTED] Server configuration updated..");
                        resolve(true);
                    }
                    else {
                        console.error("[CONNECTED] Error [" + data['errorcode'] + "] updating service");
                    }
                }, error => {
                    if (error.status != 0){
                        console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doUpdateUrl + "'");
                    }
    
                    resolve(false);
                });        
            }
            else {
                resolve(!_toupdate && this._isconnected);   // nothing to update
            }
        });    
    }

    async UpdateConnection(timestamp){
        if (this.sync._started){
            if (!this.Triggers){
                await this.DeleteConnection();
            }
    
            if (!this._isconnected){
                await this.CreateConnection();
            }
        }

        return this._UpdateConnection(timestamp);     // both connected and configured: do the update
    }

    CancelRefresh(){
        return new Promise((resolve) => {
            if (this._isconnected){
                this.sync.DoRequest(this._doCancelUrl, null, null, false)
                .then(data => {
                    let _success = (data['errorcode'] == 0);
                    if (!_success){
                        console.error("[CONNECTED] Error [" + data['errorcode'] + "] sending cancelation");
                    }

                    resolve(_success);
                }, error => {
                    if (error.status != 0){
                        console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doCancelUrl + "'");
                    }

                    resolve(false);
                });
            }  

            resolve(false)  // not connected
        });
    }
}

/************************************/
/* SYNCHRONIZATION SERVICE          */
/************************************/

@Injectable()
export class syncService implements OnDestroy {
    private _doRefreshUrl = 'model/synchronize.php';

    /*
        LOGIN: Access to POS application (owner / waiter)
        GUEST: Access through QR code in table
        DEVRY: Access through web for home delivery 
    */

    private _access: string = null;     // 'LOGIN', 'GUEST', 'DEVRY' 
    get Access() {
        return this._access;
    }

    set Access(value){
        this._access = value;
        console.info("[INITIALIZE] Setting access mode to: '"  + this._access + "'")
    }

    private _serverversion: any = null;
    get ServerVersion(){
        return this._serverversion;
    }

    SetServerVersion(version, restored){
        return new Promise((resolve) => {
            if (this._serverversion != version){
                this.store.CheckObsoleteData(version, restored).then(
                data => {
                    this._serverversion = version;
                    resolve(true);  // updated
                });
            }
            else {
                resolve(false);     // not updated
            }    
        })
    }

    private _session: Session = new Session();
    get Session() : Session {
        return this._session;
    }

    get _started(){
        return (this.Session?.session != null);
    }

    private _timestamp = 0;
    private _reference = 0;

    private _clock: MainClock = null;
    get Clock(){
        if (!this._clock){
            this._clock = new MainClock();
        }
        return this._clock;
    }

    private _pending: Pending = null;
    private get Pending(){
        if (!this._pending){
            this._pending = new Pending(this);
        }
        return this._pending;
    }

    get LoadStage(){
        return this.Pending.Stage;
    }

    private _stage_target = null;
    get LoadTarget(){
        if (this._stage_target == null){
            let _target = null;

            if (!_target && this._session.place && this._session.place.objid){
                _target = 'PLACE';
            }    
    
            if (!_target && this._session.qrcode && this._session.qrcode.objid){
                _target = 'QRCODE';
            }    
    
            if (!_target && this._session.device && this._session.session){
                _target = 'SESSION';
            }  

            this._stage_target = _target;
        }
        return this._stage_target;
    }

    private _connected: ConnectedServer = null;
    get Connected(){
        if (!this._connected){
            this._connected = new ConnectedServer(this);
        }
        return this._connected;
    }

    /**********************************/
    /* PUBLIC OBSERVERS               */
    /**********************************/
   
    get LoadPercent(){
        return this.Pending.LoadPercent;
    }

    get OnRefreshStage(){
        return this.Pending.OnRefreshStage;
    }

    get OnRefreshPercent(){
        return this.Pending.OnRefreshPercent;
    }

    /**********************************/
    /* BACKGROUND REFRESH CONTROL     */
    /**********************************/

    private _refresh_timeout = null;
    private async _initializeRefresh(){
        if (this._rfrstatus == 'EXPIRED'){
            this._rfrstatus = 'STOPPED';
        }

        this._timestamp = 0;

        console.info("[INITIALIZE] Connected to data service");
        await this._resumeRefresh();
    }

    private _repeatRefresh(){
        if (this._refresh_timeout){
            clearTimeout(this._refresh_timeout);
            this._refresh_timeout = null;
        }

        this._refresh_timeout = setTimeout(() => {
            if (this._rfrstatus == 'RUNNING') {
                if (!this._onrefresh){
                    this._DoRefresh().then(
                    data => {
                        this._repeatRefresh();
                    });        
                }
                else {
                    this._repeatRefresh();
                }
            }
        }, AppConstants.MainRefresh);
    }

    private _rfrstatus = 'STOPPED'; 
    private _onrefresh = false;
    private async _resumeRefresh(){
        let _expired = (this._rfrstatus == 'EXPIRED');
        if (!_expired){
            if (this._rfrstatus == 'RUNNING'){
                console.warn("[INITIALIZE] Already running!");
                return;
            }
    
            this._rfrstatus = 'RUNNING';
            await this._DoRefresh(true);
            if (this._rfrstatus != 'RUNNING'){
                return;     // someone stopped the refresh
            }
    
            this._repeatRefresh();    
        }
    }

    private async _pauseRefresh(){
        let _expired = (this._rfrstatus == 'EXPIRED');
        if (!_expired){
            this._rfrstatus = 'STOPPED';
            await this._CancelOngoing();    
        }
    }

    private async _finalizeRefresh(){
        let _expired = (this._rfrstatus == 'EXPIRED');
        
        await this._pauseRefresh();
        if (!_expired && this._started){
            await this._DoRefresh();        
        }
    }
    
    /**********************************/
    /* REFRESH OPERATION              */
    /**********************************/

    private _onExpired = new Subject<any>();
    public OnExpired = this._onExpired.asObservable();

    private _expired_notified = false;
    async _OnSessionExpired(){
        if (this._expired_notified){
            return;
        }

        if (this._rfrstatus != 'EXPIRED'){
            this._expired_notified = true;

            await this._pauseRefresh();
            this._rfrstatus = 'EXPIRED';

            const alert = this.alertCtrl.alert({
                header: this.lang.tr('@user_session_expired_title'),
                message: this.lang.tr('@user_session_expired_message'),
                backdropDismiss: true,
                buttons: [{
                    text: this.lang.tr('@ok'),
                    handler: () => {
                        setTimeout(() => {
                            alert.dismiss(true);
                        }, 100);
        
                        return false;
                    }
                }]
            });
            
            alert.onDidDismiss().then(
            data => {
                this._expired_notified = false;    
                this._onExpired.next();
            });

            await alert.present();
        }
    }

    /********************************/
    /* SERVER REFRESH               */
    /********************************/

    private _serverreference = null;    // server elapsed time reference
    
    private set serverreference(value){
        this._serverreference = value;
    }

    private get serverreference(){
        let _ms = performance.now() - this._serverreference;
        return Math.round(_ms / 1000);
    }

    private _uuids_done = new Map();           // relation between the uuids and the objids

    private _resolvedobjid(_uuid){
        return this._uuids_done.get(_uuid) || null;
    }

    private _onChange = new Subject<any>();     // change recovered from server
    public OnChange = this._onChange.asObservable();

    private _onReload = new Subject<any>();     // change recovered from synchanges
    public OnReload = this._onReload.asObservable();

    public DoRefresh(change){
        this._onChange.next(change);

        // change is applied: remove from resolved map
        if (('_uuid' in change) && (this._uuids_done.has(change['_uuid']))){
            this._uuids_done.delete(change['_uuid']);
        }
    }

    private _syncchanges: Array <SyncChange> = [];
    private _waitchanges: Array <SyncChange> = [];

    private _resolve_syncchanges(){
        let _serverreference = this._reference + this.serverreference;  // adjust local interval to server time

        for(let _change of this._syncchanges){
            _change['reference'] = _serverreference;

            if (_change.entrynfo['_actn'] == "do_insert"){
                let _objid = this._resolvedobjid(_change.entrynfo['_uuid']);
                if (_objid){
                    _change.entrynfo['objid'] = _objid;
                    _change.entrynfo['_actn'] = "do_update";
                }
            }
        }
    }

    private _resolve_waitchange(_change){
        if ('_uuid' in _change.relation){
            let _objid = this._resolvedobjid(_change.relation['_uuid']);
            if (_objid){
                _change.relation['objid'] = _objid;
            }
        }

        for(let _requires in _change.requires){
            this._resolve_waitchange(_change.requires[_requires]);
        }
    }

    private _resolve_waitchanges(updates){
        // keep the resolved items in the _uuid list
        for (let _update of updates){
            if ('_uuid' in _update){
                this._uuids_done.set(_update['_uuid'], _update['objid']);
            }
        }

        // resolve any waiting change to the final value
        for(let _change of this._waitchanges){
            if (_change.entrynfo['_actn'] == "do_insert"){
                let _objid = this._resolvedobjid(_change.entrynfo['_uuid']);
                if (_objid){
                    _change.entrynfo['objid'] = _objid;
                    _change.entrynfo['_actn'] = "do_update";
                }
            }

            this._resolve_waitchange(_change);

            // skip update if change is still pending
            for(let _idx = updates.length - 1; _idx >= 0; _idx--){
                let _update = updates[_idx];
                if (_change.entrynfo['table'] == _update['table']){
                    let _skip = false;

                    if (('_uuid' in _change.entrynfo) && (_change.entrynfo['_uuid'] == _update['_uuid'])){
                        _skip = true;   // found change by _uuid
                    }

                    if (('objid' in _change.entrynfo) && (_change.entrynfo['objid'] == _update['objid'])){
                        _skip = true;   // found change by objid
                    }

                    if (_skip){
                        updates.splice(_idx, 1);
                    }
                }
            }
        }

        // set the new syncchanges set
        this._syncchanges = this._waitchanges;        
        this._waitchanges = [];
    }

    private _append_waitchanges(){
        this.store.SetChanges(this._syncchanges);  

        if (this._waitchanges.length > 0){
            this.CommitChanges(this._waitchanges, false);
        }

        this._waitchanges = [];
    }

    get Changes(){
        return this._syncchanges;
    }

    private _refreshCompleted = new Subject<any> ();
    public OnRefreshCompleted = this._refreshCompleted.asObservable();
    private _commitCompleted = new Subject <any> ();
    public OnCommitCompleted = this._commitCompleted.asObservable();

    private async _CacheLoad(){
        this._stage_target = null;     // stablish the cache target for this load / save operation

        if (this._timestamp == 0){
            let _data = null;

            let _subscription = this.Pending.OnRefreshStage.subscribe(
            stage => {
                if (this.LoadTarget != 'SESSION'){
                    if (stage > 6){     // wait for main objects to be loaded
                        _subscription.unsubscribe();
    
                        // add the stored changes waiting to be synchronized
                        for (let _change of this._syncchanges){
                            this._onReload.next(_change);
                        }
                    }    
                }
            });
    
            switch(this.LoadTarget){
                case 'SESSION':
                    _data = await this.store.LoadSessionData(this._session.device, this._session.session);
                    break;

                case 'PLACE':
                    _data = await this.store.LoadPlaceData(this._session.place.objid);
                    break;
            }

            if (_data){
                console.info("[SYNCHRONIZE] Loaded from (" + this.LoadTarget + ") cache [" + _data['updates'].length + "] entries");
                this._timestamp = _data['timestamp']; 
                console.info("[SYNCHRONIZE] updates (" + this.LoadTarget + ") will be resumed since: " + this._timestamp);

                if (this._pending){     // is destroyed??
                    this._pending.Concat(_data['updates'], false);
                }    

                this._refreshCompleted.next();
            }
        }

        let _getparams = {
            access: this._access,           // access mode
            timestamp: this._timestamp,     // last queried timestamp
            target: this.LoadTarget         // the loaded target
        };

        if (this._session.place && this._session.place.objid){
            _getparams['place'] = this._session.place.objid;
        }

        if (this._session.qrcode && this._session.qrcode.objid){
            _getparams['table'] = this._session.qrcode.objid;
        }

        return _getparams;
    }

    private async _CacheSave(data){
        if (!data){
            return;     // no data provided 
        }

        switch(data['target'] || this.LoadTarget){
            case 'SESSION':
                return await this.store.SaveSessionData(this._session.device, this._session.session, data['updates'], data['timestamp']);
            case 'PLACE':
                return await this.store.SavePlaceData(this._session.place.objid, data['updates'], data['timestamp']);
        }
    }

    private _lastchanges = null;

    private _WriteSyncChanges(){
        if ((this._lastchanges === null) || (this._lastchanges != this._syncchanges.length)){
            this._resolve_syncchanges();    // add server reference value to changes 

            if (this._syncchanges.length > 0){
                console.info("[STORAGE]: Updating the changeset with " + this._syncchanges.length + " changes.");
            }
        }

        this._lastchanges = this._syncchanges.length;   // reduce number of logs (only on size changed)
    }

    private _toreset = false;

    private _DoRefresh(_reset = false, _synchronous = false){
        let _connected = true;
        
        return new Promise(async (resolve) => {
            if (!await this.Connected.UpdateConnection(this._timestamp) && (this._timestamp != 0)){
                _connected = false;     // not connected (only full refresh)
            }
    
            if (_reset){    // wait until the next request is sent
                this._toreset = true;   
            }

            if (this._session == null){
                resolve(false);  // session has bee released
                return; 
            }

            if (!this._started || this._onrefresh){
                resolve(!this._started);   // already refreshing (true) / session released (false)
            }
            else {
                this._onrefresh = _connected;
                try {
                    if (this._toreset){
                        this._toreset = false;
                        this.Pending.Reset();            
                        this._timestamp = 0;
                    }

                    let _getparams = await this._CacheLoad();    // recovers localy stored data
                    if (!_connected){
                        if (this._waitchanges.length > 0){
                            // if any waitchange is stored when connection is lost, recover it here
                            for(let _waitchange of this._waitchanges){
                                this._syncchanges.push(_waitchange);
                            }

                            this._waitchanges = [];
                        }

                        resolve(true);
                    }
                    else {  // we are connected. Synchronize with the server
                        this._WriteSyncChanges();

                        this.DoRequest(this._doRefreshUrl, _getparams, this._syncchanges, false)
                        .then(async data => {
                            if (data['updated']){   // server has been updated
                                this.store.ClearChanges();
                            }

                            let success = (data['errorcode'] == 0);  
                            if (success){
                                if (this._syncchanges.length > 0){
                                    console.info("[SYNCHRONIZE] Writing [" + this._syncchanges.length + "] entries to server");

                                    let _subscription = this.OnRefreshCompleted.subscribe(
                                    data => {
                                        _subscription.unsubscribe();
                                        this._commitCompleted.next();
                                    });
                                }
                                else {
                                    if (data['updates'].length > 0){
                                        console.info("[SYNCHRONIZE] Updating [" + data['updates'].length + "] entries from server");
                                    }    
                                }

                                this._resolve_waitchanges(data['updates']);

                                if (data['timestamp'] != 0){
                                    await this._CacheSave(data);     // update localy stored data
                                }
                                
                                this._timestamp = data['timestamp'];
                                this._reference = data['reference'];

                                if (this._pending){     // is destroyed??
                                    this._pending.Concat(data['updates'], _synchronous);
                                }

                                if (data['expired']){
                                    console.warn("[SERVICE] Client connection information was lost or expired: Reconnecting");
                                    this._connected = null;         // generate the connection information
                                }
                            }
                            else {
                                if (data['errorcode'] == 2) {
                                    this._OnSessionExpired();
                                }
                                else {
                                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doRefreshUrl + "'");
                                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                                }
                            }
    
                            this.serverreference = performance.now();    
                            this._onrefresh = false;
                            this._refreshCompleted.next();
    
                            resolve(success);
                        }, error => {
                            if (error.status != 0){
                                console.error("[REQUEST] Error [" + error.status + "] in http request '" + this._doRefreshUrl + "'");
                            }
    
                            this._onrefresh = false;
                            this._refreshCompleted.next();
                            this._append_waitchanges();
    
                            resolve(false);
                        });
                    }
                }
                catch(error){
                    console.error(error);

                    this._onrefresh = false;
                    this._refreshCompleted.next();
                    this._append_waitchanges();

                    resolve(false);
                }                
            }
        });
    }

    private _indexOfRelation(_require, _changes){
        for(let _idx = 0; _idx < _changes.length; _idx++){
            let _relation = _changes[_idx].relation;
            if ('_uuid' in _relation){
                if ((_relation['table'] == _require['table']) && (_relation['_uuid'] == _require['_uuid'])){
                    return _idx;
                }
            }
        }

        return -1;  // relation not found
    }

    private CheckDepends(a, b){
        let _relation = b.relation;

        for(let _dfield in a.requires){
            // check if a is direct dependecy of b
            let _requires = a.requires[_dfield].relation;
            if ('_uuid' in _requires){  
                if ((_relation['table'] == _requires['table']) && (_relation['_uuid'] == _requires['_uuid'])){
                    return true;    // a is direct dependency of b
                }
            }

            // check if a is indirect dependency of b
            let _indirect = a.requires[_dfield].requires;
            for (let _ifield in _indirect){
                if (this.CheckDepends(_indirect[_ifield], b)){
                    return true;    // a is indirect dependency of b
                }    
            }
        }

        return false;    // a is not a dependency of b
    }

    private SortChanges(_changes){
        let _pvt = 0;
        while(_pvt < _changes.length){
            let _hasDepends = false;
            for(let _idx = _pvt+1; _idx < _changes.length; _idx++){
                _hasDepends = this.CheckDepends(_changes[_pvt], _changes[_idx]);
                if (_hasDepends){
                    let _swap = _changes[_idx];
                    _changes[_idx] = _changes[_pvt];
                    _changes[_pvt] = _swap;

                    break;  // dependecy found (swapped with current)
                }
            }

            if (!_hasDepends){
                _pvt++;     // pivot has no dependencies, move to next
            }
        }

        // check for missing relations
        for(let _idx=0; _idx < _changes.length; _idx++){
            let _change = _changes[_idx];
            for(let _field in _change.requires){
                let _require = _change.requires[_field].relation;
                if (!('objid' in _require) || (!_require.objid)){
                    let _message = null

                    let _pos =  this._indexOfRelation(_require, _changes);
                    if (_pos == -1){
                        _message = "@commit_error_relation_not_found";
                    }

                    if (_pos > _idx){
                        _message = "@commit_error_relation_bad_order";
                    }

                    if (_message){
                        let _error = {
                            missing: _change,
                            require: _require,
                            changes: _changes.slice(0),
                            message: _message
                        };

                        console.error("[COMMIT ERROR]: ", { error: _error, json: JSON.stringify(_error) });
                        throw(_error);    
                    }
                }
            }
        }
    }

    SortUpdates(_updates){
        return this.Pending.SortUpdates(_updates);
    }

    private _IndexOfChange(needle: SyncChange, haystack: Array <SyncChange>){
        for(let _idx = 0; _idx < haystack.length; _idx++) {
            let _change = haystack[_idx];
            if (needle.entrynfo['table'] == _change.entrynfo['table']){
                if ('objid' in needle.entrynfo){
                    if (needle.entrynfo['objid'] == _change.entrynfo['objid']){
                        return _idx;    // same table / objid
                    }
                }

                if ('_uuid' in needle.entrynfo) {
                    if(needle.entrynfo['_uuid'] == _change.entrynfo['_uuid']){
                        return _idx;    // same table / _uuid
                    }
                }
            }
        }

        return -1;   // change not found
    }

    private RollbackChanges(target, changes){
        for(let _change of changes){
            let _idx = target.indexOf(_change);
            if (_idx != -1){
                target.splice(_idx, 1);
            }
        }
    }
  
    private _ForceRefresh(){
        return new Promise(async (resolve) => {
            if (this._onrefresh){
                let _subscription = this.OnRefreshCompleted.subscribe(
                async data => {
                    _subscription.unsubscribe();
                    await this._DoRefresh(false, true);
                    resolve(true)
                })
            }
            else {
                await this._DoRefresh(false, true);
                resolve(true);
            }
        });
    }

    private _CancelOngoing(){
        return new Promise(async (resolve) => {
            if (this._onrefresh){
                let _wait = performance.now();
                
                let _subscription = this.OnRefreshCompleted.subscribe(
                async data => {
                    _subscription.unsubscribe();
                    resolve(true);

                    console.info("[COMMIT] Ongoing refresh returned in " + (performance.now() - _wait).toFixed(2) + " ms..");
                })

                this.Connected.CancelRefresh();
            }
            else {  // no request is ongoing
                this.Connected.CancelRefresh().then(
                data => {
                    resolve(true);  
                });
            }
        });
    }

    private _logcommits = false;
    async CommitChanges(changes: Array <SyncChange>, force: boolean){
        let _target = (this._onrefresh) ? this._waitchanges : this._syncchanges;

        console.info("[COMMIT] commiting " + changes.length + " entries (now = " + force + ")");
        for(let change of changes){
            if (this._logcommits){
                console.info("[COMMIT] on table '" + change.entrynfo['table'] + "' (" + (this._onrefresh ? 'synchanges': 'waitchanges') +  "):", change.entrynfo);
            }

            // replace any previous changes over this object
            let _idx = this._IndexOfChange(change, _target);
            if (_idx != -1){    // update
                if (this._logcommits){
                    console.info("[COMMIT] remove previous '" + _target[_idx].entrynfo['table'] + "':", _target[_idx].entrynfo);
                }
                _target.splice(_idx, 1);
            }
            
            _target.push(change);
        }

        // sort the changes on the target (synchanges or waitchanges) list
        if (changes.length > 0){
            try {
                this.SortChanges(_target);
            }
            catch(e){
                this.RollbackChanges(_target, changes);
                this.toast.ShowAlert('danger', this.lang.tr(e.message));
            }            
        }

        if (changes.length > 0){
            let _cancel = performance.now();
            await this._CancelOngoing();
            console.info("[COMMIT] Cancel ongoing completed in: " + (performance.now() - _cancel).toFixed(2) + " ms");

            if (force){
                let _forced = performance.now();
                await this._ForceRefresh();
                console.info("[COMMIT] Forced commit completed in: " + (performance.now() - _forced).toFixed(2) + " ms");
            }
        }
    }

    /**********************************/
    /* STARTS HERE                    */
    /**********************************/

    private _doGetDeviceUrl = 'device/getuuid.php';
    private _doSetDeviceUrl = 'device/setuuid.php';
    private _doGetTicketUrl = 'device/getticket.php';

    /*
        HOW DOES DEVICE ID WORKS?

        On startup a device-id is calculated. This value will remain until a browser update.
        On the GetDeviceId() function will try to get the stored value un localStorage
            If not found, we'll request the server for the last device-id stored for the current calculated device-id
        Well write the final device-id (stored in cache, stored in server or calculated one) in the localStorage
        Well update the server information with the current device-id and the calculated one

        This way, if the local cache is removed, then the device-id will be recovered from the server, based on the last update
        If the calculated value is updated at the same time the localStorage is cleared, the device-id will be lost!!
        In other circumstances, the device-id can be recovered.
    */

    private _device_promise = null;
    get DeviceId(){
        if (this._session.device){
            return this._session.device;
        }

        if (!this._device_promise){
            this._device_promise = this.GetVisitorId();
        }

        return this._device_promise;
    }

    private async GetDeviceId(){
        console.info("[DEVICE ID]", this._session.device);

        let _store = false, _server = false;   

        let _persistent = await this.store.GetDevice();
        if (_persistent){     // found in local storage
            _store = true;      
        }

        if (!_persistent){    // uuid not found in store: recover from server
            let _data = (await this.DoRequest(this._doGetDeviceUrl, { device: this._session.device }));
            if (_data){
                _server = (_data['errorcode'] == 0);   // found in server 
                if (_server){
                    _persistent = _data['uuid'];
                }
                else {
                    if (_data['errorcode'] != 3){    // not found (this is not an error)
                        console.error("[DEVICE ID] GET returned errorcode [" + _data['errorcode'] + "]");
                    }
                }
            }
        }

        if (!_persistent){      // still not found: use calculated
            _persistent = this._session.device;
        }

        // update the uuid and calc values in the server
        this.DoRequest(this._doSetDeviceUrl, { calc: this._session.device, uuid: _persistent }).then(
        _data => {
            if ((_data['errorcode'] != 0)){
                console.error("[DEVICE ID] SET (uuid) returned errorcode [" + _data['errorcode'] + "]");
            }
        });        

        this._session.device = _persistent;
        console.info("[STORAGE ID]", this._session.device);

        if (!_store) {  // update device id in storage
            this.store.SetDevice(_persistent).then(
            _data => {
                console.info("[DEVICE ID] Device ID stored!"); 
            });
        }
    }

    private async GetVisitorId(){
        let _browserid = "";    // https://stackoverflow.com/questions/27247806/generate-unique-id-for-each-device/27249628

        _browserid += window.navigator.mimeTypes.length.toString();
        _browserid += window.navigator.userAgent.replace(/\D+/g, '');
        _browserid += window.navigator.plugins.length;
        _browserid += window.screen.height.toString() || '';
        _browserid += window.screen.width.toString() || '';
        _browserid += window.screen.pixelDepth.toString() || '';

        _browserid = "BROWSER" + new CRC32(_browserid).value.toString(16);

        this._session.device = this.device.uuid;
        if (!this._session.device){
            let _promise = FingerprintJS.load();

            try {
                let _fingerprint = await _promise;
                if (_fingerprint){
                    let _result = await _fingerprint.get();
                    if (_result){
                        this._session.device = "VISITOR [" + _result.visitorId + " - " + _browserid + "]";
                    }
                }
            } 
            catch (error) {
                console.error("[FINGERPRINT] Returned error (falling back to unsecure method)", error );
                if (!this._session.device){     
                    this._session.device = _browserid;
                }    
            }    
        }

        await this.GetDeviceId();
    }

    private async GetLastServed(place){
        let _serial = this._session.serial;
        
        // check if data is available in the browser cache 
        if (!place || !place.objid){
            return;
        }

        // recover data from the server
        try {
            let _data = (await this.DoRequest(this._doGetTicketUrl, { place: place.objid, serial: _serial }));
            if (_data){
                let _success = (_data['errorcode'] == 0);
                if (_success){
                    if (_data['last']){
                        this.store.SetLastTicket(place, _serial, _data['last']);    
                    }
                }
                else {
                    console.error("[DEVICE TICKET] returned errorcode [" + _data['errorcode'] + "]");
                }                
            }    
        }
        catch(error){
            console.error("[DEVICE TICKET] Server request failed.");
        }
    }

    private _stageReady = new Subject<any>();
    public OnStageReady = this._stageReady.asObservable();

    get IsStageReady(){
        if (this.LoadTarget != 'SESSION'){
            return (this.LoadStage > 6);
        }
        else {  // check if new place
            if (this._session.place && !this._session.place.objid) {
                return this.LoadStage == 9;
            }
        }

        return false;
    }

    private _stage_subscription = null;
    private _stageready_notified = false;

    private _onDeviceIdReady = new Subject<any>();
    public OnDeviceIdReady = this._onDeviceIdReady.asObservable();

    constructor(private http: httpService, private lang: languageService, private alertCtrl: alertService, private toast: toastService, private store: storeService, private device: Device){
        this.GetVisitorId().then(   // obtain a solid, unique device identifier
        data => {
            this._onDeviceIdReady.next();
        });     

        this._stage_subscription = this.OnRefreshStage.subscribe(
        stage => {
            console.info("[INITIALIZE] Loading stage [" + this.LoadTarget + " - " + this.LoadStage + "] items..");
            if (this.IsStageReady && !this._stageready_notified){
                this._stageready_notified = true;
                console.info("[INITIALIZE] Main load stage completed.");
                this._stageReady.next();
            }
        });

        window.addEventListener("unload", async event => {
            console.info("** UNLOADING PAGE **");

            let unloaddata = JSON.stringify({
                session: this._session.session,
                place: this._session.place? this._session.place.objid : null,
                table: this._session.qrcode? this._session.qrcode.objid : null
            })

            if (this._session.session){
                navigator.sendBeacon(AppConstants.baseURL + '/model/conn/unload.php', unloaddata);
            }
        });
    }

    ngOnDestroy(){
        this._finalizeRefresh();

        if (this._stage_subscription){
            this._stage_subscription.unsubscribe();
            this._stage_subscription = null;
        }

        if (this._pending){
            this._pending.OnDestroy();
            this._pending = null;        
        }

        if (this._connected){
            this._connected.OnDestroy();
            this._connected = null;
        }
    }
    
    async Start(session){
        await this.DeviceId;    // wait until the device identifier  is ready

        console.info("[STORAGE] Searching for pending changes..");
        let _changes = await this.store.GetChanges() as Array <SyncChange>;
        if (_changes){
            console.warn("[STORAGE] Found " + _changes.length + " changes from previous sessions");
            this._syncchanges = _changes;
        }
        else {
            console.info("[STORAGE] No changes from previous sessions (up to date)");
        }

        if (session){
            this._session.session = session;
            await this.Connected.CreateConnection();

            this._initializeRefresh();
        }
        else {
            this._finalizeRefresh();
        }
    }

    async Stop(){
        console.info("[FINALIZE] Closing data service connection");
        await this.Connected.DeleteConnection();
        this._session.session = null;

        await this._finalizeRefresh();
        await this.store.ReleaseToken();
        console.info("[FINALIZE] Syncronization is stopped");
    }

    private _onReleased = new Subject<any>();
    public OnReleased = this._onReleased.asObservable();

    async Release(){
        console.info("[FINALIZE] Syncronization is released");
        this._session.Clear();
        this._onReleased.next();
    }

    async ResetStage(){
        await this._pauseRefresh();
        this._pending.Stage = 0;
    }

    async SetToken(session, token){
        this.Session.token = token;

        this.store.SetToken(token);
        let _token = await this.store.RecoverToken();
        if (_token){    // token available: update the session information
            _token.session = session
        }
        else {          // no token: initialize the token data
            _token = {
                session: session,
                placeid: null,
                orderid: null
            }
        }

        await this.store.UpdateToken(_token);
    }

    async SetUser(user){
        this.Session.user = user;
    }

    async SetPlace(place){
        await this._pauseRefresh();

        // notify only when place is available
        this._pending.DoNotify = (place != null);   

        // update the place in the session token
        if (this.Session.place != place){
            let _token = await this.store.RecoverToken();
            if (_token){
                _token.placeid = place ? place.objref : null;
            }
            await this.store.UpdateToken(_token);          
        }

        this._timestamp = 0;

        this.Session.place = place;
        this.Session.qrcode = null;
    
        if (place){
            // get the last served ticket numbers
            await this.GetLastServed(place);        

            // simulate full load on new place
            if (!place.objid){
                this.Pending.Stage = 9;
            }
        }

        this._resumeRefresh();
    }

    async SetTable(qrcode){
        await this._pauseRefresh();

        this.Pending.Stage = 0;
        this._pending.DoNotify = (qrcode != null);   // notify only when table is available

        this._timestamp = 0;

        this.Session.place = null;
        this.Session.qrcode = qrcode;

        this._resumeRefresh();        
    }

    async SetTicket(ticket){
        let _token = await this.store.RecoverToken();
        if (_token){
            _token.orderid = ticket ? ticket.objref : null;
        }
        await this.store.UpdateToken(_token);          
    }

    /**********************************/
    /* GENERIC SERVER REQUEST         */
    /**********************************/

    DoRequest(url, params = null, post = null, showtoast = true, headers = null, responseType = null, serializer = null, timeout = null){
        let _getparams = "?device=" + this._session.device + "&session=" + this._session.session;

        if (!params || !('lang' in params)){   // language can be overriden in params
            _getparams += "&lang=" + this.lang.GetLanguage(true);
        }

        if (params){
            for(let _param in params){
                _getparams += "&" + _param + "=" + params[_param];
            }    
        }
        
        let _request = null;
        if (post){
            _request = this.http.post(AppConstants.baseURL + url + _getparams, post, (headers) ? headers : httpService.headers, responseType, serializer, timeout);
        }
        else {
            _request = this.http.get(AppConstants.baseURL + url + _getparams, true, (headers) ? headers : httpService.headers, responseType, timeout);
        }

        return new Promise(async (resolve, reject) => {
            let _subscription = _request.subscribe(
            data => {
                (data !== null) ? resolve(data) : reject("no response");
            },
            error => {
                reject(error);

                if (showtoast){
                    if (error.status != 0){
                        this.toast.ShowAlert('danger', this.lang.tr('@http_request_error', [ error.status ]));
                        console.error(this.lang.tr('@http_request_error', [ error.status ]));                        
                    }
                    else {
                        console.error("[HTTP] status = 0 (you are offline)");
                    }
                }
            },
            () => {
                _subscription.unsubscribe();
            });        
        });
    }
}
