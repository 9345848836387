import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConstants} from '@app/app.constants';
import { httpService } from '@app/modules/common/http';
import { languageService } from '@app/modules/common/language';
import { toastService} from '@app/modules/common/toast';

@Injectable()
export class uploadService {
    private uploadUrl = AppConstants.baseURL + 'common/upload.php';
    
    constructor(private http: httpService, private lang: languageService, private toast: toastService){
        // nothing to do
    }
    
    post(data, source = null){
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            
            for (var field in data) {
              if (data.hasOwnProperty(field)) {
                  formData.append(field, data[field], data[field].name); 
              }
            }            
                        
            let _getparams = (source) ? '?source=' + source : '';
            let _subscription = this.http.post(this.uploadUrl + _getparams, formData, httpService.headers, httpService.serializer.multipart).subscribe(
            data => {
                if (data['errorcode'] == 0){
                    resolve(data['filename']);
                }
                else {
                    reject(this.lang.tr(this.lang.tr('@service_request_error_' + data['errorcode'])));
                }
            },
            error => {
                reject(this.lang.tr('@http_request_error', [ error.status ]));
            },
            () => {
                _subscription.unsubscribe();
            });
        });
    }
}

export namespace uploadService {
    export enum uploadSource {
        attach = 'attach'
    };
}

@Injectable()
export class downloadService {
    private _doDownloadUrl = 'common/download.php';
    
    constructor(private http: httpService, private lang: languageService, private toast: toastService){
        // nothing to do
    }
    
    file(url){
        return new Promise<ArrayBuffer>((resolve, reject) => {
            let _request = this.http.get(AppConstants.baseURL + this._doDownloadUrl + "?url=" + encodeURIComponent(url));
            let _subscription = _request.subscribe(
            data => {
                if (data['errorcode'] == 0){
                    let _binary = atob(data['base64']);
                    let _length = _binary.length;    
                                    
                    let _bytes = new Uint8Array(_length);
                    for (let i = 0; i < _length; i++) {
                        _bytes[i] = _binary.charCodeAt(i);
                    }
                    resolve(_bytes.buffer);
                }
                else {
                    reject(this.lang.tr(this.lang.tr('@service_request_error_' + data['errorcode'])));
                }
            },
            error => {
                reject(this.lang.tr('@http_request_error', [ error.status ]));
            },
            () => {
                _subscription.unsubscribe();
            });
        });
    }
}
