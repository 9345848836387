import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { FamilyPeriod } from './period';
import { FamilyProduct } from './familyproduct';
import { Product } from './product';
import { ProductSelect } from './productselect';

export interface _Family {
    status: string;
    product: number;
    allday: boolean;
};

interface _FamilyData extends _Family {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class FamilyData extends DataObject {
    protected _family: _FamilyData = {
        status: null,
        product: null,
        allday: true
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._family.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._family.created;
    }

    get status(): string {
        return this._family.status;
    }

    set status(value: string){
        if (this.patchValue(this._family, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get product() : Product {
        return this._children['product'] || null;
    }

    set product(value: Product) {
        if (this.SetChild('product', value, 'product')){
            this.ToUpdate = true;
        }
    }

    get allday(): boolean {
        return this._family.allday;
    }

    set allday(value: boolean) {
        if (this.patchValue(this._family, 'allday', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPeriod(child: FamilyPeriod){
        this.AddChild('periods', child, 'family');
    }

    DelPeriod(child: FamilyPeriod){
        this.DelChild('periods', child, 'family');
    }

    AddItem(child: FamilyProduct){
        this.AddChild('items', child, null);       
    }

    DelItem(child: FamilyProduct){
        this.DelChild('items', child, null);
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get periods() : Array <FamilyPeriod>{
        return this._chldlist['periods'] || [];
    }

    get items() : Array <FamilyProduct>{
        return this._chldlist['items'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._family.status,
            product: this._family.product,
            allday: this._family.allday ? '1':'0'
        };
    }

    protected get Depend(){
        return {
            product: { item: this.product, relation_info: { to: 'families', by: 'product' } }   // this[by -> 'product'][to -> 'families'] => this
        };
    }

    protected get Children(){
        let _children = [];
        
        for(let _item of this.periods){
            _children.push(_item)
        }

        for (let _item of this.items){
            _children.push(_item)
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_family: _Family){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._family, 'status', _family['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._family, 'product', _family['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._family, 'allday', _family['allday']) || _toUpdate;

        return _toUpdate;
    }    
    
    set Data(_family: _Family){
        this.patchValue(this._family, 'created', _family['created']);
        
        if (this._patchData(_family)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._family;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_family: _Family){
        this._patchData(_family);

        if (_family['product']){     // update children: 'product'
            let _objid = _family['product'].toString();
            this.SetChild('product', new Product(_objid, this.data, this._objoptions), 'product')
        }
        else {
            this.SetChild('product', null, 'product')
        }
    }

    private _ddbb(info): _FamilyData {
        let _family: _FamilyData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            product: info['product'] ? parseInt(info['product']) : null,
            allday: (info['allday'] == '1')
        };
        return _family;
    }

    protected _OnUpdate(info){
        let _family = this._ddbb(info);

        this.patchValue(this._family, 'objid', _family['objid']);
        this.patchValue(this._family, 'created', _family['created']);
        this.DoPatchValues(_family);
        
        if (info['periods']){   // update children: 'periods'
            this.SetChildren <FamilyPeriod> (info['periods'], 'periods', FamilyPeriod, 'family');
        }

        if (info['items']){     // update children: 'items'
            this.SetChildren <FamilyProduct> (info['items'], 'items', FamilyProduct, 'family');
        }
    }
}

export class Family extends FamilyData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('FAMILY', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Family {
        return this._Copy(store) as Family;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.product){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.product.DelFamily(this);
            }
            else {
                this.product.DoRefresh('FAMILY');
            }    
        }
    }

    get name(){
        return this.product?.name;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        return (this.product && this.product.IsValid) && (this.status && (this.status != 'DE'));
    }

    get IsActive() {
        if (!this.IsValid || (this._family.status != 'AC')){
            return false;
        }

        if (this._family.allday){
            return true;
        }

        for(let _period of this.periods){
            if (_period.IsActive){
                return true;
            }
        }

        return false;
    }

    SetProduct(olditem: Product | ProductSelect, newitem: Product  | ProductSelect){
        if (!this.IsValid){
            return null;
        }

        let _toUpdate = false;
        
        _toUpdate = this.items.some(
        (familyproduct) => {
            return (familyproduct.product && (familyproduct.product == olditem)) || (familyproduct.select && (familyproduct.select == olditem));
        });
    
        if (_toUpdate){
            // update the reference to the new product
            for(let _familyproduct of this.items){
                if (_familyproduct.item == olditem){
                    if (newitem.IsValid){
                        _familyproduct.item = newitem;
                    }
                    else {
                        this.DelItem(_familyproduct);
                    }
                }    
            }

            return this;
        }

        return null;    // this family is not affected
    }    
}
