import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Ticket } from './ticket';
import { Audit } from './audit';

export interface _TicketAudit {
    status: string;
    ticket: number;
    audit: number;
};

interface _TicketAuditData extends _TicketAudit {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class TicketAuditData extends DataObject {
    protected _audit: _TicketAuditData = {
        status: null,
        ticket: null,
        audit: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._audit.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._audit.created;
    }

    get status(): string{
        return this._audit.status;
    }

    set status(value: string){
        if(this.patchValue(this._audit, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get ticket(): Ticket {
        return this._children['ticket'] || null;
    }

    set ticket(child: Ticket){
        if (this.SetChild('ticket', child, 'ticket')){
            this.ToUpdate = true;
        }
    }

    get audit(): Audit {
        return this._children['audit'] || null;
    }    

    set audit(child: Audit){
        if (this.SetChild('audit', child, 'audit')){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return {
            status: this._audit.status,
            ticket: this._audit.ticket,
            audit: this._audit.audit
        };
    }

    protected get Depend() {
        return {
            ticket: { item: this.ticket, relation_info: { to: null, by: 'ticket' } },   // no relation to this in this[by -> 'ticket']
            audit: { item: this.audit, relation_info: { to: null, by: 'audit' } }      // no relation to this in this[by -> 'audit']
        };
    }

    protected get Children(){
        return [ /* empty */ ];
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_audit: _TicketAudit){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._audit, 'status', _audit['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'ticket', _audit['ticket']) || _toUpdate;
        _toUpdate = this.patchValue(this._audit, 'audit', _audit['audit']) || _toUpdate;

        return _toUpdate;
    };

    set Data(_audit: _TicketAudit){
        this.patchValue(this._audit, 'created', _audit['created']);
        
        if (this._patchData(_audit)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._audit;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_audit: _TicketAuditData){
        this._patchData(_audit);

        if (_audit['ticket']){   // update children: 'ticket'
            let _objid = _audit['ticket'].toString();
            this.SetChild('ticket', new Ticket(_objid, this.data, this._objoptions), 'ticket')
        }
        else {
            this.SetChild('ticket', null, 'ticket');
        }

        if (_audit['audit']){    // update children: 'audit'
            let _objid = _audit['audit'].toString();
            this.SetChild('audit', new Audit(_objid, this.data, this._objoptions), 'audit')
        }
        else {
            this.SetChild('audit', null, 'audit');
        }
    }

    private _ddbb(info): _TicketAuditData {
        let _audit: _TicketAuditData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            ticket: info['ticket'] ? parseInt(info['ticket']) : null,
            audit: info['audit'] ? parseInt(info['audit']) : null
        };
        return _audit;
    }
   
    protected _OnUpdate(info){
        let _audit = this._ddbb(info);
        this.patchValue(this._audit, 'objid', _audit['objid']);
        this.patchValue(this._audit, 'created', _audit['created']);
        this.DoPatchValues(_audit);
    }
}

export class TicketAudit extends TicketAuditData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('TICKETAUDIT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): TicketAudit {
        return this._Copy(store) as TicketAudit;
    }
}
