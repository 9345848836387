import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { CatalogObject } from './catalog';
import { Place } from './place';
import { ProductCategory } from './productcategory';
import { ProductSelect } from './productselect';
import { Family } from './family';

export interface _Product {
    status: string;
    place: number;
    name: string;
    code: string;
    price: number;
    taxrate: number,
    photo: {
        url: string;
        b64: any;
    },
    description: string;
    isgroup: boolean;
    isfamily: boolean;
    generic: boolean;
    type: string;
    parent: number;
    ctgref: string;
    sort: number;
    flags: string;
};

interface _ProductData extends _Product {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ProductData extends CatalogObject {
    protected _product: _ProductData = {
        status: null,
        place: null,
        name: null,
        code: null,
        price: null,
        taxrate: null,
        photo: {
            url: null,
            b64: null
        },
        description: null,
        isgroup: false,
        isfamily: false,
        generic: false,
        type: null,
        parent: null,
        ctgref: null,
        sort: 0,
        flags: null
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._product.created = new Date();
    }

    /****************************/
    /* TICKET PRODUCT FLAGS     */
    /****************************/

    protected ProductFlags = Object.freeze({
        HideApp: 0,         // Hide product on QR APP access 
        HidePdf: 1,         // Hide product on PDF menu
        HideWeb: 2,         // Hide product on WEB access
        MarketPrice: 3,     // Does not have a fixed price
        Weighted: 4         // This product has to be weighted
    });

    protected get flags(): Array<any> {
        let _array = Object.keys(this.ProductFlags).map(() => false);

        if (this._product.flags){
            let _flags = Array.from(this._product.flags);
            for(let i=0; i < _flags.length; i++){
                _array[i] = (_flags[i] == '1');
            }        
        }

        return _array;
    }

    protected set flags(value: Array<any>) {
        let _array = [];
        for(let i=0; i < value.length; i++){
            _array[i] = (value[i] === true) ? '1' : '0';
        }

        let _flags = _array.join(''); 
        if(this.patchValue(this._product, 'flags', _flags)){
            this.ToUpdate = true;
        }
    }

    protected _setFlagValue(flag, value){
        let _flags = this.flags;
        _flags[flag] = value;
        this.flags = _flags;        
    }

    get HideApp() : boolean {
        return this.flags[this.ProductFlags.HideApp];
    }

    set HideApp(value: boolean){
        this._setFlagValue(this.ProductFlags.HideApp, value);
    }

    get HidePdf() : boolean {
        return this.flags[this.ProductFlags.HidePdf];
    }

    set HidePdf(value: boolean){
        this._setFlagValue(this.ProductFlags.HidePdf, value);
    }

    get HideWeb() : boolean {
        return this.flags[this.ProductFlags.HideWeb];
    }

    set HideWeb(value: boolean){
        this._setFlagValue(this.ProductFlags.HideWeb, value);
    }

    get MarketPrice() : boolean {
        return this.flags[this.ProductFlags.MarketPrice];
    }

    set MarketPrice(value: boolean) {
        this._setFlagValue(this.ProductFlags.MarketPrice, value);        
    }

    get IsWeighted() : boolean {
        return this.flags[this.ProductFlags.Weighted];
    }

    set IsWeighted(value: boolean ) {
        this._setFlagValue(this.ProductFlags.Weighted, value);        
    }

    /****************************/
    /* PRODUCT OVERLOAD         */
    /****************************/

    get created(){
        return this._product.created;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string{
        return this._product.status;
    }

    set status(value: string){
        if (this.patchValue(this._product, 'status', value)){
            this.ToUpdate = true;
        }    
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place){
        this.SetChild('place', value, 'place');
    }

    get name(): string{
        return this._product.name;
    }

    set name(value: string){
        if (this.patchValue(this._product, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get code(): string{
        return this._product.code;
    }

    set code(value: string){
        if (this.patchValue(this._product, 'code', value)){
            this.ToUpdate = true;
        }
    }

    get price(): number{
        return this._product.price;
    }

    set price(value: number){
        if (this.patchValue(this._product, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get taxrate(): number{
        return this._product.taxrate;
    }

    set taxrate(value: number){
        if (this.patchValue(this._product, 'taxrate', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._product.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._product.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }

    get base64(): any {
        return this._product.photo.b64;        
    }

    set base64(value: any){
        if (this.patchValue(this._product.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string{
        return this._product.description;
    }

    set description(value: string){
        if (this.patchValue(this._product, 'description', value)){
            this.ToUpdate = true;
        }
    }

    get isgroup(): boolean{
        return this._product.isgroup;
    }

    set isgroup(value: boolean){
        if (this.patchValue(this._product, 'isgroup', value)){
            this.ToUpdate = true;
        }
    }

    get isfamily(): boolean{
        return this._product.isfamily;
    }

    set isfamily(value: boolean){
        if (this.patchValue(this._product, 'isfamily', value)){
            this.ToUpdate = true;
        }
    }    

    get generic(): boolean{
        return this._product.generic
    }

    set generic(value: boolean){
        if (this.patchValue(this._product, 'generic', value)){
            this.ToUpdate = true;
        }       
    }

    get type(): string{
        return this._product.type;
    }

    set type(value: string){
        if (this.patchValue(this._product, 'type', value)){
            this.ToUpdate = true;
        }
    }

    get parent() : Product {
        return this._children['parent'] || null;
    }

    set parent(value: Product){
        if (this.SetChild('parent', value, 'parent')){
            this.ToUpdate = true;
        }
    }

    get ctgref(): string{
        return this._product.ctgref;
    }

    set ctgref(value: string){
        if (this.patchValue(this._product, 'ctgref', value)){
            this.ToUpdate = true;
        }
    }

    get sort(): number{
        return this._product.sort;
    }

    set sort(value: number){
        if (this.patchValue(this._product, 'sort', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddCategory(child: ProductCategory){
        this.AddChild('categories', child, 'product');
    }

    DelCategory(child: ProductCategory){
        this.DelChild('categories', child, 'product');
    }

    AddSelect(child: ProductSelect){
        this.AddChild('selects', child, 'product');
    }

    DelSelect(child: ProductSelect){
        this.DelChild('selects', child, 'product');
    }

    AddFamily(child: Family){
        this.AddChild('families', child, 'product');
    }

    DelFamily(child: Family){
        this.DelChild('families', child, 'product');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get categories() : Array <ProductCategory> {
        return this._chldlist['categories'] || [];
    }

    get selects() : Array <ProductSelect> {
        return this._chldlist['selects'] || [];
    }

    get families(): Array <Family> {
        return this._chldlist['families'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {   
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }
    
            return {
                place: this._product.place,
                status: this._product.status,
                name: this._product.name,
                code: this._product.code,
                price: this._product.price,
                taxrate: this._product.taxrate,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                description: this._product.description,
                isgroup: this._product.isgroup ? '1':'0',
                isfamily: this._product.isgroup ? '1':'0',
                generic: this._product.generic ? '1' : '0',
                type: this._product.type,
                parent: this._product.parent,
                ctgref: this._product.ctgref,
                sort: this._product.sort,
                flags: this._product.flags
            };    
        })();   
    }

    protected get Depend() {
        return {
            place: { item: this.place, relation_info:  { to: 'products', by: 'place' } },   // this[by -> 'place'][to -> 'products'] => this
            parent: { item: this.parent, relation_info:  { to: null, by: 'parent' } }       // no relation to this in this[by -> 'parent']
        };
    }

    protected get Children(){
        let _children = [];

        for(let _item of this.categories){
            _children.push(_item)
        }

        for(let _item of this.selects){
            _children.push(_item)
        }

        for(let _item of this.families){
            _children.push(_item)
        }

        return _children;
    }

    /****************************/
    /* DATA OBJECT              */
    /****************************/
    
    private _patchData(_product: _Product){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._product, 'place', _product['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'ctgref', _product['ctgref']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'status', _product['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'name', _product['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'code', _product['code']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'price', _product['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'taxrate', _product['taxrate']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'photo', _product['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'description', _product['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'isgroup', _product['isgroup']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'isfamily', _product['isfamily']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'generic', _product['generic']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'type', _product['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'parent', _product['parent']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'sort', _product['sort']) || _toUpdate;
        _toUpdate = this.patchValue(this._product, 'flags', _product['flags']) || _toUpdate;

        return _toUpdate;
    }    

    set Data(_product: _Product){
        this.patchValue(this._product, 'created', _product['created']);
        
        if (this._patchData(_product)){
            this.ToUpdate = true;
        }
   }

    get Info(){
        return this._product;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_product: _Product){
        this._patchData(_product);

        if (_product['place']){         // update children: 'place'
            let _objid = _product['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place');
        }
        else {
            this.SetChild('place', null, 'place');
        }
        
        if(_product['parent']){         // update children: 'parent'
            let _objid = _product['parent'].toString();
            this.SetChild('parent', new Product(_objid, this.data, this._objoptions), 'parent')
        }        
        else {
            this.SetChild('parent', null, 'parent');
        }
    }

    private _ddbb(info): _ProductData {
        let _product: _ProductData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            name: info['name'],
            code: info['code'],
            price: info['price'] ? parseFloat(info['price']): null,
            taxrate: info['taxrate'] ? parseFloat(info['taxrate']): null,
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },
            description: info['description'],
            isgroup: ('isgroup' in info) ? (info['isgroup'] != '0') : false,
            isfamily: ('isfamily' in info) ? (info['isfamily'] != '0') : false,
            generic: ('generic' in info) ? (info['generic'] != '0') : false,
            type: info['type'],
            parent: info['parent'] ? parseInt(info['parent']) : null,
            ctgref: info['ctgref'],
            sort: info['sort'] ? parseInt(info['sort']) : 0,
            flags: info['flags']
        };
        return _product;
    }

    protected _OnUpdate(info){
        let _product = this._ddbb(info);

        this.patchValue(this._product, 'objid', _product['objid']);
        this.patchValue(this._product, 'created', _product['created']);
        this.DoPatchValues(_product);

        if(info['categories']){     // update children: 'categories'
            this.SetChildren <ProductCategory> (info['categories'], 'categories', ProductCategory, 'product');
        }

        if(info['selects']){        // update children: 'selects'
            this.SetChildren <ProductSelect> (info['selects'], 'selects', ProductSelect, 'product');
        }
        
        if(info['families']){         // update children: 'families'
            this.SetChildren <Family> (info['families'], 'families', Family, 'product');
        }

        // remove the catalog options after the update
        for(let _category of this.categories){
            if (_category.IsCatalog){
                this.DelCategory(_category);
            }
        }

        for(let _select of this.selects){
            if (_select.IsCatalog){
                this.DelSelect(_select);
            }
        }
    }
}

export class Product extends ProductData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('PRODUCT', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Product {
        return this._Copy(store) as Product;
    }

    OnResolve(){
        let _catalog = this.Catalog as Product;
        if (_catalog && this.IsValid){
            this.place.DelProduct(_catalog);   
            super.OnResolve();
            this.place.AddProduct(this);    

            this.data.storage.DelObject(_catalog);
        }
        else {
            super.OnResolve();
        }

        this.DoRefresh('PRODUCT');
    }

    /********************************/
    /* CATALOG FLAG MANAGEMENT      */
    /********************************/

    get IsCatalog(): boolean{
        return super.IsCatalog;
    }

    set IsCatalog(value: boolean){
        if (this.IsCatalog == value){
            return;
        }

        super.IsCatalog = value;

        // update the value in all the contained items
        for(let relation of [ 'categories', 'selects' ]){
            if (this._children[relation]){
                for(let child of this._chldlist[relation]){
                    child.IsCatalog = value;
                }        
            } 
        }
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        if ((this.IsCatalog) && (value == 'DE')){
            this.place.DelProduct(this);
        }
        else {
            super.status = value;
        }

        // recover catalog items to place when deleted
        if (value == 'DE'){
            if (this.place){
                let _catalog: Product = this.Catalog as Product;
                if (_catalog){
                    this.place.AddProduct(_catalog);
                }
    
                this.place.DoRefresh('PRODUCT', true);    
                if (this.parent){
                    this.parent.DoRefresh('PRODUCT', true);
                }        
            }
        }
    }

    get code(): string{
        if (this.place.ProductCodes){
            return super.code;
        }
        return null;
    }

    set code(value: string){
        super.code = value;
    }

    get available() : boolean {
        return (this.status == 'AC') || (this.status == 'OT');
    }

    set available(value: boolean) {
        this.status = (value) ? 'AC' : 'UN';
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.name);
    }

    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    get isfamily(){
        return super.isfamily && (this.family != null);
    }

    set isfamily(value: boolean){
        super.isfamily = value;
        
        if (value == false){
            this.family = null;
        }
    }

    get family(): Family {
        for(let _family of this.families){
            if (_family.IsValid){
                return _family;
            }
        }

        return null;
    }

    set family(value: Family) {
        if (this.family != value){
            if (this.family){
                this.family.status = 'DE';
            }

            if (value != null){
                this.AddFamily(value);
            }

            super.isfamily = (this.family != null);
        }
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get Direct() : ProductSelect {     
        let _select: ProductSelect = null;

        if (!this.isgroup && !this.HasOptions) {
            let _direct_available = this.selects.some(
            (select) => {
                return (select.options.length == 0);
            });    
            
            if (!_direct_available){    // not available - create on demmand
                _select = new ProductSelect(null, this.data, { volatile: true });
                _select.name = this._product.name;
                _select.description = this._product.description;
                _select.photo = this._product.photo.url;
                _select.base64 = this._product.photo.b64;
                _select.product = this;    
            }                
        }

        return _select;     // null of group or mandatory options
    }

    get selects() : Array <ProductSelect> {
        return super.selects.sort((a, b) => {
            return a.sort - b.sort;
        });
    }

    get directs() : Array <ProductSelect> {
        let _direct = this.Direct;
        if (_direct){
            return this.selects.concat([ _direct ]);
        }

        return this.selects;
    }

    get categories() : Array <ProductCategory> {
        return super.categories.sort((a, b) => {
            return a.sort - b.sort;
        });
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsPurchased(){
        return (this.status == 'AC') || (this.status == 'UN');
    }

    get IsValid() {
        return this.status && (this.status != 'DE');
    }

    get IsEmpty(){
        if (!this.IsGroup){
            return true;
        }

        return !this.place.products.some(_product => {
            return _product.IsPurchased && _product.IsChild(this);
        });
    }

    get IsAvailable(){
        return this.available;
    }

    get IsProduct(){
        return (!this.generic) && (this.status != 'OT');
    }

    get IsGroup(){
        return (this.IsProduct && this.isgroup);
    }

    get IsFamily(){
        return (this.IsGroup && this.isfamily);
    }

    get IsVarious(){
        return (this.status == 'OT');
    }

    get IsGeneric(){
        return this.generic;
    }

    get IsDirect(){
        return (!this.generic && !this.HasOptions);        
    }

    get HasSelects(){
        return (this.selects) && (this.selects.length > 0);
    }

    get HasOptions(){
        return this.categories.some(
        category => {
            for(let option of category.options){
                if (option.IsAvailable && option.IsMandatory){
                    return true;
                }
            }
            return false;
        });
    }

    private _Price(price){
        let _price = Number(price || 0);

        if (price !== null){
            let _parts = (Math.abs(_price)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _price.toFixed(2);
            let _neg = (_price < 0); 

            return {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        return null;
    }

    get Price(){
        return this._Price(this.price);
    }

    private _StartingSingle(minprice, category, options){
        if (options.length > 0){
            return minprice + options[0].price;
        }
        return minprice;
    }

    private _StartingMultiple(minprice, category, options){
        let _minprice = minprice;
        for(let _option of options){
            _minprice = Math.min(_minprice, _minprice + _option.price);
            
            if (_minprice >= minprice){
                break;  // options are ordered by price, it is not going to get any better
            }
        }
        return _minprice;        
    }    

    private _StartingFixed(minprice, category, options){
        let _minprice = minprice;

        if (options.length >= category.fxmin){
            for(let idx=0; idx < category.fxmin; idx++){
                _minprice += options[idx].price;
            }
        }
        else {
            return minprice;
        }

        for(let idx=category.fxmin; (idx <= category.fxmax) && (idx < options.length); idx++){
            let _newprice = Math.min(_minprice, _minprice + options[idx].price);
            if (_newprice >= _minprice){
                break;  // options are ordered by price, it is not going to get any better
            }

            _minprice = _newprice;
        }

        return _minprice;
    }    
    
    get Starting(){
        let _minprice = this.price;
        for(let _category of this.categories){
            let _options = [];

            // keep the valid options
            for(let _option of _category.options){
                if (_option.IsValid){
                    _options.push(_option);
                }
            }            

             // sort the valid options (min price first)
            _options = _options.sort((a, b) => {
                return a.price - b.price;
            });

            // update the minprice for this category
            switch(_category.type){
                case 'SINGLE':
                    _minprice = this._StartingSingle(_minprice, _category, _options);
                    break;
                case 'MULTIPLE':
                    _minprice = this._StartingMultiple(_minprice, _category, _options);
                    break;
                case 'FIXED':
                    _minprice = this._StartingFixed(_minprice, _category, _options);
                    break;
            }       
        }

        return this._Price(_minprice);
    }

    IsChild(product){
        if (product == null){
            return true;
        }

        let _parent = this.parent;
        while(_parent != null){
            if (_parent == product) {
                return true;
            }
            _parent = _parent.parent;
        }
        return false;
    }

    get Depth(){
        let _depth = 0;
        let _parent = this.parent;
        while(_parent != null){
            _depth++;
            _parent = _parent.parent;
        }
        return _depth;
    }
}
