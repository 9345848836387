import { Component, Input, OnInit, OnChanges, OnDestroy, forwardRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { ModalController, NavParams } from '@ionic/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { AppConstants } from '@app/app.constants';
import { languageSupport, languageService } from '@app/modules/common/language';
import { httpService } from '@app/modules/common/http';
import { preloadService } from '@app/modules/common/preload';
import { viewService } from '@app/modules/view';

@Component({
    selector: 'modal-crop-image',
    templateUrl:'./ui-modal.html',
    styleUrls: [ 
        './ui-modal.scss'
    ]
})
export class UiModalCrop extends languageSupport implements OnInit, OnDestroy {
    public _image = null;
    public _height = 0;
    public _width = 0;
    public _loaded = false;

    constructor(private lang: languageService, private modalCtrl: ModalController, private params: NavParams, public view: viewService) {
        super(lang, null);
    }

    ngOnInit() {
        this._image = this.params.get('image');
        this._height = this.params.get('height');
        this._width = this.params.get('width');
    }    

    ngOnDestroy(){
        super.OnDestroy();
    }

    async OnCrop(data){
        await this.modalCtrl.dismiss(data);
    }
    
    async CloseModal() {
        await this.modalCtrl.dismiss(null);
    }        
}

@Component({
    selector: 'ui-image',
    templateUrl:'./ui-image.html',
    styleUrls: [ 
        './ui-image.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiImage),
            multi: true
        }            
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiImage extends languageSupport implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    @Input('readonly') _readonly = true;
    @Input('src') _src = null;
    @Input('crheight') _croppedHeight = '0';
    @Input('crwidth') _croppedWidth = '0';
    
    public _loading = 'lazy';

    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}
    
    @ViewChild('image', {static: false}) _image: ElementRef;

    get _cors(){    // use crossorigin for server images
        return !this._src.startsWith('http') || (this._src && (this._src.startsWith(AppConstants.baseURL)));
    }

    private _b64 = null;
    private get _value(){
        return this._b64;
    }

    private set _value(value){
        this._b64 = value; 
        this._src = value;
        this.change.markForCheck();
    }

    private _cachesrc = null;
    get _source(){
        return this._value || this._cachesrc || this._src;
    }

    private set _source(value){
        this._cachesrc = value;
        this.change.markForCheck();
    }

    private _c64: any = null;
    get Base64() {
        return new Promise((resolve) =>{
            if (!this._c64){
                let _img = new Image();
                _img.onload = () => {
                    let _cnv = document.createElement("canvas");
                    var _ctx = _cnv.getContext("2d");
        
                    _cnv.width = _img.naturalWidth;
                    _cnv.height = _img.naturalHeight;
                    _ctx.drawImage(_img, 0, 0);

                    let _dataurl = _cnv.toDataURL("image/png");
                    this._c64 = _dataurl.split(',', 2)[1];

                    resolve(this._c64);  
                }
    
                _img.crossOrigin = "Anonymous";
                _img.src = this._source;
            }
            else {
                resolve(this._c64);    
            }
        });
    }

    constructor(private lang: languageService, private change: ChangeDetectorRef, private http: httpService, private preload: preloadService, private modalCtrl: ModalController){
        super(lang, change);
    }
    
    public _loaded = false;
    public _online = true;

    get IsVisible(){
        return this._loaded;
    }

    OnLoaded(){
        this._loaded = true;
        this.change.markForCheck();
    }

    set Online(value){
        this._online = value;

        if (this._online && this._online_subscription){
            this._online_subscription.unsubscribe();
            this._online_subscription = null;
        }

        this.change.markForCheck();
    }

    private _online_subscription = null;
    ngOnInit() {
        this._online = this.http.IsOnline;
        if (!this._online){
            this._online_subscription = this.http.OnOnline.subscribe(
            data => {
                this.Online = data;
            });    
        }
    }

    ngOnChanges(){
        this._c64 = null; 
        this._b64 = null;

        if (!this._src){
            this._loaded = true;
            return;     // no src for image?
        }
        else {
            this._cachesrc = this.preload.Recover(this._src);
            if (!this._cachesrc){
                this.preload.Enqueue(this._src, (safeurl) => {
                    this._loading = 'eager';
                    if (safeurl){
                        this._source = safeurl;        
                    }
            
                    this.change.markForCheck();    
                });    
            }    
        }
    }

    ngOnDestroy(){
        super.OnDestroy();

        if (this._online_subscription){
            this._online_subscription.unsubscribe();
            this._online_subscription = null;
        }
    }

    private _showCropModal(_imagesrc){
        return new Promise(async (resolve) => {
            let _image = new Image();
            _image.src = _imagesrc;
            
            const modal = await this.modalCtrl.create({ 
                component: UiModalCrop,
                componentProps: { 
                    image: _image,
                    height: Number(this._croppedHeight),
                    width: Number(this._croppedWidth),
                }
            });
            
            modal.onDidDismiss().then((response) => {
                if (response && response.data){
                    this.value = response.data;
                }
                
                resolve(true);
            });        
                    
            await modal.present();                
        });
    }
    
    @Output('onChange') _onChange = new EventEmitter<any>();         
    OnChange(event){
        return new Promise((resolve) => {
            let self = this;
            this._readImage(event.target.files[0])
            .then((dataURL) => {
                self._showCropModal(dataURL).then(
                data => {
                    resolve(true);
                });
            });
            
            event.srcElement.value = null;        
            this._onChange.emit();    
        });
    }
    
    /************************************************/
    /* adjust image according to exif information   */
    /************************************************/

    // https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/32490603#32490603
    private _getOrientation(event) {
        var view = new DataView(event.target.result);
        if (view.getUint16(0, false) != 0xFFD8)
        {
            -2; // not a JPEG
        }
        var length = view.byteLength, offset = 2;
        while (offset < length) 
        {
            if (view.getUint16(offset+2, false) <= 8){
                return -1;  // not defined
            }
            
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) 
            {
                if (view.getUint32(offset += 2, false) != 0x45786966) 
                {
                    return -1;  // not defined
                }

                var little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;
                for (var i = 0; i < tags; i++)
                {
                    if (view.getUint16(offset + (i * 12), little) == 0x0112)
                    {
                        return view.getUint16(offset + (i * 12) + 8, little);
                    }
                }
            }
            else if ((marker & 0xFF00) != 0xFF00)
            {
                break;
            }
            else
            { 
                offset += view.getUint16(offset, false);
            }
        }
        
        return -1;  // not defined
    };

    // https://github.com/mshibl/Exif-Stripper/blob/master/exif-stripper.js
    private _removeExif(event){
        var imageArrayBuffer = event.target.result;
        var dv = new DataView(imageArrayBuffer);
        
	    var offset = 0, recess = 0;
	    var pieces = [];
	    var i = 0;
	    if (dv.getUint16(offset) == 0xffd8){
	        offset += 2;
	        var app1 = dv.getUint16(offset);
	        offset += 2;
	        while (offset < dv.byteLength){
	            if (app1 == 0xffe1){
	                pieces[i] = {recess:recess,offset:offset-2};
	                recess = offset + dv.getUint16(offset);
	                i++;
	            }
	            else if (app1 == 0xffda){
	                break;
	            }
	            offset += dv.getUint16(offset);
	            var app1 = dv.getUint16(offset);
	            offset += 2;
	        }
	        if (pieces.length > 0){
	            var newPieces = [];
	            pieces.forEach(function(v){
	                newPieces.push(imageArrayBuffer.slice(v.recess, v.offset));
	            }, this);
	            newPieces.push(imageArrayBuffer.slice(recess));
                
                return new Blob(newPieces, {type: 'image/jpeg'});
	        }
	    }
	}    
    
    // https://stackoverflow.com/questions/20600800/js-client-side-exif-orientation-rotate-and-mirror-jpeg-images    
    private _imgTransform(callback, orientation, image, type){
        let canvas = document.createElement('canvas');

        let w = image.width
        let h = image.height; 

        let context = canvas.getContext('2d');            
        
        let max = 1024, scale = 1; // max side: 1024 pixels
        if ((w > h) && (w > max)){
            scale = max/w;
        }
        if ((h >= w) && (h > max)){
            scale = max/h;
        }

        w = Math.round(w * scale);
        h = Math.round(h * scale);
    
        if ((orientation > 4) && (orientation < 9)) {
            canvas.width = h;
            canvas.height = w;
        } 
        else {
            canvas.width = w;
            canvas.height = h;
        }
        
        let tm = [ 1, 0, 0, 1, 0, 0 ];  // transformation matrix        
        switch (orientation) {
            case 2: tm = [ -1, 0, 0, 0, image.width, 0 ]; break;
            case 3: tm = [ -1, 0, 0, -1, image.width, image.height ]; break;
            case 4: tm = [ 1, 0, 0, -1, 0, image.height ]; break;
            case 5: tm = [ 0, 1, 1, 0, 0, 0 ]; break;
            case 6: tm = [ 0, 1, -1, 0, image.height, 0 ]; break;
            case 7: tm = [ 0, -1, -1, 0, image.height, image.width ]; break;
            case 8: tm = [ 0, -1, 1, 0, 0, image.width ]; break;
        }
        
        context.transform(scale, 0, 0, scale, 0, 0);
        context.transform(tm[0], tm[1], tm[2], tm[3], tm[4], tm[5]);        
        
        context.drawImage(image, 0, 0);
        callback(canvas.toDataURL());
    }
        
    private _readImage(file) {
        let promise = new Promise <any> ((resolve) => {
            const arrReader = new FileReader();
            arrReader.onload = (event) => {
                let _blob = this._removeExif(event);
                if (_blob){
                    const image = new Image();
                    image.onload = () => {
                        this._imgTransform((dataURL) => {
                            resolve(dataURL);
                        }, this._getOrientation(event), image, file.type);                        
                    }
                    image.src = URL.createObjectURL(_blob);
                }
                else {  // no exif information ?? 
                    const imgReader = new FileReader();
                    imgReader.onload = (event) => {
                        const image = new Image();
                        image.onload = () => {
                            this._imgTransform((dataURL) => {
                                resolve(dataURL);
                            }, -1, image, file.type);                        
                        }
                        image.src = imgReader.result as string;
                    };
                    imgReader.readAsDataURL(file);
                }
            }
            arrReader.readAsArrayBuffer(file);            
        });
        
        return promise;
    }    
    
    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._value || this._src;
    }

    set value(v: any) {
        if (v !== this._src) {
            this._value = v;

            this._onChangeCallback(v);
            this._onTouchCallback(v);

            this._onChange.emit();
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._src) {
            this.value = value;
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    } 
};
