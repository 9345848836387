import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Input, forwardRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, } from "@angular/forms";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppConstants } from '@app/app.constants';
import { geocodeService, Address } from '@app/modules/common/geocode';
import { languageSupport, languageService } from '@app/modules/common/language';
import { toastService } from '@app/modules/common/toast';

let GMAP_API_LOADED: boolean = false;

@Component({
    selector: 'ui-modal-address',
    templateUrl:'./ui-modal.html',
    styleUrls: [ 
        './ui-modal.scss'
    ]
})
export class UiModalAddress extends languageSupport implements OnInit, OnDestroy {
    public _title = '';
    public _value = null;
    
    public _mapinfo = {
        initialized: false,
        validated: false,
        
        zoom: 17,
        formatted: null,
        accuracy: 0,
        position: {
            lat: 0, 
            lng: 0
        },
        markers: [
            {
                position: {
                    lat: 0, 
                    lng: 0
                }
            }
        ]
    };
    
    // class methods

    private _gmapsApiLoaded = new Subject<any> ();
    public gmapsApiLoaded = this._gmapsApiLoaded.asObservable();

    constructor(private lang: languageService, private geocode: geocodeService, private toast: toastService, private modalCtrl: ModalController, private params: NavParams, private http: HttpClient) {
        super(lang, null);

        // https://github.com/angular/components/tree/master/src/google-maps
        if (!GMAP_API_LOADED) {
            this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=' + AppConstants.googleApiKey, 'callback').subscribe(
                () => {
                    GMAP_API_LOADED = true;
                    console.info("[GOOGLE MAPS] API loaded.");
                    setTimeout(() => {
                        this._gmapsApiLoaded.next(true);
                    }, 0);
                },
                (error) => {
                    console.error("[GOOGLE MAPS] Error loading API: ", error)
                }
            )
        }
        else {
            console.warn("[GOOGLE MAPS] API already loaded");
            setTimeout(() => {
                this._gmapsApiLoaded.next(true);
            }, 0);
        }
    }
    
    private async _onGeocodeResponse(address, _keepcoords = false) {
        this._value['formatted'] = address['formatted'];
        this._value['address'] = address['street']['route'];
        this._value['number'] = address['number'];
        this._value['locality'] = address['postal_code'] + ' ' + address['street']['locality'] + ', ' + address['area']['level2'] + ', ' + address['area']['level1'];
        
        if (!_keepcoords){
            this._value['latitude'] = address['location']['lat'];
            this._value['longitude'] = address['location']['lng'];
        }

        this.AddressForm.patchValue({
            address: this._value.address,
            number: this._value.number,
            door: this._value.door,
            locality: this._value.locality
        });

        this._mapinfo.formatted = this._value.formatted;
        this._mapinfo.position = this._mapinfo.markers[0].position = {
            lat: Number(this._value.latitude),
            lng: Number(this._value.longitude)
        };  
    
        this.geocode.RequestTimezne(address['location']['lat'], address['location']['lng']).then(
        data => {
            this._value['timezone'] = data;  
        });
    }
    
    private _addressForm = null;
    get AddressForm(){
        if (this._addressForm == null){
            let _address = this._value ? this._value.address : null;
            let _number = this._value ? this._value.number : null;
            let _door = this._value ? this._value.door : null;
            let _locality = this._value ? this._value.locality : null;

            this._addressForm = new FormGroup({
                address: new FormControl(_address, [
                    Validators.required,
                ]),
                number: new FormControl(_number, [
                    // no validators required 
                ]),
                door: new FormControl(_door, [
                    // no validators required 
                ]),
                locality: new FormControl(_locality, [
                    Validators.required,
                ])
            })
        }
        return this._addressForm;
    }
    
    private _markAsValid(init){
        setTimeout(() => {
            this._mapinfo.validated = true;
            if (init){
                this._mapinfo.initialized = true;
                this.AddressForm.markAsPristine();
            }
        }, 100);
    }

    ngOnInit() {
        this._title = this.params.get('title');
        let _value = this.params.get('value');

        this._value = (_value) ? JSON.parse(JSON.stringify(_value)) : null;        
        if (this._value){
            if (this._value.address){   // form data already available
                this._mapinfo.formatted = this._value.formatted;
                this._mapinfo.position = this._mapinfo.markers[0].position = {
                    lat: Number(this._value.latitude),
                    lng: Number(this._value.longitude)
                };

                this._markAsValid(true);
            }
            else {  // form data not calculated yet
                this.geocode.ResolveAddress(this._value.formatted).then(
                data => {
                    this._onGeocodeResponse(data, true);
                    this._markAsValid(true);
                });
            }
        }
        else {
            this._value = {
                formatted: null,
                address: null,
                number: null,
                locality: null,
                
                timezone: 'UTC',
                
                accuracy: 0,
                latitude: 0,
                longitude: 0
            };
        }
    }
    
    ngOnDestroy(){
        super.OnDestroy();
    }

    async onFormChanged() {
        this._mapinfo.validated = false;
    }
    
    async AcceptModal() {
        this._value.door = this.AddressForm.get('door').value;
        
        let result = null
        if (this._mapinfo.validated) {
            result = this._value; 
        }
        
        await this.modalCtrl.dismiss(result);
    }    

    async CloseModal() {
        await this.modalCtrl.dismiss(null);
    }

    async onMapClick(event) {
        this._value.accuracy = this._mapinfo.accuracy = 10;
        this._mapinfo.markers[0].position = {
            lat: this._value.latitude = event.latLng.lat(),
            lng: this._value.longitude = event.latLng.lng()
        }
    }

    private _onLocateSuccess(pos) {
        this._mapinfo.accuracy = pos.coords.accuracy;

        this.geocode.RequestAddress(pos.coords.latitude, pos.coords.longitude).then(
        data => {
            this.toast.HideWait();   

            if (data){
                this._onGeocodeResponse(data);
                this._value['accuracy'] = this._mapinfo['accuracy'];
                this._markAsValid(false);
            }
            else {
                this.toast.ShowAlert('danger', this.lang.tr('@address_zero_results'));
            }
        });
    }
    
    private _onLocateError(err) {
        this.toast.HideWait();        
        this.toast.ShowAlert('danger', this.lang.tr('@geolocation_error', [ err.message ]));
    }
        
    async onLocate() {
        if (this.geocode.CanGeolocate) {
            this.toast.ShowWait();
            this.geocode.GetCurrentPosition(this._onLocateSuccess.bind(this), this._onLocateError.bind(this));
        }
        else { 
            this.toast.ShowAlert('danger', this.tr('@geolocation_not_supported'));
        }
    }
    
    async onValidate() {
        let _address = this.AddressForm.value.address + ", " + this.AddressForm.value.number + ", " + this.AddressForm.value.locality;
        this.toast.ShowWait();
        this.geocode.ResolveAddress(_address).then(
        data => {
            this.toast.HideWait();        
        
            if (data) {
                this._onGeocodeResponse(data);
                this._value['accuracy'] = 25;
                this._markAsValid(false);
            }
            else {
                this.toast.ShowAlert('danger', this.lang.tr('@address_zero_results'));
            }
        });
    }
}

@Component({
    selector: 'ui-address',
    templateUrl:'./ui-address.html',
    styleUrls: [ 
        './ui-address.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiAddress),
            multi: true
        }            
    ]
})
export class UiAddress implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input('placeholder') _placeholder = '';
    @Input('formControlName') _name = '';
    @Input('title') _title = '';
    @Input('value') _value = '';
    @Input('auto') _auto = false;

    @Input('formgroup') _formgroup = null;
    @Input('errornfo') _errornfo = {};

    private _innerValue: any = null;  
    private _onChangeCallback: any = () => {}
    private _onTouchCallback: any = () => {}
    
    public _data = {
        title: '',
        showTitle: false, 
        showPlaceholder: false,
        text: '',
        value: null
    }

    constructor(private modalCtrl: ModalController) {
        // nothing to do
    }
    
    ngOnInit() {
        this.onChange();
    }

    ngAfterViewInit() {
        if (this._auto){
            this.showPicker();
        }
    }
    
    @Output('onChange') _onChange = new EventEmitter<any>();     
    onChange() {
        this._data.title = (this._title == '')?this._placeholder:this._title;
        this._data.showTitle = !!this._innerValue;
        this._data.showPlaceholder = !this._innerValue || !this._innerValue.formatted;
        if (this._data.showPlaceholder){
            this._data.text = this._placeholder;
            this._data.value = this._innerValue;
        }
        else {
            this._data.text = this._innerValue.formatted;
            this._data.value = this._innerValue;
        }

        this._onChange.emit();
    }

    async showPicker() {
        const modal = await this.modalCtrl.create({ 
            component: UiModalAddress,
            componentProps: { 
                title: this._data.title,
                value: this._innerValue
            },
            cssClass: 'modal-address'
        });
        
        modal.onDidDismiss().then((detail) => {
            if ((detail !== null) && (detail.data != null)) {
                this.value = detail.data;
            }
            this.onChange();
        });
        
        return await modal.present();        
    }
    
    /********************************************/
    /* get/set accesors                         */
    /********************************************/
    
    get value(): any {
        return this._innerValue;
    }

    set value(v: any) {
        if ((this._innerValue != v) && ((this._innerValue == null) || (v == null) || (JSON.stringify(v) != JSON.stringify(this._innerValue)))){
            this._innerValue = v;
            this.onChange();
            
            this._onChangeCallback(v);
            this._onTouchCallback(v);
        }
    }    
    
    /********************************************/
    /* ControlValueAccessor                     */
    /********************************************/
    
    writeValue(value: any) {
        if (value !== this._innerValue) {
            this.value = value;
        }
        else {  // refresh anyway
            this.onChange();
        }
    }

    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this._onTouchCallback = fn;
    }    
}
