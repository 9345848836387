import { Component, Injectable, OnInit, OnDestroy } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { ModalController, NavParams } from '@ionic/angular';
import { Platform } from '@ionic/angular';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from "@ionic-native/file/ngx";
import { FileOpener } from '@ionic-native/file-opener/ngx';

import { AppConstants } from '@app/app.constants';
import { languageService, languageSupport } from '@app/modules/common/language';
import { toastService } from '@app/modules/common/toast';
import { viewService } from '@app/modules/view';
import { syncService } from '@app/modules/sync';


@Component({
    selector: 'modal-update',
    templateUrl:'./update/modal-update.html',
    styleUrls: [ 
        './update/modal-update.scss'
    ]
})
export class modalUpdate extends languageSupport implements OnInit, OnDestroy {
    public _version = {
        this: AppConstants.Version,
        next: null,
        date: null,
        link: null,
        file: null
    };
    
    constructor(lang: languageService, private params: NavParams, private androidPermissions: AndroidPermissions, private transfer: FileTransfer, private file: File, private opener: FileOpener, public zone: NgZone, private view: viewService, private platform: Platform) {
        super(lang, null)
    }
    
    public _transfer: FileTransferObject = null;
    ngOnInit(){
        let _serverinfo = this.params.get('info');
        
        this._version.date = _serverinfo['publish_date'];
        if (this.platform.is('android')){
            this._version.next = _serverinfo['android_version'];
            this._version.link = _serverinfo['android_url'];
            this._version.file = _serverinfo['android_package'];
        }
        else {  // IOS
            this._version.next = _serverinfo['ios_version'];
            this._version.link = _serverinfo['ios_url'];
            this._version.file = _serverinfo['ios_package'];
        }
        
        this._transfer = this.transfer.create();      
    }
       
    ngOnDestroy(){
        super.OnDestroy();
    }

    // https://2amigos.us/blog/how-to-download-files-to-your-device-from-your-api-in-ionic-framework-v3
    private async getDownloadPath(): Promise<string> {
        let _granted = false;

        if (this.platform.is('android')) {
            console.info("[UPDATER] Check permission of external storage");
            let _result = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
            console.info("[UPDATER] Checking for permission");
            if (!_result.hasPermission) {
                console.info("[UPDATER] Permission not granted: Requesting permission");
                _granted = await new Promise((resolve) => {
                    this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
                    success => {
                        console.info("[UPDATER] Permission to write file is GRANTED");
                        resolve(true);
                    },
                    err => {
                        console.info("[UPDATER] Permission to write file is DENIED");
                        resolve(false);
                    })
                });
            }

            if (_granted){
                return this.file.externalRootDirectory + "/Download/";
            }

            return null;
        } 
        else {    // IOS
            return this.file.documentsDirectory;
        }
    }
    
    public _progress = 0.0
    private _SetProgress(value){
        this._progress = value;
    }

    public _installfile = null;
    async OnInstall() {
        let _filepath = this._installfile;
        this._installfile = null;

        let _granted = false;
        try {
            let _result = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.REQUEST_INSTALL_PACKAGES);
            if (!_result.hasPermission) {
                _granted = await new Promise((resolve) => {
                    this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.REQUEST_INSTALL_PACKAGES).then(
                    success => {
                        console.info("[UPDATER] Permission to install is GRANTED");
                        resolve(true);
                    },
                    err => {
                        console.info("[UPDATER] Permission to install is DENIED");
                        resolve(false);
                    });
                });
        
                if (_granted){
                    console.info("[UPDATER] Installing APK file from (" + _filepath + ")");
                    await this.opener.open(_filepath, 'application/vnd.android.package-archive');
                    console.info("[UPDATER] Update complete");
            
                    // manually install after 5 seconds
                    setTimeout(() => {
                        this._installfile = _filepath;
                    }, 5000);    
                }
            }
        } 
        catch (error) {
            console.info("[UPDATER] Error during installation process", error);
            this._installfile = _filepath;
        }
    }
    
    public _fleready = false;
    public _download = false;  
    get ProgressType(){
        return (this._download) ? 'determinate' : 'indeterminate';
    }
    
    async OnUpdate(){
        this._transfer.onProgress((event) => {
            this.zone.run(() => {
                this._SetProgress(Math.round((event.loaded / event.total) * 100)/100);
            });
        });
        
        let _download_path = await this.getDownloadPath();
        if (_download_path){
            let _filepath = _download_path + this._version.file;
            console.info("[VERSION] Downloading APK:");
            console.info("  from: '" + this._version.link + "'");
            console.info("  into: '" + _filepath + "'");
            
            this._download = true;
            this._fleready = false;
            this._transfer.download(this._version.link, _filepath, true)
            .then(() => {
                console.info("[VERSION] Download complete");
                this._fleready = true;
                this._download = false;
                
                console.info("[VERSION] Installing APK file from (" + _filepath + ")");
                this.opener.open(_filepath, 'application/vnd.android.package-archive')
                .then(() => {
                    console.info("[VERSION] Update complete");
                    
                    // manually install after 5 seconds
                    setTimeout(() => {
                        this._installfile = _filepath;
                    }, 5000);
                }, (error) => {
                    this._download = false;
                    console.error("[VERSION] Error opening file", error);
                    
                    this._installfile = _filepath;
                });                
            }, (error) => {
                console.error("[VERSION] Download error", error);
                this._download = false;
            });
        }
    }
}
                                                      
@Injectable()
export class updateService implements OnDestroy {
    private _doVersionUrl = 'common/version.php';
    private _doHasServerUrl = 'update/has_update.php';
    private _doRunServerUrl = 'update/run_update.php';
    private _doPreviousUrl = 'update/force_previous.php';
    private _doSanitizeUrl = 'update/run_sanitize.php';

    private _online_subscription = null;

    constructor(private lang: languageService, private sync: syncService, private toast: toastService, private router: Router, private modalCtrl: ModalController, private view: viewService, private platform: Platform) {
        this._online_subscription = this.view.OnOnline.subscribe(
        online => { // check for updates when back from offline
            if (online){    
                this.CheckForUpdates();
            }
        });        
    }

    ngOnDestroy() {
        if (this._online_subscription){
            this._online_subscription.unsubscribe();
            this._online_subscription = null;
        }    
    }

    /************************************/
    /* SERVER UPDATES                   */
    /************************************/

    private _server_current = "0.0.0.0";
    private _server_version = "0.0.0.0";

    get ServerCurrent(){
        return this._server_current;
    }

    get ServerVersion(){
        return this._server_version;
    }

    ServerCheck(){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doHasServerUrl, {}, null)
            .then(data => {
                this._server_current = data['current'];
                this._server_version = data['version'];

                resolve(data['update']);
            }, error => {
                console.error("[UPDATER] Error in http request");
            });
        });
    }

    ServerUpdate(){
        console.info("[UPDATER] Requesting server update..");

        return new Promise((resolve) => {
            this.sync.DoRequest(this._doRunServerUrl, {}, null, false, null, null, null, 15 * 60 * 1000)
            .then((data) => {
                console.info("[UPDATER] Update launched. Running in server.");

                let success = (data['errorcode'] == 0);  
                if (success){
                    resolve(true);
                }
                else {
                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doRunServerUrl + "'");
                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                    resolve(false);
                }
            }, error => {
                console.error("[UPDATER] Error in http request '" + this._doRunServerUrl + "'");
                resolve(false);
            });
        });
    }

    ServerSanitize(){
        console.info("[UPDATER] Requesting server sanitize..");

        return new Promise((resolve) => {
            this.sync.DoRequest(this._doSanitizeUrl, {}, null, false, null, null, null, 15 * 60 * 1000)
            .then((data) => {
                console.info("[UPDATER] Sanitize launched. Running in server.");

                let success = (data['errorcode'] == 0);  
                if (success){
                    resolve(true);
                }
                else {
                    console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + this._doSanitizeUrl + "'");
                    this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
                    resolve(false);
                }
            }, error => {
                console.error("[UPDATER] Error in http request '" + this._doSanitizeUrl + "'");
                resolve(false);
            });
        });
    }

    ServerPrevious(){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doPreviousUrl, {}, null)
            .then((data) => {
                resolve(true);
            }, error => {
                console.error("[UPDATER] Error in http request '" + this._doPreviousUrl + "'");
                resolve(false);
            });
        });        
    }

    ServerComplete(){
        this.router.navigate(["/index"], { 
            queryParams: {
                lang: this.lang.GetLanguage(true)
            } 
        }).then(() => {
            window.location.reload();
        });                
    }

    /************************************/
    /* CORDOVA UPDATES                  */
    /************************************/

    VersionInfo(){
        return new Promise((resolve) => {
            this.sync.DoRequest(this._doVersionUrl).then(
            data => {
                resolve(data);
            }, error => {
                resolve(false);
            });
        })
    }

    CheckForUpdates(){
        return new Promise((resolve) => {
            this.platform.ready().then(async () => {
                await this.VersionCheck();
                resolve(true);
            });
        });
    }
    
    private _inmodal = false;
    private async _CordovaVersionCheck(serverinfo){
        let _server_version = serverinfo[this.platform.is('android') ? 'android_version' : 'ios_version'];

        let _next = _server_version.split(".", 4).map(Number);
        let _this = AppConstants.Version.split(".", 4).map(Number);

        let _mustupdate = false;
        for (let i = 0; i < _this.length; i++) {
            if (_next[i] == _this[i]){
                continue;
            }

            if (_next[i] > _this[i]) {
                _mustupdate = true;
                break;  // next version is greater
            } 

            if (_next[i] < _this[i]) {
                _mustupdate = false;
                break;  // next version is smaller
            } 
        }

        if (_mustupdate){
            const _modal = await this.modalCtrl.create({ 
                component: modalUpdate,
                componentProps: {
                    info: serverinfo
                },
                backdropDismiss: false,
                cssClass: 'modal-update'
            });            
            
            await _modal.present();
            this._inmodal = true;
        }
    }

    private async VersionCheck(){
        if (!this._inmodal){
            this.VersionInfo().then(
            data => {
                console.info("[VERSION] information:");
                console.info("  version (server): " + data['server_version']);
                console.info("  version (local): " + AppConstants.Version);
                console.info("  build: " + AppConstants.Build);
                console.info("  version (ios): " + data['ios_version']);
                console.info("  version (android): " + data['android_version']);
                console.info("  published on: " + data['publish_date']);
                console.info("  mode: " + data['mode']);
                console.info("  server: " + data['server_domain']);
                console.info("  database: " + data['server_database']);
                console.info("  restored: " + data['server_restored']);

                this.sync.SetServerVersion(data['server_version'], data['server_restored']);
                
                if (this.platform.is('cordova')){
                    this._CordovaVersionCheck(data);
                    setTimeout(() => {
                        this.VersionCheck();
                    }, AppConstants.isProduction ? (300 * 1000) : (30 * 1000));     // every 5 minutes (production) or 30 seconds (development)
                }
            });
        }
    }
}
