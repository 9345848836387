import { Component, OnInit, OnDestroy  } from '@angular/core';
import { Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Address } from '@app/modules/model/address';

import { AppConstants } from '@app/app.constants';
import { languageService, languageSupport } from '@app/modules/common/language';
import { geocodeService } from '@app/modules/common/geocode';
import { viewService } from '@app/modules/view';

/************************************/
/* FAR AWAY INFORMATION             */
/************************************/

let GMAP_API_LOADED: boolean = false;

@Component({
    selector: 'ui-away',
    templateUrl:'./ui-away.html',
    styleUrls: [ 
        './ui-away.scss'
    ]
})
export class UiAway extends languageSupport implements OnInit, OnDestroy {
    @Input('center') _center: Address = null;
    
    private _gmapsApiLoaded = new Subject<any> ();
    public gmapsApiLoaded = this._gmapsApiLoaded.asObservable();

    public _mapinfo = {
        zoom: 17,
        accuracy: 0,
        position: {
            lat: 0, 
            lng: 0
        },
        markers: [{
            position: {
                lat: 0,
                lng: 0
            },
            options: {
                draggable: false,
                icon: '/assets/image/marker-place.png'
            }
        }, {
            position: {
                lat: 0,
                lng: 0
            },
            options: {
                draggable: false
            }
        }]
    };

    private _distance: number = 0;
    get Distance(){
        if (this._distance > 1000){
            return (this._distance / 1000).toFixed(2) + " " + this.lang.tr("@kilometres");
        }
        else {
            return this._distance + " " + this.lang.tr("@metres");
        }
    }

    constructor(private lang: languageService, private geocode: geocodeService, public view: viewService, private http: HttpClient) {
        super(lang, null);

        // https://github.com/angular/components/tree/master/src/google-maps
        if (!GMAP_API_LOADED) {
            this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=' + AppConstants.googleApiKey, 'callback').subscribe(
                () => {
                    GMAP_API_LOADED = true;
                    console.info("[GOOGLE MAPS] API loaded.");
                    setTimeout(() => {
                        this._gmapsApiLoaded.next(true);
                    }, 0);
                },
                (error) => {
                    console.error("[GOOGLE MAPS] Error loading API: ", error)
                }
            )
        }
        else {
            console.warn("[GOOGLE MAPS] API already loaded");
            setTimeout(() => {
                this._gmapsApiLoaded.next(true);
            }, 0);
        }
    }
    
    // https://stackoverflow.com/questions/6048975/google-maps-v3-how-to-calculate-the-zoom-level-for-a-given-bounds    
    @ViewChild('googlemap', { static: false }) _googlemap: any;                                      
    private BoundsZoomLevel(coords1, coords2) {
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 21, ZOOM_DEFAULT = 17;

        function getNorthEast(coords1, coords2){
            return { 
                lat: (coords1.lat > coords2.lat) ? coords1.lat : coords2.lat, 
                lng: (coords1.lng > coords2.lng) ? coords1.lng : coords2.lng
            };
        }

        function getSouthWest(coords1, coords2){
            return { 
                lat: (coords1.lat < coords2.lat) ? coords1.lat : coords2.lat, 
                lng: (coords1.lng < coords2.lng) ? coords1.lng : coords2.lng 
            };            
        }
        
        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }
                                      
        let mapDim = { height: 0, width: 0 };
        if (!this._googlemap){
            return ZOOM_DEFAULT;
        }
        else {
            mapDim = {
                height: this._googlemap._elementRef.nativeElement.clientHeight,
                width: this._googlemap._elementRef.nativeElement.clientHeight
            };
            
            if ((mapDim.height == 0) || (mapDim.width == 0)){
                return ZOOM_DEFAULT;
            }
        }

        var ne = getNorthEast(coords1, coords2);
        var sw = getSouthWest(coords1, coords2);

        var latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;

        var lngDiff = ne.lng - sw.lng;
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

        return Math.min(latZoom, lngZoom, ZOOM_MAX) - 2;
    }

    private _locate_subscription = null;
    ngOnInit(){
        this._locate_subscription = this.geocode.OnPositionChanged.subscribe(
        (data) => {
            this.UpdatePosition();
        });
    }
    
    ngAfterViewInit(){
        this.UpdatePosition();
    }

    ngOnDestroy(){
        super.OnDestroy();

        if (this._locate_subscription){
            this._locate_subscription.unsubscribe();
            this._locate_subscription = null;
        }
    }

    @Output('onChange') _onChange = new EventEmitter<any>();    
    private UpdatePosition(){
        if (!this._googlemap){
            setTimeout(() => {
                this.UpdatePosition()
            }, 250);
        }
        else {
            this._distance = this.geocode.DistanceTo(this._center);

            this._mapinfo.markers[0].position = {
                lat: this._center.latitude,
                lng: this._center.longitude
            }
    
            this._mapinfo.markers[1].position = {
                lat: this.geocode.CurrentPosition.latitude,
                lng: this.geocode.CurrentPosition.longitude,
            }
    
            this._mapinfo.position = {
                lng: (this._mapinfo.markers[0].position.lng + this._mapinfo.markers[1].position.lng) / 2,
                lat: (this._mapinfo.markers[0].position.lat + this._mapinfo.markers[1].position.lat) / 2,
            }
    
            this._mapinfo.zoom = this.BoundsZoomLevel(
                this._mapinfo.markers[0].position, 
                this._mapinfo.markers[1].position
            );
    
            this._onChange.emit({
                accuracy: this.geocode.CurrentPosition.accuracy,
                distance: this._distance
            });    
        }
    }

    @Output('onClose') _onClose = new EventEmitter<any>();    
    OnClose(){
        this._onClose.emit();
    }
}
