import { AppConstants } from '@app/app.constants';
import { dataService } from '@app/modules/data';
import { DataObject, ObjectOptions } from './base';

import { Place } from './place';
import { QrCode } from './qrcode';
import { Ticket } from './ticket';
import { Product } from './product';
import { ExtraQrCode } from './extratable'; 
import { ExtraPeriod } from './period';
import { ExtraProduct } from './extraproduct'; 

export interface _Extra {
    place: number;
    status: string;
    name: string;
    price: number;
    photo: {
        url: string;
        b64: any;
    },
    description: string;
    type: string;
    allday: boolean;
    allprd: boolean;
};

interface _ExtraData extends _Extra {
    objid?: number;
    _uuid?: string;
    created?: Date;
};

abstract class ExtraData extends DataObject {
    protected _extra: _ExtraData = {
        place: null,
        status: null,
        name: null,
        price: null,
        photo: {
            url: null,
            b64: null
        },
        description: null,
        type: null,
        allday: true,
        allprd: true
    };

    constructor(table: string, objid: string, data: dataService, objoptions: ObjectOptions){
        super(table, objid, data, objoptions);
        this._extra.created = new Date();
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get created(){
        return this._extra.created;
    }

    get status(): string {
        return this._extra.status;
    }

    set status(value: string){
        if (this.patchValue(this._extra, 'status', value)){
            this.ToUpdate = true;
        }
    }

    get place() : Place {
        return this._children['place'] || null;
    }

    set place(value: Place) {
        if (this.SetChild('place', value, 'place')){
            this.ToUpdate = true;
        }
    }

    get name(): string {
        return this._extra.name;
    }

    set name(value: string) {
        if (this.patchValue(this._extra, 'name', value)){
            this.ToUpdate = true;
        }
    }

    get price(): number {
        return this._extra.price;
    }

    set price(value: number) {
        if (this.patchValue(this._extra, 'price', value)){
            this.ToUpdate = true;
        }
    }

    get photo(): string{
        return this._extra.photo.url;
    }

    set photo(value: string){
        if (this.patchValue(this._extra.photo, 'url', value)){
            this.ToUpdate = true;
        }
    }
   
    get base64(): any {
        return this._extra.photo.b64;
    }

    set base64(value: any){
        if (this.patchValue(this._extra.photo, 'b64', value)){
            this.ToUpdate = true;
        }
    }

    get description(): string {
        return this._extra.description;
    }

    set description(value: string) {
        if (this.patchValue(this._extra, 'description', value)){
            this.ToUpdate = true;
        }
    }

    get type(): string {
        return this._extra.type;
    }

    set type(value: string) {
        if (this.patchValue(this._extra, 'type', value)){
            this.ToUpdate = true;
        }
    }

    get allday(): boolean {
        return this._extra.allday;
    }

    set allday(value: boolean) {
        if (this.patchValue(this._extra, 'allday', value)){
            this.ToUpdate = true;
        }
    }

    get allprd(): boolean {
        return this._extra.allprd;
    }

    set allprd(value: boolean) {
        if (this.patchValue(this._extra, 'allprd', value)){
            this.ToUpdate = true;
        }
    }

    /****************************/
    /* CHILDREN MANAGEMENT      */
    /****************************/

    AddPeriod(child: ExtraPeriod){
        this.AddChild('periods', child, 'extra');
    }

    DelPeriod(child: ExtraPeriod){
        this.DelChild('periods', child, 'extra');
    }

    AddTable(child: ExtraQrCode){
        this.AddChild('tables', child, 'extra');        
    }

    DelTable(child: ExtraQrCode){
        this.DelChild('tables', child, 'extra');
    }

    AddProduct(child: ExtraProduct){
        this.AddChild('products', child, 'extra');       
    }

    DelProduct(child: ExtraProduct){
        this.DelChild('products', child, 'extra');
    }

    /****************************/
    /* CHILD ACCESS             */
    /****************************/

    get periods() : Array <ExtraPeriod>{
        return this._chldlist['periods'] || [];
    }

    get tables() : Array <ExtraQrCode>{
        return this._chldlist['tables'] || [];
    }

    get products() : Array <ExtraProduct>{
        return this._chldlist['products'] || [];
    }

    /****************************/
    /* COMMIT OPERATION         */
    /****************************/

    protected get Change() {
        return (async () => {
            let _base64 = this.base64;
            if (!_base64){
                let _uploadUrl = AppConstants.baseURL + AppConstants.uploadPath;
                if (this.photo && !this.photo.startsWith(_uploadUrl)){
                    this.base64 = await this.uploadTobase64(this.photo);
                }
            }

            return {
                place: this._extra.place,
                status: this._extra.status,
                name: this._extra.name,
                price: this._extra.price,
                photo: {
                    url: this.uploadToMysql(this.photo),
                    b64: this.base64
                },
                description: this._extra.description,
                type: this._extra.type,
                allday: this._extra.allday ? '1':'0',
                allprd: this._extra.allprd ? '1':'0'
            };
        })();
    }

    protected get Depend(){
        return {
            place: { item: this.place, relation_info: { to: 'extras', by: 'place' } }   // this[by -> 'place'][to -> 'extras'] => this
        };
    }

    protected get Children(){
        let _children = [];
        
        for(let _item of this.periods){
            _children.push(_item)
        }

        for (let _item of this.tables){
            _children.push(_item)
        }

        for (let _item of this.products){
            _children.push(_item)
        }

        return _children;
    }
    
    /****************************/
    /* DATA OBJECT              */
    /****************************/

    private _patchData(_extra: _Extra){
        let _toUpdate = false;

        _toUpdate = this.patchValue(this._extra, 'place', _extra['place']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'status', _extra['status']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'name', _extra['name']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'price', _extra['price']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'photo', _extra['photo']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'description', _extra['description']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'type', _extra['type']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'allday', _extra['allday']) || _toUpdate;
        _toUpdate = this.patchValue(this._extra, 'allprd', _extra['allprd']) || _toUpdate;

        return _toUpdate;
    }    
    
    set Data(_extra: _Extra){
        this.patchValue(this._extra, 'created', _extra['created']);
        
        if (this._patchData(_extra)){
            this.ToUpdate = true;
        }
    }

    get Info(){
        return this._extra;
    }

    set Info(value){
        this.DoPatchValues(value);
    }

    private DoPatchValues(_extra: _Extra){
        this._patchData(_extra);

        if (_extra['place']){     // update children: 'place'
            let _objid = _extra['place'].toString();
            this.SetChild('place', new Place(_objid, this.data, this._objoptions), 'place')
        }
        else {
            this.SetChild('place', null, 'place')
        }
    }

    private _ddbb(info): _ExtraData {
        let _extra: _ExtraData = {
            objid: info['objid'] ? parseInt(info['objid']) : null,
            created: new Date(Date.parse(this.mysqlToDateStr(info['created']))),
            status: info['status'],
            place: info['place'] ? parseInt(info['place']) : null,
            name: info['name'],
            price: info['price'] ? parseFloat(info['price']) : null,
            photo: {
                url: this.mysqlToUpload(info['photo']),
                b64: null
            },              
            description: info['description'],
            type: info['type'],
            allday: (info['allday'] == '1'),
            allprd: (info['allprd'] == '1')
        };
        return _extra;
    }

    protected _OnUpdate(info){
        let _extra = this._ddbb(info);

        this.patchValue(this._extra, 'objid', _extra['objid']);
        this.patchValue(this._extra, 'created', _extra['created']);
        this.DoPatchValues(_extra);
        
        if (info['periods']){   // update children: 'periods'
            this.SetChildren <ExtraPeriod> (info['periods'], 'periods', ExtraPeriod, 'extra');
        }

        if (info['tables']){    // update children: 'tables'
            this.SetChildren <ExtraQrCode> (info['tables'], 'tables', ExtraQrCode, 'extra');
        }

        if (info['products']){  // update children: 'products'
            this.SetChildren <ExtraProduct> (info['products'], 'products', ExtraProduct, 'extra');
        }
    }
}

export class Extra extends ExtraData {
    constructor(objid: string, data: dataService, objoptions: ObjectOptions = null){
        super('EXTRA', objid, data, objoptions);
    }

    Copy(store: Array<DataObject> = []): Extra {
        return this._Copy(store) as Extra;
    }

    /****************************/
    /* CLASS MEMBERS            */
    /****************************/

    get status(): string {
        return super.status;
    }

    set status(value: string){
        if (this.status == 'DE'){
            return;     // cannot modify deleted items
        }

        super.status = value;

        if (this.place){
            if ((this.status == 'DE') && (this.ToInsert) && (!this.CopyOf || this.CopyOf.ToInsert)){
                this.place.DelExtra(this);
            }
            else {
                this.place.DoRefresh('EXTRA');
            }    
        }
    }

    get avatar() : string {
        return super.photo || this.avatarPhoto(this.name);
    }
        
    get hasPhoto() : boolean {
        return (super.photo && !this.isAvatarPhoto(super.photo));
    }

    get tables() : Array <ExtraQrCode>{
        if (this.data.qrcode){  // guest mode: no tables included with the extra
            let _tables = new Set(super.tables);

            for(let _extra of this.data.qrcode.extras){
                if (_extra.IsValid && (_extra.extra == this)){
                    _tables.add(_extra);
                }
            }

            return Array.from(_tables)
        }
        else {                  // login mode: tables are included with the extra
            return super.tables;
        }
    }

    get products() : Array <ExtraProduct>{
        let _products = [];
        for(let _product of super.products){
            if (_product.IsValid){
                _products.push(_product);
            }
        }
        return _products;
    }

    /****************************/
    /* CUSTOM METHODS           */
    /****************************/

    get IsValid() {
        let _valid = this.status && (this.status != 'DE');
        if (_valid){
            _valid = !this.products.some(
            (extraproduct) => {
                return !extraproduct.product.IsValid;
            });
        }
        return _valid;
    }

    get IsActive(){
        if (this.status != 'AC'){
            return false;
        }

        if (this.allday){
            return true;
        }

        for(let _period of this.periods){
            if (_period.IsActive){
                return true;
            }
        }

        return false;
    }
        
    get Price(){
        let _price = Number(this.price || 0);
        
        if (this.price !== null){
            let _parts = (Math.abs(_price)).toString().split('.');

            let _int = _parts[0];
            let _dec = (_parts.length > 1) ? (_parts[1] + '0').slice(0, 2) : "00";
            let _str = _price.toFixed(2);
            let _neg = (_price < 0); 

            return {
                Int: _int,
                Dec: _dec,
                Str: _str,
                Neg: _neg
            }    
        }
        return null;
    }

    _CheckApplies(product: Product){
        return this._extra.allprd || this.products.some(
        (_extraproduct) => {
            return (_extraproduct.product == product) || (_extraproduct.product.isgroup && product.IsChild(_extraproduct.product));
        })
    }

    ApplyToTicket(ticket: Ticket){
        let _applies = ((ticket.products.length > 0) && !ticket.IsCancelled);

        // check if table applies
        if (_applies){
            _applies = this.tables.some((_extratable) => {
                return (_extratable.qrcode == ticket.qrcode);
            });
        }

        // check if products applies
        if (_applies){
            _applies = this.allprd || this.products.some(
            (_extraproduct) => {
                return ticket.products.some(
                (_ticketproduct) => {
                    return (_ticketproduct.status == 'AC') && (this._CheckApplies(_ticketproduct.product));
                });
            });
        }

        return _applies;
    }

    ApplyToTable(qrcode: QrCode){
        let _applies = true;

        // check if table applies
        if (_applies){
            _applies = this.tables.some((_extratable) => {
                return (_extratable.qrcode == qrcode);
            });
        }

        return _applies;
    }

    SetProduct(oldproduct: Product, newproduct: Product){
        if (!this.IsValid){
            return null;
        }
        
        let _toUpdate = this.products.some(
        (extraproduct) => {
            return (extraproduct.product == oldproduct)
        });

        if (_toUpdate){
            // updating groups: only update the reference
            if (oldproduct.isgroup){
                for(let _extraproduct of this.products){
                    if (_extraproduct.product == oldproduct){
                        if (newproduct.IsValid){
                            _extraproduct.product = newproduct;
                        }
                        else {  // remove the product 
                            this.DelProduct(_extraproduct);
                        }
                    }
                }

                return null;
            }

            // updating extras: make changes in offer copy
            else {
                let _extra: Extra = this.Copy();
                if (_extra){    // replace the product on the copied offer
                    for(let _extraproduct of _extra.products){
                        if (_extraproduct.product == oldproduct){
                            if (newproduct.IsValid){
                                _extraproduct.product = newproduct;
                            }
                            else {  // remove the product
                                _extra.DelProduct(_extraproduct);
                            }
                        }
                    }
                    _extra.CopyOf = null;
                }
    
                return _extra;    
            }
        }

        return null;    // this offer is not affected
    }
}
