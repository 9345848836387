export class GenericUtils {
    
     // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
    public static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    /* normalices a string (to be used as variable) */ 
    private static _normalize_map = new Map()
    public static Normalize(str){
        if (str){
            let _str = this._normalize_map.get(str);
            if (!_str){
                _str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, "_");
                this._normalize_map.set(str, _str);
            }
            return _str;
        }

        return null;    // no string provided
    }

    /* enables advances logging on console */
    public static VerboseLogging(){
        return true;
    }

    /********************************/
    /* DEEP OBJECT COPY             */
    /********************************/

    public static CopyOject(obj){
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (obj instanceof Date) {  // create new instance for Date entries
            return new Date(obj.getTime());
        }

        let _cloned = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                // Asigna el valor clonado de cada propiedad al nuevo objeto
                _cloned[key] = this.CopyOject(obj[key]);
            }
        }
    
        return _cloned;
    }

    /********************************/
    /* SIMPLE (QUICK) HASH          */
    /********************************/

    public static simpleHash(str){
        let rev = str.split('').reverse().join('');

        function _hash(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
              const char = str.charCodeAt(i);
              hash = (hash << 5) - hash + char;
            }
            return (hash >>> 0).toString(36).padStart(7, '0');        
        }

        return _hash(str) + _hash(rev);
    }

    /********************************/
    /* ARRAY BUFFER SERIALIZATION   */
    /********************************/
    
    public static imageTobase64(url, format='image/png', resize=1.0, quality=0.8) {
        return new Promise <string> ((resolve) =>{
            let _img = new Image();
            _img.onload = () => {
                let _cnv = document.createElement("canvas");
                var _ctx = _cnv.getContext("2d");
    
                _cnv.width = _img.naturalWidth * resize;
                _cnv.height = _img.naturalHeight * resize;
                _ctx.drawImage(_img, 0, 0, _cnv.width, _cnv.height);

                resolve(_cnv.toDataURL(format, quality));
            }

            _img.crossOrigin = "Anonymous";
            _img.src = url;
        });
    }

    public static uint8ArrayToBase64(bytes) {
        let binary = '';
        let length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    
    public static base64ToUint8Array(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    public static arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    public static base64ToArrayBuffer(base64) {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    public static stringToBase64(str) {
        let _arrabuffer = new TextEncoder().encode(str).buffer;
        if (_arrabuffer){
            return GenericUtils.arrayBufferToBase64(_arrabuffer);
        }
        return null;
    }

    public static base64ToString(base64) {
        let _arrabuffer = GenericUtils.base64ToArrayBuffer(base64);
        if (_arrabuffer){
            return new TextDecoder().decode(_arrabuffer);
        }
        return null;
    }
}

export class TaxIdValidators {

    public static ToTaxId(taxid){
        let _taxid = taxid;
        if (taxid){
            _taxid = _taxid.toUpperCase().replace(/[^A-Z0-9]/g, '');
        }
        return _taxid;    
    }

    /*************************/
    /* DNI/CIF VALIDATORS    */
    /*************************/
    
    // https://www.lawebdelprogramador.com/codigo/JavaScript/1992-Validar-un-CIF-NIF-y-DNI.html

    private static _ValidateDNI(dni){
        var lockup = 'TRWAGMYFPDXBNJZSQVHLCKE';
        var valueDni = dni.substring(0, dni.length-1);
        var letra = dni.substr(dni.length-1, 1).toUpperCase();
     
        if (lockup.charAt(valueDni % 23) == letra){
            return true;
        }
    
        return false;    
    }
    
    private static _ValidateCIF_type1(cif){
        var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
        var digits = cif.substr(1, cif.length - 2);
        var letter = cif.substr(0, 1);
        var control = cif.substr(cif.length - 1);
        var sum = 0;
        var i;
        var digit;
    
        if (!letter.match(/[A-Z]/)) {
            return false;
        }
    
        for (i = 0; i < digits.length; ++i) {
            digit = parseInt(digits[i]);
    
            if (isNaN(digit)) {
                return false;
            }
    
            if (i % 2 === 0) {
                digit *= 2;
                if (digit > 9) {
                    digit = Math.floor(digit / 10) + (digit % 10);
                }
    
                sum += digit;
            } else {
                sum += digit;
            }
        }
    
        sum %= 10;
        if (sum !== 0) {
            digit = 10 - sum;
        } else {
            digit = sum;
        }
    
        if (letter.match(/[ABEH]/)) {
            return String(digit) === control;
        }
        if (letter.match(/[NPQRSW]/)) {
            return letters[digit] === control;
        }
    
        return String(digit) === control || letters[digit] === control;
    }

    private static _ValidateCIF_type2(cif){
        var valueCif = cif.substr(1, cif.length-2);
     
        var suma = 0;
         for (let i = 1; i < valueCif.length; i = i+2){
            suma = suma + parseInt(valueCif.substr(i,1));
        }
     
        var suma2 = 0; 
        for (let i=0; i < valueCif.length; i = i+2) {
            let result = parseInt(valueCif.substr(i,1))*2;
            if (String(result).length == 1) {
                suma2 = suma2 + result;
            }
            else {
                suma2 = suma2 + parseInt(String(result).substr(0,1)) + parseInt(String(result).substr(1,1));
            }
        }
     
        suma = suma + suma2;
     
        let unidad = parseInt(String(suma).substr(1,1));
        unidad = 10 - unidad;
        
        if (unidad == 10) {
            unidad = 0;
        }

        return (cif.substr(cif.length-1,1) == String(unidad));
    }

    private static _ValidateCIF(cif){
        if (!cif || cif.length !== 9) {
            return false;
        }
            
        let primerCaracter = cif.substr(0,1);
        if (primerCaracter.match(/^[XYZ]$/)){
            let newcif;
            if(primerCaracter=="X"){
                newcif = cif.substr(1);
            }
    
            if(primerCaracter=="Y"){
                newcif = "1" + cif.substr(1);
            }
    
            if(primerCaracter=="Z"){
                newcif = "2" + cif.substr(1);
            }
    
            return this._ValidateDNI(newcif);
        }

        return this._ValidateCIF_type1(cif) || this._ValidateCIF_type2(cif) || this._ValidateDNI(cif);
    }
    
    public static ValidateTaxId(value){
        let _valid = false;

        let _taxid = this.ToTaxId(value);
        if (_taxid){
            _valid = _valid || this._ValidateDNI(_taxid);
            _valid = _valid || this._ValidateCIF(_taxid);
        }

        return _valid;
    }    
}

export class GlobalMutex {
    private _mutex: Map <string, { locked: boolean; queue: Function[] }> = new Map();

    private _getMutex(key: string): { locked: boolean; queue: Function[] } {
        if (!this._mutex.has(key)) {
            this._mutex.set(key, { locked: false, queue: [] });
        }
        return this._mutex.get(key)!;
    }

    async lock(key: string) {
        const mutex = this._getMutex(key);
        await new Promise<void>((resolve) => {
            if (!mutex.locked) {
                mutex.locked = true;
                resolve();
            } 
            else {
                mutex.queue.push(resolve);
            }
        });
    }

    unlock(key: string) {
        const mutex = this._getMutex(key);
        if (mutex.queue.length > 0) {
            const resolve = mutex.queue.shift();
            resolve!();
        } 
        else {
            mutex.locked = false;
        }
    }
}