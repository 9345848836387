import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

import { languageService } from '@app/modules/common/language'

@Injectable()
export class toastService {
    private _waiting = false;
    
    constructor(private lang: languageService, public toast: ToastController, public loadingCtr: LoadingController) {
        // nothing to do
    }
    
    private _loading = null;
    async ShowWait(message = null, timeout = 5000){
        if (this._loading){
            return;
        }
        
        this._waiting = true;
        this._loading = await this.loadingCtr.create({
            duration: timeout,
            message: this.lang.tr(message || "@loading_wait"),
            translucent: true
        });        

        await this._loading.present();
        if (!this._waiting){
            this.HideWait();
        }
    }
    
    HideWait(){
        setTimeout(async () => {
            if (this._loading){
                await this._loading.dismiss();
            }

            this._loading = null;
            this._waiting = false;
        }, 500);
    }
    
    async ShowAlert(type, text, duration = 5000) {
        const t = await this.toast.create({
            color: type,
            message: text,
            duration: duration
        });
        
        t.present();      
    }
}
