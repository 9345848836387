import { Component, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { languageService, languageSupport } from '@app/modules/common/language';
import { toastService } from '@app/modules/common/toast';
import { syncService } from '@app/modules/sync';

/***********************************/
/* STRIPE SMALL ICON               */
/***********************************/

@Component({
    selector: 'ui-online-paid-icon',
    template: `<i class="fa-brands fa-stripe-s"></i>`,
    styleUrls: []
})

export class UiOnlinePaidIcon {
    // nothing to do
}

/***********************************/
/* STRIPE CONNECT                  */
/***********************************/

@Component({
    selector: 'ui-payment-connect',
    template: `
    <div style="margin: 10px">
        <ion-item *ngIf="AccountStatus" style="margin: 0px; --min-height: 30px; margin-bottom: 10px;" lines="none">
            <ion-icon slot="start" [color]="StatusColor" [name]="StatusIcon"></ion-icon>
            <ion-label [color]="StatusColor">
                {{tr('@place_edit_payonline_status_' + AccountStatus)}}<br/>
                <span class="small">
                    {{tr('@place_edit_payonline_detail_' + AccountStatus)}}
                </span>
            </ion-label>
        </ion-item>    
        <button type="button" style="margin: 2px 0px 2px 0px;" (click)="OnConnect()">
            {{tr('@place_edit_online_connect')}}
        </button>   
        <ion-item style="margin: 5px 0px 0px 0px; --min-height: 30px" lines="none">
            <ion-label color="dark" class="note">
                <span class="note">{{tr('@place_check_online_fees')}}</span>
            </ion-label>
        </ion-item>
    </div>    
    `,
    styleUrls: [ 
        './ui-stripe.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiPaymentConnect extends languageSupport implements OnDestroy  {
    @Input('place') _place = null;
    @Input('args') _args = null;

    get AccountStatus(){
        let _stripe = this._place.stripe;
        if (_stripe){
            return _stripe.status;
        }

        return null; 
    }

    get StatusColor(){
        return (this.AccountStatus == 'AC') ? 'success' : 'danger';
    }

    get StatusIcon(){
        return (this.AccountStatus == 'AC') ? 'checkmark-circle-outline' : 'alert-circle-outline';
    }

    constructor(private lang: languageService, private change: ChangeDetectorRef, private toast: toastService, private sync: syncService){
        super(lang, change);
    }

    ngOnDestroy() {
        super.OnDestroy();
    }
        
    OnConnect(){
        this.toast.ShowWait(null, 15000);

        let _doConnect = 'stripe/connect.php';
        let _getparams = {
            token: this.sync.Session.token,
            place: this._place.objid
        };

        // add the payment type inside the args input argument
        if (this._args){
            for(let _arg in this._args){
                if (this._args[_arg] !== null){     // no not include null parameters (will be converted to 'null' in url)
                    _getparams[_arg] = this._args[_arg];
                }
            }
        }

        this.sync.DoRequest(_doConnect, _getparams, null, true, null)
        .then((data) => {
            if (data['errorcode'] == 0){
                window.location.href = data['redirect'];
            }
            else {
                this.toast.HideWait();
                
                console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + _doConnect + "'");
                this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
            } 
        }), error => {
            console.error("[REQUEST] Error [" + error.status + "] in http request '" + _doConnect + "'");
            this.toast.HideWait();
        }
    }
}

/***********************************/
/* STRIPE LICENSE PAYMENT          */
/***********************************/

@Component({
    selector: 'ui-payment-license',
    template: `
    <button type="button" style="margin: 20px 0px 20px 0px;" (click)="OnPayment()">
        <img src="assets/image/stripe-icon.png" alt="stripe">
        <span style="flex-grow: 1;">
            {{tr('@pay_now')}}<br/>
            <span class='small'>{{tr('@pay_license')}}</span>
        </span>
    </button>       
   `,
    styleUrls: [ 
        './ui-stripe.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiPaymentLicense extends languageSupport implements OnDestroy  {
    @Input('place') _place = null;
    @Input('args') _args = null;

    constructor(private lang: languageService, private change: ChangeDetectorRef, private toast: toastService, private sync: syncService){
        super(lang, change);
    }

    ngOnDestroy() {
        super.OnDestroy();
    }
        
    OnPayment(){
        this.toast.ShowWait(null, 15000);

        let _doPayment = 'stripe/checkout.php';
        let _getparams = {
            token: this.sync.Session.token,
            action: 'LICENSE',
            place: this._place.objid
        };

        // add the payment type inside the args input argument
        if (this._args){
            for(let _arg in this._args){
                if (this._args[_arg] !== null){     // no not include null parameters (will be converted to 'null' in url)
                    _getparams[_arg] = this._args[_arg];
                }
            }
        }

        this.sync.DoRequest(_doPayment, _getparams, null, true, null)
        .then((data) => {
            if (data['errorcode'] == 0){
                window.location.href = data['redirect'];
            }
            else {
                this.toast.HideWait();
                
                console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + _doPayment + "'");
                this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
            } 
        }), error => {
            console.error("[REQUEST] Error [" + error.status + "] in http request '" + _doPayment + "'");
            this.toast.HideWait();
        }
    }
}

/***********************************/
/* STRIPE TICKET PAYMENT          */
/***********************************/

@Component({
    selector: 'ui-payment-ticket',
    template: `
    <button type="button" style="margin: 20px 0px 20px 0px;" (click)="OnPayment()">
        <img src="assets/image/stripe-icon.png" alt="stripe">
        <span style="flex-grow: 1;">
            {{tr('@pay_now')}}<br/>
            <span class='small'>{{tr('@pay_ticket')}}</span>
        </span>
    </button>   
   `,
    styleUrls: [ 
        './ui-stripe.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UiPaymentTicket extends languageSupport implements OnDestroy  {
    @Input('tickets') _tickets = null;
    @Input('args') _args = null;

    constructor(private lang: languageService, private change: ChangeDetectorRef, private toast: toastService, private sync: syncService, private route: ActivatedRoute){
        super(lang, change);
    }

    ngOnDestroy() {
        super.OnDestroy();
    }
        
    OnPayment(){
        this.toast.ShowWait(null, 15000);

        let _doPayment = 'stripe/checkout.php';
        let _getparams = {
            token: this.sync.Session.token,
            action: 'TICKET'
        };

        let _tickets = '';
        for(let _ticket of this._tickets){
            _tickets += _ticket.objid + ","
        }
        _getparams['tickets'] = _tickets.slice(0, -1);

        let _urlValid = this.route.snapshot.queryParamMap.get("validQR");
        if (_urlValid){
            _getparams['validQR'] = _urlValid;
        }
        
        let _urlTable = this.route.snapshot.queryParamMap.get("table");
        if (_urlTable){
            _getparams['table'] = _urlTable;
        }

        if (this._args){
            for(let _arg in this._args){
                if (this._args[_arg] !== null){     // no not include null parameters (will be converted to 'null' in url)
                    _getparams[_arg] = this._args[_arg];
                }
            }
        }

        this.sync.DoRequest(_doPayment, _getparams, null, true, null)
        .then((data) => {
            if (data['errorcode'] == 0){
                window.location.href = data['redirect'];
            }
            else {
                this.toast.HideWait();
                
                console.error("[SERVICE] Error [" +  data['errorcode'] + "] in '" + _doPayment + "'");
                this.toast.ShowAlert('danger', this.lang.tr('@service_request_error_' + data['errorcode']));
            } 
        }), error => {
            console.error("[REQUEST] Error [" + error.status + "] in http request '" + _doPayment + "'");
            this.toast.HideWait();
        }
    }
}